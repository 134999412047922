import React from "react";
import Button from "../../../UI/Button";
import InputFile from "../../../UI/InputFileUpload";
import pdf_logo from '../../../../assets/img/TCG_L_D/pdf_logo.png';
import InputFile2 from "../../../UI/InputFile";

const IdUploadModal = (props) => {


    return (
        <>
            <div
                className={`modal   fade-scale ${props.isModalVisible.show ? ' show ' : ''}`}
                id="uploadpop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
                style={{background: '#00000088'}}
            >
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-body file-upload-body ">
                            <h2 className="d-flex  title pt-2 ">
                                Upload your files
                                <div onClick={props.handleCloseModal} data-bs-dismiss="modal" className="close-btn">
                                    <i className="bi bi-x-circle" />
                                </div>
                            </h2>
                            {/* Uploads Box */}
                            <div className="file-uploads-box text-center p-3 my-3">
                                {props.isFileSelected ? (
                                    <div className="uploaded" >
                                        <img
                                            className="img-fluid"
                                            style={{ maxWidth: '100%', height: '190px' }}
                                            // src={userfile.preview}
                                            src={props.userfile.type === 'application/pdf' ? pdf_logo : props.userfile.preview}

                                            alt={props.userfile.title}
                                        />
                                        {/* for dispaly pdf file name  */}
                                        {props.userfile.type === 'application/pdf' ? (<p>{props.userfile.title}</p>) : ('')}
                                    </div>) : (
                                    <div className="choose-filebody">
                                        <div>
                                            <i className="bi bi-cloud-arrow-up" />
                                        </div>
                                        <div className="file-text">
                                            JPG, PNG, or PDF, smaller than 10MB
                                        </div>
                                        <div className="my-3 choose-items">
                                            <div className="choose-filebody">
                                                <InputFile
                                                    id={`${props.idType}_doc`}
                                                    name={`${props.idType}_doc`}
                                                    type="file"
                                                    accept=".jpg,.jpeg,.png,.pdf"  // Accept only jpg, png, and pdf files
                                                    className="iput-file "
                                                    onChange={props.handleFileChange}
                                                    iconLabel="Choose File"
                                                    labelClassName="choose-file p-2 px-3 cursor-pointer"
                                                />
                                            </div>
                                        </div>
                                        {props.progressbar && <div className="text-center my-1 imgloader ">
                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Animated striped example"
                                                aria-valuenow={75}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div
                                                    className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                                    style={{ width: "100%" }}
                                                ></div>
                                            </div>
                                        </div>}
                                    </div>)}
                            </div>
                            {props.errorMessage && (
                                <div className="error_msg mb-1 mt-0">
                                    {props.errorMessage.txt}
                                </div>
                            )}
                            <div className="row ">
                                <div className="col-6 pe-0">
                                    {!props.isFileSelected ? (<InputFile2
                                        type="file"
                                        className="iput-file d-none"
                                        accept=".jpg,.jpeg,.png,.pdf"  // Accept only jpg, png, and pdf files
                                        id={`${props.idType}_cam`}
                                        name={`${props.idType}_cam`}
                                        onChange={props.handleFileChange}
                                        iconClass="bi bi-camera-fill me-2 ms-0"
                                        iconLabel="Take Photo"
                                        labelClassName="com-button px-1 takephoto  w-100 skipbtn"
                                    />) : (
                                        <Button
                                            className="com-button px-1 takephoto reupload w-100 skipbtn"
                                            onClick={(e) => props.retakeClick(e)}
                                            name="image"
                                            buttonText={
                                                <>
                                                    <i className="bi bi-arrow-repeat me-2 ms-0" /> Re Upload
                                                </>
                                            }
                                        />)}
                                </div>
                                <div className="col-6">
                                    <Button
                                        type="button"
                                        id="fileuplSubmit"
                                        className="com-button w-100"
                                        onClick={(e) => (!props.btnLoader ? props.handleSubmit(e) : null)}
                                        buttonText="Submit"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IdUploadModal;
