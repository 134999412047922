import React from "react";

const Header = () => {
    return (
        <>
            <section className="top-strip text-center py-1  ">
                <div className="container text-white">
                    <i className="bi bi-clock-history" /> Act Before the FCA Moratorium Ends!
                </div>
            </section>
        </>
    )
}
export default Header;