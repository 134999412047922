import React from 'react';
import tcglLogo from "../../../../assets/img/tcgl-logo.svg";
import LogoBasedOnDomain from "../../Layouts/Common/LogoBasedOnDomain";

const Header = () => {
	return (
		<>
			<section className="header-wrap text-center">
				<div className="logo py-3">
					<LogoBasedOnDomain className="logo_img" width="836" height="142"/>
				</div>
			</section>
			<section className="homebanner">
				<div className="container">
					<h2 className="  text-center "> Have you ever taken out a car on finance?</h2>
					<h4 className=" text-center subtext">You could be owed £1,000s in compensation </h4>
					<div className="text-center mt-3">
						<p className="claim-value">Your Potential Claim Value <span className="amount"> <em>£1100*/ vehicle!</em> </span> </p>
					</div>
				</div>
			</section>
		</>
	);
};

export default Header;