export const split_Flow = 
    {
        default:{
            1:'Split_1', 
            2:'Signature',
            3:'Proceed',
            4:'Thankyou'
        },
        TCG_L_C:{
            1:'TCG_L_C', 
            2:'v3/signature',
            3:'v3/car-registration',
            4:'v3/id-upload',
            5:'thankyou'
        },
        TCG_L_B:{
            1:'TCG_L_B', 
            2:'v2/signature',
            3:'v2/car-registration',
            5:'thankyou'
        },
        TCG_L_A:{
            1:'TCG_L_A', 
            2:'v1/signature',
            3:'v1/car-registration',
            4:'v1/id-upload',
            5:'v1/thankyou'
        },
        followup:{
            1:'thankyou',
        },
        TCG_L_C_2:{
            1:'TCG_L_C_2', 
            2:'v3/signature',
            3:'v3/car-registration',
            4:'v3/id-upload',
            5:'thankyou'
        },
        TCG_L_C_3:{
            1:'TCG_L_C_3', 
            2:'v5/signature',
            3:'v5/authorise',
            4:'v5/car-registration',
            5:'v5/id-upload',
            6:'thankyou'
        },
        TCG_L_C_4:{
            1:'TCG_L_C_4', 
            2:'v3_C4/signature',
            3:'v5_C4/car-registration',
            4:'v5_C4/id-upload',
            5:'thankyou'
        }
    }