import { useState } from "react";
import { Api } from "../api/Api";

export const useSkipHandling = () => {
  const [isLoading, setIsLoading] = useState(false);
 const handleSkip = async (
   visitor_parameters,
   data,
   form_data,
   query_string,
   skip_data,
   message_type,
   page_name
  ) => {
    setIsLoading(true);
    const response = await Api.post("v1/data-ingestion-pipeline", {
      visitor_parameters,
      data,
      form_data,
      query_string,
      skip_data,
      message_type,
      page_name
    }).catch(function (error) {
      return {
        data: {
          status: "Success"
        }
      }
    });
    setIsLoading(false);
    return response;
  };
  return { handleSkip, isLoading };
};
