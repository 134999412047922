export const Split_TCG_L_B = [
    {
        heading: "Why might I have a claim?",
        content: "<p>If you purchased a vehicle on finance during, or after 2007 then it is likely that the car finance was mis-sold to you due to the car dealership failing to disclose commission they were paid by the lender for setting up your car finance. Unbeknown to consumers, some lenders allowed car dealerships to increase the interest rate paid by consumers for their car finance, to increase the commission they earned. It is estimated that Lenders made over £8.1billion in commission payments to car dealerships from between 2007 and 2020. *See: <a target='_blank' class='text-break'  href='https://www.fca.org.uk/publication/consultation/cp24-15.pdf'>https://www.fca.org.uk/publication/consultation/cp24-15.pdf</a> Annex 2 S.3,Page18.</p>"
    },
    {
        heading: "How long will my claim take?",
        content: "<p> Where it is identified that your Claim is about a Fixed Fee Commission your Lender is currently expected to provide a response within 8 weeks, but it is taking some Lenders longer than this due to the high volume of Claims they are receiving in this area. Additionally, on 25 October 2024, the Court of Appeal handing down a ruling relating to Fixed Fee Commissions, which may impact how Claims about this type of commission model are progressed. <br /> If your Claim is about a Discretionary Commission Arrangement, your Lender does not currently have to respond to this until after 04 December 2025, due to the Financial Conduct Authority’s ongoing review into detriment caused to consumers by this type of commission model.<br /> The Financial Conduct Authority has indicated that they may have completed their review as early as May 2025 and if so, would seek to end the proposed pause early.</p>"
    },
    {
        heading: "How much could my claim be worth?",
        content: "<p>The amount of compensation you may be entitled to is dependent on several factors, including the amount of finance your lender provided, the date the finance was taken out, the duration of the agreement, the interest rate you paid and the value of the commission payment your Lender made to the car dealership. <br />The Financial Conduct Authority, confirmed in their publication Our work on motor finance – final finding – March 2019 that on a typical motor finance agreement of £10,000, a Discretionary Commission Arrangement typically resulted in consumers being overcharged £1,100 over a four-year agreement.* <br /> *See: <a href='https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf' target='_blank'> https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf </a> s. 2.15, Pg9.</p>"
    },
    {
        heading: "Do I need to use a law firm, or claims management company to make my claim?",
        content: '<p>You do not have to use a law firm, or claims management company to make your claim. You can claim directly yourself for free to your lender, and then the Financial Ombudsman.<br /> Although you can make your claim directly, you may find it preferrable to use a professional representative, like us, to help you manage the claims process due to our expertise in this area, or simply because you do not have the time.</p>'
    },
    {
        heading: "What are your fees?",
        content: "<p>If we are successful in obtaining an offer or payment of Compensation, then you pay us a Success Fee which is calculated based on the amount of Compensation your lender offers per credit agreement. The table below details the Success Fee percentage applicable to different bands of Compensation, and the maximum total Success Fee for each compensation band.</p> <table class='table table-striped table-bordered'><thead><tr><th scope='col'>Compensation</th><th scope='col'>Success Fee Percentage</th><th scope='col'>Maximum Fee Charge</th></tr></thead><tbody><tr><td>£1 - £1,499</td><td>30%</td><td>£420</td></tr><tr><td>£1,500 - £9,999</td><td>28%</td><td>£2,500</td> </tr><tr><td>£10,000 - £24,999</td><td>25%</td><td>£5,000</td></tr><tr><td>£25,000 - £49,999</td><td>20%</td><td>£7,500</td></tr><tr><td>£50,000, or more</td><td>15%</td><td>£10,000</td></tr></tbody></table><p>The percentages and amounts in the table above are exclusive of VAT, which is charged at the prevailing rate.</p><p>If you have an outstanding balance with your Lender they may use all, or part of, your compensation to pay this outstanding balance meaning that you may not receive any cash “in hand”. In this event, our Success Fee will still be calculated against the full compensation amount.</p><p>Example 1: If your Lender makes an offer of £1,000 compensation and you have no outstanding balance with the Lender, your Lender will pay us £1,000. Of this, £360 will be deducted to pay our Success Fee and VAT, and £640 will be paid to you.</p><p>Example 2: If your Lender makes an offer of £1,000 compensation and you have an outstanding balance of £800 with the Lender, your Lender may use the compensation offered to pay the outstanding balance meaning they will only pay us £200. We would use this £200 toward our Success Fee and VAT, which would leave a balance of £160 payable by you.</p>"
    },
    {
        heading: "I was referred to you via a third-party, how are they paid?",
        content: "<p>If you have been introduced to us via a third-party, we will pay them a fee for this introduction. This fee is usually a percentage of our Success Fee for any successful claim. This fee is paid directly from us to the third-party at no cost to you.</p>"
    }
];


export const Split_TCG_L_A = [
    {
        heading: "Why might I have a claim?",
        content: "<p>If you purchased a vehicle on finance during, or after 2007 then it is likely that the car finance was mis-sold to you due to the car dealership failing to disclose commission they were paid by the lender for setting up your car finance. Unbeknown to consumers, some lenders allowed car dealerships to increase the interest rate paid by consumers for their car finance, to increase the commission they earned. It is estimated that Lenders made over £8.1billion in commission payments to car dealerships from between 2007 and 2020. *See: <a target='_blank' class='text-break' href='https://www.fca.org.uk/publication/consultation/cp24-15.pdf'>https://www.fca.org.uk/publication/consultation/cp24-15.pdf</a> Annex 2 S.3,Page18.</p>"
    },
    {
        heading: "How long will my claim take?",
        content: "<p> Where it is identified that your Claim is about a Fixed Fee Commission your Lender is currently expected to provide a response within 8 weeks, but it is taking some Lenders longer than this due to the high volume of Claims they are receiving in this area. Additionally, on 25 October 2024, the Court of Appeal handing down a ruling relating to Fixed Fee Commissions, which may impact how Claims about this type of commission model are progressed. <br /> If your Claim is about a Discretionary Commission Arrangement, your Lender does not currently have to respond to this until after 04 December 2025, due to the Financial Conduct Authority’s ongoing review into detriment caused to consumers by this type of commission model.<br /> The Financial Conduct Authority has indicated that they may have completed their review as early as May 2025 and if so, would seek to end the proposed pause early.</p>"
    },
    {
        heading: "How much could my claim be worth?",
        content: "<p>The amount of compensation you may be entitled to is dependent on several factors, including the amount of finance your lender provided, the date the finance was taken out, the duration of the agreement, the interest rate you paid and the value of the commission payment your Lender made to the car dealership. <br />The Financial Conduct Authority, confirmed in their publication Our work on motor finance – final finding – March 2019 that on a typical motor finance agreement of £10,000, a Discretionary Commission Arrangement typically resulted in consumers being overcharged £1,100 over a four-year agreement.* <br /> *See: <a href='https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf' target='_blank'> https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf </a> s. 2.15, Pg9.</p>"
    },
    {
        heading: "Do I need to use a law firm, or claims management company to make my claim?",
        content: '<p>You do not have to use a law firm, or claims management company to make your claim. You can claim directly yourself for free to your lender, and then the Financial Ombudsman.<br /> Although you can make your claim directly, you may find it preferrable to use a professional representative, like us, to help you manage the claims process due to our expertise in this area, or simply because you do not have the time.</p>'
    },
    {
        heading: "What are your fees?",
        content: "<p>If we are successful in obtaining an offer or payment of Compensation, then you pay us a Success Fee which is calculated based on the amount of Compensation your lender offers per credit agreement. The table below details the Success Fee percentage applicable to different bands of Compensation, and the maximum total Success Fee for each compensation band.</p> <table class='table table-striped table-bordered'><thead><tr><th scope='col'>Compensation</th><th scope='col'>Success Fee Percentage</th><th scope='col'>Maximum Fee Charge</th></tr></thead><tbody><tr><td>£1 - £1,499</td><td>30%</td><td>£420</td></tr><tr><td>£1,500 - £9,999</td><td>28%</td><td>£2,500</td> </tr><tr><td>£10,000 - £24,999</td><td>25%</td><td>£5,000</td></tr><tr><td>£25,000 - £49,999</td><td>20%</td><td>£7,500</td></tr><tr><td>£50,000, or more</td><td>15%</td><td>£10,000</td></tr></tbody></table><p>The percentages and amounts in the table above are exclusive of VAT, which is charged at the prevailing rate.</p><p>If you have an outstanding balance with your Lender they may use all, or part of, your compensation to pay this outstanding balance meaning that you may not receive any cash “in hand”. In this event, our Success Fee will still be calculated against the full compensation amount.</p><p>Example 1: If your Lender makes an offer of £1,000 compensation and you have no outstanding balance with the Lender, your Lender will pay us £1,000. Of this, £360 will be deducted to pay our Success Fee and VAT, and £640 will be paid to you.</p><p>Example 2: If your Lender makes an offer of £1,000 compensation and you have an outstanding balance of £800 with the Lender, your Lender may use the compensation offered to pay the outstanding balance meaning they will only pay us £200. We would use this £200 toward our Success Fee and VAT, which would leave a balance of £160 payable by you.</p>"
    },
    {
        heading: "I was referred to you via a third-party, how are they paid?",
        content: "<p>If you have been introduced to us via a third-party, we will pay them a fee for this introduction. This fee is usually a percentage of our Success Fee for any successful claim. This fee is paid directly from us to the third-party at no cost to you.</p>"
    }
];


export const TCG_L_D = [
    {
        heading: " Why might I have a claim?",
        content: (<> If you purchased a vehicle on finance during, or after 2007 then
            it is likely that the car finance was mis-sold to you due to the
            car dealership failing to disclose commission they were paid by
            the lender for setting up your car finance. Unbeknown to
            consumers, some lenders allowed car dealerships to increase the
            interest rate paid by consumers for their car finance, to increase
            the commission they earned. It is estimated thatLenders made over £8.1billion in commission payments to car dealerships from between 2007 and 2020.
            <a
                target="_blank"
                className="text-break"
                href="https://www.fca.org.uk/publication/consultation/cp24-15.pdf"
            >
                *See:
                https://www.fca.org.uk/publication/consultation/cp24-15.pdf
                 Annex 2 S.3,Page18.
            </a> </>)
    },
    {
        heading: "How long will my claim take?",
        content: <> Where it is identified that your Claim is about a Fixed Fee Commission your Lender is currently expected to provide a response within 8 weeks,
        but it is taking some Lenders longer than this due to the high volume of Claims they are receiving in this area. Additionally,
        on 25 October 2024, the Court of Appeal handing down a ruling relating to Fixed Fee
        Commissions, which may impact how Claims about this type of commission model are
        progressed.
        <br />
        If your Claim is about a Discretionary Commission Arrangement,
        your Lender does not currently have to respond to this until after 04 December 2025,
        due to the Financial Conduct Authority’s ongoing review into detriment caused to consumers by this type of commission model.
        <br />
        The Financial Conduct Authority has indicated that they may have completed their review as early as May 2025 and if so, would seek to end the proposed pause early.</>
    },
    {
        heading: " How much could my claim be worth?",
        content: <>The amount of compensation you may be entitled to is dependent on several factors,
        including the amount of finance your lender provided, the date the finance was taken out, the duration of the agreement, the interest rate you paid and the value of the
        commission payment your Lender made to the car dealership. <br />
        The Financial Conduct Authority, confirmed in their publication Our work on motor finance
        – final finding – March 2019 that on a typical motor finance agreement of £10,000, a
        Discretionary Commission Arrangement typically resulted in consumers being overcharged
        £1,100 over a four-year agreement.* <br />
        *See:{' '}
        <a
          href='https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf'
          target='_blank'>
          https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf
        </a>
        s. 2.15, Pg9.</>
    },
    {
        heading: " Do I need to use a law firm, or claims management company to make my claim?",
        content: <> You do not have to use a law firm, or claims management company to make your claim. You
        can claim directly yourself for free to your lender, and then the Financial Ombudsman.
        <br />
        Although you can make your claim directly, you may find it preferrable to use a
        professional representative, like us, to help you manage the claims process due to our
        expertise in this area, or simply because you do not have the time.</>
    },
    {
        heading: "What are your fees?",
        content: <>
        <p>
            If we are successful in obtaining an offer or payment of Compensation, then you pay us a
            Success Fee which is calculated based on the amount of Compensation your lender offers
            per credit agreement. The table below details the Success Fee percentage applicable to
            different bands of Compensation, and the maximum total Success Fee for each compensation
            band.
          </p>
          <table class='table table-striped table-bordered'>
            <thead>
              <tr>
                <th scope='col'>Compensation</th>
                <th scope='col'>Success Fee Percentage</th>
                <th scope='col'>Maximum Fee Charge</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>£1 - £1,499</td>
                <td>30%</td>
                <td>£420</td>
              </tr>
              <tr>
                <td>£1,500 - £9,999</td>
                <td>28%</td>
                <td>£2,500</td>
              </tr>
              <tr>
                <td>£10,000 - £24,999</td>
                <td>25%</td>
                <td>£5,000</td>
              </tr>
              <tr>
                <td>£25,000 - £49,999</td>
                <td>20%</td>
                <td>£7,500</td>
              </tr>
              <tr>
                <td>£50,000, or more</td>
                <td>15%</td>
                <td>£10,000</td>
              </tr>
            </tbody>
          </table>

          <p>
            The percentages and amounts in the table above are exclusive of VAT, which is charged
            at the prevailing rate.
          </p>
          <p>
            If you have an outstanding balance with your Lender they may use all, or part of, your
            compensation to pay this outstanding balance meaning that you may not receive any cash
            “in hand”. In this event, our Success Fee will still be calculated against the full
            compensation amount.
          </p>
          <p>
            Example 1: If your Lender makes an offer of £1,000 compensation and you have no
            outstanding balance with the Lender, your Lender will pay us £1,000. Of this, £360 will
            be deducted to pay our Success Fee and VAT, and £640 will be paid to you.
          </p>
          <p>
            Example 2: If your Lender makes an offer of £1,000 compensation and you have an
            outstanding balance of £800 with the Lender, your Lender may use the compensation
            offered to pay the outstanding balance meaning they will only pay us £200. We would use
            this £200 toward our Success Fee and VAT, which would leave a balance of £160 payable by
            you.
          </p>
      </>
    },
    {
        heading: " I was referred to you via a third-party, how are they paid?",
        content: <> If you have been introduced to us via a third-party, we will pay them a fee for this
        introduction. This fee is usually a percentage of our Success Fee for any successful
        claim. This fee is paid directly from us to the third-party at no cost to you.</>
    },
]