import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import LogoBasedOnDomain from "../../../Includes/Layouts/Common/LogoBasedOnDomain";
import InputButton from "../../../UI/InputButton";
import Button from "../../../UI/Button";

const BasicQuestions = (props) => {
  const [isYesModalVisible, setIsYesModalVisible] = useState("hide");
  const [checkedQuestionOne, setCheckedQuestionOne] = useState([]);
  const [isCheckAnswerModalVisible, setIsCheckAnswerModalVisible] =
    useState("hide");
  const handleOptions = (e,index) => {
    setCheckedQuestionOne({[index]:true})
    if (e.target.value == "1") {
      setIsYesModalVisible("show");
    } else {
      props.slideChange(e);
    }
  };
  const handleFirstModalSubmit = (e) => {
    setIsYesModalVisible("hide");
    setIsCheckAnswerModalVisible("show");
  };
  const handleSecondModalSubmit = (e) => {
    setIsCheckAnswerModalVisible("hide");
  };
  const handleBackButtonSubmit = (e) => {
    setIsYesModalVisible("hide");
  };
  const radio = props.questionnaire.answers.map((answer, index) => {
    return (
      <div
        className={`option-btn optionBank mb-2 text-center qsnext1 progress-button ${(checkedQuestionOne[index] == true) ? 'check_btn1' : ''}`}
        key={`${index}_${answer.value}`}
      >
        {answer.label}
        <RadioButton
          className="radio"
          labelName={``}
          labelClassName=""
          onClick={(e)=>{handleOptions(e,index)}}
          value={answer.value}
          name={`question_${props.questionnaire.questionId}`}
          id={`question_${props.questionnaire.questionId}_${answer.value}`}
          gtm_question={props.questionnaire.gtmQuestion}
          gtm_trigger={props.questionnaire.gtm_trigger}
          gtm_answer={answer.gtmAnswer}
          clickevent={props.questionnaire.clickevent}
          validation={props.validation}
        />
      </div>
    );
  });

  return (
    <>
      <div className={`slide slide5 ${props.className}`}>
        <div className="form-style">
          <div className="text-center">
            <h2 className="mb-3 title mt-2">{props.questionnaire.question}</h2>
            <div className="wraper-option mb-3 mt-3">{radio}</div>
          </div>
          <div className="pb-2 animate__animated animate__fadeInUp">
            {!props.questionnaire.question == 1 && <Button
              name="back04"
              className="btn center-block back-but sctop"
              id="backStep1"
              buttonText="< Back"
              type="button"
              style={{ float: "none" }}
              onClick={props.previousSlideChange}
            />}
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${isYesModalVisible}`}
        id="Sry_msgpopups1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="Sry_msgpopupsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s">
            <div className="modal-header p-0">
              <h1 className="modal-title fs-5" id="add_dbLabel">
                <LogoBasedOnDomain className="" bg="black" />
              </h1>
            </div>
            <div className="modal-body">
              <div className="cont_flex animate__animated animate__fadeInUp animate__delay-.5s">
                <h2 className="pt-2">Please check your answers</h2>
                <p className="fs-6 fw-semibold">
                  You have confirmed that you were told about the commission the
                  car dealerships would receive. Unfortunately, this means we
                  are unable to pursue a claim against your lenders.
                </p>
                <p className="fs-6">
                  If you would like to change your answer, please click back. If
                  not please click next to proceed.
                </p>
              </div>
            </div>

            <div className="modal-footer m-0">
              <div className="form-navigation m-0 animate__animated animate__fadeInUp animate__delay-.5s">
                    <Button
                      type="button"
                      className="mt-3 btn-comm p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3 progress-button fw-bold"
                      id="question_1_yes_modal"
                      onClick={handleFirstModalSubmit}
                      name={`question_1_yes_modal`}
                      buttonText={`Next >`}
                    />
                  <br />
                    <Button
                      type="button"
                      className="mt-3 btn center-block back-but sctop"
                      id="question_1_yes_back"
                      onClick={handleBackButtonSubmit}
                      name={`question_1_yes_back`}
                      buttonText={`< Back`}
                    />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${isCheckAnswerModalVisible}`}
        id="Sry_msgpopups2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="Sry_msgpopupsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s">
            <div className="modal-header p-0">
              <h1 className="modal-title fs-5" id="add_dbLabel">
                <LogoBasedOnDomain className="" bg="black" />
              </h1>
            </div>
            <div className="modal-body">
              <div className="cont_flex animate__animated animate__fadeInUp animate__delay-.5s">
                <h2 className="pt-2">Sorry</h2>
                <p className="fs-6 fw-semibold">
                  We are unable to process a claim for you, as you don’t meet
                  the eligibility criteria.
                </p>
                <p className="fs-6">
                  We may be able to support you with other claim types and if
                  so, we will be in touch using the email address you have
                  provided.
                </p>
                <p className="fs-6">
                  You can opt out of receiving communications from us at any
                  time by sending us an email to:{" "}
                  <a href="mailto:dataprotection@theclaimsguyslegal.com">
                    dataprotection@theclaimsguyslegal.com
                  </a>
                </p>
              </div>
            </div>
            <div className="modal-footer m-0">
              <div className="form-navigation m-0 animate__animated animate__fadeInUp animate__delay-.5s">
                    <Button
                      type="button"
                      className="btn center-block back-but sctop"
                      id="question_1_yes_back"
                      onClick={handleSecondModalSubmit}
                      name={`question_1_yes_back`}
                      buttonText={`< Back`}
                    />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicQuestions;
