import React, { useState, useRef } from "react";
import LogoBasedOnDomain from "../Common/LogoBasedOnDomain";
import RadioButtonV2 from "../../../UI/RadioButtonV2";
import Danger from "../../../../assets/img/TCG_L_D/danger.png";
import Button from "../../../UI/Button";
import TextField from "../../../UI/TextField";
import { useForm } from "react-hook-form";
import { nameValidate, preNameDuplicationCheck, slideValidation, profanityValidation } from "../../../../Utility/Validations";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const PreviousSurnameModel = (props) => {
  const scrollToPreSn = useRef([]);
  const [modelStatus, setModelStatus] = useState('show');
  const [previousNameSection, setPreviousNameSection] = useState({ 'prevName1': false, 'prevName2': false, 'prevName3': false });
  const previousNameCheckArr = { 'prevName2': [1], 'prevName3': [1, 2] };
  const { register, errors, trigger, setError, clearErrors, getValues, setValue } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const PrevSurnameClick = (e) => {
    if (e.target.value == 'no') {
      props.setPreviousSurnameModelShow(false);
      // setTimeout(() => {
        props.setPreviousAddressModelShow(true)
      // }, 1000);
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'previous surname',
        answer: '',
      });
    } else {
      setPreviousNameSection(prevState => ({
        ...prevState,
        prevName1: true,
      }));
    }
  }

  const AddMoreSurname = async () => {
    const isValid = await preNameDuplicationCheck(getValues, setError, clearErrors);
    if (!isValid) return;
    var nextSection = Object.entries(previousNameSection).find(([key, value]) => value === false);
    if (nextSection) {
      var addValidationArr = [];
      previousNameCheckArr[nextSection[0]].forEach(element => {
        addValidationArr.push(`txtPrevLName_${element}`);
      });
      const validationCheck = await slideValidation(addValidationArr, trigger);
      const profanityCheck = profanityFieldsCheck();
      if (profanityCheck && validationCheck == 'Success') {
        setPreviousNameSection((prevState) => ({
          ...prevState,
          [nextSection[0]]: true,
        }));
        setTimeout(() => {
          scrollToPreSn.current.scrollIntoView({
            behavior: "smooth",
            block: 'end'
          });
        }, 50);
      }
    }
  }
  const checkDuplicateExtraPreviousName = () => {

  }

  const SubmitSurname = async () => {
    const profanityCheck = profanityFieldsCheck();
    if (!profanityCheck) return false;
    const validationCheck = await slideValidation(['txtPrevLName_1'], trigger);
    if (validationCheck != 'Success') return false;
    const isValid = await preNameDuplicationCheck(getValues, setError, clearErrors);
    if (!isValid) return;
    if (profanityCheck && validationCheck == 'Success') {
      const value = getValues();
      setModelStatus('hide');
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'previous surname',
        answer: '',
      });

      props.handlePreviousDataSubmit('', value)
      setTimeout(() => {
        props.setPreviousAddressModelShow(true)
      }, 1000);
    }
  }

  const profanityFieldsCheck = () => {
    const profanityFields = profanityValidation(['txtPrevLName_1', 'txtPrevLName_2', 'txtPrevLName_3'], getValues);
    if (profanityFields != 'success') {
      setError(profanityFields, { message: "Please Enter valid Name" });
      return false;
    } else {
      return true;
    }
  }
  return (
    <>
      <div className={`modal fade bg-dark ${modelStatus}`} id="prevSurname" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered   modal-md">
          <div className="modal-content">
            <div className="modal-header py-3 text-center bg-pophead text-center justify-content-center">
              <LogoBasedOnDomain height="30" />
            </div>
            <div className="modal-body  creditpopup">
              <form>
                <div>
                  <div className="text-center">
                    <img height="50" src={Danger} alt="" />
                    <h5 className=" py-2 lh-base">
                      Did you have any previous surname when you took car finance? [ <small><i>for the last 20 years</i> </small> ]</h5>
                  </div>

                  <div className="row mt-4">
                    <div className="col-6">
                      <div className="optionBtn blackoption  drop-in    w-100   fs-6 text-start pointer shadow-lg ">
                        <RadioButtonV2
                          className="radio"
                          id="prevYes"
                          name="previousSurname"
                          value="yes"
                          onClick={PrevSurnameClick}
                          labelName="Yes"
                          divClass="pe-2"
                          spanclass="me-1"
                          labelClassName="d-flex align-items-center  p-lg-2 p-2"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="optionBtn blackoption p-0  drop-in  w-100   fs-6 text-start pointer shadow-lg ">
                        <RadioButtonV2
                          className="radio"
                          id="prevNo"
                          name="previousSurname"
                          value="no"
                          onClick={PrevSurnameClick}
                          labelName="No"
                          divClass="pe-2"
                          spanclass="me-1"
                          labelClassName="d-flex align-items-center p-lg-2 p-2"
                        />
                      </div>
                    </div>
                  </div>

                  {previousNameSection.prevName1 && <div className="mt-3 prevsurname1 modelInput">
                    <p>Previous Surname 1</p>
                    <div className="mb-3">
                      <TextField
                        type="text"
                        className="form-control py-3 shadow-sm"
                        placeholder="Enter Previous Surname"
                        name={`txtPrevLName_1`}
                        id={`txtPrevLName_1`}
                        autocomplete="off"
                        onkeypress={(e) => nameValidate(e)}
                        onBlur={(e) => checkDuplicateExtraPreviousName(e)}
                        onChange={(e) => { clearErrors(`txtPrevLName_1`) }}
                        validation={register({
                          required: "Please Enter Surname",
                          minLength: {
                            value: 3,
                            message: "Please Enter Valid Surname",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message: "Your Name is Invalid. Please Recheck",
                          },
                        })}
                        validationMsg={errors[`txtPrevLName_1`]?.message}
                      />
                    </div>
                  </div>}

                  {previousNameSection.prevName2 && <div className="mt-3 prevsurname1 modelInput">
                    <p>Previous Surname 2</p>
                    <div className="mb-3">
                      <TextField
                        type="text"
                        className="form-control py-3 shadow-sm"
                        placeholder="Enter Previous Surname"
                        name={`txtPrevLName_2`}
                        id={`txtPrevLName_2`}
                        autocomplete="off"
                        onkeypress={(e) => nameValidate(e)}
                        onBlur={(e) => checkDuplicateExtraPreviousName(e)}
                        onChange={(e) => { clearErrors(`txtPrevLName_2`) }}
                        validation={register({
                          required: "Please Enter Surname",
                          minLength: {
                            value: 3,
                            message: "Please Enter Valid Surname",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message: "Your Name is Invalid. Please Recheck",
                          },
                        })}
                        validationMsg={errors[`txtPrevLName_2`]?.message}
                      />
                    </div>
                  </div>}

                  <div className="mb-3">
                    {previousNameSection.prevName3 &&
                      <div className="mt-3 surname1 modelInput">
                        <p>Previous Surname 3</p>
                        <div className="mb-3">
                          <TextField
                            type="text"
                            className="form-control py-3 shadow-sm"
                            placeholder="Enter Previous Surname"
                            name={`txtPrevLName_3`}
                            id={`txtPrevLName_3`}
                            autocomplete="off"
                            onkeypress={(e) => nameValidate(e)}
                            onBlur={(e) => checkDuplicateExtraPreviousName(e)}
                            onChange={(e) => { clearErrors(`txtPrevLName_3`) }}
                            validation={register({
                              required: "Please Enter Surname",
                              minLength: {
                                value: 3,
                                message: "Please Enter Valid Surname",
                              },
                              pattern: {
                                value: /^[a-zA-Z\s]+$/,
                                message: "Your Name is Invalid. Please Recheck",
                              },
                            })}
                            validationMsg={errors[`txtPrevLName_3`]?.message}
                          />
                        </div>
                      </div>}
                    {(previousNameSection.prevName1 && !previousNameSection.prevName3) &&
                      <div className="mt-2 text-end addmorediv">
                        <Button
                          type="button"
                          className="btn btn-dark"
                          id="addSurname1"
                          onClick={AddMoreSurname}
                          buttonText="+ Add More"
                        />
                      </div>}
                  </div>

                  {previousNameSection.prevName1 && <div className="continue-btn" ref={scrollToPreSn}>
                    <Button
                      type="button"
                      className="btn-comm2 p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3 fw-bold"
                      id="SurnameContinue"
                      onClick={SubmitSurname}
                      buttonText="Continue"
                    />
                  </div>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PreviousSurnameModel;