import React, { useState } from 'react';
import { Salutation } from "../../../../Constants/Constants";
import TextField from "../../../UI/TextField";
import SelectBox from '../../../UI/SelectBox';
import { nameValidate, checkDuplicateExtraPreviousName } from '../../../../Utility/Validations';
const PreviousName = (props) => {
  //txtPrevLName is the field used for Surname field
  const checkNextButtonStaus = () => {
    if (props?.getValues) {
      const values = props.getValues();
      if (values[`txtPrevLName_${props.preCount}`].length >= 3) {
        props?.setShowNextButton && props.setShowNextButton(true);
      }
    }
  }
  return (
    <>
      <fieldset className='field-set mb-3'>
        <legend className="mb-1 fs-6">{props.prevName}</legend>
        {/* <div className="personal_detail"> */}
        <div className="">
          {/* <div className="field pb-3">
          <label className="mb-1 fs-6">Title</label>
          <SelectBox
            className="form-select select1"
            OptionValue={Salutation}
            name={`txtPrevSalutation_${props.preCount}`}
            id={`txtPrevSalutation_${props.preCount}`}
            onChange={checkNextButtonStaus}
            myRef={props.validation({
              required: "Select Title",
              message: "Please Select Title",
            })}
            validationMsg={props.validationMsg[`txtPrevSalutation_${props.preCount}`]?.message}
          />
        </div> */}
          {/* <div className="field pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
          <label className="mb-1 fs-6">First name</label>
          <div className="field-icon">
            <TextField
              type="text"
              className="capitalised-input"
              placeholder="First Name"
              name={`txtPrevFName_${props.preCount}`}
              id={`txtPrevFName_${props.preCount}`}
              autocomplete="off"
              onChange={checkNextButtonStaus}
              validation={props.validation({
                required: "Please Enter First Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid First Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}
              validationMsg={props.validationMsg[`txtPrevFName_${props.preCount}`]?.message}
            />
          </div>
        </div> */}
          <div className="field pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
            {/* <label className="mb-1 fs-6">Last name</label> */}
            <div className="field-icon">
              <TextField
                type="text"
                className="capitalised-input"
                placeholder="Surname"
                name={`txtPrevLName_${props.preCount}`}
                id={`txtPrevLName_${props.preCount}`}
                autocomplete="off"
                onkeypress={(e) => nameValidate(e)}
                onBlur = {(e) => checkDuplicateExtraPreviousName(e, props.setError)}
                onChange={(e) => { checkNextButtonStaus(); props.clearErrors(`txtPrevLName_${props.preCount}`) }}
                validation={props.validation({
                  required: "Please Enter Surname",
                  minLength: {
                    value: 3,
                    message: "Please Enter Valid Surname",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid. Please Recheck",
                  },
                })}
                validationMsg={props.validationMsg[`txtPrevLName_${props.preCount}`]?.message}
              />
              {/* <input type="text" name="lname" className="capitalised-input" placeholder="Last name" /> */}
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
}

export default PreviousName;