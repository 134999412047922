import React, { useEffect, useState } from "react";
import GetLenders from "../../../../Utility/GetLenders";
import CheckBoxV2 from "../../../UI/CheckBoxV2";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";
import Select from "react-select";
import Button from "../../../UI/Button";

const LenderList = (props) => {
  const { getLenderList, isLoading } = GetLenders();
  const [banks, setBanks] = useState([]);
  const [remainingBanks, setRemainingBanks] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [additionalBanks, setAdditionalBanks] = useState([]);
  const [clickIndex, setClickIndex] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  // const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await getLenderList();
      if (response) {
        const parsedBanks = response;
        setBanks(parsedBanks.slice(0, 4));
        props.setRemainingBanks(response);
        const sortedRemainingBanks = parsedBanks
          .slice(4)
          .sort((a, b) => a.label.localeCompare(b.label));
        setRemainingBanks(sortedRemainingBanks);
      }
    })();
  }, []);

  const handleCheckboxChange = (event) => {
    props.setValidationError(false);
    const { id, checked } = event.target;
    const bank =
      banks.find((bank) => `${bank.id}` === id) ||
      additionalBanks.find((bank) => `${bank.id}` === id);

    if (checked && bank) {
      setSelectedBanks((prev) => [...prev, bank]);
      setAdditionalBanks((prev) => [...prev, bank]);
    
    } else {
      if(bank.value == 1 || bank.value == 3 || bank.value == 4 || bank.value == 54){
      setSelectedBanks((prev) =>
        prev.filter((selectedBank) => selectedBank.id !== bank.id)
      );
      setAdditionalBanks((prev) =>
        prev.filter((selectedBank) => selectedBank.id !== bank.id)
      );
    }
    else{
      setSelectedBanks((prev) =>
        prev.filter((selectedBank) => selectedBank.id !== bank.id)
      );
      setAdditionalBanks((prev) =>
        prev.filter((selectedBank) => selectedBank.id !== bank.id)
      );
      setRemainingBanks((prev) => {
        const updatedBanks = [...prev, bank];
        updatedBanks.sort((a, b) => a.label.localeCompare(b.label));
        return updatedBanks;
      });
      
    }
  }
  
  };

  const handleSelectChange = (selectedOption) => {
    props.setValidationError(false);
    const selectedBankId = parseInt(selectedOption.value, 10);
    const selectedBank = remainingBanks.find(
      (bank) => bank.id === selectedBankId
    );
    if (selectedBank) {
      setAdditionalBanks((prev) => [...prev, selectedBank]);
      setRemainingBanks((prev) =>
        prev.filter((bank) => bank.id !== selectedBankId)
      );
      setSelectedBanks((prev) => [...prev, selectedBank]);
    }
    setTimeout(() => setSelectedOption(null), 0);
    
  };

  const showIndex = (e, index) => {
    e.preventDefault();
   setClickIndex(clickIndex == index ? null : index);
  }

  const handleSubmit = (e) => {
    if (selectedBanks.length === 0) {
      props.setValidationError(true);
    } else {
      props.setValidationError(false);
      localStorage.setItem(
        "selectedBanks",
        JSON.stringify(
          selectedBanks.map((bank) => ({
            bank_code: bank.bank_code,
            id: bank.id,
            value: `${bank.id}`,
            label: bank.label,
          }))
        )
      );
      // GTMDataLayer({
      //   clickEvent: "slideclick",
      //   question: "lenders",
      //   answer: "",
      // });
      props.setBanks(selectedBanks);
      props.slideChange(e.target.name);
    }
  };


  return (
    <>
      <div
        id="slide-1"
        className="col-12 top_minus_1 mt-lg-0 mt-md-0 mt-sm-0 mt-3"
      >
        <fieldset className="ques1 drop-in-2">
          <legend className="drop-in-2 fw-bold fs-5  tex-lg-start text-md-start text-sm-center text-center">
            Select all lenders you've taken out finance with prior to 2021.
          </legend>
          <div className="row">
            {banks.map((bank) => (
              <div className="col-lg-12 mb-2 text-center" key={bank.id}>
                <div className="optionBtn p-0  w-100 drop-in-3 fw-bold fs-6 text-start pointer">
                  <CheckBoxV2
                    className="radio"
                    name="lender"
                    value={bank.label}
                    checked={selectedBanks.some(
                      (selectedBank) => selectedBank.id === bank.id
                    )}
                    onChange={handleCheckboxChange}
                    dataId={`${bank.id}`}
                    labelClassName="d-flex align-items-center p-lg-3 p-md-3 p-sm-3 p-2 px-3 "
                    labelText={
                      <>
                        <div>
                          <span className="me-3"></span>
                        </div>
                        <div>{bank.label}
                        </div>
                      </>
                    }
                  />
                </div>
              </div>
            ))}

            <div className="mb-2 mt-2">
              <Select
                className="lender-select"
                options={remainingBanks}
                value={selectedOption}
                name="lender"
                id="selectanother"
                placeholder="Another lender..."
                onChange={handleSelectChange}
              />
            </div>

            {props.validationError && (
              <div className="col-lg-12 text-center">
                <div className="">
                  <span className="error_msg">Select at least one lender</span>
                </div>
              </div>
            )}
          </div>
        </fieldset>
        <div className="mt-lg-3 mt-md-3 mt-sm-2 mt-2">
          <div className={additionalBanks.length > 0 ? `${`animate`}` : ''}>
            <Button
              type="button"
              className="nextbtn2 btnside p-2 w-100 py-3 fw-bold shadow-lg"
              id="formSubmitBtn"
              name="formSubmitBtn"
              onClick={(e) => {
                handleSubmit(e);
              }}
              buttonText="Register My Claim"
              spanText="bi bi-arrow-right pe-none"
            />
          </div>
        </div>
        {additionalBanks.length > 0 &&
          <fieldset className="ques1 drop-in-2 mt-3">
               <div><b >Lender's Selected :</b> </div>

           <p className="font-11 mb-3 mt-2"> Your lender may be part of a group - to view grouped lenders use the dropdown next to the lender name. By proceeding you are giving us instruction to investigate and submit claims to all lenders in each group. </p>
         
            {additionalBanks.map((bank, index) => (
              <div
                className="col-12 d-inline selectoptions"
                key={bank.id}
              >
                <div id="1" className="optionBtn otheroption  p-0">
                  <CheckBoxV2
                    className="test"
                    name="lender"
                    value={bank.label}
                    checked={selectedBanks.some(
                      (selectedBank) => selectedBank.id === bank.id
                    )}
                    onChange={handleCheckboxChange}
                    dataId={`${bank.id}`}
                    labelClassName="otherlend d-flex align-items-center p-2"
                    labelText={
                      <>
                        <span className="me-2"></span>
                        <p className="plender">{bank.label}</p>
                        {bank.sublender.length > 0 &&
                        
                          <div className="selected-defendant group-active">
                            <Button className="group-button" buttonText="Group" onClick={(e) => showIndex(e, index)} />
                          </div>
                        }

                      </>
                    }
                  />
                </div>
                {bank.sublender.length > 0 &&
                  <>
                    {/* <div className="selected-defendant group-active">
                        <Button className="group-button" buttonText="Group" onClick={(e) => showIndex(e, index)} />
                      </div> */}
                    {clickIndex === index && (
                      <div className="sublender-div">
                        <p className="b-0"><b>Lenders in this group:</b></p>
                        {bank.sublender.map((item, idx) => (
                          <div key={idx}>
                            <p>{item.label}</p>
                          </div>
                        ))}
                      </div>
                    )}

                  </>}
              </div>
            ))}
          </fieldset>}
      </div>
    </>
  );
};
export default LenderList;
