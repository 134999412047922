import React, { useEffect, useState } from "react";
import tcglLogo from "../../../../assets/img/tcgl-logo.svg"
import spinner from "../../../../assets/img/infinite-spinner.svg"
import lock from "../../../../assets/img/lock.svg";
import Email from "../../../UI/Email.js"
import Button from "../../../UI/Button.js";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import LogoBasedOnDomain from "../../Layouts/Common/LogoBasedOnDomain.js";
import InputButton from "../../../UI/InputButton";
import { getBrandName } from "../Common/BrandBasedOnDomain.js";
import GTMDataLayer from "../../../../Utility/GTMDataLayer.js";

const EmailModal = (props) => {
    const { emailValidation } = useEmailValidation();
    const [loading1, setLoading1] = useState(true);
    const [validation, setValidaton] = useState(false);
    const brandName = getBrandName();
    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading1(false);
        }, 7000);
        return () => clearTimeout(timeout);
    }, []);

    const emailValidCheck = async (email) => {
        if (email !== "") {
            const getEmailValidation = await emailValidation(
                email,
                props.uuid
            );
            if (getEmailValidation.data.status_code === 1) {
                props.setEmailError("");
                setValidaton(true);
                
                return 1;
            } else {
                props.setEmailError("Invalid email.");
                return false;
            }
        }
    };
    const handleNextChange = async(e) => {
        let values = props.getValues();
        let email = values.txtEmail;
        let emailValidation = await emailValidCheck(email);
        if(emailValidation){
            GTMDataLayer({
                clickEvent: 'slideclick',
                question: 'email address',
                answer: '',
              });
            props.slideChange(e);
        }else {
                props.setError("txtEmail", {
                    type: "manual",
                    message: "Please Enter Valid Email",
                });
            }
    }
    return (
        <>
            <div
                className={`modal fade ${props.showSlide2}`}
                id="emailpopup"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content form-modal">
                        <div className="modal-header text-center">
                            {/* <img src={tcglLogo} alt="" /> */}
                            <LogoBasedOnDomain className="" bg="" />

                        </div>
                        <div className="modal-body">
                            {/* Loader */}
                            {loading1 && <div className="loader-wrap loader2 text-center ">
                                <div className="mt-5 mb-5">
                                    <img
                                        className="mb-2"
                                        height={80}
                                        src={spinner}
                                        alt=""
                                    />
                                    <h4> Processing your details...</h4>
                                    <h6 className="mt-3">
                                        Please wait. This might take a few moments.
                                    </h6>
                                </div>
                            </div>}
                            {/* Loader */}

                            {!loading1 && <div id="emailpop" >
                                <h3>Before we continue...</h3>
                                <p>
                                    Please provide your email below so that we can keep you updated on
                                    your potential refund.
                                </p>
                                <div className="input-group mb-3">
                                    <label htmlFor="" className="form-label w-100">
                                        Email address
                                    </label>
                                    <Email
                                        type="email"
                                        name="txtEmail"
                                        id="txtEmail"
                                        className="form-control d-block"
                                        placeholder="Email Address"
                                        onBlur={ (e) => emailValidCheck(e.target.value)}
                                        onChange={props.handleChange}
                                        validation={props.validation({
                                            required: "Please Enter Valid Email Address",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Invalid Email Address",
                                            },
                                        })}
                                    // validationMsg={
                                    //     props.validationMsg.txtEmail && props.validationMsg.txtEmail.message
                                    // }
                                    />
                                    <span className="input-group-text">
                                        <i className="bi bi-lock-fill" />
                                    </span>

                                </div>
                                {props.validationMsg.txtEmail && (
                                    <span className="address1_error error_msg">{props.validationMsg.txtEmail && props.validationMsg.txtEmail.message}</span>
                                )}

                                <div className="my-3 text-center">
                                    <Button
                                        type="button"
                                        className="button next-btn slide_2 w-100 green_color_but"
                                        id=""
                                        value="Next"
                                        name="slide2"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        buttonText="Continue"
                                        onClick={(e) => handleNextChange(e)}
                                    />
                                    <Button
                                        name="back1"
                                        className=" mt-3 bck-btn"
                                        id="backStep1"
                                        buttonText="Back"
                                        type="button"
                                        style={{ float: "none" }}
                                        onClick={props.previousSlideChange}
                                    />

                                </div>
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <p>
                                <small>
                                    <span>
                                        <img src={lock} alt="" />{" "}
                                    </span>
                                    Your personal information will be treated carefully in accordance
                                    with our Privacy Policy. We {brandName}, a trading style of  The Claims Guys Legal, will contact you about claim
                                    opportunities using the contact details you provide. You can opt out
                                    of receiving communications from us at any time by sending an email
                                    to{" "}
                                    <a href="mailto: dataprotection@theclaimsguyslegal.com">
                                        dataprotection@theclaimsguyslegal.com.
                                    </a>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EmailModal;