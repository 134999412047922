import React from 'react';
import Logo from "../../../../assets/img/logo_v3.png";
import Review from "../../../../assets/img/review.svg";
import Star from "../../../../assets/img/star.png";
import StarHalf from "../../../../assets/img/star-half.png";
import LogoBasedOnDomain from '../Common/LogoBasedOnDomain';


const Header = () => {
	return (
		<>
			 <header id="header_shrink">
            <div className="container m-auto">
            <div className="row">
               <div className="col-12">
                  <LogoBasedOnDomain  className="logo_img" bg="black" width="836" height="142"/>
               </div>
            </div>
            </div>
         </header>
         <div className="header-rating">
            <div className="reviews-io header-icons-rating">
              <div className="reviews-io-rating">
                <div className="reviews-io-rating--link">
                  <img className="reviews-io-rating--logo" src={Review} alt="REVIEWS.io Logo" fetchpriority='hight'/>
                </div>
                <div className="reviews-io-stars">
                  <img src={Star} alt="" fetchpriority='hight'/>
                  <img src={Star} alt="" fetchpriority='hight'/>
                  <img src={Star} alt="" fetchpriority='hight'/>
                  <img src={Star} alt="" fetchpriority='hight'/>
                  <img src={StarHalf} alt="" fetchpriority='hight'/>
                </div>
              </div>
            </div>
          </div>
		</>
	);
};

export default Header;