import React, { useState } from "react";
import { Split_TCG_L_A } from "../../../../Constants/SplitFaq";
const AccordionItem = ({ title, content, index, openIndex, setOpenIndex }) => {
    const isOpen = index === openIndex;

    const toggleAccordion = () => {
        setOpenIndex(isOpen ? null : index);
    };

    return (
        <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id={`flush-heading${index}`}>
                    <button
                        className={`accordion-button ${isOpen ? "" : "collapsed"}`}
                        type="button"
                        onClick={toggleAccordion}
                        aria-expanded={isOpen}
                        aria-controls={`flush-collapse${index}`}
                    >
                        {title}
                    </button>
                </h2>
                <div
                    id={`flush-collapse${index}`}
                    className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
                    aria-labelledby={`flush-heading${index}`}
                >
                    <div className="accordion-body">
                    <span dangerouslySetInnerHTML={{ __html: content }}></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Accordion = () => {
    const [openIndex, setOpenIndex] = useState(null);


    return (
        <>

            {Split_TCG_L_A.map((item, index) => (
                <AccordionItem
                    key={index}
                    index={index}
                    title={item.heading}
                    content={item.content}
                    openIndex={openIndex}
                    setOpenIndex={setOpenIndex}
                />
            ))}
        </>
    );
};

export default Accordion;
