import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
// import "../../../assets/css/TheClaimsGuys/custom.scss";
import logoWhite from '../../../assets/img/logo-white.png';
// import "../../assets/css/TheClaimsGuys/custom.scss";
import tcgl from '../../../assets/img/CSPC096/tcgl-logo.svg';
import footerlogo from "../../../assets/img/CSPC096/footer-logo.png";
import successtick from "../../../assets/img/CSPC096/success-tick.gif";
import Accordion from '../../Pages/Thankyou/Accordion';
import { getBrandName } from "../../Includes/Layouts/Common/BrandBasedOnDomain";
import LogoBasedOnDomain from '../../Includes/Layouts/Common/LogoBasedOnDomain';
import Footer from '../../Includes/Layouts/TCG_L_C/Footer';
import "../../../assets/Thankyou/css/Thankyou.scss";
// import "../../../assets/css/TCG_L_B/custom.scss"

const Thankyou = () => {
  localStorage.clear();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookie, setShowCookie] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);
  const brandName = getBrandName();
  const modalClick = (clickValue) => {
    switch (clickValue) {

      case "showPrivacy": {
        setShowPrivacy(true);
        break;
      }
      case "exitPrivacy": {
        setShowPrivacy(false);
        break;
      }
      case "showCookie": {
        setShowCookie(true);
        break;
      }
      case "exitCookie": {
        setShowCookie(false);
        break;
      }
      case "showComplaint": {
        setShowComplaint(true);
        break;
      }
      case "exitComplaint": {
        setShowComplaint(false);
        break;
      }
    }
  };
  return (
    <div className='TCG_L_B' id="signaturesect">
      {/* <header>
        <section className="header-wrap text-center">
          <div className="logo py-3">
            <img height="40" src="dist/img/tcgl-logo.svg" alt="" />
          </div>
        </section>
      </header> */}
      <section className="header-wrap text-center">
        <div className="logo py-3">
          {/* <img src={tcgl} alt=""style={{height: "30px", verticalAlign:"middle"}}/> */}
          <LogoBasedOnDomain height="30px" verticalAlign="middle" className="logo_img_ty"/>
        </div>

      </section>
      {/* <header className='header-wrap'>
         <div className="container">
            <div className="col-xl-12 text-center">

            
            <div className="logo py-3">
                  <img src={tcgl} alt=""style={{height: "30px", verticalAlign:"middle"}}/>
                  </div>
            </div>
         </div>
      </header> */}

      <section className="homebanner" style={{ padding: "none" }}>
        <div className="container">
          <h2 className="  text-center ">Have you ever taken out a car on finance?</h2>

          <h4 className=" text-center subtext">You could be owed £1,000s in compensation            </h4>

          <div className="text-center mt-3">
            <p className="claim-value">Your Potential Claim Value <span className="amount"> <em style={{ animation: "animateThis 2s ease-in infinite" }}>£1,100 per vehicle!*</em> </span> </p>
          </div>
        </div>
      </section>
      <section className="dashboad-bottom" style={{ padding: "0" }} >
        <div className="inner  pt-3 pb-5">
          <div className="container" style={{ borderRadius: "20px 20px 0 0" }}>
            <div className="slide6">
              <div className="wrap-number text-center  success ">
                <div className="my-5">
                  <img height="80" src={successtick} alt="" fetchpriority='hight' />
                </div>
                <h1> Thank You </h1>
                <h5>
                  We will be in touch regarding the   next steps and updates.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="modal fade" id="carLender" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body ">
              <h2 className="  title pt-2">
                When did you purchase the vehicle?
              </h2>
              <div className="wraper-option banklist mb-4 mt-4 ">
                <div className="option-btn optionBank mb-2 selLender"    > <span className="check"> </span>On or before 27th January 2021
                  <label for="lender1 ">
                    <input type="radio" name=" " id="lender1" />
                  </label>
                </div>

                <div className="option-btn optionBank mb-2 selLender"  > <span className="check"> </span>On or after 28th January 2021
                  <label for="lender3 ">
                    <input type="radio" name=" " id="lender3" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="faqsection">
        <div className="container">
          <div className="title">
            <h2 className="text-center">FAQ</h2>
          </div>
          <div className="row">

            <div className="col-lg-12 mb-lg-5 mb-md-5 mb-sm-5 mb-2">
            <Accordion/>
            </div>
          </div>
        </div>
      </section> */}
      {/* <footer className="py-lg-5 py-md-5 py-sm-4 py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 tex-lg-start mb-3 text-lg-start text-md-start text-sm-center text-center">
              <div className="ft-logo" style={{textAlign:'center'}}> 
              <LogoBasedOnDomain width="200px"  bg="black"/>
              </div>
            </div>
            <div className="col-lg-8">
              <p className="text-lg-start text-lg-start text-sm-center text-center"> Copyright ©2024 {brandName}. All rights
                reserved. {brandName} is a trading style of The Claims Guys Legal. The Claims Guys Legal Limited is authorised
                and regulated by the Solicitors Regulation Authority (SRA Number: 642517).
              </p>
              <ul className="m-0 p-0  text-lg-start text-lg-start text-sm-center text-center ">
                <li><a href="">Privacy Policy</a></li>
                <li>*<a target="_blank" href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf">https://www.fca.org.uk</a> </li>
              </ul>
            </div>
          </div>
        </div>
      </footer> */}
      <div className='TCG_L_C'>
        <div className="btm_sec">
          <footer className="_29ahV">
            <div className="container-md">
              <div className="row justify-content-between g-3">
                <div className="col-12 col-md-12 col-sm-12 col-xl-12 text-center">
                  <p className="fot_cont mb-2 text-lg-start text-center">
                    *The FCA confirmed in their publication "Our work on motor finance – Final Finding"
                    that on a 4 year car finance agreement of £10,000, a Discretionary Commission Arrangement
                    typically resulted in consumers being overcharged £1,100 <a href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf" className="text-white" target='_blank'>https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf</a> s. 2.15, Pg9.
                  </p>
                </div>

              </div>
            </div>
            <div className="container-md">
              <div className="row">
                <div className="col-12 text-center fot_cont pt-3 fw-bold br-top">
                  Copyright ©2024 {brandName}.
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Thankyou;
