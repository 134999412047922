import { useState, useRef } from "react";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";
import goodnews from "../../../assets/img/followup/goodnews.png";
import FollowUpFooter from "./FollowUpFooter";
import Button from "../../UI/Button";
import { useHistory } from "react-router-dom";
import FollowUpLoader from "./FollowUpLoader";
import CheckBox from "../../UI/CheckBox";
import { getBrandName } from "../../Includes/Layouts/Common/BrandBasedOnDomain";
import axios from "axios";
import * as EnvConstants from "../../../Constants/EnvConstants";
import { useFollowupDataIngestion } from "../../../Hooks/useFollowupDataIngestion";

const FollowUpAuthorise = () => {
  const history = useHistory();
  const errorFocus = useRef();
  const [loaderStatus, setLoaderStatus] = useState('show');
  const [authoriseStatus, setAuthoriseStatus] = useState(false);
  const [authoriseError, setAuthoriseError] = useState();
  const atpSub6 = localStorage.getItem('atp_sub6') || '';
  const uuid = localStorage.getItem('uuid') || '';
  const pending_details = JSON.parse(localStorage.getItem('pending_details')) || '';
  const additionalBanks = JSON.parse(localStorage.getItem('additionalBanks')) || '';
  const brand = getBrandName();
  const AppTCGL_URL = EnvConstants.AppTCGL_URL;
  const { saveFollowupUserIngestion } = useFollowupDataIngestion();
  const followupData = JSON.parse(localStorage.getItem("followup_data")) || {};

  setTimeout(() => {
    setLoaderStatus('hide')
  }, 2000);

  const CheckboxClick = (e) => {
    setAuthoriseStatus(e.target.checked);
    setAuthoriseError(false);
  }
  const handlePageChange = async() => {
    if (authoriseStatus) {
      var user_data= {'authorized_status' : 1}
      await saveFollowupUserIngestion(
        uuid,
        followupData,
        user_data,
        'followup_authorise'
      );
      var redirectUrl = (pending_details?.is_salutation != 1 || pending_details?.is_firstname != 1 || pending_details?.is_lastname != 1 || pending_details?.is_phonenumber != 1 || pending_details?.is_user_postcode != 1) ? `/followup-verification` : (pending_details?.pending_car_registration.length > 0 || additionalBanks?.length > 0) ? `/followup-car-registration` : (pending_details?.is_id_upload == 0) ? `/followup-id-upload` : `/followup-thank-you`;

      window.scrollTo(0, 0);
      history.push(`${redirectUrl}?uuid=${uuid}&source=${atpSub6}`);

    } else {
      setAuthoriseError(true);
      errorFocus.current.scrollIntoView({
        behavior: "smooth",
        block: 'end'
      });
    }

  }

  const viewFinanceDoc = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(AppTCGL_URL);
      window.open(response.data.url, '_blank');
    } catch (error) {
      console.error('Error fetching the PDF URL:', error);
    }
  };

  return (
    <>
      <FollowUpLoader status={loaderStatus} />
      <div className="TGGtcg">
        <div className="bg_grade">
          <header id="header" className="sticky-header">
            <div className="container text-center">
              <a ><LogoBasedOnDomain /></a>
            </div>
          </header>
          <section className="section_create">
            <div className="bg_clor">
              <div className="container-md " style={{ paddingTop: "90px" }}>
                <div className="row p-0 m-0">

                  <div className="col-md-8 col-sm-12 col-12 offset-md-2">
                    <div className="content">
                      <div className="">
                        <div className="slide_header slide_author text-md-start text-center"><img src={goodnews} alt="" />
                          <p className="p500">We are ready to assist you further with your complaint.</p>
                          <div className="form-check check_box_agree d-flex" id="signaturetickbox">
                            <div>
                              <CheckBox
                                className="form-check-input"
                                dataId="flexCheck1"
                                onChange={CheckboxClick}
                              />
                            </div>
                            <div className="ps-3">
                              <label className="form-check-label text-start" >I have read the car finance claim documents, including the Client Care Letter, Terms of Engagement and Letter of Authority, and the statements below and agree to be bound by them. I agree for my signature to be applied to a Letter of Authority and FOS Complaint Form for each lender and a Letter of Authority for the DVLA.</label>
                            </div>
                          </div>
                          <div className="mb-4" ref={errorFocus}>{authoriseError && <span className="error_msg">Please tick to authorise your car finance claim</span>}</div>
                          <div className="border border-primary p-2 mb-3 border-3 text-center">
                            <p className="mb-0 pb-0 fw-bold"> <a target="_blank" style={{ color: "#0d6efd", textDecoration: "underline" }}
                              href="" onClick={viewFinanceDoc}>Car finance
                              claims documents</a></p>
                          </div>

                          <p className="demo" style={{ fontSize: "13px !important", fontWeight: "400 !important" }}>Please note that {brand} is a trading style of The Claims Guys Legal, who will handle and process
                            your claim. </p>
                        </div>
                        <div className="ess_info">
                          <ul className="list-itemsc ps-0 ms-0">
                            <li> I was not fully informed about the commission the lenders paid to the car dealerships who arranged my car finance, and I authorise The Claims Guy Legal to make claims on my behalf about all car finance agreements I held with them.</li>
                            <li>I authorise The Claims Guys Legal to:
                              <ul className="mt-2 list-itemsb">
                                <li className="pb-2"> Raise an information request to the Driver and Vehicle Licensing Agency (DVLA) and/or lenders identified to request information to support my claim, including vehicle and credit agreement details, and information about any add-on products. I agree for this information to be released to The Claims Guys Legal. </li>
                                <li className="pb-2"> Refer my claim to the Financial Ombudsman Service (FOS) if a satisfactory outcome is not provided.
                                </li>
                                <li className="pb-2">Receive payment of any compensation due on my behalf. </li>
                              </ul>
                            </li>
                            <li>
                              I further authorise The Claims Guys Legal to complete a soft credit check on me, where this is necessary to substantiate my claim. I understand that this check will not affect my credit score. I agree for the results of this check to be used to:
                              <ul className="mt-2 list-itemsb">
                                <li>Confirm the lenders that I held car finance with. </li>
                                <li>Provide evidence to support my claims, which will be referenced in any letters of claim issued to
                                  my lenders. </li>
                              </ul>
                            </li>
                          </ul>
                          <p>When you click submit The Claims Guys Legal will receive the personal information you have provided and will start processing your claim.</p>
                        </div>

                        <div className="py-4 is_sticky animate__animated animate__fadeInUp animate__delay-3s animated-element">

                          <Button
                            type="button"
                            className="zoom main-btn p-2 w-100 mb-lg-0 mb-sm-0 py-3 fs-5 fw-bold sticky"
                            buttonText={<>I Authorise  <i className="bi bi-chevron-right bold-icon"></i></>}
                            onClick={handlePageChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <FollowUpFooter />
      </div>
    </>
  )
}

export default FollowUpAuthorise;