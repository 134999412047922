import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import InputButton from "../../UI/InputButton";
import { useSignature } from "../../../Hooks/useSignature";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import Button from "../../UI/Button";
import sign from "../../../assets/img/sign.png";
import Header from "../../Includes/Layouts/TCG_L_C/Header";
import Footer from "../../Includes/Layouts/TCG_L_C/Footer";
import GetLenders from "../../../Utility/GetLenders";
import AdditionalLender from "../../Includes/Layouts/TCG_L_C/AdditionalLender";
import { getBrandName } from "../../Includes/Layouts/Common/BrandBasedOnDomain";
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import Loader from "../../Includes/Layouts/TCG_L_C/Loader";
import axios from 'axios';
import * as EnvConstants from "../../../Constants/EnvConstants";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};

const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const SignatureV5 = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "TCG_L_C_3",
    "v5/signature"
  );
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const signPadRef = useRef();
  const storedFormData = JSON.parse(localStorage.getItem("formData"));
  const [btnLoader, setBtnLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { saveSignature, isLoading } = useSignature();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [show1, setShow1] = useState(true);
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const [isCheckedFocus, setIsCheckedFocus] = useState(false);
  const split_name = query.get("splitName");

  const message_type = "signature_store";
  const history = useHistory();
  const { getLenderList } = GetLenders();
  const [loaderStatus, setLoaderStatus] = useState('show');
  const [buttonText, setButtonText] = useState("Submit And Proceed");
  const brandName = getBrandName();
  const [tcgurl, setTcgurl] = useState('');
  const authorised_status = 0;
  const handleClear = () => {
    const signPad = signPadRef.current;
    signPad.clear();
  };
  const focusRef = useRef(null);
  const focusTermsRef = useRef(null);
  useEffect(() => {
    (async () => {
      const response = await getLenderList();
    })();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const timeout = setTimeout(() => {
      setLoaderStatus('hide');
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const viewtcgdoc = async (e) => {
    e.preventDefault();

    try {
      const url = EnvConstants.AppTCGL_URL;
      const response = await axios.get(url);
      window.open(response.data.url, '_blank');
    } catch (error) {
      console.error('Error fetching the PDF URL:', error);
    }
  };
  const handleSubmit = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        focusRef.current.scrollIntoView({
          behavior: "smooth",
          block: 'end'
        });
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }

      const rpdata = signPad.toData();
      let signLength = 0;
      for (let i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }

      if (signLength > 13) {
        setBtnLoader(true);
        const response = await saveSignature(
          signPad.getTrimmedCanvas().toDataURL('image/png'),
          visitorParameters.visitor_parameters,
          storedFormData,
          visitorParameters.data,
          queryString,
          message_type,
          authorised_status
        );
        if (split_name) {
          GTMDataLayer({
            clickEvent: 'slideclick',
            question: 'signature',
            answer: '',
          });

          history.push(
            "/" +
            DynamicRouteNextPage +
            "?uuid=" +
            visitorParameters.visitor_parameters.uuid +
            "&splitName=" +
            split_name
          );
        }
        if (response.data.status === "Success") {
          setBtnLoader(false);

          const formdata = JSON.parse(localStorage.getItem("formData"));
          if (formdata === undefined || formdata === null || formdata == "") {
            localStorage.setItem("formData", JSON.stringify(formdata));
          } else {
            formdata.user_sign = signPad.getTrimmedCanvas().toDataURL('image/png');
            localStorage.setItem("formData", JSON.stringify(formdata));
          }
        }
      } else {
        focusRef.current.focus();
        dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (error) {
      setBtnLoader(false);
      console.error("Error submitting signature:", error);
    }
  };

  const focusOnCheckbox = () => {
    if (signPadRef.current) {
      signPadRef.current.off();
      const canvas = signPadRef.current.getCanvas();
      if (canvas) {
        canvas.tabIndex = 0;
        canvas.focus();
      }
      signPadRef.current.on();
    }
    setIsCheckedFocus(true);
    focusTermsRef.current.scrollIntoView({
      behavior: "smooth",
      block: 'end'
    });
  };

  return (
    <>
      <GetVisitorsParams />
      <Loader loader={loaderStatus} contentText="" />
      <div className="TCG_L_C">
        {!show1 && (
          <div className="wrapper w-100">
            <div className="main-container">
              <Header />
              <section className="form">
                <form className="main_form" id="step_1_form">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-8 col-12 btn-relative">
                        <div className="sign-here animate__animated animate__fadeInUp animate__delay-.5s">
                          <div className="sign-note" style={{padding : "15px 25px 0"}}>
                            <p className="pb-3 ">
                              <img src={sign} alt="" fetchpriority='hight' />
                              <span className="fw-bold ps-2 sign-title">
                                Your Signature
                              </span>
                            </p>
                          </div>
                          <div className="sign-pad sign-note sign-info" style={{ borderRadius:"0px"}}>
                            <div className="text-start signature-footer p-2">
                            {/* <p className="sml-note pb-2">By signing below I confirm that I was not told about the commission the lenders I have told you about would pay to the car dealerships for arranging my car finance.
                            </p> */}
                            <p>
                              <span className="sml-note pb-2">
                              By signing below I confirm that I was not told about the commission the lenders I have told you about would pay to the car dealerships for arranging my car finance.
                              </span>
                            </p>
                            {/* <ul className="ulclass">
                              <li className="liclass">
                                {" "}
                                I was not fully informed about the commission the above lenders, and their grouped lenders, paid to the car dealerships who arranged my car finance. I authorise The Claims Guy Legal to make claims on my behalf about all car finance agreements I held.
                              </li>
                              <li className="liclass">
                                {" "}
                                  I authorise The Claims Guys Legal to:
                                {" "}
                                <ul className="sub_ul mt-2">
                                  <li className="sub_li">
                                  raise an information request to the Driver and Vehicle Licensing Agency (DVLA) and/or lenders identified to request information to support my claim, including Vehicle and Credit Agreement details, and information about any add-on products. I agree for this information to be released to The Claims Guys Legal.
                                  </li>
                                  <li className="sub_li">
                                  refer my claim to the Financial Ombudsman Service (FOS), where a satisfactory outcome is not provided.
                                  </li>
                                  <li className="sub_li">
                                  receive payment of any compensation due on my behalf.
                                  </li>

                                </ul>
                              </li>
                              <li className="liclass">
                              I further authorise The Claims Guys Legal to complete a soft credit check on me, where this is necessary to substantiate my claim. I understand that this check will not affect my credit score. I agree for the results of this check to be used to:
                                <ul className="sub_ul mt-2">
                                  <li className="sub_li">
                                  confirm the lenders that I held car finance with.
                                  </li>
                                  <li className="sub_li">
                                  provide evidence to support my claims, which will be referenced in any letters of claim issued to my lenders.
                                  </li>
                                </ul>
                              </li>
                             </ul> */}
                            </div> 
                            <div className="border-div">
                              {(deviceType === "mobile" ||
                                deviceType === "tablet") && (
                                  <MobileView>
                                    <SignatureCanvas
                                      canvasProps={{
                                        className: "writing-line",
                                        id: "canvas_signature",
                                      }}
                                      clearOnResize={false}
                                      ref={signPadRef}
                                      onEnd={() => {
                                        if (!signPadRef.current.isEmpty()) {
                                          dispatch({
                                            type: "signPadChange",
                                            payload: { value: false },
                                          });
                                          dispatch({
                                            type: "validSignature",
                                            payload: { value: false },
                                          });
                                        }
                                      }}
                                    />
                                  </MobileView>
                                )}

                              {deviceType === "browser" && (
                                <BrowserView>
                                  <SignatureCanvas
                                    canvasProps={{
                                      className: "writing-line",
                                      id: "canvas_signature",
                                    }}
                                    clearOnResize={false}
                                    ref={signPadRef}
                                    onEnd={() => {
                                      if (!signPadRef.current.isEmpty()) {
                                        dispatch({
                                          type: "signPadChange",
                                          payload: { value: false },
                                        });
                                        dispatch({
                                          type: "validSignature",
                                          payload: { value: false },
                                        });
                                      }
                                    }}
                                  />
                                </BrowserView>
                              )}
                              <InputButton
                                name="clear"
                                className="clearbutton mt-2 clear-sign"
                                id="clear"
                                value="Clear"
                                btnType="button"
                                onClick={handleClear}
                                style={{ border: 'none' }}
                              />
                            </div>
                          </div>
                          <span ref={focusTermsRef} tabIndex={0}></span>
                          {state.isCanvasEmpty && (
                            <div
                              className="sign_msg col-12 text-center sign_msg signature-validation"
                              style={{
                                backgroundColor: "red",
                                borderRadius: "5px",
                              }}
                              id="signatures_required"
                            >
                              Signature is required!
                            </div>
                          )}
                          {state.isValid && (
                            <div
                              className="sign_msg col-12 text-center sign_msg signature-validation"
                              style={{ backgroundColor: "red" }}
                              id="signatures_valid"
                            >
                              Draw valid signature!
                            </div>
                          )}
                          {/* <div className="clear-btn clr"> */}
                            {/* <p className="sml-note-v5 pb-2" style={{lineHeight:"18px"}}>By signing you are confirming that the lenders you have told us about did not inform you of commission.</p> */}
                            {/* <div className="bt_points">
                              <p>
                                <span className="sml-note">
                                  When you click Submit And Proceed we will receive the
                                  personal information you have provided and
                                  will start processing your claim.
                                </span>
                              </p>
                            </div> */}
                            {/* <div>
                              <div className="d-flex align-item-top sign-check mb-3 mt-3">
                                <div className="pe-2">
                                  <div className="chicon">
                                    <i className="bi bi-lock" />
                                  </div>
                                </div> */}
                                {/* <div>
                                  <label htmlFor="checkbox1" className="sign-info" style={{ textAlign: "left" }}>
                                    Your personal information will be treated carefully in
                                    accordance with our Privacy Policy. We Claim Finder, is a
                                    trading style of The Claims Guys Legal, will contact you
                                    about claim opportunities using the contact details you
                                    provide. You can opt out of receiving communications from
                                    us at any time by sending an email to{" "}
                                    <a href="maiilto:dataprotection@theclaimsguyslegal.com">
                                      dataprotection@theclaimsguyslegal.com
                                    </a>
                                  </label>
                                </div> */}
                              {/* </div>
                            </div> */}
                          {/* </div> */}
                        </div>
                        <span ref={focusRef} tabIndex={0}></span>
                        <div
                          className="box_button sign_Btn_paGe pt-4 animate__animated animate__fadeInUp animate__delay-.5s animated-element wht_iFNot "
                          id="signbutmain"
                          style={{ zIndex: 99999 }}
                        >
                          <div className="bg-1 hme_btn">
                            <Button
                              type="button"
                              className="button button--moema button--text-thick next-but blick_notice override-styles w-100"
                              id="nextPlateSUb_001"
                              buttonText={buttonText}
                              aria-label="submitid"
                              name="subRegNo1"
                              onClick={handleSubmit}
                              gtm_question='signature'
                              clickevent='slideclick'
                              gtm_answer=''
                            // gtm_trigger={signdata}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </section>
              <div className="btm_sec"></div>
              {/* <FAQSection /> */}
              <Footer />
            </div>
          </div>)}
      </div>
      {/* {!showLoader && ( */}
      {loaderStatus === 'hide' && <AdditionalLender setShow1={setShow1} show1={show1} uuid={query_uuid} />}


    </>
  );
};
export default SignatureV5;
