import React, { useState, useEffect, useContext } from "react";
import "../../../assets/css/followup/followup.scss";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";
import FollowUpFooter from "./FollowUpFooter";
import FollowUpLender from "./FollowUpLender";
import FollowUpAddLender from "./FollowUpAddLender";
import FollowUpSignature from "./FollowUpSignature";
import FollowUpLoader from "./FollowUpLoader";
import { useHistory } from "react-router-dom";
import { queryString, urlParams } from "../../../Utility/QueryString";
import { useFollowUpPendingDetails } from "../../../Hooks/useFollowUpPendingDetails";
import GetLenders from "../../../Utility/GetLenders";
import Button from "../../UI/Button";
import icon1 from "../../../assets/img/followup/icon1.png";
import icon2 from "../../../assets/img/followup/icon2.png";
import icon3 from "../../../assets/img/followup/icon3.png";
import bannerImg from "../../../assets/img/followup/bg-float2.png";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useFollowupLoad } from "../../../Hooks/useFollowupLoad";

const FollowUp = () => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const history = useHistory();
  const { followUpPendingDetails } = useFollowUpPendingDetails();
  const { loadFollowup } = useFollowupLoad();
  const { getLenderList } = GetLenders();
  const [loaderStatus, setLoaderStatus] = useState("show");
  const [slideStatus, setSlideStatus] = useState({
    proceedSlide: true,
    showLenderSlide: false,
    addLenderSlide: false,
    signatureSlide: false,
  });
  const [followupDataStatus, setFollowupDataStatus] = useState({});
  const [lenderList, setLenderList] = useState([]);
  const [userLenderList, setUserLenderList] = useState([]);
  const [uuid, setUuid] = useState();
  const userToken = urlParams.get("atp_sub2");
  const atpSub6 = urlParams.get("atp_sub6") ? urlParams.get("atp_sub6") : "FLP";
  localStorage.setItem("user_token", userToken);
  localStorage.setItem("atp_sub6", atpSub6);
  const slideChange = (e) => {
    var nextSlide = "";
    switch (e.target.id) {
      case "proceedBtn":
        nextSlide = "showLenderSlide";
        window.scrollTo(0, 0);
        break;
      case "addLender":
        nextSlide = "addLenderSlide";
        window.scrollTo(0, 0);
        break;
      case "confirmLender":
        if (followupDataStatus.is_user_sign == 0) {
          window.scrollTo(0, 0);
          nextSlide = "signatureSlide";
        }
        break;
      default:
        break;
    }
    const newStatus = Object.keys(slideStatus).reduce((acc, key) => {
      acc[key] = key == nextSlide;
      return acc;
    }, {});

    setSlideStatus(newStatus);
    if (nextSlide == "") {
      window.scrollTo(0, 0);
      const pending_details = JSON.parse(localStorage.getItem('pending_details')) || '';
      const additionalBanks = JSON.parse(localStorage.getItem('additionalBanks')) || '';
      var redirectUrl = (pending_details?.is_authorised == 0) ? `/followup-authorise` : (pending_details?.is_salutation != 1 || pending_details?.is_firstname != 1 || pending_details?.is_lastname != 1 || pending_details?.is_phonenumber != 1 || pending_details?.is_user_postcode != 1) ? `/followup-verification` : (pending_details?.pending_car_registration.length > 0 || additionalBanks?.length > 0) ? `/followup-car-registration` : (pending_details?.is_id_upload == 0) ? `/followup-id-upload` : `/followup-thank-you`;

      history.push(`${redirectUrl}?uuid=${uuid}&source=${atpSub6}`);
    }
  };
  useEffect(() => {
    (async () => {
      const response = await followUpPendingDetails(queryString, null);
      if (response) {
        const followupDetails = response.data.response.followup_data;
        const userUuid = response.data.response.user_info.uuid;
        if (response.data.status === "Success") {
          const flpResponse = await loadFollowup(
            "followup_load",
            followupDetails,
            "v1/followup",
            queryString,
            "v1",
            userUuid
          );
        }
        localStorage.setItem("followup_data", JSON.stringify(followupDetails));
        localStorage.setItem(
          "pending_details",
          JSON.stringify(response.data.response.pending_details)
        );
        localStorage.setItem(
          "user_info",
          JSON.stringify(response.data.response.user_info)
        );
        if (response?.data?.response?.user_info?.last_name) {
          localStorage.setItem("sur_names", JSON.stringify({ "txtLName": response?.data?.response?.user_info?.last_name }));
        }
        if (response?.data?.response?.user_info?.address_id) {
          localStorage.setItem("userSelectedAddress", JSON.stringify({ "address1": response?.data?.response?.user_info?.address_id }));
        }
        setFollowupDataStatus({
          is_qualified: response.data.response.user_info.is_qualified,
          is_user_complete:
            response.data.response.pending_details.is_user_complete,
          is_questionnaire:
            response.data.response.pending_details.is_questionnaire,
          is_user_sign: response.data.response.pending_details.is_user_sign,
          filled_question:
            response.data.response.pending_details.filled_question,
          user_name: response.data.response.user_info.user_name,
          user_banks: response.data.response.user_info.user_banks,
          pending_details:response.data.response.pending_details
        });
        setUuid(userUuid);
        localStorage.setItem("uuid", userUuid);
        var userBanks = response.data.response.user_info.user_banks;
        const bankList = await getLenderList();
        const filteredBanks = bankList.filter(
          (bank) => !userBanks.includes(bank.id)
        );
        const getUserBanks = bankList.filter((bank) =>
          userBanks.includes(bank.id)
        );
        // const pendingCarRegistration = bankList.filter((bank) =>
        //   response.data.response.pending_details.pending_car_registration.includes(
        //     bank.id
        //   )
        // );
        const pendingCarRegistration = bankList.filter((bank) => {
          const pendingCarRegistrationList = response.data.response.pending_details.pending_car_registration;

          if (Array.isArray(pendingCarRegistrationList)) {
            return pendingCarRegistrationList.includes(bank.id);
          }
          return false;
        });

        localStorage.setItem("pendingCarRegistration", JSON.stringify(pendingCarRegistration));
        setLenderList(filteredBanks);
        setUserLenderList(getUserBanks);
        setLoaderStatus("hide");

        if (
          response.data.response.user_info.is_qualified == 0 ||
          response.data.response.pending_details.is_user_complete == 1
        ) {
          history.push(
            `/followup-thank-you?uuid=${userUuid}&source=${atpSub6}`
          );
          return false;
        }
      }
    })();
  }, []);
  return (
    <>
      <FollowUpLoader status={loaderStatus} />
      <div className="TGGtcg">
        <div className="bg_grade">
          <header id="header" className="sticky-header">
            <div className="container">
              {/* <a href="index.html"><img className="logo" src="dist/img/logo-white.webp" alt=""/></a> */}
              <a>
                <LogoBasedOnDomain />
              </a>
            </div>
          </header>

          <section className="section_create">
            <div className="bg_clor">
              <div className="container-md " style={{ paddingTop: "90px" }}>
                <div className="row p-0 m-0 pb-4">
                  <div className="col-md-12 col-sm-12 col-lg-6">
                    <div className="content">
                      {slideStatus.proceedSlide && (
                        <div className="slide1">
                          <h4>Dear {followupDataStatus?.user_name}</h4>
                          <p className="banner-subtext">
                            Thank you for starting your claim. We have received
                            some information but need a few more details. Please
                            provide the missing information to proceed.
                          </p>
                          <h5 className="card_head">
                            Why Provide Essential Information?
                          </h5>
                          <div className="card_info">
                            <div className="icon">
                              <img src={icon1} alt="Icon" />
                            </div>
                            <div className="content">
                              <h3>
                                <strong>Efficient Processing: </strong>
                              </h3>
                              <p>
                                Providing all required details allows us to
                                process your claim more efficiently.
                              </p>
                            </div>
                            <div className="ribbon"></div>
                          </div>
                          <div className="card_info">
                            <div className="icon">
                              <img src={icon2} alt="Icon" />
                            </div>
                            <div className="content">
                              <h3>
                                <strong> Accurate Assessment:</strong>
                              </h3>
                              <p>
                              Complete information ensures we can provide everything needed for your lender to evaluate your claim accurately.
                              </p>
                            </div>
                            <div className="ribbon"></div>
                          </div>
                          <div className="card_info">
                            <div className="icon">
                              <img src={icon3} alt="Icon" />
                            </div>
                            <div className="content">
                              <h3>
                                <strong> Timely Updates:</strong>
                              </h3>
                              <p>
                                Full details enable us to keep you informed
                                about the status and progress of your claim.
                              </p>
                            </div>
                            <div className="ribbon"></div>
                          </div>
                          <div className="py-4">
                            <Button
                              type="button"
                              id="proceedBtn"
                              className="main-btn zoom p-2 w-100 mb-lg-0 mb-sm-0 py-3 fs-5 fw-bold sticky next2"
                              buttonText={
                                <>
                                  {" "}
                                  Proceed{" "}
                                  <i
                                    className="bi bi-chevron-right bold-icon"
                                    id="proceedBtn"
                                  ></i>
                                </>
                              }
                              onClick={slideChange}
                            />
                          </div>
                        </div>
                      )}
                      {slideStatus.showLenderSlide && (
                        <FollowUpLender
                          slideChange={slideChange}
                          bankList={userLenderList}
                        />
                      )}

                      {slideStatus.addLenderSlide && (
                        <FollowUpAddLender
                          slideChange={slideChange}
                          addLenderList={lenderList}
                          uuid={uuid}
                        />
                      )}

                      {slideStatus.signatureSlide && (
                        <FollowUpSignature
                          slideChange={slideChange}
                          uuid={uuid}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-6 d-lg-block d-none d-sm-none text-end">
                    <div>
                      <img width="80%" src={bannerImg} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <FollowUpFooter />
      </div>
    </>
  );
};

export default FollowUp;
