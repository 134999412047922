import { Api } from "../api/Api";

export const useSkipIdUpload = () => {

  const skipidupload = async (uuid, page_name,type) => {
    return await Api.post("v1/skip-id-upload", {
      uuid,
      page_name,
      type,
    });
  };

  return {  skipidupload };
};
