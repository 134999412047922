import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";
import { v4 } from 'uuid';
import { AppAdtopiaUPID } from "../Constants/EnvConstants";
export const useGetUUID = () => {

  const { ErrorWebhookTrigger } = useErrorWebhook();
  const getUserUUID = async (uuid) => {
    let getUuid = 1;
    const response = await Api.post("v1/get-uuid", {
      uuid,
    }).catch(function (error) {
      uuid = v4();
      const erroMessage = {
        serviceName: 'GET UUID',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      getUuid = 0;
      ErrorWebhookTrigger(erroMessage);
    });
    if (getUuid == 0) {
      return {
        data: {
          response: {
            uuid: uuid,
            country_code: null,
            ip_address: null,
            status: "Success"
          }
        }
      }
    } else {
      return response;
    }
  };
  return { getUserUUID };
};
