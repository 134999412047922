import React from 'react';
import Button from '../../../UI/Button';
import forword_arrow from "../../../../assets/img/TCG_L_D/arrow_forward_ios.svg";

const FileUploadBox = (props) => {

    const handleDisable =(txt) =>{
        props.setSelectedFileType('');
        props.setIsFileSelected(false);
      }

    return (
        <div className={`upload-box p-3 mb-3 py-lg-4 py-md-3 py-sm-3 py-3 nation-card bg-white  drop-in ${props.selectedFileType == props.fileType ? 'updated':''}`}>
            <div className="d-flex align-items-center">
                <div>
                    <div className="icon-circle me-3">
                        <i className={`bi ${props.iconClassName}`} />
                    </div>
                </div>
                <div id="upload">
                    <h3>{props.title}</h3>
                    {props.selectedFileType == props.fileType ? (<div
                        className="click-upload p-1 px-2 pe-3 fileupload "
                    >
                        <i className="bi bi-check-circle-fill me-1" />
                        File Uploaded
                    </div>) : (
                        <Button
                            className="click-upload p-1 px-3 clickupload-label"
                            buttonText="Click to upload"
                            id={props.fileId}
                            name="idType"
                            onClick={(e) => props.handleUploadClick(e,props.fileType)}
                            disabled={props.selectedFileType && props.selectedFileType !== props.fileType}
                        />)}
                </div>
                <div className="forward-angle">
                    {props.selectedFileType && props.selectedFileType == props.fileType ? (
                        <div className="close-file" onClick={(e) => handleDisable(e)}>
                            <i className="bi bi-x-lg"></i>
                        </div>
                    ) : (
                        <div className="forwardarrow">
                            <i>
                                <img
                                    src={forword_arrow}
                                    alt=""
                                />
                            </i>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};



export default FileUploadBox;
