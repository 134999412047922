import React,{ useContext} from 'react';
import {split_Flow} from "../Constants/DynamicRouting";

export const DynamicRoutingSplit = (LpName,CurrentPage) => {
  if(LpName == '' || LpName == null){
    const query = new URLSearchParams(window.location.search);
    LpName = query.get("splitName");
  }

  const currentPageKey = getKeyByValue(split_Flow[LpName],CurrentPage);

  const nextKey = findNext(currentPageKey, split_Flow[LpName]);
  const DynamicRouteNextPage = split_Flow[LpName][nextKey];
  const DynamicRouteSplitName = LpName;
  return {DynamicRouteSplitName,DynamicRouteNextPage} 
} 
const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}
function findNext(key, obj) {
  var keys = Object.keys(obj);
  return keys[(keys.indexOf(key) + 1) % keys.length];
}

