
const ProgressBar = (props) => {
  return (
    <>
   
   <div className="progress_bar">
          <div className="progress-bar-line">
            <span className={props.load}></span>
          </div>
        </div>
    </>
  );
};

export default ProgressBar;
