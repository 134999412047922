import React, { useState } from "react";
import { DobYear, DobMonth, DobDay } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import { dobValidation } from "../../../../Utility/Validations";
import { monthMapping } from "../../../../Constants/Constants";

const DateOfBirth = (props) => {
  const [dobValues, setDobValues] = useState({
    DobDay: "",
    DobMonth: "",
    DobYear: "",
  });
  const [valids, setValids] = useState(""); // State to manage validation message

  const updateDobValue = (e) => {
    const values = props.getValues();
    setDobValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));

    if (
      values.DobDay !== "" &&
      values.DobMonth !== "" &&
      values.DobYear !== ""
    ) {
      var validDate = dobValidation(
        values.DobDay,
        values.DobMonth,
        values.DobYear
      );
      if (!validDate) {
        props.setError(e.target.name, {
          type: "manual",
          message: "Please Enter Valid Date",
        });
        setDobValues((prevValues) => ({
          ...prevValues,
          [e.target.name]: "",
        }));
      } else {
        const dob = new Date(
          values.DobYear,
          monthMapping[values.DobMonth],
          values.DobDay
        );
        const EndDate = new Date(2000, 5, 1);

        if (dob >= EndDate) {
          setValids("Please Enter a date before 01/6/2000");
        } else {
          setValids("");
        }
      }
    }
  };

  return (
    <>
      <div className="mb-3 col-lg-4 col-md-4 col-sm-4">
        <SelectBox
          placeholderOption="Day"
          className="form-select form-control"
          OptionValue={DobDay}
          name="DobDay"
          id="DobDay"
          onChange={(e) => {
            props.clearErrors("DobDay");
            updateDobValue(e);
          }}
          myRef={props.validation({
            required: "Please Select Date",
          })}
          value={dobValues.DobDay}
          validationMsg={
            props.validationMsg.DobDay && props.validationMsg.DobDay.message
          }
        />
        <span
          className="alert alert-red"
          role="alert"
          style={{ display: "none" }}
        >
          {" "}
          Please enter day{" "}
        </span>
      </div>
      <div className="mb-3 col-lg-4 col-md-4 col-sm-4 ps-lg-0">
        <SelectBox
          placeholderOption="Month"
          className="form-select form-control"
          OptionValue={DobMonth}
          name="DobMonth"
          id="DobMonth"
          onChange={(e) => {
            props.clearErrors("DobMonth");
            updateDobValue(e);
          }}
          myRef={props.validation({
            required: "Please Select Month",
          })}
          value={dobValues.DobMonth}
          validationMsg={
            props.validationMsg.DobMonth && props.validationMsg.DobMonth.message
          }
        />
        <span
          className="alert alert-red"
          role="alert"
          style={{ display: "none" }}
        >
          {" "}
          Please enter month{" "}
        </span>
      </div>
      <div className="mb-3 col-lg-4 col-md-4 col-sm-4 ps-lg-0">
        <SelectBox
          placeholderOption="Year"
          className="form-select form-control"
          OptionValue={DobYear}
          name="DobYear"
          id="DobYear"
          value={dobValues.DobYear}
          onChange={(e) => {
            props.clearErrors("DobYear");
            updateDobValue(e);
          }}
          myRef={props.validation({
            required: "Please Select Year",
          })}
          validationMsg={
            props.validationMsg.DobYear && props.validationMsg.DobYear.message
          }
        />
        <i
          className="validate"
          aria-hidden="true"
          style={{ display: "none" }}
        ></i>
        <span
          className="alert alert-red"
          role="alert"
          style={{ display: "none" }}
        >
          {" "}
          Please enter year{" "}
        </span>
      </div>
      {valids !='' && (<span className="error_msg1">{valids}</span>)}

    </>
  );
};

export { DateOfBirth };
