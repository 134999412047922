import React, { useState, useEffect } from 'react';
import CheckBox from '../../../UI/CheckBox';
import Button from '../../../UI/Button';
import MultipleSelect from '../../../UI/MultipleSelect';
import GTMDataLayer from '../../../../Utility/GTMDataLayer';


const Lenders = (props) => {
  const [selectedLender, setSelectedLender] = useState([]);
  const [otrSelectedLender, setotrSelectedLender] = useState([]);
  const [validationError, setValidationError] = useState({ errorStatus: false, message: '' });
  const [lenderList, setLenderList] = useState([]);
  const [lenderListSection, setLenderListSection] = useState({ section1: [], section2: [] })
  useEffect(() => {
    const lenders = JSON.parse(localStorage.getItem('banks'));
    if (lenders) {
      setLenderList(lenders);
      setLenderListSection({ section1: lenders.slice(0, 12), section2: lenders.slice(12).sort((a, b) => a.label.localeCompare(b.label)) })
    }
  }, []);

  const clickEvent = (e) => {
    setValidationError({ errorStatus: false, message: '' });
    let lenderId = e.target.value;
    const updatedData = selectedLender.includes(lenderId)
      ? selectedLender.filter(id => id !== lenderId)
      : [...selectedLender, lenderId];
    setSelectedLender(updatedData);
  }
  const multipleClickEvent = (arrData) => {
    setotrSelectedLender(arrData);
    setValidationError({ errorStatus: false, message: '' });
  }
  const nextEvent = (e) => {
    if (selectedLender.length == 0 && otrSelectedLender.length == 0) {
      setValidationError({ errorStatus: true, message: 'Please Select Your Lender' });
    } else {
      const banks = selectedLender.map((item) => {
        return lenderList.find(option => option.value === item);
      });
      const selectedBanks = [...banks, ...otrSelectedLender];
      localStorage.setItem('selectedBanks', JSON.stringify(selectedBanks));
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'lenders',
        answer: '',
      });
      props.slideChange(e)
    }

  }
  const CheckboxLenders = lenderListSection.section1.map((item, index) => {
    return (
      <div className={`option-btn optionBank mb-2 ${selectedLender.includes(item.value) ? 'current' : ''}`} key={`bank_${index}`}> <span className="check"></span>
        <div className="textcont">{item.label}</div>
        <CheckBox
          className={``}
          value={item.value}
          dataId={`bank_${item.value}`}
          name={`bank`}
          checked={selectedLender.includes(item.value) ? true : false}
          onChange={(e) => { clickEvent(e) }}
          labelText={``}
          labelClassName=''
        />
      </div>
    )
  });
  return (
    <>

      <div className={`slide slide1 ${props.className}`}>
        <h2 className="text-center my-lg-5 my-md-5 my-sm-3 my-3"> Please select lenders you've secured car finance with.
        </h2>

        <div className="wraper-option banklist mb-3 mt-3 bank-home  ">
          {CheckboxLenders}
        </div>

        <div>
          <p className="mb-1">Others</p>
          <div className="mb-2 drop-down-multi mb-4">
            <MultipleSelect
              className={``}
              options={lenderListSection.section2}
              myRef={props.validation}
              onChange={multipleClickEvent}
            />

          </div>
        </div>
        {validationError.errorStatus && (
          <span
            className="error_msg "
            id="txtEmail_err"
          >
            <label id="error" className="error" > {validationError.message}</label>
          </span>
        )}
        <div className="text-center pb-2 ">
          <Button
            type="button"
            className='btn-comm p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3 progress-button'
            id='bank'
            onClick={nextEvent}
            name={`bank`}
            buttonText={`Next >`}
          />
        </div>
      </div>
    </>
  );
}

export default Lenders;