import React from "react";
import InputButton from "../../../UI/InputButton";
const PreviousDetailsConfirm = (props) => {
  const values = props.getValues();
  const fullName = `${values.lstSalutation} ${values.txtFName} ${values.txtLName}`;
  var previousNameArray = [];
  for (let i = 1; i <= 3; i++) {
    // const storedPrevLName = localStorage.getItem(`txtPrevLName_${i}`);
    const localVal =  JSON.parse(localStorage.getItem('sur_names')) || {};
    const storedPrevLName = localVal[`txtPrevLName_${i}`];
    previousNameArray[i] = {
      'txtPrevLName': storedPrevLName || values[`txtPrevLName_${i}`] || null
    };
  }
  var previousPostcodeArray = [];
  for (let i = 1; i <= 5; i++) {
    previousPostcodeArray[i] = {
      'txtPrevPostCode': values[`txtPrevPostCode_${i}`] || null,
      'txtPrevHouseNumber': values[`txtPrevHouseNumber_${i}`] || null,
      'txtPrevAddress2': values[`txtPrevAddress2_${i}`] || null,
      'txtPrevAddress3': values[`txtPrevAddress3_${i}`] || null,
      'txtPrevCounty': values[`txtPrevCounty_${i}`] || null,
      'txtPrevTown': values[`txtPrevTown_${i}`] || null,
      'txtPrevCountry': values[`txtPrevCountry_${i}`] || null
    };
  }


  const previousName = previousNameArray.map((item, index) => {
    if (item.txtPrevLName != null) {
      return (
        <React.Fragment key={index}>
          <p className="fs-6"><b>Your Previous Surname {index}</b></p>
          <div className="name-col">
            <p> {`${item.txtPrevLName}`}</p>
          </div>
        </React.Fragment>
      )
    }
  })

  const previousPostcode = previousPostcodeArray.map((item, index) => {
    if (item.txtPrevPostCode != null && item.txtPrevHouseNumber != null) {
      return (
        <React.Fragment key={index}>
          <p className="fs-6"><b>Your Previous Address {index}</b></p>
          <div className="address-col">
            <p>
              {item.txtPrevHouseNumber} <br />
              {item.txtPrevAddress2 && (<>{item.txtPrevAddress2} <br /></>)}
              {item.txtPrevAddress3 && (<>{item.txtPrevAddress3} <br /></>)}
              {item.txtPrevCounty && (<>{item.txtPrevCounty} <br /></>)}
              {item.txtPrevTown && (<>{item.txtPrevTown} <br /></>)}
              {item.txtPrevCountry && (<>{item.txtPrevCountry} <br /></>)}
            </p>
          </div>
        </React.Fragment>
      )
    }
  })

  const currentAddress = (
    <>
      <p>
        {values.txtHouseNumber && (<>{values.txtHouseNumber} <br /></>)}
        {values.txtAddress2 && (<>{values.txtAddress2} <br /></>)}
        {values.txtAddress3 && (<>{values.txtAddress3} <br /></>)}
        {values.txtCounty && (<>{values.txtCounty} <br /></>)}
        {values.txtTown && (<>{values.txtTown} <br /></>)}
        {values.txtCountry && (<>{values.txtCountry} <br /></>)}
      </p>
    </>
  );

  const handleConfirmSubmit = (e) => {
    //props.setPreviousDetailsPopData(false);
    //props.submitOtherFormDetails();
    //props.slideChange(e);
    props.formSubmit();
  }

  const handleConfirmBackButton = (e) => {
    props.setPreviousDetailsPopData(false);
    props.setShow1(true)
    props.previousSlideChange(e);

  }

  return (
    <>
      <div className="prev-dtls">
        <div className="modal fade show" id="NameAddressDetails" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="NameAddressDetailsLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-popupdetails animate__animated animate__zoomIn animate__delay-.5s ">
              <div className="modal-body">
                <div className="cont_flex animate__animated animate__fadeInUp animate__delay-.5s">
                  <h2>Please review and confirm your name and address.</h2>
                  <p className="fs-6"><b>Your Name</b></p>
                  <div className="name-col">
                    <p>{fullName} </p>
                  </div>
                  {previousName}
                  <p className="fs-6"><b>Your Current Address</b></p>
                  <div className="address-col">
                    {currentAddress}
                  </div>
                  {previousPostcode}
                </div>
                <div className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s" style={{ zIndex: "2", position: "relative" }}>
                  <InputButton
                    name="slide-confirm"
                    className="btn w-100 next-but center-block"
                    id="next-03"
                    value="I Confirm"
                    btnType="button"
                    style={{ float: "none" }}
                    onClick={handleConfirmSubmit}
                  /><br />
                  <InputButton
                    name="back7"
                    className="btn center-block back-but sctop"
                    id="backModal1"
                    value="Edit"
                    btnType="button"
                    style={{ float: "none" }}
                    onClick={handleConfirmBackButton}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreviousDetailsConfirm;
