import React, { useContext, useState } from "react";
import TextField from "../../../UI/TextField";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import privacy_pic from "../../../../assets/img/TCG_L_D/privacy_pic.png";
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";

const ContactDetails = (props)=>{

    const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [contactValidation, setContactValidation] = useState({ 'txtEmail': false, 'txtPhone': false });
  const [loader, setLoader] = useState('hide');
  const [contactPolicy, setContactPolicy] = useState(true);
  const phoneValidCheck = async () => {
    const values = props.getValues();
    const phone = values['txtPhone'];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone, visitorParameters.visitor_parameters.uuid)
      if (getPhoneValidation.data.status_code === 0) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      }
      else {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: true,
        }));
        props.clearErrors("txtPhone");
        return 1;
      }
    } else {
      props.setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Phone Number",
      });
    }
  };
  const emailValidCheck = async () => {
    const values = props.getValues();
    const email = values['txtEmail'];
    if (email !== "") {
      const getEmailValidation = await emailValidation(email, visitorParameters.visitor_parameters.uuid)
      if (getEmailValidation.data.status_code === 1) {
        setContactValidation(prevState => ({
          ...prevState,
          txtEmail: true,
        }));
        props.clearErrors("txtEmail");
        return 1;
      } else {
        setContactValidation(prevState => ({
          ...prevState,
          txtEmail: false,
        }));
        props.setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
      }
    } else {
      props.setError("txtEmail", {
        type: "manual",
        message: "Please Enter Valid Email",
      });
    }
  };
  const validNumber = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }
  const eventOnchange = (e) => {
    var field = e.target.id;
    setContactValidation(prevState => ({
      ...prevState,
      [field]: false,
    }));
    if (e.target.name == 'txtphone' && e.target.value.length >= 11) phoneValidCheck()
  }

    return(
        <>
            <fieldset className="p-2 px-3">
                                <legend className="px-2 m-0">Contact Information</legend>
                                <div className="row">
                                    <div className="col-lg-6  ">
                                        <div className="mb-2 input-group">
                                            <Telephone
                                                type="tel"
                                                name="txtPhone"
                                                className="form-control"
                                                placeholder="Mobile Number"
                                                onBlur={phoneValidCheck}
                                                onKeyPress={validNumber}
                                                id="txtPhone"
                                                maxlength="11"
                                                onChange={(e) => { props.clearErrors("txtPhone"); eventOnchange(e) }}
                                                defaultValue=""
                                                validation={props.validation({
                                                    pattern: {
                                                      value: /^[0-9]*$/i,
                                                      message: "Please Enter Valid Phone Number",
                                                    },
                                                    required: "Please Enter Valid Phone Number",
                                                  })}
                                                //   validationMsg={
                                                //     props.validationMsg.txtPhone && props.validationMsg.txtPhone.message
                                                //   }
                                                
                                            />
                                            <span className="input-group-text privacyico">
                                                <img src={privacy_pic} alt="" />
                                            </span>
                                            {
                                                    props.validationMsg.txtPhone && <span className='error_msg'>{props.validationMsg.txtPhone.message}</span>
                                                  }
                                        </div>
                                    </div>
                                    <div className="col-lg-6 ps-lg-0 ">
                                        <div className="mb-2 input-group">
                                            <Email
                                                type="email"
                                                name="txtEmail"
                                                id="txtEmail"
                                                className="form-control"
                                                placeholder="Email Address"
                                                defaultValue=""
                                                onBlur={emailValidCheck}
                                                autoComplete="new-email"
                                                onKeyPress={e => { if (e.key === ' ') e.preventDefault() }}
                                                onChange={(e) => { props.clearErrors("txtEmail"); eventOnchange(e) }}
                                                validation={props.validation({
                                                  required: "Please Enter Valid Email Address",
                                                  pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Invalid Email Address",
                                                  },
                                                })}
                                                // validationMsg={ props.validationMsg.txtEmail && <span className='error_msg'>{props.validationMsg.txtEmail.message}</span>}
                                            />
                                            
                                            <span className="input-group-text privacyico">
                                                <img src={privacy_pic} alt="" />
                                            </span>
                                            { props.validationMsg.txtEmail && <span className='error_msg'>{props.validationMsg.txtEmail.message}</span>}
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
        </>
    )
}
export default ContactDetails;