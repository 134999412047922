import React, { useEffect, useState } from 'react';
import "../../../assets/css/TCG_L_A/thankyou.scss";

import Header from '../../Includes/Layouts/TCG_L_A/Header';
import Footer from '../../Includes/Layouts/TCG_L_A/Footer';

const ThankyouV1 = () => {
  const [showLoader, setShowLoader] = useState(true);

  localStorage.clear();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);


  return (
    <>
  
      {/* main wraping section */}
      <div className="tankyouV1">
      {showLoader && (
          <div className="TheClaimsGuys_ver_A">
            <div id="preloader">
              <div id="loader" />
              {/* <h3>Analysing Your Details</h3> */}
            </div>
          </div>
        )}
      {!showLoader && <><div className="TheClaimsGuys_ver_A"><Header /></div>

      <section className="thankyouwrapper">
        <div className="container">
          <div className="row ">
            <div className="col-lg-10  col-12  offset-lg-1 thankyou_div">
              <div className="main-container pt-3 d-flex justify-content-center">
                <div className="check-container">
                  <div className="check-background">
                    <svg
                      viewBox="0 0 65 51"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 25L27.3077 44L58.5 7"
                        stroke="white"
                        strokeWidth={13}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="check-shadow" />
                </div>
              </div>
              <h2 className="cs_hero_title text-center animate__animated animate__fadeInUp animate__delay-1s">
                Thank you for completing our claim enquiry form.
              </h2>
              <h3
                className="text-center mt-3 w-100 lit_sm animate__animated animate__fadeInUp animate__delay-.5s"
                style={{ maxWidth: "unset !important" }}
              >
                Our team will now review the information you have provided and will
                contact you to finalise your enquiry.
              </h3>
            </div>
          </div>
        </div>
      </section>
      <div className="TheClaimsGuys_ver_A"><Footer /></div> </>}
      </div>
    </>

  );
}

export default ThankyouV1;
