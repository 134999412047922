import { Api } from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const useUuidValidate = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const validateUuid = async (uuid) => {
    const response = await Api.get("v1/followup/get-uuid-validation?uuid=" + uuid, {})
    .catch(function (error) {
      const erroMessage = {
        serviceName : 'VALIDATE UUID',
        errorReason:error.message,
        errorCode:error.code
      }
      // ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const TriggerFollowupMethod = async (userId,followup_method) => {
    const response = await Api.post("v1/followup/trigger-followup-method",
       {
        userId,
        followup_method
       })
    .catch(function (error) {
      const erroMessage = {
        serviceName : 'Trigger Followup Method',
        errorReason:error.message,
        errorCode:error.code
      }
      // ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };
  return { validateUuid,TriggerFollowupMethod };
}

