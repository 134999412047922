import { useState } from "react";
import { getBrandName } from "../../Includes/Layouts/Common/BrandBasedOnDomain";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";
import FollowUpUnsubscribeModel from "./FollowUpUnsubscribeModel";

const FollowUpFooter = () => {
  const brand = getBrandName();
  const [unsubscribeModelStatus, setUnsubscribeModelStatus] = useState(false);
  return (
    <>
      <footer className="py-lg-5 py-md-5 py-4">
        <div className="wrap larger text-size-smallest">
          <div className="container">
            <div className="ftloog  smaller mb-3">
              <LogoBasedOnDomain className="" bg="black" />
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12 col-12 text-lg-start text-center">
                <p>Copyright ©2024 {brand}. All rights reserved.</p>
                <p>
                  {brand} is a trading style of The Claims Guys Legal. The
                  Claims Guys Legal Limited is authorised and regulated by the
                  Solicitors Regulation Authority (SRA Number: 642517).
                </p>
                <p>
                  *
                  <a
                    href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0d6efd", textDecoration: "underline" }}
                  >
                    https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf
                  </a>
                </p>
                {/* <p>
                  If you prefer not to receive any further communications from
                  The Claims Guys Legal, please{" "}
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#unsubscribemodal"
                    style={{ color: "#0d6efd", textDecoration: "underline" }}
                    onClick={()=>{setUnsubscribeModelStatus(true)}}
                  >
                    click here{" "}
                  </a>
                  to unsubscribe.
                </p> */}
                <ul className="p-0">
                  <li>
                    <a
                      href="https://theclaimsguyslegal.com/privacy-policy/"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {unsubscribeModelStatus && <FollowUpUnsubscribeModel setUnsubscribeModelStatus={setUnsubscribeModelStatus}/>}
    </>
  );
};

export default FollowUpFooter;
