import React, { useState, useContext } from 'react';
import Telephone from "../../../UI/Telephone"
import { numberValidation } from '../../../../Utility/Validations';
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { slideValidation } from '../../../../Utility/Validations';
import Button from '../../../UI/Button';
import InputButton from "../../../UI/InputButton";
import GTMDataLayer from '../../../../Utility/GTMDataLayer';

const ContactDetails = (props) => {
    const { visitorParameters } = useContext(VisitorParamsContext);
    const { phoneValidation } = usePhoneValidation();
    const [contactValidation, setContactValidation] = useState({ 'txtPhone': false });
    const phoneValidCheck = async () => {
        const values = props.getValues();
        const phone = values['txtPhone'];
        if (phone !== "") {
            const getPhoneValidation = await phoneValidation(phone, visitorParameters.visitor_parameters.uuid)
            if (getPhoneValidation.data.status_code === 0) {
                setContactValidation(prevState => ({
                    ...prevState,
                    txtPhone: false,
                }));
                props.setError("txtPhone", {
                    type: "manual",
                    message: "Please Enter Valid Working Phone Number",
                });
                return 0;
            } else if (getPhoneValidation.data.status_code === 2) {
                setContactValidation(prevState => ({
                    ...prevState,
                    txtPhone: false,
                }));
                props.setError("txtPhone", {
                    type: "manual",
                    message: "Phone Number Already Exist",
                });
                return 0;
            } else if (getPhoneValidation.data.status_code === 3) {
                setContactValidation(prevState => ({
                    ...prevState,
                    txtPhone: false,
                }));
                props.setError("txtPhone", {
                    type: "manual",
                    message: "Please Enter Valid Phone Number",
                });
                return 0;
            }
            else {
                setContactValidation(prevState => ({
                    ...prevState,
                    txtPhone: true,
                }));
                props.clearErrors("txtPhone");
                return 1;
            }
        } else {
            props.setError("txtPhone", {
                type: "manual",
                message: "Please Enter Valid Phone Number",
            });
        }
    };

    const eventOnchange = (e) => {
        var field = e.target.id;
        setContactValidation(prevState => ({
            ...prevState,
            [field]: false,
        }));
        if (e.target.name = 'txtphone' && e.target.value.length >= 11) phoneValidCheck()
    }

    const handleNextClick = async (e) => {
        var validationArr = ['txtPhone'];
        const validationCheck = await slideValidation(validationArr, props.trigger);
        const contactStatus = Object.entries(contactValidation).find(([key, value]) => value === false);

        if (validationCheck === 'Success') {
            if (contactStatus) {
                if (contactStatus[0] === 'txtPhone') {
                    await phoneValidCheck();
                    return;
                }
            }
            GTMDataLayer({
                clickEvent: 'slideclick',
                question: 'contact details',
                answer: '',
              });
            props.slideChange(e);
        }
    };

    return (
        <>

            <div className={`slide5  questionwrapper ${props.showSlide6}`}>
                <h4 className="contacthead">Enter Your Personal Details</h4>
                <div className=" mt-4 animate__animated animate__fadeInUp">
                    <fieldset className="p-2 px-3">
                        <legend className="px-2 m-0">Contact Information</legend>
                        <div className="row">
                            <div className="col-lg-12  col-12">
                                <div className="input-group mb-3">
                                    <Telephone
                                        name="txtPhone"
                                        id="txtPhone"
                                        className="form-control"
                                        placeholder="Phone Number"
                                        onBlur={phoneValidCheck}
                                        onChange={(e) => { props.clearErrors("txtPhone"); eventOnchange(e); }}
                                        onKeyPress={e => { numberValidation(e) }}
                                        maxlength="11"
                                        minLength="11"
                                        validation={props.validation({
                                            pattern: {
                                                value: /^[0-9]*$/i,
                                                message: "Please Enter Valid Phone Number",
                                            },
                                            required: "Please Enter Phone Number",
                                        })}
                                    ></Telephone>
                                    <span className="input-group-text">
                                        <i className="bi bi-lock-fill" />{" "}
                                    </span>

                                </div>
                                {props.validationMsg.txtPhone && (
                                    <span
                                        className="error_msg error"
                                        id="phone_err"
                                    >
                                        {props.validationMsg.txtPhone && "Please Enter Valid Phone Number"}
                                    </span>
                                )}
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="col-lg-12 col-12 text-center text-lg-end mt-3">
                    <div className="row">
                        <InputButton
                            name="back5"
                            className="back-btn order-lg-1 order-md-1 order-2"
                            id="backStep5"
                            value="Back"
                            btnType="button"
                            style={{ float: "none" }}
                            onClick={props.previousSlideChange}
                        />
                        <Button
                            type="button"
                            className="button next-btn nxt-btn slide_6 order-lg-2 order-md-2 order-1"
                            id=""
                            value="Next"
                            name="slide_6"
                            onClick={(e) => handleNextClick(e)}
                            buttonText="Next"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactDetails;