import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import Button from "../../../UI/Button";

const BasicQuestionnaire = (props) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState([]);
  const question_id = props.questionnaire.questionId;

  const handleOptions = (e,index) => {
    setCheckedQuestionOne({[index]:true})
      props.slideChange(e);
    
  };

  const Radio = props.questionnaire.answers.map((answer, index) => {
    return (
      <div key={index} className={`form-check px-0 col-lg-6 col-12 `}>
        <RadioButton
          id={`question_${props.questionnaire.questionId}_${answer.value}`}
          value={answer.value}
          name={`question_${props.questionnaire.questionId}`}
          className="form-check-input"
          labelName={answer.label}
          labelClassName={`radio label-class ${(checkedQuestionOne[index] == true) ? 'check_btn2' : ''}`}
          onClick={(e)=>{handleOptions(e,index)}}
          validation={props.validation}
          gtm_question={props.questionnaire.gtmQuestion}
          gtm_trigger={props.questionnaire.gtm_trigger}
          gtm_answer={answer.gtmAnswer}
          clickevent={props.questionnaire.clickevent}
        />
      </div>
    );
  });

  return (
    <div className={props.className}>
      <h4>{props.questionnaire.question}</h4>
      <div className="d-block d-lg-flex choose_Y_N col-lg-12 col-12">
        {Radio}
      </div>
      <div>
        <Button
          name="back2"
          className=" bck-btn"
          id="backStep2"
          buttonText="Back"
          type="button"
          style={{ float: "none" }}
          onClick={props.previousSlideChange}
        />
      </div>
    </div>
  );
};

export default BasicQuestionnaire;
