import { useCallback, useState } from "react";
import { Api } from "../api/Api";

export const useVehicleValidation = () => {
  const [isLoading, setIsLoading] = useState(false);

  const validateRegNum = useCallback(async (regNum, uuid, pageName) => {
    setIsLoading(true);
    const getValidation = await Api.post("/v1/ukvd-validation", {
      carRegNo: regNum,
      uuid: uuid,
      page_name: pageName,
    }).catch(function (error) {
      setIsLoading(false);
    });
    setIsLoading(false);
    return getValidation;
  }, []);


  const storeVehicleDetails = async (visitor_parameters, data, form_data, query_string, vehicle_data, message_type,page_name) => {
    const response = await Api.post(
        "v1/data-ingestion-pipeline",
        {
            visitor_parameters,
            data,
            form_data,
            query_string,
            vehicle_data,
            // providers,
            message_type,
            page_name
        });
    return response;
};

const storefollowUpVehicleDetails = async (query_string, followup_vehicle_data, followup_data, message_type) => {
  const response = await Api.post(
      "v1/data-ingestion-pipeline",
      {
          query_string,
          followup_vehicle_data,
          followup_data,
          message_type
      });
  return response;
};

  return { isLoading, validateRegNum ,storeVehicleDetails,storefollowUpVehicleDetails};
};
