import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import SignatureCanvas from "react-signature-canvas";
import CheckBox from "../../UI/CheckBox";
import { useHistory } from "react-router-dom";
import InputButton from "../../UI/InputButton";
import { useSignature } from "../../../Hooks/useSignature";
import { signCheckText } from "../../../Constants/Constants";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../../Utility/CheckUUID";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import Button from "../../UI/Button";
import star from "../../../assets/img/star.png";
import star_half from "../../../assets/img/star-half.png";
import sign_arrow from "../../../assets/img/arrow-green.png";
import sign from "../../../assets/img/sign.png";
//   import clear from "../../../assets/img/clear.png";
import Header from "../../Includes/Layouts/TCG_L_C/Header";
import Footer from "../../Includes/Layouts/TCG_L_C/Footer";
import FAQSection from "../../Includes/Layouts/TCG_L_C/FAQSection";
//   import verify from "../../../assets/img/verify.png";
import GetLenders from "../../../Utility/GetLenders";
import AdditionalLender from "../../Includes/Layouts/TCG_L_C/AdditionalLender";
import financePdf from "../../../assets/pdf/car_finance_claims_documents.pdf"
import { getBrandName } from "../../Includes/Layouts/Common/BrandBasedOnDomain";
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import Loader from "../../Includes/Layouts/TCG_L_C/Loader";
import axios from 'axios';
import * as EnvConstants from "../../../Constants/EnvConstants";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};

const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const SignatureV3_C_4 = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "TCG_L_C_4",
    "v3_C4/signature"
  );

  // const { isCheck } = CheckUUID();
  // isCheck();
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const signPadRef = useRef();
  const storedFormData = JSON.parse(localStorage.getItem("formData"));
  const [btnLoader, setBtnLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { saveSignature, isLoading } = useSignature();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [show1, setShow1] = useState(true);
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const [isCheckedFocus, setIsCheckedFocus] = useState(false);
  const split_name = query.get("splitName");

  const message_type = "signature_store";
  const history = useHistory();
  const { getLenderList } = GetLenders();
  const [loaderStatus, setLoaderStatus] = useState('show');
  const [buttonText, setButtonText] = useState("Submit And Proceed");
  const brandName = getBrandName();
  const [tcgurl, setTcgurl] = useState('');
  // const [signdata,setSignData]=useState(false);
  const authorised_status = 1;

  const handleClear = () => {
    const signPad = signPadRef.current;
    signPad.clear();
  };
  const focusRef = useRef(null);
  const focusTermsRef = useRef(null);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    return dispatch({ type: "privacyAgreed", payload: { value: false } });
  };
  useEffect(() => {
    (async () => {
      const response = await getLenderList();
    })();
    window.scrollTo({ top: 0, behavior: "smooth" });

    const timeout = setTimeout(() => {
      setLoaderStatus('hide');
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const viewtcgdoc = async (e) => {
    e.preventDefault();

    try {
      const url  = EnvConstants.AppTCGL_URL;
      const response = await axios.get(url);
      window.open(response.data.url, '_blank');
    } catch (error) {
      console.error('Error fetching the PDF URL:', error);
    }
  };
  const handleSubmit = async () => {
    try {
      if (!isChecked) {
        focusTermsRef.current.scrollIntoView({
          behavior: "smooth",
          block: 'end'
        });
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        focusRef.current.scrollIntoView({
          behavior: "smooth",
          block: 'end'
        });
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }

      const rpdata = signPad.toData();
      let signLength = 0;
      for (let i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }

      if (signLength > 13) {
        setBtnLoader(true);
        // setSignData(true);
        const response = await saveSignature(
          signPad.getTrimmedCanvas().toDataURL('image/png'),
          visitorParameters.visitor_parameters,
          storedFormData,
          visitorParameters.data,
          queryString,
          message_type,
          authorised_status
        );
        if (split_name) {
          // history.push(
          //   "/v3/car-registration?uuid=" +
          //     visitorParameters.visitor_parameters.uuid
          // );

          GTMDataLayer({
            clickEvent: 'slideclick',
            question: 'signature',
            answer: '',
          });

          history.push(
            "/" +
            DynamicRouteNextPage +
            "?uuid=" +
            visitorParameters.visitor_parameters.uuid +
            "&splitName=" +
            split_name
          );
        }
        if (response.data.status === "Success") {
          setBtnLoader(false);

          const formdata = JSON.parse(localStorage.getItem("formData"));
          if (formdata === undefined || formdata === null || formdata == "") {
            localStorage.setItem("formData", JSON.stringify(formdata));
          } else {
            formdata.user_sign = signPad.getTrimmedCanvas().toDataURL('image/png');
            localStorage.setItem("formData", JSON.stringify(formdata));
          }

          // if (split_name === "TCG_L_C") {
          //   history.push(
          //     "/v3/car-registration?uuid=" +
          //       visitorParameters.visitor_parameters.uuid
          //   );
          // }
        }
      } else {
        focusRef.current.focus();
        dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (error) {
      setBtnLoader(false);
      console.error("Error submitting signature:", error);
    }
  };

  const focusOnCheckbox = () => {
    if (signPadRef.current) {
      signPadRef.current.off();
      const canvas = signPadRef.current.getCanvas();
      if (canvas) {
        canvas.tabIndex = 0;
        canvas.focus();
      }
      signPadRef.current.on();
    }
    setIsCheckedFocus(true);
    focusTermsRef.current.scrollIntoView({
      behavior: "smooth",
      block: 'end'
    });
  };

  return (
    <>
      <GetVisitorsParams />
      <Loader loader={loaderStatus} contentText=""/>
      <div className="TCG_L_C">
        {!show1 && (
          <div className="wrapper w-100">
            <div className="main-container">
              <Header />
              <section className="form">
                <form className="main_form" id="step_1_form">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-8 col-12 btn-relative">
                        <div className="sign-here animate__animated animate__fadeInUp animate__delay-.5s">
                          <div className="sign-note">
                            <p className="pb-3 ">
                              <img src={sign} alt="" fetchpriority='hight'/>
                              <span className="fw-bold ps-2 sign-title">
                                Your Signature
                              </span>
                            </p>
                            <p className="sml-note pb-2">{brandName} is a trading style of The Claims Guys Legal, who will handle and process your claim.</p>
                            <p>
                              <span className="sml-note">
                                Please carefully read the{" "}
                                <a href={tcgurl}  target="_blank" onClick={viewtcgdoc}>car finance claims documents</a> and
                                statements below before you add your signature.
                              </span>
                            </p>
                            <ul className="ulclass">
                              <li className="liclass">
                                {" "}
                                I was not fully informed about the commission the lenders I have told you about, and their grouped lenders, paid to the car dealerships who arranged my car finance. I authorise The Claims Guys Legal to make claims on my behalf about all car finance agreements I held.
                              </li>
                              <li className="liclass">
                                {" "}
                                  I authorise The Claims Guys Legal to:
                                {" "}
                                <ul className="sub_ul mt-2">
                                  <li className="sub_li">
                                    Raise an information request to the
                                    Driver and Vehicle Licensing Agency (DVLA)
                                    and/or lenders identified to request information
                                    to support my claim, including Vehicle and
                                    Credit Agreement details, and information about
                                    any add-on products. I agree for this
                                    information to be released to The Claims Guys
                                    Legal; and
                                  </li>
                                  <li className="sub_li">
                                    Refer my claim to the Financial
                                    Ombudsman Service (FOS), where a satisfactory
                                    outcome is not provided; and
                                  </li>
                                  <li className="sub_li">
                                    Receive payment
                                    of any compensation due on my behalf.
                                  </li>

                                </ul>
                              </li>
                              <li className="liclass">
                                I further authorise The Claims Guys Legal to
                                complete a soft credit check on me, where this
                                is necessary to substantiate my claim. I
                                understand that this check will not affect my
                                credit score. I agree for the results of this
                                check to be used to:
                                <ul className="sub_ul mt-2">
                                  <li className="sub_li">
                                    Confirm the lenders that I held car
                                    finance with; and
                                  </li>
                                  <li className="sub_li">
                                    Provide evidence to support
                                    my claims, which will be referenced in any
                                    letters of claim issued to my lenders.
                                  </li>
                                </ul>
                              </li>
                            </ul>
                            <p className="sml-note pb-3" style={{fontSize:"13px",lineHeight:"18px"}}> By signing you are confirming that the lenders you have told us about did not inform you of commission.</p>
                            <div
                              className="form-check check_box_agree d-flex"
                              id="signaturetickbox"

                            >
                              <div>
                                <CheckBox
                                  className={`form-check-input ${state.isAgreed ? 'blink_red' : isCheckedFocus && !isChecked ? 'blink_green' : ''}`}
                                  type="checkbox"
                                  value=""
                                  id="check_rew"
                                  // checked={isChecked}
                                  onChange={handleCheckboxChange}
                                />
                              </div>
                              <div>
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheck1"
                                >
                                I have read the car finance claim documents, including the Client Care letter, the Terms of Engagement and Letter of Authority, and the statements above and agree to be bound by them. I agree for my signature to be applied to a Letter of Authority and FOS Complaint Form for each lender and a Letter of Authority for the DVLA.
                                </label>
                              </div>
                            </div>
                            {state.isAgreed && <span className="error_msg">Please tick to accept in order to proceed</span>}
                          </div>

                          <div className="sign-pad">
                          {/* <h5>Sign below <i class="icodown"><img src={sign_arrow} /></i></h5> */}
                          <div className="border-div">
                            {(deviceType === "mobile" ||
                              deviceType === "tablet") && (
                                <MobileView>
                                  <SignatureCanvas
                                    canvasProps={{
                                      className: "writing-line",
                                      id: "canvas_signature",
                                    }}
                                    clearOnResize={false}
                                    ref={signPadRef}
                                    onEnd={() => {
                                      if (!signPadRef.current.isEmpty()) {
                                        // setButtonText("Proceed");
                                        dispatch({
                                          type: "signPadChange",
                                          payload: { value: false },
                                        });
                                        dispatch({
                                          type: "validSignature",
                                          payload: { value: false },
                                        });
                                      }
                                    }}
                                  />
                                </MobileView>
                              )}

                            {deviceType === "browser" && (
                              <BrowserView>
                                <SignatureCanvas
                                  canvasProps={{
                                    className: "writing-line",
                                    id: "canvas_signature",
                                  }}
                                  clearOnResize={false}
                                  ref={signPadRef}
                                  onEnd={() => {
                                    if (!signPadRef.current.isEmpty()) {
                                      dispatch({
                                        type: "signPadChange",
                                        payload: { value: false },
                                      });
                                      dispatch({
                                        type: "validSignature",
                                        payload: { value: false },
                                      });
                                    }
                                  }}
                                />
                              </BrowserView>
                            )}
                            <InputButton
                              name="clear"
                              className="clearbutton mt-2 clear-sign"
                              id="clear"
                              value="Clear"
                              btnType="button"
                              onClick={handleClear}
                              style={{ border:'none' }}
                            />
                            </div>
                          </div>
                          <span ref={focusTermsRef} tabIndex={0}></span>
                          {state.isCanvasEmpty && (
                            <div
                              className="sign_msg col-12 text-center sign_msg signature-validation"
                              style={{
                                backgroundColor: "red",
                                borderRadius: "5px",
                              }}
                              id="signatures_required"
                            >
                              Signature is required!
                            </div>
                          )}
                          {state.isValid && (
                            <div
                              className="sign_msg col-12 text-center sign_msg signature-validation"
                              style={{ backgroundColor: "red" }}
                              id="signatures_valid"
                            >
                              Draw valid signature!
                            </div>
                          )}
                          <div className="clear-btn clr">
                            
                            <div className="bt_points">
                              <p>
                                <span className="sml-note">
                                  When you click Submit And Proceed we will receive the
                                  personal information you have provided and
                                  will start processing your claim.
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <span ref={focusRef} tabIndex={0}></span>
                        {(isChecked || isCheckedFocus) && (
                          <div
                            className="box_button sign_Btn_paGe pt-4 animate__animated animate__fadeInUp animate__delay-.5s animated-element wht_iFNot "
                            id="signbutmain"
                            style={{ zIndex: 99999 }}
                          >
                            <div className="bg-1 hme_btn">
                              <Button
                                type="button"
                                className="button button--moema button--text-thick next-but blick_notice override-styles w-100"
                                id="nextPlateSUb_001"
                                buttonText={buttonText}
                                aria-label="submitid"
                                name="subRegNo1"
                                onClick={handleSubmit}
                                gtm_question='signature'
                                clickevent='slideclick'
                                gtm_answer=''
                              // gtm_trigger={signdata}
                              />
                            </div>
                          </div>
                        )}
                        {(!isChecked && !isCheckedFocus) && (
                          <div
                            className="box_button sign_Btn_paGe pt-4 animate__animated animate__fadeInUp animate__delay-.5s animated-element abutdiv btn-sticky"
                            id="tme_hide"
                          >
                            <Button
                              type="button"
                              className="button button--moema button--text-thick next-but blick_notice override-styles w-100"
                              id=""
                              buttonText="Submit And Proceed"
                              aria-label="submitid"
                              onClick={focusOnCheckbox}
                              name="subRegNo2"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </section>
              <div className="btm_sec"></div>
              {/* <FAQSection /> */}
              <Footer />
            </div>
          </div>)}
      </div>
      {/* {!showLoader && ( */}
      {loaderStatus === 'hide' && <AdditionalLender setShow1={setShow1} show1={show1} uuid={query_uuid} />}


    </>
  );
};
export default SignatureV3_C_4;
