import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";

export const useFollowUpPendingDetails = () => {

  const { ErrorWebhookTrigger } = useErrorWebhook();
  const followUpPendingDetails = async ( queryString, uuid  ) => {
    const pendingDetails = await Api.get("v1/followup/get-pending-details?query_string=" + queryString, 
    {}).catch(function (error) {
      const erroMessage = {
        serviceName: 'FOLLOWUP PENDING DETAILS',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return pendingDetails;
  };
  return {
    followUpPendingDetails,
  };
};
