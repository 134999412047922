import React, { useState, useContext } from 'react';
import RadioButtonV2 from "../../../UI/RadioButtonV2";
import Logo from "../../../../assets/img/logo_v3.png";
import InputButton from "../../../UI/InputButton";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import PreviousName from './PreviousName';
import PreviousPostCode from './PreviousPostCode';
import { slideValidation, profanityValidation, preNameDuplicationCheck } from '../../../../Utility/Validations';
import PreviousDetailsConfirm from './PreviousDetailsConfirm';
import ProgressBar from "../../../Includes/Layouts/TCG_L_C/ProgressBar";
import LogoBasedOnDomain from "../../../Includes/Layouts/Common/LogoBasedOnDomain";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const PreviousDetails = (props) => {
  const [showNextButton, setShowNextButton] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [selectedOption, setSelectedOption] = useState('');
  const [previousNameSection, setPreviousNameSection] = useState({ 'prevName1': true, 'prevName2': false, 'prevName3': false });
  const [previousPostcodeSection, setPreviousPostcodeSection] = useState({ 'prevPostcode1': true, 'prevPostcode2': false, 'prevPostcode3': false, 'prevPostcode4': false, 'prevPostcode5': false })
  const [previousDetailsPopData, setPreviousDetailsPopData] = useState(false);
  const [slideValidationError, setSlideValidationError] = useState(false);
  const [skipClickStatus, setSkipClickStatus] = useState(true);
  const previousNameCheckArr = { 'prevName2': [1], 'prevName3': [1, 2] };
  const previousPostCodeCheckArr = { 'prevPostcode2': [1], 'prevPostcode3': [1, 2], 'prevPostcode4': [1, 2, 3], 'prevPostcode5': [1, 2, 3, 4] };

  const additionalData = async (e) => {
    if (e.target.name == 'addPrevName') {
      const isValid = await preNameDuplicationCheck(props.getValues,props.setError,props.clearErrors);
      if (!isValid) return;
      var nextSection = Object.entries(previousNameSection).find(([key, value]) => value === false);
      if (nextSection) {
        var addValidationArr = [];
        previousNameCheckArr[nextSection[0]].forEach(element => {
          // addValidationArr.push(`txtPrevSalutation_${element}`, `txtPrevFName_${element}`, `txtPrevLName_${element}`);
          addValidationArr.push(`txtPrevLName_${element}`);
        });
        const validationCheck = await slideValidation(addValidationArr, props.trigger);
        if (validationCheck == 'Success') {
          setPreviousNameSection((prevState) => ({
            ...prevState,
            [nextSection[0]]: true,
          }));
        }

      }
    }
    if (e.target.name == 'addPrevPostcode') {
      var nextSection = Object.entries(previousPostcodeSection).find(([key, value]) => value === false);
      if (nextSection) {
        var addValidationArr = [];
        previousPostCodeCheckArr[nextSection[0]].forEach(element => {
          addValidationArr.push(`txtPrevPostCode_${element}`, `txtPrevAddress1_${element}`, `txtPrevHouseNumber_${element}`);
        });
        const validationCheck = await slideValidation(addValidationArr, props.trigger);
        if (validationCheck == 'Success') {
          setPreviousPostcodeSection((prevState) => ({
            ...prevState,
            [nextSection[0]]: true,
          }));
        }
      }
    }
  }
  const handleSubmit = async (e) => {
    const splitName = localStorage.getItem('split_name')
    const isValid = await preNameDuplicationCheck(props.getValues,props.setError,props.clearErrors);
    if (!isValid) return;
    const values = props.getValues();
    var previousNameArray = [];
    for (let i = 1; i <= 3; i++) {
      previousNameArray[i] = {
        // 'txtPrevSalutation': values[`txtPrevSalutation_${i}`] || null,
        // 'txtPrevFName': values[`txtPrevFName_${i}`] || null,
        'txtPrevLName': values[`txtPrevLName_${i}`] || null
      };
      console.log(previousNameArray,888888)
    }
    var previousPostcodeArray = [];
    for (let i = 1; i <= 5; i++) {
      previousPostcodeArray[i] = {
        'txtPrevPostCode': values[`txtPrevPostCode_${i}`] || null,
        'txtPrevHouseNumber': values[`txtPrevHouseNumber_${i}`] || null
      };
    }
    const isValidPreviousName = previousNameArray.some((element) => {
      return Object.values(element).every((value) => value !== null && value !== '');
    });

    const isValidPreviousPostcode = previousPostcodeArray.some((element) => {
      return Object.values(element).every((value) => value !== null && value !== '');
    });

    const profanityFields = profanityValidation(['txtPrevLName_1','txtPrevLName_2','txtPrevLName_3'], props.getValues);
    if (profanityFields != 'success') {
      props.setError(profanityFields, { message: "Please Enter valid Name" });
      return false;
    }

    if (isValidPreviousName || isValidPreviousPostcode) {
      if(splitName=='pcp/TCG_L_C_2'){
        GTMDataLayer({
          clickEvent: 'slideclick',
          question: 'previous address',
          answer: '',
        });
      }
      setSlideValidationError(false);
      setPreviousDetailsPopData(true);
    } else {
      setSlideValidationError(true);
    }
  };
  const handleNoClick = (e) => {
    setSelectedOption(e.target.value);
    props.slideChange(e);
  };
  const handleYesClick = (e) => {
    setSelectedOption(e.target.value);
    props.slideChange(e);
  };
  const handleSkipButton = (e) => {
    // props.setValue('txtPrevSalutation_1', '');
    // props.setValue('txtPrevFName_1', '');
    props.setValue('txtPrevLName_1', '');
    props.setValue('txtPrevPostCode_1', '');
    props.setValue('txtPrevAddress1_1', '');
    setSkipClickStatus(!skipClickStatus);
    setPreviousNameSection({ 'prevName1': true, 'prevName2': false, 'prevName3': false });
    setPreviousPostcodeSection({ 'prevPostcode1': true, 'prevPostcode2': false, 'prevPostcode3': false, 'prevPostcode4': false, 'prevPostcode5': false });
    props.slideChange(e);
  }
  return (
    <>
      <div className={`modal fade ${props.showSlide4}`} id="addPreDetails1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addPreDetails1Label" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-header p-0">
              <h1 className="modal-title fs-5" id="add_dbLabel">
                <LogoBasedOnDomain className="" bg="black" width="836" height="142" />
                {/* <img src={Logo} alt="" /> */}
              </h1>
            </div>
            <div className="modal-body">
              <div className="cont_flex animate__animated animate__fadeInUp animate__delay-.5s">
                <p className="fs-6"><b>Did you have any previous address or previous surname when you registered with your lender?</b></p>
              </div>
            </div>
            <div className="modal-footer m-0">
              <div className="form_drop yes_no_sec col-lg-12 col-12 ">
                <div className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s" style={{ zIndex: 2, position: 'relative' }}>
                  <div className="choose_Y_N personal_detail col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                    <div className="form-check px-0">
                      <RadioButtonV2
                        className="custom-checkbox next03"
                        name="slide4No"
                        id="preno"
                        value="No"
                        labelName="No"
                        checked={selectedOption === 'No'}
                        onClick={handleNoClick}
                        dataBsDismiss="modal"
                      />
                    </div>
                    <div className="form-check px-0">
                      <RadioButtonV2
                        className="custom-checkbox next03"
                        name="slide4Yes"
                        id="preno1"
                        value="Yes"
                        labelName="Yes"
                        checked={selectedOption === 'Yes'}
                        onClick={handleYesClick}
                        dataBsDismiss="modal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className={`slideADD ${props.showSlide5}`} id="slide_ADD3">
        <ProgressBar load="load1" />
        <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
          <label className="">
            Your Previous Details
            <span>To help your lender identify all the car finance agreements you had, we need to give them as many of your previous names and addresses you can remember.</span></label>
          <div className="form-group pb-0 animate__animated animate__fadeInUp animate__delay-.5s">
            <label className="animate__animated animate__fadeInUp animate__delay-.5s pb-0">Enter Previous Surname</label>
            {previousNameSection.prevName1 && <PreviousName
              validation={props.validation}
              validationMsg={props.validationMsg}
              preCount='1'
              getValues={props.getValues}
              setShowNextButton={setShowNextButton}
              prevName=" Previous Surname"
              clearErrors={props.clearErrors}
              setError={props.setError}
            />}
            {previousNameSection.prevName2 && <PreviousName
              validation={props.validation}
              validationMsg={props.validationMsg}
              preCount='2'
              prevName=" Previous Surname 1"
              clearErrors={props.clearErrors}
              setError={props.setError}
            />}
            {previousNameSection.prevName3 && <PreviousName
              validation={props.validation}
              validationMsg={props.validationMsg}
              preCount='3'
              prevName=" Previous Surname 2"
              clearErrors={props.clearErrors}
              setError={props.setError}
            />}
          </div>
          {!previousNameSection.prevName3 && <div className='mb-2' style={{ textAlign: "end" }}><button type="button" className="prev-add-btn" style={{ padding: "6px" }} id="addPrevName" name="addPrevName" onClick={(e) => additionalData(e)}>+ Add More</button></div>}
        </div>
        <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
          <label className="animate__animated animate__fadeInUp animate__delay-.5s pb-0">
            Enter Your previous address below</label>
          {previousPostcodeSection.prevPostcode1 &&
            <PreviousPostCode
              validation={props.validation}
              validationMsg={props.validationMsg}
              trigger={props.trigger}
              clearErrors={props.clearErrors}
              preCount='1'
              setShowNextButton={setShowNextButton}
              scrollToEvent={props.scrollToEvent}
              skipClickStatus={skipClickStatus}
              prevAddrs="Previous Address"
              setError={props.setError}
            />}

          {previousPostcodeSection.prevPostcode2 &&
            <PreviousPostCode
              validation={props.validation}
              validationMsg={props.validationMsg}
              trigger={props.trigger}
              clearErrors={props.clearErrors}
              preCount='2'
              setShowNextButton={setShowNextButton}
              scrollToEvent={props.scrollToEvent}
              prevAddrs="Previous Address 1"
              setError={props.setError}
            />}

          {previousPostcodeSection.prevPostcode3 &&
            <PreviousPostCode
              validation={props.validation}
              validationMsg={props.validationMsg}
              trigger={props.trigger}
              clearErrors={props.clearErrors}
              preCount='3'
              setShowNextButton={setShowNextButton}
              scrollToEvent={props.scrollToEvent}
              prevAddrs="Previous Address 2"
              setError={props.setError}
            />}

          {previousPostcodeSection.prevPostcode4 &&
            <PreviousPostCode
              validation={props.validation}
              validationMsg={props.validationMsg}
              trigger={props.trigger}
              clearErrors={props.clearErrors}
              preCount='4'
              setShowNextButton={setShowNextButton}
              scrollToEvent={props.scrollToEvent}
              prevAddrs="Previous Address 3"
              setError={props.setError}
            />}

          {previousPostcodeSection.prevPostcode5 &&
            <PreviousPostCode
              validation={props.validation}
              validationMsg={props.validationMsg}
              trigger={props.trigger}
              clearErrors={props.clearErrors}
              preCount='5'
              setShowNextButton={setShowNextButton}
              scrollToEvent={props.scrollToEvent}
              prevAddrs="Previous Address 4"
              setError={props.setError}
            />}
        </div>
        {!previousPostcodeSection.prevPostcode5 && <div style={{ textAlign: "end" }}><button type="button" className="prev-add-btn" style={{ padding: "6px" }} id="addPrevPostcode" name="addPrevPostcode" onClick={(e) => additionalData(e)}>+ Add More</button></div>}

        <div className="form-navigation pt-3 animate__animated animate__fadeInUp animate__delay-.5s" id="nextAdd" ref={props.scrollToEvent}>
          <ul>
            <li>
              <InputButton
                name="skip1"
                className="btn center-block sctop"
                id="skipStepADD"
                value="Skip"
                btnType="button"
                style={{ float: "none" }}
                onClick={handleSkipButton}
              />
            </li>
            {slideValidationError && <span className='error_msg'>Please enter a valid previous name or previous address</span>}
            {showNextButton && (
              <>
                <li>
                  <InputButton
                    name="slide5"
                    className="btn center-block next-but sctop"
                    id="next-03"
                    value="Next"
                    btnType="button"
                    style={{ float: "none" }}
                    onClick={handleSubmit}
                  />
                </li>
                <li>
                  <InputButton
                    name="back3"
                    className="btn center-block back-but sctop"
                    id="backStep2"
                    value="Back"
                    btnType="button"
                    style={{ float: "none" }}
                    onClick={props.previousSlideChange}
                  />
                </li>
              </>
            )}

          </ul>
        </div>
      </div>
      {previousDetailsPopData && <PreviousDetailsConfirm
        getValues={props.getValues}
        slideChange={props.slideChange}
        setPreviousDetailsPopData={setPreviousDetailsPopData}
        previousSlideChange={props.previousSlideChange}
      />}
    </>
  );
}

export default PreviousDetails;