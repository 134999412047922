import React, {useState} from "react";
import { useBanks } from "../Hooks/useBanks";

const GetLenders = () => {
  const [isLoading, setIsLoading] = useState('show');
  const { getBanks } = useBanks();
  const getLenderList = async () => {
    const response = await getBanks();
    console.log("response");
    console.log(response);
    if (response.data) {
      const banks = response.data.map(bank => ({
        bank_code: bank.bank_code,
        id: bank.id,
        value: String(bank.id),
        label: bank.bank_name,
        sublender:  bank.sub_lender_list != null && bank.sub_lender_list.defendants.length > 0 ? bank.sub_lender_list.defendants.map((subLender)=>({
          bank_code: subLender.id,
          id: subLender.id,
          value: String(subLender.id),
          label: subLender.name,
          bank:bank.bank_code
        })) : []
      }));
      localStorage.setItem('banks', JSON.stringify(banks));
      setIsLoading('hide');
      return banks;
    }
  };

  return { getLenderList, isLoading };
};

export default GetLenders;  

