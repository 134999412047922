import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";
import moneyIncomeAnimate from "../../../assets/img/followup/money-income-animate.svg";
import notice from "../../../assets/img/followup/notice.png";
import FollowUpFooter from "./FollowUpFooter";
import Button from "../../UI/Button";
import SelectBox from "../../UI/SelectBox";
import TextField from "../../UI/TextField";
import Telephone from "../../UI/Telephone";
import FollowUpPostcode from "./FollowUpPostcode";
import { Salutation } from "../../../Constants/Constants";
import {
  nameValidate,
  numberValidation,
  slideValidation,
  profanityValidation,
} from "../../../Utility/Validations";
import { usePhoneValidation } from "../../../Hooks/usePhoneValidation";
import { useFollowupDataIngestion } from "../../../Hooks/useFollowupDataIngestion";


const FollowUpVerification = () => {
  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const { phoneValidation } = usePhoneValidation();
  const pending_details = JSON.parse(localStorage.getItem("pending_details"));
  const [pendingDetails, setPendingDetails] = useState(pending_details);
  const [contactValidation, setContactValidation] = useState({
    txtPhone: false,
  });
  const { saveFollowupUserIngestion } = useFollowupDataIngestion();
  const history = useHistory();
  const atpSub6 = localStorage.getItem("atp_sub6") || "";
  const uuid = localStorage.getItem("uuid") || "";
  const followupData = JSON.parse(localStorage.getItem("followup_data"));

  const handlePageChange = async () => {
    const formData = getValues();
    const validationArr = Object.keys(formData);
    const checkForProfanity = ["txtFName", "txtLName"].filter((element) =>
      validationArr.includes(element)
    );

    const validationCheck = await slideValidation(validationArr, trigger);
    if (validationCheck != "Success") {
      return false;
    }

    if (checkForProfanity.length > 0) {
      const profanityFields = profanityValidation(checkForProfanity, getValues);
      if (profanityFields != "success") {
        setError(profanityFields, { message: "Please Enter valid Name" });
        return false;
      }
    }

    if (validationArr.includes("txtPhone")) {
      if (contactValidation.txtPhone == false) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return false;
      }
    }

    if (validationCheck == "Success") {
      await saveFollowupUserIngestion(
        uuid,
        followupData,
        formData,
        "followup_user_details"
      );
      if (formData?.txtLName) {
        localStorage.setItem("sur_names", JSON.stringify({ "txtLName": formData?.txtLName }));
      }
      if (formData?.address1) {
        localStorage.setItem("userSelectedAddress", JSON.stringify({ "address1": formData?.address1 }));
      }

      var redirectUrl = (pendingDetails?.pending_car_registration.length > 0) ? `/followup-car-registration` : (pendingDetails?.is_id_upload == 0) ? `/followup-id-upload` : `/followup-thank-you`;

      window.scrollTo(0, 0);
      history.push(`${redirectUrl}?uuid=${uuid}&source=${atpSub6}`);
    }
  };

  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone, uuid);
      if (getPhoneValidation.data.status_code === 0) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: false,
        }));
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: false,
        }));
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: false,
        }));
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      } else {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: true,
        }));
        clearErrors("txtPhone");
        return 1;
      }
    } else {
      setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Phone Number",
      });
    }
  };

  return (
    <>
      <div className="TGGtcg">
        <div className="bg_grade">
          <header id="header" className="sticky-header">
            <div className="container">
              <a>
                <LogoBasedOnDomain />
              </a>
            </div>
          </header>

          <section className="section_create bg_clor">
            <div className="container-md top_pad_banner">
              <form id="form" >
                <div className="row p-0 m-0">

                  <div className="col-6 d-md-block d-none d-sm-none">
                    <div>
                      <img width="100%" src={moneyIncomeAnimate} alt="" />
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12 col-12 pb-4">


                    <h1 className="mt-5  animate__animated animate__fadeInUp  animate__delay-.5s   cs_fs_94 ">
                      Please enter & check your details below
                    </h1>
                    <h2 className="banner-subtext animate__animated animate__fadeInUp animate__delay-.5s">
                      (The information below will support your lender in identifying you and allow us to keep you up to date.) </h2>

                    <div className="form_drop col-lg-12 col-12">
                      <div className="sect_bt pb-3 animate__animated animate__fadeInUp animate__delay-.5s">
                        <div className="row name_detail animate__animated animate__fadeInUp animate__delay-.5s">
                          {!pendingDetails.is_salutation && (
                            <div className="col-lg-4 col-md-4 col-12 pb-2">
                              <label htmlFor="format2">Title</label>
                              <SelectBox
                                className="form-select form-control"
                                OptionValue={Salutation}
                                name="lstSalutation"
                                id="lstSalutation"
                                myRef={register({
                                  required: "Select Title",
                                  message: "Please Select Title",
                                })}
                                validationMsg={errors.lstSalutation?.message}
                                onChange={() => {
                                  clearErrors(`lstSalutation`);
                                }}
                              />
                            </div>
                          )}
                          {!pendingDetails.is_firstname && (
                            <div className="col-lg-4 col-md-4 col-12 pb-2">
                              <label htmlFor="topmention1">First Name</label>
                              <TextField
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                name="txtFName"
                                id="txtFName"
                                autocomplete="off"
                                onkeypress={(e) => nameValidate(e)}
                                validation={register({
                                  required: "Please Enter First Name",
                                  minLength: {
                                    value: 3,
                                    message: "Please Enter Valid First Name",
                                  },
                                  pattern: {
                                    value: /^[a-zA-Z\s]+$/,
                                    message: "Your Name is Invalid",
                                  },
                                })}
                                validationMsg={errors.txtFName?.message}
                                onChange={() => {
                                  clearErrors(`txtFName`);
                                }}
                              />
                            </div>
                          )}
                          {!pendingDetails.is_lastname && (
                            <div className="col-lg-4 col-md-4 col-12 pb-2">
                              <label htmlFor="topmention2">Last Name</label>
                              <TextField
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                name="txtLName"
                                id="txtLName"
                                autocomplete="off"
                                onkeypress={(e) => nameValidate(e)}
                                validation={register({
                                  required: "Please Enter Last Name",
                                  minLength: {
                                    value: 3,
                                    message: "Please Enter Valid Last Name",
                                  },
                                  pattern: {
                                    value: /^[a-zA-Z\s]+$/,
                                    message: "Your Name is Invalid",
                                  },
                                })}
                                validationMsg={errors.txtLName?.message}
                                onChange={() => {
                                  clearErrors(`txtLName`);
                                }}
                              />
                            </div>
                          )}
                          {!pendingDetails.is_phonenumber && (
                            <div className="col-12 pb-2">
                              <label htmlFor="topmention2">
                                Mobile Number:
                              </label>
                              <Telephone
                                name="txtPhone"
                                id="txtPhone"
                                className="form-control"
                                placeholder="Mobile Phone Number"
                                onChange={(e) => {
                                  clearErrors("txtPhone");
                                  setContactValidation({ txtPhone: false });
                                }}
                                onBlur={phoneValidCheck}
                                onKeyPress={numberValidation}
                                maxlength="11"
                                autoComplete="new-phone"
                                validation={register({
                                  pattern: {
                                    value: /^[0-9]*$/i,
                                    message:
                                      "Please Enter Valid Mobile Phone Number",
                                  },
                                  required:
                                    "Please Enter Valid Mobile Phone Number",
                                })}
                                validationMsg={
                                  errors.txtPhone && errors.txtPhone.message
                                }
                              />
                              {errors.txtPhone && (
                                <span
                                  className="error_msg error mt-1"
                                  id="phone_err"
                                >
                                  {errors.txtPhone &&
                                    "Please Enter Valid Phone Number"}
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {!pendingDetails.is_user_postcode && (
                        <div
                          className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                          style={{ zIndex: "2", position: "relative" }}
                        >
                          <p className="png_t mb-0">
                            Address Registered with Lender(s)
                          </p>
                          <FollowUpPostcode
                            validation={register}
                            validationMsg={errors}
                            trigger={trigger}
                            clearErrors={clearErrors}
                            setError={setError}
                            setValue={setValue}
                          />
                        </div>
                      )}

                      <p className="margin-none my-4 text-end">
                        <Button
                          type="button"
                          className="main-btn p-2 w-100 mb-lg-0 mb-sm-0 py-3 fs-5 fw-bold sticky pulse2"
                          buttonText="Submit"
                          onClick={handlePageChange}
                        />
                      </p>

                      <p style={{ fontSize: "13px" }}> Your personal information will be treated carefully in accordance with our legal
                        partner’s Privacy Policy. We Claim Finder, a trading style of The Claims Guys Legal, will
                        contact you about claim opportunities using the contact details you provide. You can
                        opt out of receiving communications from The Claims Guys Legal at any time by
                        sending an email to dataprotection@theclaimsguyslegal.com</p>

                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>

        <FollowUpFooter />
      </div>
    </>
  );
};

export default FollowUpVerification;
