import React, { useEffect, useState } from "react";
import claimsfinderblack from "../../../../assets/img/TCG_L_D/claimsfinder-black.png";
import loader03 from "../../../../assets/img/TCG_L_D/loader03.svg";
import LogoBasedOnDomain from "../Common/LogoBasedOnDomain";

const LenderVerModal  =(props)=>{
  const [shows,setShows] = useState('hide');
  
  useEffect(()=>{
    if(props.show == "show" || props.show == "block"){
      props.show == "block" ? setShows('block') : setShows("show")
      setTimeout(()=>{
        setShows('hide');
      },2000)
    }
  },[props.show])
    return(
    <>
    <div
      className="overlay loader1 flex align-items-center justify-content-center"
      style={  shows == "block" ? { display: "block" } : { display: "none" }}

     >
      <div>
        <div className="loader-logo">
        <LogoBasedOnDomain height="40" style={{ marginBottom: "auto" }} bg="black"/>
        </div>
        <div className="text-center loader-set">
          <img height={100} src={loader03} alt="" />
          <h3 className="fs-5 loadertext2"> Analysing your Result</h3>
        </div>
      </div>
    </div>

    <div
      className="overlay loader2 flex align-items-center justify-content-center"
      style={  shows == "show" ? { display: "block" } : { display: "none" }}
    >
        <div>
        <div className="loader-logo">
        <LogoBasedOnDomain height="40" style={{ marginBottom: "auto" }} bg="black"/>
        </div>
        <div className="text-center loader-set">
          <img height={100} src={loader03} alt="" />
          <h3 className="fs-5 loadertext2">
            {" "}
            Verifying your lender information...
          </h3>
        </div>
      </div>
      </div>
    </>)
}
export default LenderVerModal;