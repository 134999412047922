import React from "react";
import GTMDataLayer from "../../Utility/GTMDataLayer";

const InputFile = (props) => {
  const handleButtonClick = (e) => {
    if (e.target.dataset.gtm_trigger) {
      GTMDataLayer({
        clickEvent: e.target.dataset.clickevent,
        question: e.target.dataset.gtm_question,
        answer: e.target.dataset.gtm_answer,
      });
    }
    props.onClick(e);
  };
  return (
      <label
      htmlFor={props.id} 
      className={props.labelClassName}
      name={props.labelName}
      onClick={props.onClick ? handleButtonClick : undefined}
      >
         <i className={props.iconClass} />{props.iconLabel}
          <input
              className={props.className}
              name={props.name}
              id={props.id}
              style={props.style}
              onChange={props.onChange}
              type={props.type}
              disabled={props.disabled}
              value={props.value}
              accept={props.accept}
              capture={props.capture}
              data-gtm_question={props.gtm_question}
              data-gtm_trigger={props.gtm_trigger}
              data-gtm_answer={props.gtm_answer}
              data-clickevent={props.clickevent}
          />
      </label>
  );
};
export default InputFile;
