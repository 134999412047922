import React, { useState, useContext } from "react";
import Telephone from "../../../UI/Telephone";
import { numberValidation } from "../../../../Utility/Validations";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import Email from "../../../UI/Email";
import { slideValidation } from "../../../../Utility/Validations";
import Button from "../../../UI/Button";
import InputButton from "../../../UI/InputButton";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const ContactDetails = (props) => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const [contactValidation, setContactValidation] = useState({
    txtPhone: false,
    txtEmail: false,
  });
  const phoneValidCheck = async () => {
    const values = props.getValues();
    const phone = values["txtPhone"];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(
        phone,
        visitorParameters.visitor_parameters.uuid
      );
      if (getPhoneValidation.data.status_code === 0) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      } else {
        setContactValidation((prevState) => ({
          ...prevState,
          txtPhone: true,
        }));
        props.clearErrors("txtPhone");
        return 1;
      }
    } else {
      props.setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Phone Number",
      });
      return 0;
    }
  };

  const emailValidCheck = async () => {
    const values = props.getValues();
    const email = values["txtEmail"];
    if (email !== "") {
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );
      if (getEmailValidation.data.status_code === 1) {
        setContactValidation((prevState) => ({
          ...prevState,
          txtEmail: true,
        }));
        props.clearErrors("txtEmail");
        // document.getElementById('contactDetails').click();
        return 1;
      } else {
        setContactValidation((prevState) => ({
          ...prevState,
          txtEmail: false,
        }));
        props.setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
      }
    } else {
      props.setError("txtEmail", {
        type: "manual",
        message: "Please Enter Valid Email",
      });
    }
  };
  const eventOnchange = (e) => {
    var field = e.target.id;
    setContactValidation((prevState) => ({
      ...prevState,
      [field]: false,
    }));
    if ((e.target.name = "txtphone" && e.target.value.length >= 11))
      phoneValidCheck();
  };
  // const handleNextClick = async (e) => {
  //   var validationArr = ['txtPhone', 'txtEmail'];
  //   const validationCheck = await slideValidation(validationArr, props.trigger);
  //   const contactStatus = Object.entries(contactValidation).find(([key, value]) => value === false);
  //   if (validationCheck == 'Success') {
  //     if (contactStatus) {
  //       (contactStatus[0] == 'txtPhone') ? await phoneValidCheck() : await emailValidCheck();
  //       return;
  //     }
  //     props.setLoaderStatus(true);
  //     setTimeout(() =>{
  //       props.setLoaderStatus(false);
  //       props.slideChange(e);
  //     },3000)
  //   }
  // };
  const handleNextClick = async (e) => {
    try {
      var validationArr = ["txtPhone", "txtEmail"];
      const validationCheck = await slideValidation(
        validationArr,
        props.trigger
      );
      if (validationCheck === "Success") {
        const contactStatus = Object.entries(contactValidation).find(
          ([key, value]) => value === false
        );
        if (contactStatus) {
          let response;
          props.setLoaderStatus(true);
          if (contactStatus[0] === "txtPhone") {
            response = await phoneValidCheck();
          } else if (contactStatus[0] === "txtEmail") {
            response = await emailValidCheck();
          }
          if (response == 1) {
            props.setLoaderStatus(false);
            GTMDataLayer({
              clickEvent: 'slideclick',
              question: 'contact details',
              answer: '',
            });
            props.slideChange(e);
          }
          return;
        }else{
          props.setLoaderStatus(false);
          props.slideChange(e);
        }
      }
    } catch (error) {
      console.error("Error during validation or submission process:", error);
    }
  };

  return (
    <>
      <div className={`slide slide4 ${props.className}`}>
        <div className="form-style">
          <h2 className="mb-3  title mt-2  animate__animated animate__fadeInUp ">
            {" "}
            What's the best way to Communicate?
          </h2>
          <div className="mb-3">
            <div className="row  contactinfo animate__animated animate__fadeInUp">
              <div className="col-lg-6  ">
                <div className="mb-3">
                  <Telephone
                    name="txtPhone"
                    id="txtPhone"
                    className="form-control"
                    placeholder="Phone Number"
                    onBlur={phoneValidCheck}
                    onChange={(e) => {
                      props.clearErrors("txtPhone");
                      eventOnchange(e);
                    }}
                    onKeyPress={(e) => {
                      numberValidation(e);
                    }}
                    maxlength="11"
                    minLength="11"
                    validation={props.validation({
                      pattern: {
                        value: /^[0-9]*$/i,
                        message: "Please Enter Valid Phone Number",
                      },
                      required: "Please Enter Phone Number",
                    })}
                  ></Telephone>
                  {props.validationMsg.txtPhone && (
                    <span className="error_msg error" id="phone_err">
                      {props.validationMsg.txtPhone &&
                        "Please Enter Valid Phone Number"}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-6 ps-lg-0 ">
                <div className="mb-3">
                  <Email
                    name="txtEmail"
                    className="form-control"
                    id="txtEmail"
                    placeholder="Email Addresss"
                    onBlur={emailValidCheck}
                    onKeyPress={(e) => {
                      if (e.key === " ") e.preventDefault();
                    }}
                    onChange={(e) => {
                      props.clearErrors("txtEmail");
                      eventOnchange(e);
                    }}
                    validation={props.validation({
                      required: "Please Enter Valid Email Address",
                    })}
                  ></Email>
                  {props.validationMsg.txtEmail && (
                    <span className="error_msg error" id="txtEmail_err">
                      {props.validationMsg.txtEmail.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <p className="animate__animated animate__fadeInUp">
              {" "}
              <strong>Mobile Phone</strong> is recommended. An automated SMS is
              sent when we receive news from your bank, and we’ll need to speak
              to you in order to finalise details
            </p>
          </div>
        </div>
        <div className="pb-2  animate__animated animate__fadeInUp ">
              <Button
                type="button"
                className="mt-3 btn-comm p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3  progress-button fw-bold"
                id="contactDetails"
                onClick={(e) => handleNextClick(e)}
                name={`contactDetails`}
                buttonText={`Next >`}
              />
              <Button
                name="back03"
                className="mt-3 btn center-block back-but sctop"
                id="backStep1"
                buttonText="< Back"
                type="button"
                style={{ float: "none" }}
                onClick={props.previousSlideChange}
              />
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
