import React, { useState, useEffect, useRef } from "react";
import CheckBoxV2 from "../../../UI/CheckBoxV2";
import SelectBox from "../../../UI/SelectBox";
import Button from "../../../UI/Button";
import CheckBox from "../../../UI/CheckBox";
import Select from "react-select";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";
import GetLenders from "../../../../Utility/GetLenders";


const Lenders = (props) => {
  const [banks, setBanks] = useState([]);
  const [remainingBanks, setRemainingBanks] = useState([]);
  const [additionalBanks, setAdditionalBanks] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [validationError, setValidationError] = useState(false);
  const [counter, setCounter] = useState(0);
  const scrollToErr = useRef(null);
  const { getLenderList, isLoading } = GetLenders();

  useEffect(() => {
    (async () => {
      const response = await getLenderList();

      // if (response) {
        const parsedBanks = response;
        setBanks(parsedBanks.slice(0, 11));

        const sortedRemainingBanks = parsedBanks.slice(11).sort((a, b) => a.label.localeCompare(b.label));
        setRemainingBanks(sortedRemainingBanks);
        props.setLoaderStatus(false); 
        //}

    })();
  }, []);
  useEffect(() => {
    const storedBanks = localStorage.getItem('banks');
    if (storedBanks) {
      const parsedBanks = JSON.parse(storedBanks);
      setBanks(parsedBanks.slice(0, 7));
      if (parsedBanks.length > 7) {
        const remaining_banks = parsedBanks.slice(7);
        // remaining_banks.unshift({
        //   bank_code: "0",
        //   id: 0,
        //   value: "0",
        //   label: "Another lender..."
        // });
        const sortBanks = remaining_banks.sort((a, b) => a.label.localeCompare(b.label));
        setRemainingBanks(sortBanks);
      }
    } 
  }, [])

  const handleSelectChange = (selectedOption) => {
    setValidationError(false);
    const selectedBankId = parseInt(selectedOption.value, 10);
    const selectedBank = remainingBanks.find(
      (bank) => bank.id === selectedBankId
    );
    if (selectedBank) {
      setAdditionalBanks((prev) => [...prev, selectedBank]);
      setRemainingBanks((prev) =>
        prev.filter((bank) => bank.id !== selectedBankId)
      );
      setSelectedBanks((prev) => [...prev, selectedBankId.toString()]);
    }
  };

  const handleCheckboxChange = (event) => {
    setValidationError(false);
    const { id, checked } = event.target;

    // Remove "lender_" from the id
    const extractedId = id.replace("lender_", "");

    if (checked) {
      setSelectedBanks((prev) => [...prev, extractedId]);
    } else {
      setSelectedBanks((prev) =>
        prev.filter((bankId) => bankId !== extractedId)
      );
    }
  };

  const handleSubmit = (e) => {
    const storedBanks = JSON.parse(localStorage.getItem("banks"));

    const updatedSelectedBanks = selectedBanks.map((selectedBankId) => {
      return storedBanks.find((bank) => bank.value === selectedBankId);
    });

    localStorage.setItem("selectedBanks", JSON.stringify(updatedSelectedBanks));

    if (updatedSelectedBanks.length === 0) {
      setValidationError(true);
      setTimeout(() => {
        scrollToErr.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    } else {
      setValidationError(false);
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'lenders',
        answer: '',
      });
      props.slideChange(e);
    }
  };

  return (
    <>
     
      <div className={`slide1 questionwrapper ${props.showSlide1}`}>
        <h4>
          Select ALL lenders below you want to check for potential claims.
        </h4>
        <div className="selectoptions">
          <div className={`row`}>
            {banks.map((bank) => (
              <div
                key={bank.value}
                className={`col-md-6 col-sm-12 col-12 option-btn optionBank mb-2 ${
                  selectedBanks.includes(`${bank.value}`) ? "current" : ""
                }`}
              >
                {" "}
                <span className="check"></span>
                <div className="textcont">{bank.label}</div>
                <CheckBox
                  className={``}
                  value={bank.value}
                  dataId={`lender_${bank.value}`}
                  name="lender"
                  checked={selectedBanks.includes(`${bank.value}`)}
                  onChange={handleCheckboxChange}
                  labelText={``}
                  labelClassName=""
                />
              </div>
            ))}
            <div className={`col-md-6 col-sm-12 col-12  mb-2`}></div>
          </div>
          {/* </ul> */}

          <div className="mb-3">
            <Select
              className=" select_search"
              options={remainingBanks}
              name="lstTitle"
              id="selectanother"
              placeholder="Another lender..."
              onChange={handleSelectChange}
            />
          </div>
          {/* option select view lender */}
          <div className="col-12 d-inline">
            {additionalBanks.map((bank) => (
              <div key={bank.value} className="l1_lender anotherlenderoptions">
                <CheckBoxV2
                  className="custom-checkbox"
                  name="lender"
                  value={bank.bank_name}
                  checked={selectedBanks.includes(`${bank.value}`)}
                  onChange={handleCheckboxChange}
                  dataId={`${bank.value}`}
                  labelClassName="selected-false"
                  labelText={
                    <>
                      <span
                        className="option_title title_1"
                        style={{ pointerEvents: "none" }}
                      >
                        {bank.label}
                      </span>
                    </>
                  }
                />
              </div>
            ))}
            {validationError && (
              <span className="error_msg" ref={scrollToErr}>
                Select at least one lender
              </span>
            )}
          </div>
          {/*  */}
          <div className="col-lg-12 col-12 text-center text-lg-end">
            <Button
              name="slide1"
              className="button next-btn-home1 fixed_but but_anim green_color_but text-center"
              id=""
              value="Next"
              type="button"
              style={{ float: "none" }}
              onClick={handleSubmit}
              buttonText="Next"
            />
          </div>
        </div>
      </div>
     
    </>
  );
};

export default Lenders;
