import React, { useState } from "react";
import CheckBox from "../../../UI/CheckBox";
import Button from "../../../UI/Button";
import InputButton from "../../../UI/InputButton";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const BasicQuestionCheckbox = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [validationError, setValidationError] = useState({
    errorStatus: false,
    message: "",
  });

  const clickEvent = (e) => {
    setValidationError({ errorStatus: false, message: "" });
    let optionValue = e.target.value;
    const updatedData = selectedOptions.includes(optionValue)
      ? selectedOptions.filter((id) => id !== optionValue)
      : [...selectedOptions, optionValue];
    setSelectedOptions(updatedData);
  };

  const nextEvent = (e) => {
    if (selectedOptions.length == 0) {
      setValidationError({ errorStatus: true, message: "Please Select" });
    } else {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'higher commission',
        answer: '',
      });
      props.slideChange(e);
    }
  };

  const checkboxList = props.questionnaire.answers.map((item, index) => {
    return (
      <div
        className={`option-btn optionBank mb-2 ${
          selectedOptions.includes(item.value) ? "current" : ""
        }`}
        key={`${index}`}
      >
        <span className="check"> </span>
        <div className="textcont">{item.label}</div>
        <CheckBox
          className={``}
          value={item.value}
          dataId={`question_${props.questionnaire.questionId}_${item.value}`}
          name={`question_${props.questionnaire.questionId}[]`}
          // checked={selectedOptions.includes(item.value) ? true : false}
          onChange={(e) => {
            clickEvent(e);
          }}
          labelText={``}
          labelClassName=""
          validation={props.validation}
        />
      </div>
    );
  });
  return (
    <>
      <div className={`slide slide6 ${props.className}`}>
        <div className="form-style">
          <div className="text-center">
            <h2 className="mb-3  title mt-2">
              {" "}
              {props.questionnaire.question}
            </h2>
            <div className="wraper-option banklist mb-3 mt-3 bank-home  ">
              {checkboxList}
            </div>
            {validationError.errorStatus && (
              <span className="error_msg ">
                <label id="error" className="error">
                  {" "}
                  {validationError.message}
                </label>
              </span>
            )}
            <div className="text-start pb-2 ">
                  <Button
                    type="button"
                    className="btn-comm p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3 qsnext2 progress-button fw-bold"
                    id={`question_${props.questionnaire.questionId}`}
                    onClick={(e) => nextEvent(e)}
                    name={`question_${props.questionnaire.questionId}`}
                    buttonText={`Next >`}
                  />
                  <Button
                    name="back05"
                    className="mt-3 btn center-block back-but sctop"
                    id="backStep3"
                    buttonText="< Back"
                    type="button"
                    style={{ float: "none" }}
                    onClick={props.previousSlideChange}
                  />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicQuestionCheckbox;
