import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import loader03 from "../../../../assets/img/TCG_L_D/loader03.svg";

const CarRegistrationFollowupModal = (props) => {
    const [checked,setChecked] = useState('')

    return (
        <>
            <div
                className={`modal  fade-scale transp_bag ${props.questionareModal && "show d-block"}`}
                id="carLender"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
                style={{background: '#00000088'}}
            >
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-body ">
                            <h2 className="  title pt-2">
                            {props.questionnaire.question}
                            </h2>
                            <div className="wraper-option banklist mb-4 mt-4 ">
                                {props.questionnaire.answers.map((answer, index) => (
                                    <div className={`option-btn optionBtn2   mb-2  selLender ${checked == `${answer.value}`? 'current':''}`}
                                        key={index}
                                    >
                                        <span className="check"> </span>
                                        {answer.label}
                                        <RadioButton
                                            className="form-check-input"
                                            type="radio"
                                            labelName={``}
                                            labelClassName=""
                                            onClick={(e) => {props.questionModalClick(e);setChecked(e.target.value);}}
                                            value={answer.value}
                                            name={`question_${props.questionnaire.questionId}`}
                                            id={`question_${props.questionnaire.questionId}_${answer.value}`}
                                        />
                                    </div>
                                ))}
                                
                                {props.isLoaderVisible && (
                                    <div className="text-center loader loaderLender">
                                        <img height={50} src={loader03} alt="Loading..." />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CarRegistrationFollowupModal;
