import TCG_L_D_Form from "../../Forms/TCG_L_D/TCG_L_D_Form";
import "../../../assets/css/TCG_L_D/custom.scss";
import { useState } from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../Utility/FbViewPixel";
import FAQ from "../../Includes/Layouts/TCG_L_D/FAQ";
import Footer from "../../Includes/Layouts/TCG_L_D/Footer";
const TCG_L_D = () => {
  const [modal, setModal] = useState('hide');
  const [addModal, setAddModal] = useState('hide');
  const [footerEnable,setFooterEnable] = useState(true);
  const splitName = "pcp/TCG_L_D";
  if (modal == "show") {
    setTimeout(() => {
      setModal("hide");
      setAddModal("show");
    }, 2000)
  }
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="pcp/TCG_L_D" />
      <FbViewPixel />
      <div className="TCG_L_D">
        <form>
          <TCG_L_D_Form
            setModal={setModal}
            splitName={splitName}
            setFooterEnable={setFooterEnable}
          />
          {/* End Banner */}
        </form>
        <FAQ />
        <Footer footerEnable={footerEnable} superScriptQuote={!footerEnable} />
        </div>
    </>

  )


}
export default TCG_L_D;
