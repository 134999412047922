import { useState } from "react";
import { Api } from "../api/Api";

export const useFollowupAdditionalBank = () => {
    const [isLoading, setIsLoading] = useState(false);
    const saveFollowupBankIngestion = async (
        uuid,
        followup_data,
        bank_data,
        message_type
    ) => {
        setIsLoading(true);
        const response = await Api.post("v1/data-ingestion-pipeline", {
            uuid,
            followup_data,
            bank_data,
            message_type

        }).catch(function (error) {
            return {
                data: {
                    status: "Success"
                }
            }
        });
        setIsLoading(false);
        return response;
    };
    return { saveFollowupBankIngestion, isLoading };
};
