import React, { useContext, useEffect, useState, useRef } from "react";
import Header from "../../Includes/Layouts/TCG_L_C/Header";
import tcgllogo from "../../../assets/img/tcgl-logo.png";
import Footer from "../../Includes/Layouts/TCG_L_C/Footer";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { useForm } from "react-hook-form";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";
import {
  useVehicleValidation,
  storeVehicleDetails,
} from "../../../Hooks/useVehicleValidation";
import { useSkipHandling } from "../../../Hooks/useSkipHandling";
import FAQSection from "../../Includes/Layouts/TCG_L_C/FAQSection";
import QueryStringContext from "../../../Contexts/QueryString";
import CheckBoxV2 from "../../UI/CheckBoxV2";
import { useUserInfo } from "../../../Hooks/useUserInfo";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";
import RadioButtonV2 from "../../UI/RadioButtonV2";
import TextField from "../../UI/TextField";
import Button from "../../UI/Button";
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import Loader from "../../Includes/Layouts/TCG_L_C/Loader";

const CarRegistrationV3 = () => {

  const page_name = "pcp/TCG_L_C";
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "TCG_L_C",
    "v3/car-registration"
  );

  const [vehicleNumberInput, setVehicleNumberInput] = useState("");
  const [keeperLatest, setKeeperLatest] = useState(null);
  const [formattedDates, setFormattedDates] = useState([]);
  const [vehicleDateModal, setVehicleDateModal] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { isLoading, validateRegNum } = useVehicleValidation();
  const { handleSkip } = useSkipHandling();
  const [responseslug, setResponseSlug] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const uuid =
    visitorParameters.visitor_parameters.uuid ?? localStorage.getItem("uuid");
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const split_name = query.get("splitName");
  const { queryString } = useContext(QueryStringContext);
  const { storeVehicleDetails } = useVehicleValidation();
  const [vehicle, setVechicle] = useState("");
  const [year, setVechicleYear] = useState("");
  const [model, setVechicleModel] = useState("");
  const [edition, setEdition] = useState("");
  const type = "car_registration";
  const history = useHistory();
  const [formData, setFormData] = useState([]);
  const { getBankInfo } = useUserInfo();
  const [currentBankIndex, setCurrentBankIndex] = useState(0);
  const [loading, setIsLoading] = useState('hide');
  const findRegRef = useRef(null);
  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  useEffect(() => {
    const fetchData = async () => {
      let storedSelectedBanks =
        JSON.parse(localStorage.getItem("selectedBanks")) || [];
      let storedAdditionalBanks =
        JSON.parse(localStorage.getItem("additionalBanks")) || [];

      if (storedSelectedBanks.length === 0) {
        const response = await getBankInfo(query_uuid);
        setFormData(response.data.response);
      } else {
        let mergedBanks = [...storedSelectedBanks];
        storedAdditionalBanks.forEach((additionalBank) => {
          if (
            !mergedBanks.find(
              (bank) => bank.bank_code === additionalBank.bank_code
            )
          ) {
            mergedBanks.push(additionalBank);
          }
        });
        setFormData(mergedBanks);
      }
    };

    fetchData();
  }, [query_uuid]);

  const handleNextButtonClick = async () => {
    await trigger("vehicle_number");
;
    if (errors.vehicle_number) return;

    try {
      // setShowSpinner(true);
      const validationResponse = await validateRegNum(
        getValues("vehicle_number"),
        uuid,
        page_name
      );
      console.log(validationResponse,"validation")
      if (validationResponse && validationResponse.data.keeper_date) {
        if (
          validationResponse.status === 200 &&
          validationResponse.data.is_valid === 1
        ) {
          // Validation successful
          clearErrors("vehicle_number"); // Clear vehicle number error if any
          clearErrors("selected_value"); // Clear vehicle number error if any
        } else {
          setError("vehicle_number", { message: "Enter valid vehicle number" });
        }
        const keeperDatesObj = JSON.parse(validationResponse.data.keeper_date);
        // Convert the response to a string before storing it in localStorage
        const responseString = JSON.stringify(validationResponse.data);

        // Store the response in localStorage
        localStorage.setItem("validationResponse", responseString);

        const storedResponseString = localStorage.getItem("validationResponse");

        // Convert the stored JSON string back to an object
        const storedValidationResponse = JSON.parse(storedResponseString);
        setVechicle(storedValidationResponse.car_reg_no);
        setVechicleYear(storedValidationResponse.vehicle_year);
        setVechicleModel(storedValidationResponse.vehicle_model);
        setEdition(storedValidationResponse.vehicle_make);

        if (keeperDatesObj && keeperDatesObj.keeper_dates) {
          const keeperDates = keeperDatesObj.keeper_dates;

          const latestDate = new Date(
            Math.max(...keeperDates.map((date) => new Date(date)))
          );

          const nextDayDate = new Date(latestDate);
          nextDayDate.setDate(latestDate.getDate() + 1);

          const nextDayDateString = nextDayDate.toISOString().split("T")[0];

          setKeeperLatest(nextDayDateString);
        }
      }

      if (
        validationResponse.status === 200 &&
        validationResponse.data.is_valid === 1
      ) {
        const keeperDateStr = validationResponse.data.keeper_date;
        const keeperDateObj = JSON.parse(keeperDateStr);

        // Extract the array of keeper dates
        const keeperDates = keeperDateObj.keeper_dates;

        // Convert each keeper date to the desired format
        const formattedDatesArray = keeperDates.map((dateStr) => {
          const parts = dateStr.split(" "); // Split the date string by space
          const month = parts[1];
          const year = parts[2];
          const formattedDate = `${month} ${year}`;
          return { formattedDate, originalDate: dateStr }; // Store both formatted and unformatted dates
        });

        formattedDatesArray.push({
          formattedDate: "Other",
          originalDate: "other",
        });

        // Set the formatted dates in the state variable
        setFormattedDates(formattedDatesArray);

        setVehicleDateModal(true);
        setTimeout(() => {
          findRegRef.current.scrollIntoView({
            behavior: "smooth",
            block: 'end'
          });
        }, 100);

        //GTMDataLayer trigger on find vehicle btn 
        GTMDataLayer({
          clickEvent: 'slideclick',
          question: 'car reg number',
          answer: '',
        });

      } else {
        setError("vehicle_number", { message: "Enter valid vehicle number" });
      }
    } catch (error) {
      console.error("Error validating car registration: ", error);
      setError("vehicle_number", { message: "Network error" });
    } finally {
      document.getElementById("choose1").checked = false;
      document.getElementById("choose2").checked = false;

      // setShowSpinner(false);
  }
  };

  const skipCarRegisterValidationApi = async (
    query_uuid,
    page_name,
    type,
    bank_id
  ) => {
    try {
      // await handleSkip(query_uuid, page_name, type, bank_id);
      const skip_data = {
        type:type,
        bank_id:bank_id
      }
      const response = await handleSkip(
          visitorParameters.visitor_parameters,
          visitorParameters.data,
          JSON.parse(localStorage.getItem("formData")),
          queryString,
          skip_data,
          "skip-vehicle-details",
          page_name
      );
    } catch (error) {
      console.log(error);
    }
  };

  const skipThisPage = () => {
    const bank = formData[currentBankIndex]; // Get the current bank
    let bank_id = bank.id;
    skipCarRegisterValidationApi(query_uuid, page_name, type, bank_id);
    if (currentBankIndex < formData.length - 1) {
      setCurrentBankIndex(currentBankIndex + 1);
    } else {

      history.push(
        "/" +
        DynamicRouteNextPage +
        "?uuid=" +
        visitorParameters.visitor_parameters.uuid +
        "&splitName=" +
        split_name
      );
      //history.push(`/v3/id-upload?uuid=${encodeURIComponent(query_uuid)}`);
    }
    document.getElementById("vehicle_number").value = '';
  


    setVehicleDateModal(false);
  };

  const vehicleModalClick = (value) => {
    setSelectedDate(value);
  };
  const handleBlur = (e) => {
    // e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
    e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')
  };
  const continueClick = async (e) => {
    const splitname = localStorage.getItem('split_name')
    const vehicleNumber = getValues("vehicle_number");
    if (vehicleNumber === "") {
      setError("vehicle_number", { message: "Please Enter Vehicle Number" });
      return false;
    } else if (
      vehicleNumber !== "" &&
      vehicleDateModal &&
      selectedDate === null
    ) {
      setError("selected_value", { message: "Please choose one" });
      return false;
    } else if (vehicleNumber !== "" && !vehicleDateModal) {
      setError("selected_value", {
        message: "Please find your vehicle details.",
      });
      return false;
    }

    if (vehicleNumber != "" && selectedDate != null) {
      setIsLoading('show'); // Show the loader

      // let flag = responseslug.length;
      // for (let i = 0; i < responseslug.length; i++) {

      const bank = formData[currentBankIndex]; // Get the current bank
      let vehicle_data = {
        yourVehicle: "",
        carRegNo: vehicle,
        carYear: year,
        carModel: model,
        carMake: edition,
        pageName: page_name,
        question_option_id: selectedDate,
        question_id: 3,
        bank_id: bank.id, // Include bank ID
      };
      // const storedFormData = JSON.parse(localStorage.getItem("formData"));
      // const formdata = {
      //   storedFormData
      // };
      const response = await storeVehicleDetails(
        visitorParameters.visitor_parameters,
        visitorParameters.data,
        JSON.parse(localStorage.getItem("formData")),
        queryString,
        vehicle_data,
        "store-vehicle-details",
        page_name
      );
      //}
      // if (response) {

      //GTMDataLayer trigger on continue btn for purchase vehicle
      // GTMDataLayer({
      //   clickEvent: 'slideclick',
      //   question: 'purchase vehicle',
      //   answer: '',
      // });
      if(splitname!=='pcp/TCG_L_C_2'){
        GTMDataLayer({
          clickEvent: 'slideclick',
          question: 'purchase vehicle',
          answer: '',
        });
      }

      if (currentBankIndex < formData.length - 1) {
        setSelectedDate(null);
        setCurrentBankIndex(currentBankIndex + 1);
      } else {

        history.push(
          "/" +
          DynamicRouteNextPage +
          "?uuid=" +
          visitorParameters.visitor_parameters.uuid +
          "&splitName=" +
          split_name
        );
        // history.push(`/v3/id-upload?uuid=${encodeURIComponent(query_uuid)}`);
      }
      // }

      setVehicleNumberInput("");
      document.getElementById("vehicle_number").value='';
      setVehicleDateModal(false);

      const radios = document.getElementsByName("chosebtn");
      radios.forEach((radio) => {
        radio.checked = false;
      });

      setTimeout(() => {
        setIsLoading('hide'); // Hide the loader after 3 seconds
      }, 2000);
    }
  };
  const ukvdValidation = (e) => {
    setVehicleNumberInput(e.target.value);
    const value = e.target.value;

    if (value.trim() === "") {
      // If input field is empty, set vehicleDateModal to false
      setVehicleDateModal(false);
    }
    clearErrors("vehicle_number");
    if (vehicleNumberInput == "") {
      setVehicleDateModal(false);
    }
  };

  const renderCarRegistrationArea = () => {
    const currentBank = formData[currentBankIndex];

    return (
      <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
        <label className="animate__animated animate__fadeInUp animate__delay-.5s">
          Enter your Car Registration Number you have with your Lender{" "}
        </label>
        <div className="form_drop col-lg-12 col-12 py-lg-2 py-1 mt-2">
          <div className="vehicle_name">
            {/* <p className="note_cond">Enter Registration</p> */}
            <p className="vehicle_dis_name">
              <span className="top_vhName">
                {currentBank && `${currentBank.label}`}
              </span>
            </p>
          </div>
          <div className="searchbox-wrap pb-2">
            <TextField
              onKeyUp={handleBlur}
              // onkeypress={(e) => carnovalidate(e)}
              onBlur={handleBlur}
              type="text"
              placeholder="ENTER REG"
              name="vehicle_number"
              id="vehicle_number"
              maxlength="13"
              autocomplete="off"
              validation={register({
                required: "Please Enter Vehicle Number",
                // maxLength: {
                //   value: 9,
                //   message: "Please Enter Valid Vehicle Number",
                // },
              })}
            />
            <label
              htmlFor="reg_num"
              id="findVh1"
              className={`mb-0 ${vehicleNumberInput ? "button-blink" : ""}`}
              onClick={handleNextButtonClick}
            >
              Find Vehicle
            </label>
          </div>
          {errors.vehicle_number && (
            <p
              className="px-2 pt-1 mt-1 tiny_note animate__animated animate__fadeInUp animate__delay-.5s"
              style={{
                background: "red",
                borderRadius: "4px",
                width: "100%",
                color: "white",
              }}
            >
              {errors.vehicle_number.message}
            </p>
          )}
          <div
            className={`vehicle_detail py-3 px-3 ${vehicleDateModal && "show d-block"
              }`}
          >
            <p className="vh_na_top">VEHICLE REGISTRATION :  {vehicle}</p>
            <p className="vh_clr_typ">
              Make : {edition}
              <br />
              {/* Model Name and Edition */}
              Model : {model}
            </p>
          </div>
          <div
            className={`field  ${vehicleDateModal && "show d-block"}`}
            id="purchase1"
            style={{ display: "none" }}
          >
            <label>When did you purchase the vehicle?</label>
            <div className="form_drop yes_no_sec col-lg-12 col-12 ">
              <div
                className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                style={{ zIndex: 2, position: "relative" }}
              >
                <div className="choose_ques col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                  <div className="form-check px-0 pb-2">
                    <RadioButtonV2
                      type="radio"
                      className="custom-checkbox"
                      name="chosebtn"
                      id="choose1"
                      value="6"
                      onClick={(e) => {
                        vehicleModalClick(e.target.value);
                        clearErrors("selected_value");
                      }}
                      labelClassName="txt_update"
                      labelName="On or before 27th January 2021"
                      htmlFor="choose1"
                      gtm_question='purchase vehicle'
                      clickevent='slideclick'
                      gtm_answer=''
                      // gtm_trigger={true}
                    />
                  </div>
                  <div className="form-check px-0 pb-2">
                    <RadioButtonV2
                      type="radio"
                      className="custom-checkbox"
                      name="chosebtn"
                      id="choose2"
                      value="7"
                      onClick={(e) => {
                        vehicleModalClick(e.target.value);
                        clearErrors("selected_value");
                      }}
                      labelClassName="txt_update"
                      labelName="On or after 28th January 2021"
                      htmlFor="choose2"
                      gtm_question='purchase vehicle'
                      clickevent='slideclick'
                      gtm_answer=''
                      // gtm_trigger={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {errors.selected_value && (
            <p
              className="px-2 pt-1 mt-1 tiny_note animate__animated animate__fadeInUp animate__delay-.5s"
              style={{
                background: "red",
                borderRadius: "4px",
                width: "100%",
                color: "white",
              }}
            >
              {errors.selected_value.message}
            </p>
          )}
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (loading == 'hide') {
      setTimeout(() => {
        findRegRef.current.scrollIntoView({
          behavior: "smooth",
          block: 'end'
        });
      }, 100);
    }
  }, [loading, currentBankIndex])
  return (
    <>
      <GetVisitorsParams />
      <Loader loader={loading} contentText=""/>
      <div className="TCG_L_C">
        {/* {loading && (
          <div>Loading............</div>
          <div className="loader_full_Pg">
            <div id="wifi-loader">
              <svg className="circle-outer" viewBox="0 0 86 86">
                <circle className="back" cx={43} cy={43} r={40} />
                <circle className="front" cx={43} cy={43} r={40} />
                <circle className="new" cx={43} cy={43} r={40} />
              </svg>
              <svg className="circle-middle" viewBox="0 0 60 60">
                <circle className="back" cx={30} cy={30} r={27} />
                <circle className="front" cx={30} cy={30} r={27} />
              </svg>
              <svg className="circle-inner" viewBox="0 0 34 34">
                <circle className="back" cx={17} cy={17} r={14} />
                <circle className="front" cx={17} cy={17} r={14} />
              </svg>
              <div
                className="text"
                data-text="Vehicle Successfully Added "
                style={{ display: "block" }}
              />
            </div>
          </div>
        )} */}
          <div className="wrapper w-100">
            <div className="main-container">
              <Header />
              <section className="form">
                <form className="main_form" id="step_1_form">
                  <div className="container">
                    <div className="row justify-content-between">
                      <div className="form-left col-lg-7 col-md-12 col-12">
                        <h1 className="head_text mb-3">Car Registration</h1>
                        <p className="sub_head">
                          If you can remember the reg plate of your vehicle(s)
                          please enter it below.{" "}
                          <mark>
                            This will help your lender locate your vehicle(s)
                          </mark>
                        </p>
                        <div
                          className="form-right top-up col-lg-4 col-md-12 col-12 d-block d-lg-none"
                          id="focusHere"
                        >
                          <div className="badge_right">
                            <div className="logo_design">
                              <LogoBasedOnDomain className="" bg="black" />
                              {/* <img src={tcgllogo} alt="" /> */}
                            </div>
                            <div className="content_aware">
                              <p className="mb-0">
                                Your Potential Claim Value:
                              </p>
                              <h2 className="mb-0">
                                £1,100* <span>per vehicle!</span>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="form-bg">
                          <div
                            className="slideques1"
                            id="slideques1"
                            style={{ display: "block" }}
                          >
                            <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
                              {renderCarRegistrationArea()}
                              {/* //////////////// btn_sec_control //////////////// */}
                              <div className="btn_sec_control col-lg-12 col-12 form_drop py-lg-2 py-2">
                                <a className="w-50">
                                  <Button
                                    type="button"
                                    className="button w-100 btn_no_br cmn_btn"
                                    id="addMore"
                                    onClick={() => skipThisPage()}
                                    buttonText="Skip"
                                    gtm_question='car reg number'
                                    clickevent='slideclick'
                                    gtm_answer=''
                                    // gtm_trigger={true}
                                  />
                                </a>
                                <a className="w-50">
                                  <Button
                                    type="button"
                                    className="button w-100 btn_bg_cmn cmn_btn"
                                    id="showSlidenxt"
                                    onClick={continueClick}
                                    buttonText="Continue"
                                    gtm_question='car reg number'
                                    clickevent='slideclick'
                                    gtm_answer=''
                                    // gtm_trigger={true}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-right top-up col-lg-4 col-md-12 col-12 d-none d-lg-block">
                        <div className="badge_right ">
                          <div className="logo_design">
                            <LogoBasedOnDomain className="" bg="black" />
                            {/* <img src={tcgllogo} alt="" /> */}
                          </div>
                          <div className="content_aware">
                            <p className="mb-0 animate__animated animate__fadeInUp animate__delay-.5s">
                              Your Potential Claim Value:
                            </p>
                            <h2 className="mb-0 animate__animated animate__fadeInUp animate__delay-.5s">
                              £1,100* <span>per vehicle!</span>
                            </h2>
                          </div>
                        </div>
                      </div>
                      <span ref={findRegRef} tabIndex={0}></span>
                    </div>
                  </div>
                </form>
              </section>
              {/* Modal */}

              {/* <FAQSection /> */}

              <div className="btm_sec">
                <Footer superScriptQuote = {true}/>
              </div>
            </div>
          </div>
      </div>
    </>
  );
};

export default CarRegistrationV3;
