import React, { useEffect, useState } from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import TCG_L_C_4_Form from "../../Forms/TCG_L_C/TCG_L_C_4_Form";
import FbViewPixel from '../../../Utility/FbViewPixel';
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import Header from "../../Includes/Layouts/TCG_L_C/Header";
import Footer from "../../Includes/Layouts/TCG_L_C/Footer";
import FAQSection from "../../Includes/Layouts/TCG_L_C/FAQSection";
import "../../../assets/css/TCG_L_C_2/custom.scss";
import "../../../assets/css/Modal.scss";
const TCG_L_C_2 = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('TCG_L_C_4', 'TCG_L_C_4');
  const [showFaq, setShowFaq] = useState('show');
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [footerEnable, setFooterEnable] = useState(true);

  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="pcp/TCG_L_C_4" />
      <FbViewPixel />
      <div className="TCG_L_C_2">
        <div className="wrapper w-100">
          <div className="main-container">
            <Header />
            <TCG_L_C_4_Form
              nextPage={DynamicRouteNextPage}
              splitName="pcp/TCG_L_C_4"
              setShowFaq={setShowFaq}
              setFooterEnable={setFooterEnable}
            />
            <FAQSection showFaq={showFaq} />
            <Footer footerEnable={footerEnable} superScriptQuote={!footerEnable} />
          </div>
        </div>
      </div>
    </>
  )
};

export default TCG_L_C_2;
