import { Api } from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const useBanks = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const getBanks = async () => {
    const response = await Api.get("v4/lenders", {})
    .catch(function (error) {
      const erroMessage = {
        serviceName : 'USER BANKS',
        errorReason:error.message,
        errorCode:error.code
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };
  return { getBanks };
};
