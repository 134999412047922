import React, { useState, useContext, useEffect, useRef } from "react";
import tcgl from "../../../assets/img/tcgl-logo.svg";
import "../../../assets/css/CarRegistrationV1/custom.scss";
import spinner from "../../../assets/img/infinite-spinner.svg";
import { useVehicleValidation } from "../../../Hooks/useVehicleValidation";
import { useHistory } from "react-router-dom";
import QueryStringContext from "../../../Contexts/QueryString";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { useForm } from "react-hook-form";
import { useSkipCarRegisterValidation } from "../../../Hooks/useSkipCarRegisterValidation";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { Accordion } from "react-bootstrap";
import { vehiclequestionaire } from "../../../Constants/CarRegistraionV1/Questions";
import { useQuestionnaire } from "../../../Hooks/useQuestionnaire";
import { useQuestionnairev1 } from "../../../Hooks/useQuestionnairev1";
import logoblack from "../../../assets/img/reviewsio-logo-black.png";
import RadioButton from "../../UI/RadioButton";
import TextField from "../../UI/TextField";
import Button from "../../UI/Button";
import RadioButtonV2 from "../../UI/RadioButtonV2";
import CheckBoxV2 from "../../UI/CheckBoxV2";
import { getBrandName } from "../../Includes/Layouts/Common/BrandBasedOnDomain";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";
import Footer from "../../Includes/Layouts/TCG_L_A/Footer";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import AdditionalLender from "../../Includes/Layouts/TCG_L_A/AdditionalLender";
import { useUserInfo } from "../../../Hooks/useUserInfo";
import arrowDown1 from "../../../assets/img/arrow-down-1.png";
import GTMDataLayer from "../../../Utility/GTMDataLayer";
const CarRegistraionV1 = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "TCG_L_A",
    "v1/car-registration"
  );
  const page_name = "pcp/TCG_L_A";
  const [openIndex, setOpenIndex] = useState('');
  const [formattedDates, setFormattedDates] = useState([]);
  const [keeperLatest, setKeeperLatest] = useState(null);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [bankcode, setbankcode] = useState("");
  const [labelIndex, setlabelIndex] = useState("");
  const [nextPageStatus, setnextPageStatus] = useState("");
  const [checkedStatus, setcheckedStatus] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const brandName = getBrandName();
  const { SaveQuestionnaire } = useQuestionnaire();
  const query_string = localStorage.getItem("querystring");
  const [vehicleDateValue, setvehicleDateValue] = useState("");
  const [vehicleDateModal, setVehicleDateModal] = useState(false);
  const { skipRegistrationValidation, skipallValues } = useSkipCarRegisterValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const uuid = visitorParameters.visitor_parameters.uuid ?? localStorage.getItem("uuid");
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [valuesArray, setValuesArray] = useState([]);
  const [show1, setShow1] = useState(true);
  const { getBankInfo } = useUserInfo();
  const [continueErrors, setcontinueErrors] = useState('');
  const [banks, setBanks] = useState([]);
  const [skipArrayValues, setskipArrayValues] = useState([]);
  const [lookupArrayValues, setlookupArrayValues] = useState([]);
  const [skipValues, setskipValues] = useState([]);

  const { register, errors, trigger, setError, clearErrors, getValues, setValue } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const { storeVehicleDetails } = useQuestionnairev1();
  const { isLoading1, validateRegNum } = useVehicleValidation();

  useEffect(() => {
    const fetchData = async () => {
      let storedSelectedBanks =
        JSON.parse(localStorage.getItem("selectedBanks")) || [];
      let storedAdditionalBanks =
        JSON.parse(localStorage.getItem("additionalBanks")) || [];
      if (storedSelectedBanks.length == 0) {
        const response = await getBankInfo(query_uuid);
        const newbanks = response.data.response.map(bank => ({
          bank_code: bank.bank_code,
          id: bank.id,
          value: String(bank.id),
          label: bank.label
        }));
        setBanks(newbanks);
        const initialBankIds = newbanks.map((bank) => bank.id);
        setValuesArray(initialBankIds);
      } else {
        let mergedBanks = [...storedSelectedBanks];
        storedAdditionalBanks.forEach((additionalBank) => {
          if (
            !mergedBanks.find(
              (bank) => bank.bank_code == additionalBank.bank_code
            )
          ) {
            mergedBanks.push(additionalBank);
          }
        });
        setBanks(mergedBanks);
        const initialBankIds = mergedBanks.map((bank) => bank.id);
        setValuesArray(initialBankIds);
      }
    };

    fetchData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [show1]);
  useEffect(() => {
    if (!showLoader) {
      window.scrollTo(0, 30);
    }
  }, [showLoader]);

  useEffect(() => {
    if (valuesArray.length > 0) {
      setOpenIndex(valuesArray[0]);
    } else {
      setOpenIndex('');
    }
  }, [valuesArray]);
  const dataValueChange = (e) => {
    const selectedValue = e.target.value;
    setvehicleDateValue(selectedValue);
    setcontinueErrors('')
  };

  const skipThisPage = async (bank_id, bankname, index) => {
    setskipArrayValues(prevArray => [...prevArray, bank_id]);
    const valueRemoved = valuesArray.filter((value) => value !== bank_id);
    // alert(valueRemoved)
    setValuesArray(valueRemoved);
    setValue(`vehicleNumber_${bank_id}`, '');
    setVehicleNumber("");
    const response = await skipRegistrationValidation(
      query_uuid,
      page_name,
      "car_registration",
      bank_id
    );

    setnextPageStatus("Passed");
    setcheckedStatus("hide");
    clearErrors("vehicle_number");
  };
  const openModal = async (e, bankcode, index) => {
  
    const inputField = e.target.closest('div').querySelector('input');
    const name = inputField.name
    const value = inputField.value
    const bank_id = name.split('_').pop(); 
    const nameSplit = name.replace(/_\d+$/, '')
    const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if(value == ""){

      setError(`vehicleNumber_${bankcode}`, { message: "Please Enter Vehicle Number" });
      return;
    }
    if (specialCharsRegex.test(value)) {
      setError(`vehicleNumber_${bankcode}`, { message: "Please Enter valid Vehicle Number" });
      return;
    }
    setbankcode(bankcode);
    const validationResponse = await validateRegNum(
      value,
      uuid,
      page_name
    );
    if (validationResponse && validationResponse.data.keeper_date) {
      const keeperDatesObj = JSON.parse(validationResponse.data.keeper_date);
      if (keeperDatesObj && keeperDatesObj.keeper_dates) {
        const keeperDates = keeperDatesObj.keeper_dates;
        const latestDate = new Date(
          Math.max(...keeperDates.map((date) => new Date(date)))
        );
        const nextDayDate = new Date(latestDate);
        nextDayDate.setDate(latestDate.getDate() + 1);
        const nextDayDateString = nextDayDate.toISOString().split("T")[0];
        setKeeperLatest(nextDayDateString);
      }
    }
    if (
      validationResponse.status == 200 &&
      validationResponse.data.is_valid == 1
    ) {
      const keeperDateStr = validationResponse.data.keeper_date;
      const keeperDateObj = JSON.parse(keeperDateStr);
      const keeperDates = keeperDateObj.keeper_dates;
      const formattedDatesArray = keeperDates.map((dateStr) => {
        const parts = dateStr.split(" ");
        const month = parts[1];
        const year = parts[2];
        const formattedDate = `${month} ${year}`;
        return { formattedDate, originalDate: dateStr };
      });

      formattedDatesArray.push({
        formattedDate: "Other",
        originalDate: "other",
      });
      setlookupArrayValues(prevArray => [...prevArray, bankcode]);
      if (skipArrayValues.includes(bankcode)) {
        var newSkipValue = skipArrayValues.filter((value) => value !== bankcode);
        setskipArrayValues(newSkipValue);
      }
      setFormattedDates(formattedDatesArray);
      const valueRemoved = valuesArray.filter((value) => value !== bankcode);
      setValuesArray(valueRemoved);
      setVehicleDateModal(true);
      clearErrors("vehicle_number");
      setVehicleNumber(validationResponse.data.car_reg_no);
      // if (
      //   `lendsel_${index}` &&
      //   `${e.target.name.split("_")[0]}_${index}` !== ""
      // ) {
      //   setlabelIndex(`lendsel_${index}`);
      // }

      // GTMDataLayer trigger on lookup btn for car reg number
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'car reg number',
        answer: '',
      });
    }
    else {
      setError(`vehicleNumber_${bankcode}`, { message: "Enter valid vehicle number" });
    }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); //1000
  };
  const continueClick = async (questionId) => {
    setIsLoading(false);
    if (vehicleDateValue !== "") {
      setVehicleDateModal(false);
      setIsLoading(false);
      setcontinueErrors('');
      const answer = vehicleDateValue;
      const bank_id = bankcode;
      const questionvalue = questionId;
      const user_uuid = query_uuid;
      const question_data = {
        option_id: answer,
        bank_id: bank_id,
        question_id: questionvalue,
        user_uuid: user_uuid,
      };
      // const formdata = {
      //   page_name: page_name,
      // };
      const vehicle_data = {
        keeper_date: "",
        current_keeper_date: "",
        yourVehicle: "",
        carRegNo: vehicleNumber,
        pageName: page_name,
        bank_id: bank_id,
        question_id: questionvalue,
        question_option_id: answer,
      };

      const result = await storeVehicleDetails(
        visitorParameters.visitor_parameters,
        visitorParameters.data,
        JSON.parse(localStorage.getItem("formData")),
        queryString,
        vehicle_data,
        "store-vehicle-details",
        page_name
      );
      if (result.status == 200) {
        setnextPageStatus("Passed");
        setcheckedStatus("hide");
        setVehicleNumber("");
        setvehicleDateValue("");
      }
    } else {
      setcontinueErrors('Please select any options.')
     
    }

  };
//   const carnovalidate = (evt) => {
//     const char = String.fromCharCode(evt.charCode);
//     if (!/^[a-zA-Z\s]*$/.test(char)) {
//         evt.preventDefault();
//         return false;
//     }
//     evt.target.value = evt.target.value.trimStart();
// }
  const nextClick = async () => {
    if (valuesArray.length > 0) {
      setError("vehicle_number", {
        message: "Please enter the vehicle number or click the skip button",
      });
    }
    if (vehicleNumber !== "" && nextPageStatus == "" && errors == "") {
      setError("vehicle_number", { message: "Please click Lookup Button" });
    }
    if (valuesArray.length == 0) {
      history.push(
        "/" +
        DynamicRouteNextPage +
        "?uuid=" +
        visitorParameters.visitor_parameters.uuid +
        "&splitName=" +
        page_name
      );
    }
    if (vehicleNumber == "") {
      return;
    }
  };
  const onHandleChange = (event, index) => {
    setVehicleNumber(event.target.value.replace(/\s/g, ''));
    clearErrors(`vehicleNumber_${index}`)
    clearErrors("vehicle_number");
  };
  const handleToggle = (index) => {
    setOpenIndex(openIndex == index ? null : index);
  };
  const handleBlur = (e) => {
    // e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
    e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')
    clearErrors("vehicle_number");
  };
  const skipAll = async() => {
    const query_uuid = query.get("uuid");
    const page_name = "pcp/TCG_L_A";
    const response = await skipallValues(
      query_uuid,
      page_name,
      "car_registration",
      valuesArray
    );
    if(response.data.status=="Success"){
    history.push(
      "/" +
      DynamicRouteNextPage +
      "?uuid=" +
      visitorParameters.visitor_parameters.uuid +
      "&splitName=" +
      page_name
    );
  }
}
  return (

    <>
      <GetVisitorsParams />
      <div className="car_registration_wrapper">
        {showLoader && (
          <div id="preloader">
            <div className="mt-3" id="loader"></div>
            <h3>Analysing Your Results...</h3>
          </div>
        )}
        <header className="topnav " style={{ background: "#063783" }}>
          <div className="container">
            <nav className="navbar navbar-expand-lg text-center justify-content-lg-start justify-content-center justify-content-sm-center ">
              <a className="navbar-brand animate__animated animate__fadeInUp animate__delay-1s animated-element">
                <LogoBasedOnDomain className="my-lg-1 my-md-1 my-sm-2 my-2 car-header-logo" />
              </a>
            </nav>
          </div>
        </header>
        {!show1 && (
          <section className="car_registration mb-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-12 order-lg-1 order-2">
                  <form className="regwrapper">
                    <h2 className="mt-2">Car Registration</h2>
                    <h3>
                      If you can remember the reg plate of your vehicle(s)
                      please enter it below.{" "}
                      <span className="green-bg">
                        This will help your lender locate your vehicle(s)
                      </span>
                    </h3>
                    <div
                      className="accordion accordion-flush mb-3"
                      id="accordionFlushExample"
                    >
                      <div className="accordion">

                        {banks.map((bank, index) => (
                          <div
                            key={bank.id}
                            className={`accordion-item ${openIndex == bank.id ? "active" : ""
                              }`}
                          >
                            <div
                              className="accordion-title accordion_button collapsed"
                              onClick={() => handleToggle(bank.id)}
                            >
                              <div className="collapsed">
                                <span className={`${skipArrayValues.includes(bank.id) ? 'cancel_check' : lookupArrayValues.includes(bank.id) ? 'check' : 'uncheck'}`}></span>
                                <div>{bank.label}</div>
                              </div>
                              <div className="arrow_btnDown">
                                <img className="arrow-img" src={arrowDown1} />
                              </div>
                            </div>
                            <div className={`accordion-content ${openIndex == bank.id ? 'show' : 'hide'}`}>
                                <div className="material-textfield input-group">
                                  <TextField
                                    type="text"
                                    onKeyUp={handleBlur}
                                    // onkeypress={(e) => carnovalidate(e)}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    placeholder=""
                                    maxlength="13"
                                    autocomplete="off"
                                    name={`vehicleNumber_${bank.id}`}
                                    id={`vehicleNumber_${bank.id}`}
                                     validation={register({
                                      required:
                                        "Please Enter Vehicle Number",
                                      // maxLength: {
                                      //   value: 9,
                                      //   message:
                                      //     "Please Enter Valid Vehicle Number",
                                      // },
                                    })}
                                    onChange={(e) =>
                                      onHandleChange(e, bank.id)
                                    }
                                  />
                                  <label>Enter Vehicle Number</label>
                                  <Button
                                    type="button"
                                    className="reg_lookup_but carlookup"
                                    id="button-addon2"
                                    onClick={(e) =>
                                      openModal(e, bank.id, index)
                                    }
                                    buttonText="Lookup"
                                    dataBsToggle="modal"
                                    dataBsTarget="#purchaseyear"
                                  />
                                </div>
                                {errors[`vehicleNumber_${bank.id}`] &&
                                      errors[`vehicleNumber_${bank.id}`]
                                        .message && (
                                        <span
                                          className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s"
                                          style={{
                                            background: "red",
                                            borderRadius: "4px",
                                            width: "100%",
                                            marginLeft: "0px",
                                            marginTop: "0px",
                                            display: "block",
                                            color: "#fff",
                                            textAlign: "center",
                                            padding: "5px",
                                          }}
                                        >
                                          {
                                            errors[`vehicleNumber_${bank.id}`]
                                              .message
                                          }
                                        </span>
                                      )}
                                <div className="mt-3 text-lg-end text-center">
                                  <Button
                                    onClick={() =>
                                      skipThisPage(
                                        bank.id,
                                        bank.label,
                                        index
                                      )
                                    }
                                    type="button"
                                    className="btn mt-3 center-block back-but sctop"
                                    dataType="back"
                                    dataBsToggle="collapse"
                                    dataBsTarget={`#flush-collapse-${index}`}
                                    ariaExpanded="false"
                                    ariaControls={`flush-collapse-${index}`}
                                    buttonText="Skip"
                                  />

                                </div>
                              </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {errors.vehicle_number && (
                      <p
                        className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s"
                        style={{
                          background: "red",
                          borderRadius: "4px",
                          width: "100%",
                          marginLeft: "0px",
                          marginTop: "0px",
                          display: "block",
                          color: "#fff",
                          textAlign: "center",
                          padding: "5px",
                        }}
                      >
                        &nbsp;
                        {errors.vehicle_number.message}
                      </p>
                    )}
                    <div className="col-lg-12 col-12 text-center text-lg-end">
                      <Button
                        type="button"
                        className="regButton green_color_but w-100"
                        id=""
                        value="Next"
                        name=""
                        onClick={nextClick}
                        buttonText="Next"
                      />
                      <Button type="button" className="btn mt-3 center-block back-but sctop" id="backStep1" onClick={() =>
                      skipAll()
                    }name="back01" buttonText="Skip" />
                    </div>
                  </form>
                </div>
                <div className="col-lg-4 top_right_sec order-lg-2 order-1">
                  <div className="rating text-center d-block d-lg-none">
                    <img src={logoblack} alt="" fetchpriority='hight'/>
                  </div>
                  <div className="fixed">
                    <div className="potential-claim large ">
                      <h4>
                        Your Potential Claim Value: <span>£1,100*</span> per
                        vehicle!
                      </h4>
                    </div>
                    <div className="useful-facts d-none d-lg-block">
                      <h4>USEFUL FACTS</h4>
                      <ul>
                        <li>
                          Millions of UK drivers could be eligible for refunds
                        </li>
                        <li>You can claim for current and past vehicles</li>
                        <li>Start your claim in just 60 seconds</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 top_right_sec order-lg-2 order-3 d-block d-lg-none">
                  <div className="useful-facts">
                    <h4>USEFUL FACTS</h4>
                    <ul>
                      <li>
                        Millions of UK drivers could be eligible for refunds
                      </li>
                      <li>You can claim for current and past vehicles</li>
                      <li>Start your claim in just 60 seconds</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {vehicleDateModal && <div
              className={`modal fade ${vehicleDateModal && "show d-block"}`}
              id="purchaseyear"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content carregform-modal">
                  <div className="modal-header text-center">
                    {/* <img src={tcgl} alt="" /> */}
                    <LogoBasedOnDomain className="" bg="" />
                  </div>
                  <div className="modal-body">
                    {isLoading ? (
                      <div className="loader-wrap loadercarlookup1 text-center">
                        <div className="mt-5 mb-5 loader-style">
                          <img
                            className="mb-2"
                            height="80"
                            src={spinner}
                            alt="Loading..."
                          />
                          <h4>Analysing Your Results...</h4>
                          <h6 className="mt-3">
                            Please wait. This might take a few moments.
                          </h6>
                        </div>
                      </div>
                    ) : (
                      <div id="loadercarlookup">
                        <h3 className="purchase-date">
                          {vehiclequestionaire.question}
                        </h3>
                        <div className="choose_date col-lg-12 col-12">
                          {vehiclequestionaire.answers.map((answer, index) => (
                            <div
                              className="form-check px-0 col-lg-6 col-12"
                              key={index}
                            >
                              <RadioButtonV2
                                className="form-check-input"
                                type="radio"
                                name={`Radioshere4date${bankcode}`}
                                id={`Radios4_${bankcode}_${index + 1}date`}
                                value={answer.value}
                                onClick={dataValueChange}
                                labelClassName="form-check-label"
                                labelName={answer.label}
                              />
                            </div>
                          ))}
                        </div>
                        {continueErrors && (
                          <p
                            className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s"
                            style={{
                              background: "red",
                              borderRadius: "4px",
                              width: "100%",
                              marginLeft: "0px",
                              marginTop: "0px",
                              display: "block",
                              color: "#fff",
                              textAlign: "center",
                              padding: "5px",
                            }}
                          >
                            &nbsp;
                            {continueErrors}
                          </p>
                        )}
                        <div className="mb-3 text-center">
                          <Button
                            type="button"
                            className="regButtonpop w-100 green_color_but mt-3"
                            id=""
                            value="Next"
                            name=""
                            onClick={() =>
                              continueClick(vehiclequestionaire.questionId)
                            }
                            buttonText="Continue"
                            dataBsDismiss="modal"
                            ariaLabel="Close"
                          />
                        </div>
                      </div>
                    )}
                    {/* */}
                  </div>
                </div>
              </div>
            </div>}
          </section>
        )}
        <Footer />
      </div>
      {!showLoader && (
        <AdditionalLender setShow1={setShow1} show1={show1} uuid={query_uuid} />
      )}
    </>
  );
};

export default CarRegistraionV1;
