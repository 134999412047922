import React, { useState } from 'react';
import TextField from "../../../UI/TextField";
import { nameValidate, checkDuplicateExtraPreviousName } from '../../../../Utility/Validations';
const PreviousName = (props) => {
  //Note: txtPrevLName is the field used for Surname field
  const checkNextButtonStaus = () => {
    if (props?.getValues) {
      const values = props.getValues();
      if (values[`txtPrevLName_${props.preCount}`].length >= 3) {
        props?.setShowNextButton && props.setShowNextButton(true);
      }
    }
    props.setSlideValidationError(false);
  }
  return (
    <>
      <fieldset className="p-3">
        <legend>{props.prevName}</legend>

        <div>
          <TextField
            type="text"
            className="form-control"
            placeholder="Previous Surname"
            name={`txtPrevLName_${props.preCount}`}
            id={`txtPrevLName_${props.preCount}`}
            onkeypress={(e) => nameValidate(e)}
            onBlur = {(e) => checkDuplicateExtraPreviousName(e, props.setError)}
            onChange={(e) => { props.clearErrors(`txtPrevLName_${props.preCount}`); checkNextButtonStaus(); e.target.value = e.target.value.trimStart() }}
            validation={props.validation({
              minLength: {
                value: 3,
                message: "Please Enter Valid Previous Surname",
              },
              pattern: {
                value: /^[a-zA-Z\s]+$/,
                message: "Your Name is Invalid. Please Recheck",
              },
            })}
            validationMsg={props.validationMsg[`txtPrevLName_${props.preCount}`]?.message}
          ></TextField>
          <p className="mt-1 mb-0">   (Leave blank if not applicable)</p>
        </div>
      </fieldset>
    </>
  );
}

export default PreviousName;