import React, { useState, useRef } from 'react';
import thump from "../../../../assets/img/thump.png";
import RadioButton from '../../../UI/RadioButton';
import PreviousPostCode from './PreviousPostCode';
import PreviousName from './PreviousName';
import { slideValidation, profanityValidation, preNameDuplicationCheck } from '../../../../Utility/Validations';
import Button from '../../../UI/Button';
import PreviousDetailsConfirm from '../TCG_L_B/PreviousDetailsConfirm';
import InputButton from "../../../UI/InputButton";


const PreviousAddress = (props) => {
  const [previousPostCode, setPreviousPostCode] = useState(false);
  const [previousNameSection, setPreviousNameSection] = useState({ 'prevName1': true, 'prevName2': false, 'prevName3': false });
  const [previousPostcodeSection, setPreviousPostcodeSection] = useState({ 'prevPostcode1': true, 'prevPostcode2': false, 'prevPostcode3': false, 'prevPostcode4': false, 'prevPostcode5': false });
  const previousPostCodeCheckArr = { 'prevPostcode2': [1], 'prevPostcode3': [1, 2], 'prevPostcode4': [1, 2, 3], 'prevPostcode5': [1, 2, 3, 4] };
  const previousNameCheckArr = { 'prevName2': [1], 'prevName3': [1, 2] };
  const scrollToBtn = useRef(null);
  const scrollToAddBtn = useRef(null);
  const [slideValidationError, setSlideValidationError] = useState(false);
  const [previousDetailsPopData, setPreviousDetailsPopData] = useState(false);

  const previousQstClick = (e) => {

    if (e.target.value == 'Yes') {
      setPreviousPostCode(true);
    } else {

      let data = props.getValues();
      console.log("sdsddsdds",data);

      Object.keys(data).forEach(key => {
        if (key.startsWith('txtPrev') && (! key.startsWith('txtPrevL'))) {
          props.setValue(key,'');
        }
      });
      setPreviousPostCode(false);

      props.slideChange(e);
     
    }
  };
  const additionalData = async (e) => {
    var formData = props.getValues();
    if (e.target.name == 'addPrevName') {
      const isValid = await preNameDuplicationCheck(props.getValues,props.setError,props.clearErrors);
      if (!isValid) return;
      var nextSection = Object.entries(previousNameSection).find(([key, value]) => value === false);
      if (nextSection) {
        var addValidationArr = [];
        previousNameCheckArr[nextSection[0]].forEach(element => {
          // addValidationArr.push(`txtPrevSalutation_${element}`, `txtPrevFName_${element}`, `txtPrevLName_${element}`);
          addValidationArr.push(`txtPrevLName_${element}`);
        });
        if (formData[addValidationArr[addValidationArr.length - 1]] == '') {
          props.setError(addValidationArr[addValidationArr.length - 1], {
            type: "manual",
            message: "Please Enter Surname",
          });
          return false;
        }
        const validationCheck = await slideValidation(addValidationArr, props.trigger);
        if (validationCheck == 'Success') {
          setPreviousNameSection((prevState) => ({
            ...prevState,
            [nextSection[0]]: true,
          }));
          setTimeout(() => {
            if (scrollToAddBtn.current) {
              scrollToAddBtn.current.scrollIntoView({
                behavior: "smooth",
                block: 'end'
              });
            }
          }, 100);
        }

      }
    }
    if (e.target.name == 'addPrevPostcode') {
      var nextSection = Object.entries(previousPostcodeSection).find(([key, value]) => value === false);
      if (nextSection) {
        var addValidationArr = [];
        previousPostCodeCheckArr[nextSection[0]].forEach(element => {
          console.log(element);
          addValidationArr.push(`txtPrevPostCode_${element}`, `txtPrevAddress1_${element}`, `txtPrevHouseNumber_${element}`);
        });
        const validationCheck = await slideValidation(addValidationArr, props.trigger);
        if (validationCheck == 'Success') {
          setPreviousPostcodeSection((prevState) => ({
            ...prevState,
            [nextSection[0]]: true,
          }));
          setTimeout(() => {
            scrollToBtn.current.scrollIntoView({
              behavior: "smooth",
              block: 'end'
            });
          }, 100);
        }
      }
    }
  }
  const handleSubmit = async (e) => {
    const isValid = await preNameDuplicationCheck(props.getValues,props.setError,props.clearErrors);
    if (!isValid) return false;
    const values = props.getValues();
    var previousNameArray = [];
    for (let i = 1; i <= 3; i++) {
      previousNameArray[i] = {
        'txtPrevLName': values[`txtPrevLName_${i}`] || null
      };
    }
    var previousPostcodeArray = [];
    for (let i = 1; i <= 5; i++) {
      previousPostcodeArray[i] = {
        'txtPrevPostCode': values[`txtPrevPostCode_${i}`] || null,
        'txtPrevHouseNumber': values[`txtPrevHouseNumber_${i}`] || null
      };
    }
    const isValidPreviousName = previousNameArray.some((element) => {
      return Object.values(element).every((value) => value !== null && value !== '' && value.length >= 3);
    });

    const isValidPreviousPostcode = previousPostcodeArray.some((element) => {
      return Object.values(element).every((value) => value !== null && value !== '');
    });

    const profanityFields = profanityValidation(['txtPrevLName_1', 'txtPrevLName_2', 'txtPrevLName_3'], props.getValues);
    if (profanityFields != 'success') {
      props.setError(profanityFields, { message: "Please Enter valid Name" });
      return false;
    }

    if (isValidPreviousName || isValidPreviousPostcode) {
      setPreviousDetailsPopData(true);
    } else {
      setSlideValidationError(true);
    }
  }

  return (
    <>
      <div className={`slide slide7 ${props.className}`}>
        <div className="form-style">
          <div className="text-center">
            <h2 className="mb-3  title mt-2">
              <img height="50" src={thump} alt="" fetchpriority="high"/> Almost Done...
            </h2>
            <p>Your complaint is almost ready to submit!</p>
            <h2>Do you have any previous address / surname associated with this lender?  </h2>


          </div>

          <div className="wraper-option   mb-3 mt-3    " id="spr">

            <div className="option-btn optionBtn  mb-3 text-center progress-button" id="noPrev"> No
              <RadioButton
                className="radio"
                labelName={``}
                labelClassName=""
                onClick={(e) => { previousQstClick(e) }}
                value={'No'}
                name={`previous_details`}
                id={`previous_details_no`}
                gtm_question={`previous address`}
                gtm_trigger={true}
                gtm_answer={``}
                clickevent={`slideclick`}
                validation={props.validation}
              />
            </div>
            <div className="option-btn optionBtn  mb-2 text-center " id="yestPrev"> Yes
              <RadioButton
                className="radio"
                labelName={``}
                labelClassName=""
                onClick={(e) => { previousQstClick(e) }}
                value={'Yes'}
                name={`previous_details`}
                id={`previous_details_yes`}
                gtm_question={`previous address`}
                gtm_trigger={true}
                gtm_answer={``}
                clickevent={`slideclick`}
                validation={props.validation}
              />
            </div>
          </div>

          {previousPostCode && <div className="mt-2 postcode1">
            {previousNameSection.prevName1 && <PreviousName
              validation={props.validation}
              validationMsg={props.validationMsg}
              preCount='1'
              getValues={props.getValues}
              prevName=" Previous Surname"
              clearErrors={props.clearErrors}
              setSlideValidationError={setSlideValidationError}
              setError={props.setError}
            />}
            {previousNameSection.prevName2 && <PreviousName
              validation={props.validation}
              validationMsg={props.validationMsg}
              preCount='2'
              getValues={props.getValues}
              prevName=" Previous Surname 1"
              clearErrors={props.clearErrors}
              setSlideValidationError={setSlideValidationError}
              setError={props.setError}
            />}
            {previousNameSection.prevName3 && <PreviousName
              validation={props.validation}
              validationMsg={props.validationMsg}
              preCount='3'
              getValues={props.getValues}
              prevName=" Previous Surname 2"
              clearErrors={props.clearErrors}
              setSlideValidationError={setSlideValidationError}
              setError={props.setError}
            />}
            {!previousNameSection.prevName3 &&
              <div className="d-flex  remove-area justify-content-between">
                <div>
                </div>
                <div>
                  <button type="button" className="mt-3 btn btn-dark " id="addPrevName" name="addPrevName" onClick={(e) => additionalData(e)}> <i className="bi bi-plus-circle" id="addPrevName" name="addPrevName"></i> Add More</button>
                </div>

              </div>}
            <span ref={scrollToAddBtn}></span>
            {previousPostcodeSection.prevPostcode1 &&
              <PreviousPostCode
                validation={props.validation}
                validationMsg={props.validationMsg}
                trigger={props.trigger}
                clearErrors={props.clearErrors}
                preCount='1'
                prevAddrs="Previous Address"
                setError={props.setError}
                scrollToEvent={scrollToBtn}
                setSlideValidationError={setSlideValidationError}
              />}

            {previousPostcodeSection.prevPostcode2 && <PreviousPostCode
              validation={props.validation}
              validationMsg={props.validationMsg}
              trigger={props.trigger}
              clearErrors={props.clearErrors}
              preCount='2'
              prevAddrs="Previous Address 1"
              setError={props.setError}
              scrollToEvent={scrollToBtn}
              setSlideValidationError={setSlideValidationError}
            />}
            {previousPostcodeSection.prevPostcode3 && <PreviousPostCode
              validation={props.validation}
              validationMsg={props.validationMsg}
              trigger={props.trigger}
              clearErrors={props.clearErrors}
              preCount='3'
              scrollToEvent={scrollToBtn}
              prevAddrs="Previous Address 2"
              setError={props.setError}
              setSlideValidationError={setSlideValidationError}
            />}
            {previousPostcodeSection.prevPostcode4 && <PreviousPostCode
              validation={props.validation}
              validationMsg={props.validationMsg}
              trigger={props.trigger}
              clearErrors={props.clearErrors}
              preCount='4'
              scrollToEvent={scrollToBtn}
              prevAddrs="Previous Address 4"
              setError={props.setError}
              setSlideValidationError={setSlideValidationError}
            />}
            {previousPostcodeSection.prevPostcode5 && <PreviousPostCode
              validation={props.validation}
              validationMsg={props.validationMsg}
              trigger={props.trigger}
              clearErrors={props.clearErrors}
              preCount='5'
              scrollToEvent={scrollToBtn}
              prevAddrs="Previous Address 4"
              setError={props.setError}
              setSlideValidationError={setSlideValidationError}
            />}

            {!previousPostcodeSection.prevPostcode5 &&
              <div className="d-flex  remove-area justify-content-between">
                <div>
                </div>
                <div>
                  <button type="button" className="mt-3 btn btn-dark " id="addPrevPostcode" name="addPrevPostcode" onClick={(e) => additionalData(e)}> <i className="bi bi-plus-circle" id="addPrevPostcode" name="addPrevPostcode"></i> Add More</button>
                </div>

              </div>}
          </div>}
        </div>
        {slideValidationError && <span className='error_msg'>Please enter a valid previous name or previous address</span>}
        {previousPostCode && <div className="text-center pb-2  animate__animated animate__fadeInUp " ref={scrollToBtn}>
          <Button
            name="previous_details"
            className="mt-3 btn-comm p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3 fw-bold "
            id="next01"
            value="Next"
            type="button"
            buttonText={`Submit`}
            onClick={handleSubmit}
          />
        </div>}

        <div className=" pb-2 ">
          <Button
            name="back06"
            className="btn center-block back-but sctop"
            id="backStep3"
            buttonText="< Back"
            type="button"
            style={{ float: "none" }}
            onClick={props.previousSlideChange}
          />

        </div>
      </div>
      {previousDetailsPopData && <PreviousDetailsConfirm
        getValues={props.getValues}
        slideChange={props.slideChange}
        setPreviousDetailsPopData={setPreviousDetailsPopData}
        previousSlideChange={props.previousSlideChange}
      />}
    </>
  );
}

export default PreviousAddress;