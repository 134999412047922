import React from 'react';
import LogoBasedOnDomain from '../Common/LogoBasedOnDomain';

const Header = () => {
    return (
        <>
            <header className="topnav ">
                <div className="container">
                    <nav className="navbar navbar-expand-lg text-center justify-content-lg-start  justify-content-center">
                        <a className="navbar-brand animate__animated animate__fadeInUp animate__delay-1s animated-element"
                        >
                            <LogoBasedOnDomain className="logo_img" width="836" height="142"/>
                        </a>

                    </nav>
                </div>
            </header>
        </>
    );
};

export default Header;