import { useEffect, useState } from "react";
import Button from "../../UI/Button";
import Select from "react-select";
import CheckBoxV2 from "../../UI/CheckBoxV2";
import { useFollowupAdditionalBank } from "../../../Hooks/useFollowupAdditionalBank";

const FollowUpAddLender = (props) => {
  const { saveFollowupBankIngestion } = useFollowupAdditionalBank();
  const [additionalLenderList1, setAdditionalLenderList1] = useState([]);
  const [additionalLenderList2, setAdditionalLenderList2] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [additionalSelectedBanks, setAdditionalSelectedBanks] = useState([]);
  const followupData = JSON.parse(localStorage.getItem('followup_data'));
  useEffect(() => {
    setAdditionalLenderList1(props.addLenderList.slice(0, 12));
    setAdditionalLenderList2(props.addLenderList.slice(12).sort((a, b) => a.label.localeCompare(b.label)));

  }, []);

  const handleLenderChange = (selectOption) => {
    const selectedBankId = parseInt(selectOption.value);
    const selectedBank = additionalLenderList2.find(
      (bank) => bank.id === selectedBankId
    );
    if (selectedBank) {
      setAdditionalSelectedBanks((prev) => [...prev, selectedBank]);
      setAdditionalLenderList2((prev) =>
        prev.filter((bank) => bank.id !== selectedBankId)
      );
      setSelectedBanks((prev) => [...prev, selectedBank]);
    }
  }
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const bank = props.addLenderList.find((bank) => `${bank.id}` === value);
    if (checked && bank) {
      setSelectedBanks((prev) => [...prev, bank]);
    } else {
      setSelectedBanks((prev) =>
        prev.filter((selectedBank) => selectedBank.id !== bank.id)
      );
    }
  }
  const handleSlideChange = async (e) => {
    await saveFollowupBankIngestion(
      props.uuid,
      followupData,
      selectedBanks,
      'followup_additional_banks'
    );
    localStorage.setItem("additionalBanks",JSON.stringify(selectedBanks));
    props.slideChange(e);
  }

  const checkBoxBankList = additionalLenderList1.map((bank, index) => {
    return (
      <div className="form-check px-0" key={`ab_${index}`}>
        <CheckBoxV2
          className="form-check-input"
          name="additionalLender"
          dataId={`additionalLender_${bank.id}`}
          value={bank.id}
          checked={selectedBanks.some((selectedBank) => selectedBank.id === bank.id)}
          labelClassName="form-check-label lender_pad"
          labelText={bank.label}
          onChange={handleCheckboxChange}
        />
      </div>
    )
  });

  const additionalSelectedBankList = additionalSelectedBanks.map((bank, index) => {
    return (
      <div className="form-check px-0" key={`asb_${index}`}>
        <CheckBoxV2
          className="form-check-input"
          name="additionalLender"
          dataId={`additionalLender_${bank.id}`}
          value={bank.id}
          checked={selectedBanks.some((selectedBank) => selectedBank.id === bank.id)}
          labelClassName="form-check-label lender_pad"
          labelText={bank.label}
          onChange={handleCheckboxChange}
        />
      </div>
    )
  });
  return (
    <>
      <div className="slide3" >
        <div className="ess_info mb-3 col-12">
          <h5 className="text-center">Select additional Lenders</h5>
          <div className="choose_Y_N flex-column col-lg-12 col-12">
            {checkBoxBankList}
            <div className="mb-2 mt-2 drop-in">
              <Select
                className="form-select select1 p-0"
                options={additionalLenderList2}
                name="lender"
                id="selectanother"
                placeholder="Another lender..."
                onChange={handleLenderChange}
              />
            </div>
            {additionalSelectedBankList}
          </div>
        </div>
        <div className="pt-4 pb-2  animate__animated animate__fadeInUp animate__delay-.5s animated-element" style={{display:"contents" }}>
          <Button
            type="button"
            className="main-btn  p-2 w-100 mb-lg-0 mb-sm-0 py-3 fs-5 fw-bold  next4"
            buttonText={<>Next <i className="bi bi-chevron-right bold-icon" id="confirmLender"></i></>}
            id="confirmLender"
            onClick={handleSlideChange} />
        </div>
      </div>
    </>
  )
}

export default FollowUpAddLender;