//import {useState} from 'react';
import { Api, CommonEndApi } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";
import { useErrorWebhook } from "./useErrorWebhook";


export const usePostCodeAddress = () => {

  const { ErrorWebhookTrigger } = useErrorWebhook();
  const getPostcodeAddress = async (postcode, uuid) => {
    const response = await CommonEndApi.get(
      "api/get-addr-list?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&postcode=' + postcode + '&uuid=' + uuid,
      {}
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'GET POSTCODE ADDRESS LIST',
        errorReason: error.message,
        errorCode: error.code
      }
      ErrorWebhookTrigger(erroMessage);
    });;
    return response;
  };
  // const getSplitPostcodeAddress = async (AddressID, postcode, uuid) => {
  //   const response = await CommonEndApi.get(
  //     "api/get-addr-details?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&addressId=' + AddressID + "&uuid=" + uuid,
  //     {}
  //   ).catch(function (error) {
  //     const erroMessage = {
  //       serviceName: 'GET POSTCODE ADDRESS DETAILS',
  //       errorReason: error.message,
  //       errorCode: error.code
  //     }
  //     ErrorWebhookTrigger(erroMessage);
  //   });
  //   return response;
  // };
  const getSplitPostcodeAddress = async (AddressID) => {
    const response = await CommonEndApi.post(
      "api/get-addr-details",
      {
        ProjectCode : EnvConstants.AppAdtopiaUPID,
        Environment: EnvConstants.AppEnv ,
        addressId :  AddressID 
      }
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'GET POSTCODE ADDRESS DETAILS',
        errorReason: error.message,
        errorCode: error.code
      }
      ErrorWebhookTrigger(erroMessage);
    });
    let addressDetails = {};
    if (response.data && typeof response.data === 'object') {
        const addressData = response.data;
        const vendorResponse = addressData.vendor_response || {};
        
        addressDetails = {
            RawAddress: addressData.RawAddress || vendorResponse.RawAddress,
            line_1: addressData.line_1 || vendorResponse.Address?.Lines[0] || "",
            line_2: addressData.line_2 || vendorResponse.Address?.Lines[1] || "",
            line_3: addressData.line_3 || vendorResponse.Address?.Lines[2] || "",
            line_4: addressData.line_4 || vendorResponse.Address?.Lines[3] || "",
            town: addressData.town || vendorResponse.Address?.Lines[4] || "",
            county: addressData.county || vendorResponse.Address?.Lines[5] || "",
            country: addressData.country || vendorResponse.RawAddress?.Location?.Country || "England",
            CountryISO2:vendorResponse.RawAddress?.CountryISO2 || ""
        };
        addressDetails.id = "";
        addressDetails.address = "Please Select Address";
    }
    return addressDetails;
  };
  return { getPostcodeAddress, getSplitPostcodeAddress };
};
