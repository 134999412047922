
import React from 'react';
import loader03 from "../../../../assets/img/loader03.svg";
const Loader = (props) => {
  return (
    <>
      <div className="text-center loader loader1">
        <div className=" py-5">
          <div className="my-5">
            <img height="100" src={loader03} alt="" />
          </div>
          <h2 className="px-4">{props.loderContent}
          </h2>
        </div>
      </div>
    </>
  );
};

export default Loader;