import React, { useState, useEffect } from "react";
import CheckBoxV2 from "../../../UI/CheckBoxV2";
import InputButton from "../../../UI/InputButton";
import Select from "react-select";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";
import Loader from "../../Layouts/TCG_L_C/Loader";
import GetLenders from "../../../../Utility/GetLenders";
import Button from "../../../UI/Button";

const Lenders = (props) => {
  const [banks, setBanks] = useState([]);
  const [remainingBanks, setRemainingBanks] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [validationError, setValidationError] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState('show');
  const { getLenderList, isLoading } = GetLenders();
  const [clickIndex, setClickIndex] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await getLenderList();

      if (response) {
        setLoaderStatus('false');
        const parsedBanks = response;
        setBanks(parsedBanks.slice(0, 11));

        const sortedRemainingBanks = parsedBanks.slice(11).sort((a, b) => a.label.localeCompare(b.label));
        setRemainingBanks(sortedRemainingBanks);
      }

    })();
  }, []);
  const handleSelectChange = (selectedOption) => {
    setValidationError(false);
    const selectedBankId = parseInt(selectedOption.value, 10);
    const selectedBank = remainingBanks.find(
      (bank) => bank.id === selectedBankId
    );
    if (selectedBank) {
      setSelectedBanks((prev) => [...prev, selectedBank]);
    }
    setTimeout(() => setSelectedOption(null), 0);
  };
  const handleCheckboxChange = (event) => {
    setValidationError(false);
    const { id, checked } = event.target;
    const bank =
      banks.find((bank) => `${bank.id}` === id) ||
      selectedBanks.find((bank) => `${bank.id}` === id);
    if (checked && bank) {
      setSelectedBanks((prev) => [...prev, bank]);
    } else {
      setSelectedBanks((prev) =>
        prev.filter((selectedBank) => selectedBank.id !== bank.id)
      );
    }
  };
  const handleSubmit = (e) => {
    if (selectedBanks.length === 0) {
      setValidationError(true);
    } else {
      setValidationError(false);
      localStorage.setItem(
        "selectedBanks",
        JSON.stringify(
          selectedBanks.map((bank) => ({
            bank_code: bank.bank_code,
            id: bank.id,
            value: `${bank.id}`,
            label: bank.label,
          }))
        )
      );
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'lenders',
        answer: '',
      });
      props.slideChange(e);
    }
  };

  const showIndex = (e, index) => {
    e.preventDefault();
    setClickIndex(clickIndex == index ? null : index);
  }
  return (
    <>
      <Loader loader={isLoading} contentText="" />
      <div className={`slide1 ${props.showSlide1}`} id="slide_1">
        <div className="field">
          <label>
            Which of the following lenders did you use to finance your
            vehicle(s)?
            <span className="" style={{fontSize: "16px"}}>Please only select lenders who did not tell you about the commission payments they made</span>
          </label>
          <ul className="options two lender ">
            {banks.map((bank) => (
              <li key={bank.id}>
                <CheckBoxV2
                  className="custom-checkbox"
                  name="lender"
                  value={bank.label}
                  checked={selectedBanks.some(
                    (selectedBank) => selectedBank.id === bank.id
                  )}
                  onChange={handleCheckboxChange}
                  dataId={`${bank.id}`}
                  labelClassName=""
                  labelText={bank.label}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="field mb-3 margin_use animate__animated animate__fadeInUp animate__delay-.5s">
          <Select
            className=""
            options={remainingBanks.filter((bank) => !selectedBanks.some(b => b.id === bank.id))}
            name="lender"
            id="selectanother"
            placeholder="Another lender..."
            onChange={handleSelectChange}
            value={selectedOption}
          />
          <div className="field margin_use pt-1">
            {validationError && (
              <span className="error_msg">Select at least one lender</span>
            )}
          </div>
          {selectedBanks.length > 0 &&
            <fieldset className="ques1 drop-in-2 mt-3">
              <div><b >Lenders Selected :</b> </div>
              <p className="font-11 mb-3 mt-2"> Your lender may be part of a group - to view grouped lenders use the dropdown next to the lender name. By proceeding you are giving us instruction to investigate and submit claims to all lenders in each group. If you need to remove a lender please unselect </p>
              {selectedBanks.map((bank, index) => (
                <div
                  className="col-12 d-inline selectoptions"
                  key={bank.id}
                >
                  <div id="1" className="optionBtn otheroption  p-0">
                    <CheckBoxV2
                      className="custom-checkbox"
                      name="lender"
                      value={bank.label}
                      checked={selectedBanks.some(
                        (selectedBank) => selectedBank.id === bank.id
                      )}
                      onChange={handleCheckboxChange}
                      dataId={`${bank.id}`}
                      labelClassName="selected-defendant group-active pe-0"
                      labelText={
                        <>
                          {bank.label}
                          {bank.sublender.length > 0 &&
                            <div className="selected-defendant group-active">
                              <Button className="group-button" buttonText="Group" onClick={(e) => showIndex(e, index)} />
                            </div>
                          }
                        </>
                      }
                    />
                  </div>
                  {bank.sublender.length > 0 &&
                    <>
                      {/* <div className="selected-defendant group-active">
                        <Button className="group-button" buttonText="Group" onClick={(e) => showIndex(e, index)} />
                      </div> */}
                      {clickIndex === index && (
                        <div className="sublender-div">
                          <p className="b-0"><b>Lenders in this group:</b></p>
                          {bank.sublender.map((item, idx) => (
                            <div key={idx}>
                              <p>{item.label}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </>}
                </div>
              ))}
            </fieldset>
          }
          <div className=" pt-3">
            <ul>
              <li>
                <InputButton
                  name="slide1"
                  className="btn center-block next-but sctop"
                  id="next01"
                  value="Next"
                  btnType="button"
                  style={{ float: "none" }}
                  onClick={handleSubmit}
                  gtm_question='lenders'
                  clickevent='slideclick'
                  gtm_answer=''
                // gtm_trigger={true}
                />
              </li>
              <li>
              <InputButton
                name="backLender"
                className={`btn center-block back-but sctop ${props.btnStatus}`}
                id="backLender"
                value="Back"
                btnType="button"
                style={{ float: "none" }}
                onClick={props.previousSlideChange}
              />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lenders;
