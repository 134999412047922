import React, { useEffect, useState } from "react";
import logo from "../../../../assets/img/TCG_L_D/logo.webp";
import gbp_4238 from "../../../../assets/img/TCG_L_D/gbp_4238.png";
import RadioButtonV2 from "../../../UI/RadioButtonV2";
import AdditionalLender from "./AdditinalLender";
import { useUserInfo } from "../../../../Hooks/useUserInfo";
import LogoBasedOnDomain from "../../../Includes/Layouts/Common/LogoBasedOnDomain";

const AdditionalLenderModal = (props) => {
  const handleClick = (e) => {
    const { value, name } = e.target;
    if (value == 1) {
      props.slideChange(name);
    } else {
      props.slideChange(name);
    }
  };
  return (
    <>
      <div
        className={`modal bg-dark ${props.show}`}
        id="moreLender"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className={`modal-dialog modal-dialog-centered   modal-md `}>
          <div className="modal-content">
            <div className="modal-header py-3 text-center bg-pophead text-center justify-content-center">
              <LogoBasedOnDomain height="30"/>
              {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body  creditpopup">
              <div className="pb-3">
                <div className={`likeLender ${props.showRadio}`}>
                  <div className="text-center">
                    <div className="p-3 mb-2 bg-warning-subtle text-warning-emphasis rounded-2">
                      <img height={50} src={gbp_4238} alt="" />
                      <h6 className="  lh-base mt-2  fw-bold">
                        {" "}
                        Most drivers have taken out several vehicle finance
                        between 2007 and 2021.
                      </h6>
                      <h6 className=" lh-base fw-bold">
                        {" "}
                        Each has a potential claim value up to £1,100.
                      </h6>
                    </div>
                    <h5 className=" lh-base  mt-4">
                      {" "}
                      Would you like to add more Lenders?
                    </h5>
                  </div>
                  <div className="row mt-4 mb-1">
                    <div className="col-6">
                      <div className="optionBtn blackoption p-0  drop-in  w-100   fs-6 text-start pointer shadow-lg ">
                        <RadioButtonV2
                          type="radio"
                          className="radio"
                          id="yesLender"
                          name="Yes"
                          value="1"
                          labelName="Yes"
                          divClass="pe-2"
                          spanclass="me-1"
                          labelClassName="d-flex align-items-center p-2  p-lg-2"
                          onClick={(e) => handleClick(e)}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="optionBtn blackoption p-0  drop-in  w-100   fs-6 text-start pointer shadow-lg ">
                        <RadioButtonV2
                          type="radio"
                          className="radio "
                          id="noLender"
                          name="No"
                          value="2"
                          labelName="No"
                          divClass="pe-2"
                          spanclass="me-1"
                          labelClassName="d-flex align-items-center p-2  p-lg-2"
                          onClick={(e) => handleClick(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <AdditionalLender
                  show={props.showLender}
                  slideChange={props.slideChange}
                  selectedBanks={props.banks}
                  remainingBanks={props.remainingBanks}
                  addBank={props.addBank}
                  uuid={props.uuid}
                  authrizeSubmit={props.authrizeSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdditionalLenderModal;
