import React, { useEffect, useState } from "react";
import Header from "../../Includes/Layouts/TCG_L_A/Header";
import TCG_L_A_Form from "../../Forms/TCG_L_A/TCG_L_A_Form";
import FAQSection from "../../Includes/Layouts/TCG_L_A/FAQSection";
import Footer from "../../Includes/Layouts/TCG_L_A/Footer";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from '../../../Utility/FbViewPixel';
import review from "../../../assets/img/reviewsio-logo-black.png"
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import "../../../assets/css/TCG_L_A/custom.scss";
const TCG_L_A = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('TCG_L_A', 'TCG_L_A');
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [showFaq, setShowFaq] = useState('show');
  const [footerEnable,setFooterEnable] = useState(true);

  useEffect(() => {
    setLoaderStatus(false);
  }, []); 
  
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="pcp/TCG_L_A" />
      <FbViewPixel />
      {loaderStatus &&
        <div className="TheClaimsGuys_ver_A">
          <div id="preloader">
            <div className="mt-3" id="loader"></div>
            <h3>{``}</h3>
          </div>
        </div>}

      {!loaderStatus && <div className="TheClaimsGuys_ver_A">
        <Header />
        <section className=" banner_part">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-12 form-area order-lg-1 order-2 ">
                <h2>
                  Have you had a car on finance<strong> before 2021?</strong>
                </h2>
                <h3>
                  You could be owed <strong> £1,000s </strong>in compensation
                </h3>
                <TCG_L_A_Form
                  nextPage={DynamicRouteNextPage}
                  splitName="pcp/TCG_L_A"
                  setShowFaq={setShowFaq}
                  setFooterEnable={setFooterEnable}
                  setLoaderStatus={setLoaderStatus}
                />
              </div>
              <div className="col-lg-4 top_right_sec order-lg-2 order-1">
                <div className="rating text-center d-block d-lg-none">
                  <img src={review} alt="" fetchpriority="high"/>
                </div>
                <div className="fixed">
                  <div className="potential-claim large ">
                    <h4>
                      Your Potential Claim Value: <span>£1,100*</span> per vehicle!
                    </h4>
                  </div>
                  <div className="useful-facts d-none d-lg-block">
                    <h4>USEFUL FACTS</h4>
                    <ul>
                      <li>Millions of UK drivers could be eligible for refunds</li>
                      <li>You can claim for current and past vehicles</li>
                      <li>Start your claim in just 60 seconds</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* for mobile view */}
              <div className="col-lg-4 top_right_sec order-lg-2 order-3  d-block d-lg-none">
                <div className="useful-facts">
                  <h4>USEFUL FACTS</h4>
                  <ul>
                    <li>Millions of UK drivers could be eligible for refunds</li>
                    <li>You can claim for current and past vehicles</li>
                    <li>Start your claim in just 60 seconds</li>
                  </ul>
                </div>
              </div>
              {/* for mobile view */}
            </div>
          </div>
        </section>
        <FAQSection showFaq={showFaq} />
        <Footer footerEnable={footerEnable} />
      </div>
      }
    </>
  )
};

export default TCG_L_A;

