import React, { useState } from "react";
import { DateOfBirth } from "./DateOfBirth";
import SelectBox from "../../../UI/SelectBox";
import { Salutation } from "../../../../Constants/Constants";
import { slideValidation, nameValidate, profanityValidation } from "../../../../Utility/Validations";
import TextField from "../../../UI/TextField";
import Button from "../../../UI/Button";
import InputButton from "../../../UI/InputButton";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const PersonalDetails = (props) => {
  const handleNextClick = async (e) => {
    const surname = { txtLName: props.getValues().txtLName };
    localStorage.setItem('sur_names', JSON.stringify(surname));
    const validationArr = [
      "lstSalutation",
      "txtFName",
      "txtLName",
      "DobDay",
      "DobMonth",
      "DobYear",
    ];
    const profanityFields = profanityValidation(['txtFName', 'txtLName'], props.getValues);
    if (profanityFields != 'success') {
      props.setError(profanityFields, { message: "Please Enter valid Name" });
      return false;
    }
    const validationCheck = await slideValidation(validationArr, props.trigger);
    if (validationCheck == "Success") {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'personal details',
        answer: '',
      });
      props.slideChange(e);
    }
  };
  return (
    <>
      <div className={`slide slide2 ${props.className}`}>
        <div className="form-style">
          <h2 className="mb-3  title title2   mt-2  animate__animated animate__fadeInUp   ">
            Enter Personal Details
          </h2>
          <div className="row">
            <div className="col-lg-2 mb-3  animate__animated animate__fadeInUp  ">
              <SelectBox
                className="form-select select1"
                OptionValue={Salutation}
                name="lstSalutation"
                id="lstSalutation"
                onChange={() => props.clearErrors("lstSalutation")}
                clearErrors={props.clearErrors}
                myRef={props.validation({ required: "Please Select Title" })}
                validationMsg={
                  props.validationMsg.lstSalutation &&
                  props.validationMsg.lstSalutation.message
                }
              ></SelectBox>
            </div>
            <div className="col-lg-5 ps-lg-0   animate__animated animate__fadeInUp  ">
              <div className="mb-3">
                <TextField
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  name="txtFName"
                  id="txtFName"
                  onkeypress={(e) => nameValidate(e)}
                  onChange={(e) => {
                    props.clearErrors("txtFName");
                    e.target.value = e.target.value.trimStart();
                  }}
                  onBlur={(e) => {
                    e.target.value = e.target.value.trim();
                  }}
                  validation={props.validation({
                    required: "Please Enter First Name",
                    minLength: {
                      value: 3,
                      message: "Please Enter Valid First Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid. Please Recheck",
                    },
                  })}
                ></TextField>
                {props.validationMsg.txtFName && (
                  <span className="error_msg error" id="txtFName_err">
                    {props.validationMsg.txtFName.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-5 ps-lg-0 animate__animated animate__fadeInUp  ">
              <div className="mb-3">
                <TextField
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  name="txtLName"
                  id="txtLName"
                  onkeypress={(e) => nameValidate(e)}
                  onChange={(e) => {
                    props.clearErrors("txtLName");
                    e.target.value = e.target.value.trimStart();
                  }}
                  onBlur={(e) => {
                    e.target.value = e.target.value.trim();
                  }}
                  validation={props.validation({
                    required: "Please Enter Last Name",
                    minLength: {
                      value: 3,
                      message: "Please Enter Valid Last Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid. Please Recheck",
                    },
                  })}
                ></TextField>
                {props.validationMsg.txtLName && (
                  <span className="error_msg error" id="txtLName_err">
                    {props.validationMsg.txtLName.message}
                  </span>
                )}
              </div>
            </div>
            <div className="date-birth mt-2 mb-3 ">
              <div className="  mb-3  title title2   mt-2   ">
                Date Of Birth
              </div>
              <div className="row px-md-n5">
                <DateOfBirth
                  clearErrors={props.clearErrors}
                  validation={props.validation}
                  validationMsg={props.validationMsg}
                  getValues={props.getValues}
                  setError={props.setError}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pb-2  animate__animated animate__fadeInUp ">
              <Button
                type="button"
                className="btn-comm p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3  progress-button fw-bold"
                id="personalDetails"
                onClick={(e) => handleNextClick(e)}
                name={`personalDetails`}
                buttonText={`Next >`}
              />
              {" "}
              <Button
                name="back01"
                className="btn mt-3 center-block back-but sctop"
                id="backStep1"
                buttonText="< Back"
                type="button"
                style={{ float: "none" }}
                onClick={props.previousSlideChange}
              />
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
