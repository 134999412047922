import React, { useState } from "react";
import iconfaq from "../../../../assets/img/TCG_L_D/icon-faq.png"
import { TCG_L_D } from "../../../../Constants/SplitFaq";

const FAQ = () => {
  const [clickindex,setClickIndex] = useState(null)
  const handleClick = (index) =>{
    setClickIndex( clickindex === index ? null : index);
  }
  return (
    <>
      <section className="faqsection bg-white py-5">
        <div className="container">
          <div className="row title-sec ">
            <div className="col-lg-12 ">
              <h2 className="fs-3 pt-lg-3 pt-md-3 pt-sm-3 pt-0 text-center align-items-center  d-lg-flex justify-content-center">
                <div className="me-4 mb-2">
                  <img height={80} src={iconfaq} alt="" />
                </div>
                <div>Frequently Asked Questions</div>
              </h2>
            </div>
          </div>
          <div className="accordion my-lg-5 my-md-5 my-sm-4 my-3" id="accordFAQ">

            {
              TCG_L_D.map((item,index) => {
                return (
                  <div key={index} className="accordion-item">
                    <h2 className="accordion-header" id="FAQone">
                      <button
                        className={`accordion-button ${ clickindex !==index ?  `collapsed` : ""}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                        onClick={() =>handleClick(index)}
                      >
                        {item.heading}
                      </button>
                    </h2>
                    {clickindex === index &&
                    <div
                      id="collapseOne"
                      className={`accordion-collapse collapse ${clickindex === index ? `show` : ''}`}
                      aria-labelledby="FAQone"
                      data-bs-parent="#accordFAQ"
                      key={index}
                    >
                      <div className="accordion-body">
                        {item.content}
                      </div>
                    </div>
                     }
                  </div>  
                )
              }
              )}
          </div>
        </div>
      </section>
    </>
  )
}
export default FAQ;