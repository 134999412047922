import React from "react";

const CheckBoxV4 = ({
    value,
    CheckBoxText,
    name,
    checked,
    onChange,
    dataId,
    className,
    labelClassName,
    labelText,
    onClick,
    validation,
    disable,
    spanclass,
    divClass

}) => {
    return (
        <>
            <input
                type="checkbox"
                className={className}
                value={value}
                id={dataId}
                name={name}
                checked={checked}
                onChange={onChange}
                onClick={onClick}
                ref={validation}
                disabled={disable}
            />
                <label
                    className={labelClassName}
                    htmlFor={dataId}>
                    <div className={divClass}>
                        <span className={spanclass} />{" "}
                    </div>
                    {labelText}
                </label>
        </>
    );
};
export default CheckBoxV4;
