import { Api } from "../api/Api";

export const useSkipCarRegisterValidation = () => {
  const skipRegistrationValidation = async (uuid, page_name,type,bank_id) => {
    return await Api.post("v1/ukvd-delete-regno-on-skip", {
      uuid,
      page_name,
      type,
      bank_id
    });
  };
  const skipallValues = async (uuid, page_name,type,bank_array) => {
    return await Api.post("v1/skip-all-values", {
      uuid,
      page_name,
      type,
      bank_array
    });
  };

  return { skipRegistrationValidation, skipallValues };
};
