export const getBrandName = () => {
    const domainName = window.location.hostname;
    if (domainName === 'localhost' || domainName === 'dev.claimfinder.co.uk'
        || domainName === 'pre.claimfinder.co.uk' || domainName === 'claimfinder.co.uk') {
        return 'Claim Finder';
    } else if (domainName === 'dev.refundsnow.co.uk' || domainName === 'pre.refundsnow.co.uk'
        || domainName === 'refundsnow.co.uk') {
        return 'Refunds Now';
    } else if (domainName === 'dev.thepcplist.co.uk' || domainName === 'pre.thepcplist.co.uk'
        || domainName === 'thepcplist.co.uk') {
        return 'Thepcplist';
    } else {
        return 'Claim Finder';
    }
  };
  