import React, { useState } from 'react';
import InputButton from "../../../UI/InputButton";
import { Salutation } from "../../../../Constants/Constants";
import TextField from "../../../UI/TextField";
import SelectBox from '../../../UI/SelectBox';
import DateOfBirth from "../TCG_L_C/DateOfBirth";
import { monthMapping } from '../../../../Constants/Constants';
import ProgressBar from "../../../Includes/Layouts/TCG_L_C/ProgressBar";
import { slideValidation, nameValidate, profanityValidation } from '../../../../Utility/Validations';
import GTMDataLayer from '../../../../Utility/GTMDataLayer';

const PersonalDetails = (props) => {
  const [valids, setValids] = useState('');
  const handleSubmit = async (e) => {
    const validationArr = ['lstSalutation', 'txtFName', 'txtLName', 'DobDay', 'DobMonth', 'DobYear'];
    const values = props.getValues();
    const surname = { txtLName: values['txtLName'] };
    localStorage.setItem('sur_names', JSON.stringify(surname));
    const dob = new Date(values['DobYear'], monthMapping[values['DobMonth']], values['DobDay']);
    const EndDate = new Date(2000, 5, 1);
    const profanityFields = profanityValidation(['txtFName', 'txtLName'], props.getValues);
    if (profanityFields != 'success') {
      props.setError(profanityFields, { message: "Please Enter valid Name" });
      return false;
    }
    if (monthMapping[values['DobMonth']] == 1 && values['DobDay'] >= 29) {
      const leapYear = ((values['DobYear'] % 4 === 0) && (values['DobYear'] % 100 !== 0)) || (values['DobYear'] % 400 === 0) ? true : false;
      if ((leapYear == true && values['DobDay'] > 29) || (leapYear == false && values['DobDay'] >= 29)) {
        setValids('Please enter a valid date');
        return;
      } else {
        setValids('');
      }
    }
    if (dob >= EndDate) {
      setValids('Please Enter a date before 01/6/2000');
    } else {
      setValids('');
      const validationCheck = await slideValidation(validationArr, props.trigger);
      if (validationCheck == 'Success') {
        GTMDataLayer({
          clickEvent: 'slideclick',
          question: 'personal details',
          answer: '',
        });
        props.slideChange(e);
      };

    }
  }
  return (
    <>
      <div className={`slide2 ${props.showSlide2}`} id="slide_2">
        <ProgressBar load="load" />
        <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
          <label className="">
            Enter Your Personal Details</label>
          <div className="personal_detail animate__animated animate__fadeInUp animate__delay-.5s">
            <div className="field pb-3">
              <label className="mb-1 fs-6">Title</label>
              <SelectBox
                className="form-select select1"
                OptionValue={Salutation}
                name="lstSalutation"
                id="lstSalutation"
                myRef={props.validation({
                  required: "Select Title",
                  message: "Please Select Title",
                })}
                validationMsg={props.validationMsg.lstSalutation?.message}
                onChange={() => {
                  props.clearErrors(`lstSalutation`);
                }}
              />
            </div>
            <div className="field pb-3">
              <label className="mb-1 fs-6">First name</label>
              <div className="field-icon">
                <TextField
                  type="text"
                  className="capitalised-input"
                  placeholder="First Name"
                  name="txtFName"
                  id="txtFName"
                  autocomplete="off"
                  onkeypress={(e) => nameValidate(e)}
                  validation={props.validation({
                    required: "Please Enter First Name",
                    minLength: {
                      value: 3,
                      message: "Please Enter Valid First Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid",
                    },
                  })}
                  validationMsg={props.validationMsg.txtFName?.message}
                  onChange={() => {
                    props.clearErrors(`txtFName`);
                  }}
                />
              </div>
            </div>
            <div className="field pb-3">
              <label className="mb-1 fs-6">Last name</label>
              <div className="field-icon">
                <TextField
                  type="text"
                  className="capitalised-input"
                  placeholder="Last Name"
                  name="txtLName"
                  id="txtLName"
                  autocomplete="off"
                  onkeypress={(e) => nameValidate(e)}
                  validation={props.validation({
                    required: "Please Enter Last Name",
                    minLength: {
                      value: 3,
                      message: "Please Enter Valid Last Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid",
                    },
                  })}
                  validationMsg={props.validationMsg.txtLName?.message}
                  onChange={() => {
                    props.clearErrors(`txtLName`);
                  }}
                />
              </div>
            </div>
          </div>
          <label className="pt-2 animate__animated animate__fadeInUp animate__delay-.5s">
            Enter Your Date Of Birth</label>
          <DateOfBirth
            validation={props.validation}
            validationMsg={props.validationMsg}
            valids={valids}
            clearErrors={props.clearErrors}
          />
        </div>

        <div className="form-navigation pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
          <ul>
            <li>
              <InputButton
                name="slide2"
                className="btn center-block next-but sctop"
                id="next02"
                value="Next"
                btnType="button"
                style={{ float: "none" }}
                onClick={handleSubmit}
                gtm_question='personal details'
                clickevent='slideclick'
                gtm_answer=''
              // gtm_trigger={true}
              />
            </li>
            <li>
              <InputButton
                name="back1"
                className="btn center-block back-but sctop"
                id="backStep1"
                value="Back"
                btnType="button"
                style={{ float: "none" }}
                onClick={props.previousSlideChange}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default PersonalDetails;