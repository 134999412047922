import { useState } from "react";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";
import FollowUpFooter from "./FollowUpFooter";
import FollowUpLoader from "./FollowUpLoader";

const FollowUpThankYou = () => {
  const [loaderStatus, setLoaderStatus] = useState('show');
  setTimeout(() => {
    setLoaderStatus('hide')
  }, 2000);
  localStorage.clear();
  return (
    <>
      <FollowUpLoader status={loaderStatus} />
      <div className="TGGtcg">
        <div className="bg_grade bg_clor">
          <header id="header" className="sticky-header position-relative">
            <div className="container text-center">
              <a><LogoBasedOnDomain /></a>
            </div>
          </header>
          <div className="container">
            <div className="row mt-5 pb-5 justify-content-center">
              <div className="col-lg-6">

                <div className="check-tik mb-5">
                  <div className="circle-border"></div>
                  <div className="circle">
                    <div className="success"></div>
                  </div>
                </div>
                <h1 className="fw-bold text-center text-white">Thank You</h1>
                <p className="text-center text-white">We will be in touch regarding the next steps and updates.</p>
              </div>
            </div>
          </div>
        </div>


        <FollowUpFooter />

      </div>
    </>
  )
}


export default FollowUpThankYou;