import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useContext
} from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../Contexts/QueryString";
import * as EnvConstants from "../../../Constants/EnvConstants";
import { questionnaire_TCG_L_B } from "../../../Constants/Questions";
import Lenders from "../../Includes/Layouts/TCG_L_B/Lenders";
import PersonalDetails from "../../Includes/Layouts/TCG_L_B/PersonalDetails";
import PostCode from "../../Includes/Layouts/TCG_L_B/PostCode";
import BasicQuestions from "../../Includes/Layouts/TCG_L_B/BasicQuestions";
import PreviousAddress from "../../Includes/Layouts/TCG_L_B/PreviousAddress";
import ProgressBar from "../../Includes/Layouts/Common/ProgressBar";
import Loader from "../../Includes/Layouts/Common/Loader";
import ContactDetails from "../../Includes/Layouts/TCG_L_B/ContactDetails";
import BasicQuestionCheckbox from "../../Includes/Layouts/TCG_L_B/BasicQuestionCheckbox";
import { getBrandName } from "../../Includes/Layouts/Common/BrandBasedOnDomain";
import { useQuestionnaire } from "../../../Hooks/useQuestionnaire";

const TCG_L_B_Form = (props) => {
  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
    showSlide7: "hide"
  };
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "bank") {
          props.setFooterEnable(false);
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "personalDetails") {
          props.setFooterEnable(false);
          return {
            ...state,
            showSlide2: "hide",
            showSlide3: "show",
          };
        } else if (action.payload.clickedSlide.slide === "postcode") {
          props.setFooterEnable(false);
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "contactDetails") {
          props.setFooterEnable(false);
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show",
          };
        } else if (action.payload.clickedSlide.slide === "question_1") {
          props.setFooterEnable(false);
          return {
            ...state,
            showSlide5: "hide",
            showSlide6: "show",
          };
        } else if (action.payload.clickedSlide.slide === "question_2") {
          props.setFooterEnable(false);
          return {
            ...state,
            showSlide6: "hide",
            showSlide7: "show",
          };
        } else if (action.payload.clickedSlide.slide === "slide-confirm") {
          props.setFooterEnable(false);
          return {
            ...state,
            showSlide7: "hide"
          };
        } else {
          return {
            ...state,
          };
        }
      }
      case "previousSlides": {
        if (action.payload.previousSlide.slide == "back01") {
          props.setFooterEnable(true);
          return {
            ...state,
            showSlide1: "show",
            showSlide2: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back02") {
          return {
            ...state,
            showSlide2: "show",
            showSlide3: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back03") {
          return {
            ...state,
            showSlide3: "show",
            showSlide4: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back04") {
          return {
            ...state,
            showSlide4: "show",
            showSlide5: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back05") {
          return {
            ...state,
            showSlide5: "show",
            showSlide6: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back06") {
          return {
            ...state,
            showSlide6: "show",
            showSlide7: "hide",
          };
        } else if (action.payload.previousSlide.slide == "slide-confirm-back") {
          return {
            ...state,
            showSlide7: "show",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      default: {
      }
    }
  };
  const splitForm = useRef(null);
  const scrollToDiv = useRef([]);
  const scrolltobtn = useRef([]);
  const progressValueRef = useRef(null);
  const progressBarRef = useRef(null);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState('d-none');
  const triggerLoader = ["bank", "postcode"];
  const progressBar = progressBarRef.current;
  const progressValue = progressValueRef.current;
  const brandName = getBrandName();
  const { SaveQuestionnaire } = useQuestionnaire();

  const { register, errors, trigger, setError, clearErrors, getValues,setValue } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  useEffect(() => {
    if (loaderStatus) {
      const loaderTimer = setTimeout(() => {
        setLoaderStatus(false);
      }, 1000);
      return () => clearTimeout(loaderTimer);
    }
  }, [loaderStatus]);
  const slideChange = (e) => {
    props.setShowFaq('hide');
    setShowProgressBar('d-block');
    getProgressBarValue();
    const values = getValues();
    const formData = values;
    if (triggerLoader.includes(e.target.name)) setLoaderStatus(true);
    switch (e.target.name) {
      case "contactDetails":
        formSubmit();
        triggerSildeChange(e.target.name);
        break;
      case "question_1":
      case "question_2":
        saveSplitQuestionnaire(e.target.name)
        triggerSildeChange(e.target.name);
        break;
      case "slide-confirm":
      case "previous_details":
        setShowProgressBar('d-none');
        submitOtherFormDetails();
        break;
      default:
        triggerSildeChange(e.target.name);
        break;
    }
  };
  const previousSlideChange = (e) => {
    // setTimeout(() => {
    //   scrollfrmv2div.current.scrollIntoView({
    //     behavior: "smooth",
    //     top: 0
    //   });
    // }, 50);
    // setError("txtLName", { message: "" });
    e.target.getAttribute("name") == 'back01' ? props.setShowFaq('show') : props.setShowFaq('hide');
    getProgressBarValueBack();
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };
  const getProgressBarValue = () => {
    if (progress <= 96) {
      const value = progress + 16;
      setProgress(value);
      progressBar.style.width = value + "%";
      progressValue.textContent = value + "%";
    }
  };
  const getProgressBarValueBack = () => {
    if (progress <= 96) {
      const value = progress - 16;
      setProgress(value);
      progressBar.style.width = value + "%";
      progressValue.textContent = value + "%";
    }
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: "previousSlides", payload: { previousSlide } });
    }
  }, [previousSlide]);

  const formSubmit = async () => {
    const form = splitForm.current;
    const values = getValues();
    const formData = values;
    formData.page_name = props.splitName;
    formData.pCode_manual = form["pCode_EntryType"].value;
    const selectedBanks = JSON.parse(localStorage.getItem('selectedBanks'));
    if (selectedBanks) {
      formData.bank = selectedBanks.map(bank => bank.value);
    }
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("queryString", queryString);
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "split_form_submit",
        "pcp/TCG_L_B",
        queryString,
        visitorParameters.data
      );
    }
  };

  const triggerSildeChange = (slideName) => {
    setTimeout(() => {
      scrollToDiv.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 100);
    setClickedSlide({ slide: slideName });
  }

  const saveSplitQuestionnaire = async (questionId) => {
    const formdata = getValues();
    formdata.page_name = props.splitName;
    const selectedBanks = JSON.parse(localStorage.getItem('selectedBanks'));
    if (selectedBanks) {
      formdata.bank = selectedBanks.map(bank => bank.value);
    }
    let question_data = {
      question_id: questionId.split('_')[1],
      option_id: formdata[questionId],
      input_answer: null,
      bankId: null
    };
    localStorage.setItem("formData", JSON.stringify(formdata));
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      queryString,
      formdata
    )
  }

  const submitOtherFormDetails = async () => {
    const formData = getValues();
    const selectedBanks = JSON.parse(localStorage.getItem('selectedBanks'));
    if (selectedBanks) {
      formData.bank = selectedBanks.map(bank => bank.value);
    }
    if (formData.previous_details == 'Yes') {
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "extra_form_data_previous_details",
        "pcp/TCG_L_B",
        queryString,
        visitorParameters.data
      );
    }
    localStorage.setItem("formData", JSON.stringify(formData));
    if (queryString != null) {
      console.log("first if");
      console.log(queryString);
      if (queryString.indexOf("&" + field + "=") !== -1) {
        console.log("s");
        if (EnvConstants.AppConversionPixel === "true") {
          console.log("if fb");
          history.push(
            "/fbpixel?split_name=" +
            props.splitName +
            "&next_page=" +
            props.nextPage
          ); // fb fixel firing
        } else {
          console.log("else fb");
          window.scrollTo(0, 0);
          history.push(
            "/" +
            props.nextPage +
            "?uuid=" +
            visitorParameters.visitor_parameters.uuid +
            "&splitName=" +
            props.splitName
          );
        }
      } else {
        console.log("another else fb");
        window.scrollTo(0, 0);
        history.push(
          "/" +
          props.nextPage +
          "?uuid=" +
          visitorParameters.visitor_parameters.uuid +
          "&splitName=" +
          props.splitName
        );
      }
    } else {
      console.log("laset else  fb");
      window.scrollTo(0, 0);
      history.push(
        "/" +
        props.nextPage +
        "?uuid=" +
        visitorParameters.visitor_parameters.uuid +
        "&splitName=" +
        props.splitName
      );
    }
  }

  return (
    <>
      {loaderStatus && <Loader loderContent={``} />}
      <ProgressBar
        scrollToDiv={scrollToDiv}
        progressBarRef={progressBarRef}
        progressValueRef={progressValueRef}
        showProgressBar={showProgressBar}
      />
      <form
        ref={splitForm}
        name="split_form"
        id="user_form"
        method="POST"
        autoComplete="off"
      >
        <Lenders
          className={state.showSlide1}
          slideChange={slideChange}
          validation={register}
        />
        <PersonalDetails
          className={state.showSlide2}
          slideChange={slideChange}
          validation={register}
          getValues={getValues}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          previousSlideChange={previousSlideChange}
        />
        <PostCode
          validation={register}
          validationMsg={errors}
          clearErrors={clearErrors}
          setError={setError}
          slideChange={slideChange}
          trigger={trigger}
          className={`${state.showSlide3}`}
          getValues={getValues}
          previousSlideChange={previousSlideChange}
        />
        <ContactDetails
          className={`${state.showSlide4}`}
          validation={register}
          getValues={getValues}
          slideChange={slideChange}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          setLoaderStatus={setLoaderStatus}
          previousSlideChange={previousSlideChange}
        />
        <BasicQuestions
          className={`${state.showSlide5}`}
          questionnaire={questionnaire_TCG_L_B.question_1}
          slideChange={slideChange}
          validation={register}
          previousSlideChange={previousSlideChange}
        />
        <BasicQuestionCheckbox
          className={`${state.showSlide6}`}
          questionnaire={questionnaire_TCG_L_B.question_2}
          slideChange={slideChange}
          validation={register}
          trigger={trigger}
          getValues={getValues}
          previousSlideChange={previousSlideChange}
        />
        <PreviousAddress
          className={`${state.showSlide7}`}
          slideChange={slideChange}
          validation={register}
          trigger={trigger}
          getValues={getValues}
          validationMsg={errors}
          clearErrors={clearErrors}
          setError={setError}
          scrolltobtn={scrolltobtn}
          setLoaderStatus={setLoaderStatus}
          previousSlideChange={previousSlideChange}
          setValue={setValue}
        />
      </form>
      <div className="text-data-guarantee mt-3">
        <div className={`guarantee2 ${state.showSlide4 == 'show' ? 'show' : 'hide'}`}>
          <p className="mt-2 text-lg-start text-md-start text-sm-start text-center">Your personal information will be
            treated carefully in accordance with our Privacy Policy. We {brandName}, a trading style of The Claims Guys Legal, will contact you about
            claim opportunities using the contact details you provide. You can opt out of receiving communications from
            us at any time by sending an email to <a
              href="mailto:dataprotection@theclaimsguyslegal.com">dataprotection@theclaimsguyslegal.com</a>
          </p>
        </div>

        <div className={`guarantee3 ${state.showSlide4 == 'show' ? 'hide' : 'show'}`}>
          <p className="text-center mb-0  "><i className="bi bi-shield-check"></i> PERSONAL INFORMATION GUARANTEE</p>
          <p className="text-center  ">We do not cold call, spam or pass on your data for marketing</p>
        </div>
      </div>
    </>
  );
};

export default TCG_L_B_Form;
