import React from "react";
import LogoBasedOnDomain from "../../../Includes/Layouts/Common/LogoBasedOnDomain";
import TextField from "../../../UI/TextField";
import Button from "../../../UI/Button";
import bannerimg1 from "../../../../assets/img/TCG_L_D/banner-img1.png";

const CarRegistrationBody = (props) => {

    const checkCharacters = (e) => {
        const allowedCharacters = /^[a-zA-Z0-9-_.\s]+$/;
        if (!allowedCharacters.test(e.key)) {
            e.preventDefault();
            return false;
        }
    };

    const onHandleChange = (event, itemId) => {
        props.clearErrors(`vehicleNumber_${itemId}`)
        const newVehicleNumber = event.target.value;
        props.setSelectedLenders((prevLenders) =>
            prevLenders.map((lender) => lender.value === itemId ?
                { ...lender, vehicleNumber: newVehicleNumber } : lender)
        );
    }

    return (
        <>
            <section className="banner  ">
                <div className="container">
                    <div>
                        <div className="brand">
                            <div className="logo pt-3">
                                <LogoBasedOnDomain height={40} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 mb-5">
                        <div className="col-lg-6">
                            <h1 className=" text-light" style={{ fontWeight: '600' }}>Car Registration</h1>
                            <p className="text-light  ">
                                Providing your car registration number will help us expedite your
                                claim process.
                            </p>
                            {/* Car Registration */}
                            <div className="car-regi-middle">
                                <div>
                                    <h2 className="mt-4 mb-3 text-light mb-4">
                                        Enter the vehicle registration number associated with your
                                        lender(s)
                                    </h2>
                                    {props.selectedlenders && props.selectedlenders.map((item, index) => (
                                        <div key={index}>
                                            <div className="car-box p-3 mb-3 bg-white drop-in">
                                                <div className="bank-name mb-2">
                                                    {" "}
                                                    <i className="bi bi-bank2" />{item.label}
                                                </div>
                                                <div className="  numb-plate-wrap justify-content-between ">
                                                    <div className="d-flex number-plate">
                                                        <div className="flag"> </div>
                                                        <div className="input-wrap w-100">
                                                            <TextField
                                                                type="text"
                                                                value={item.vehicleNumber ? item.vehicleNumber : ''}
                                                                onkeypress={checkCharacters}
                                                                className="form-control w-100"
                                                                placeholder="Enter Vehicle Number "
                                                                maxlength="13"
                                                                autoComplete="off"
                                                                name={`vehicleNumber_${item.value}`}
                                                                id={`vehicleNumber_${item.value}`}
                                                                validation={props.register({
                                                                    required:
                                                                        "Please Enter Vehicle Number",
                                                                })}
                                                                onChange={(e) => onHandleChange(e, item.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {!item.questionData && (<Button
                                                        type="button"
                                                        className="add-more addNUmber "
                                                        onClick={(e) =>
                                                            props.addButtonClick(e, item, index)
                                                        }
                                                        buttonText="Add"
                                                        dataBsToggle="modal"
                                                    />)}
                                                </div>
                                                {/* <div
                                                    className="text-success text1 mt-1 purchasedate"
                                                > */}
                                                    {/* {item.questionData  && (
                                                        <p>Purchased On {item.purchaseDate}</p>
                                                    )} */}
                                                    {item.questionData && (<div
                                                        className={`vehicle_detail py-3 px-3 ${item.questionData && "show d-block"
                                                            }`}
                                                    >
                                                        <p className="vh_na_top">VEHICLE REGISTRATION :  {item.vehicleNumber}</p>
                                                        <p className="vh_clr_typ">
                                                            Make : {item.vehicleMake}
                                                            <br />
                                                            Model : {item.vehicleModel}
                                                        </p>
                                                    </div>)}
                                                {/* </div> */}
                                            </div>
                                            {props.errors[`vehicleNumber_${item.value}`] &&
                                                props.errors[`vehicleNumber_${item.value}`]
                                                    .message && (
                                                    <span
                                                        className="error_msg animate__animated animate__fadeInUp animate__delay-.5s"
                                                        style={{ margin: '-15px 0px 10px 0px' }}
                                                    >
                                                        {
                                                            props.errors[`vehicleNumber_${item.value}`].message
                                                        }
                                                    </span>
                                                )}
                                        </div>))}
                                    {/* End Car Cards */}
                                    <div className="row my-lg-4 my-md-4 my-sm-4 my-4">
                                        <div className="col-4 pe-0">
                                            <Button
                                                className=" com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2 skipbtn "
                                                id="next3"
                                                type="button"
                                                buttonText="Skip"
                                                onClick={() => props.handleSkipData()}
                                            />
                                        </div>
                                        <div className="col-8">
                                            <Button
                                                type="button"
                                                className="nextbtn2 py-3 p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2"
                                                id="carRegistNext"
                                                value="Next"
                                                name=""
                                                onClick={() => props.handleNextClick()}
                                                buttonText={<> Next <i className="bi bi-arrow-right" /></>}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="bannerimage-wraper text-lg-start text-md-center text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                                <img
                                    className="bannerimg fade-in d-lg-block d-md-block d-sm-none d-none"
                                    src={bannerimg1}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CarRegistrationBody;
