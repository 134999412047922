import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
// import Questionnaire from "./Components/Pages/Questionnaire";
import TCG_L_B from "./Components/Pages/Splits/TCG_L_B";
import TCG_L_C from "./Components/Pages/Splits/TCG_L_C";
// import Signature from "./Components/Pages/Signature/Signature";
import CarRegistraionV2 from "./Components/Pages/CarRegistration/CarRegistraionV2";
import SignatureV1 from "./Components/Pages/Signature/SignatureV1";
// import CarRegistraion from "./Components/Pages/CarRegistration/CarRegistraion";
// import IdUpload from "./Components/Pages/IdUpload/IdUpload";
import Thankyou from "./Components/Pages/Thankyou/Thankyou";
import NotFound from "./Components/Pages/NotFound";
// import PdfView from"./Components/Pages/PdfView";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
import CarRegistrationV3 from "./Components/Pages/CarRegistration/CarRegistrationV3";
import TCG_L_A from "./Components/Pages/Splits/TCG_L_A";
import SignatureV3 from "./Components/Pages/Signature/SignatureV3";
import IdUploadV1 from "./Components/Pages/IdUpload/IdUploadV1";
import HelmetBasedOnDomain from "./Components/Includes/Layouts/Common/HelmetBasedOnDomain";
import ThankyouV1 from "./Components/Pages/Thankyou/ThankyouV1";
import IdUploadV3 from "./Components/Pages/IdUpload/IdUploadV3";
import CarRegistraionV1 from "./Components/Pages/CarRegistration/CarRegistrationV1";
// import {getBrandName} from "./Components/Includes/Layouts/Common/BrandBasedOnDomain";
import SignatureV2 from "./Components/Pages/Signature/SignatureV2";
import TCG_L_C_2 from "./Components/Pages/Splits/TCG_L_C_2";
import CarRegistrationV4 from "./Components/Pages/CarRegistration/CarRegistrationV4";
import IdUploadV4 from "./Components/Pages/IdUpload/IdUploadV4";
import TCG_L_D from "./Components/Pages/Splits/TCG_L_D";
import ThankyouV4 from "./Components/Pages/Thankyou/ThankyouV4";
import Trigger_followup_strategy from "./Components/Followup/Trigger_followup_strategy";
import FollowUp from "./Components/Pages/FollowUp/FollowUp";
import FollowUpAuthorise from "./Components/Pages/FollowUp/FollowUpAuthorise";
import FollowUpVerification from "./Components/Pages/FollowUp/FollowUpVerification";
import FollowUpThankYou from "./Components/Pages/FollowUp/FollowUpThankYou";
import FollowUpCarRegistration from "./Components/Pages/FollowUp/FollowUpCarRegistration";
import FollowUpIdUpload from "./Components/Pages/FollowUp/FollowUpIdUpload";
import TCG_L_C_3 from "./Components/Pages/Splits/TCG_L_C_3";
import TCG_L_C_4 from "./Components/Pages/Splits/TCG_L_C_4";
import IdUploadV5 from "./Components/Pages/IdUpload/IdUploadV5";
import CarRegistrationV5 from "./Components/Pages/CarRegistration/CarRegistrationV5";
import SignatureV5 from "./Components/Pages/Signature/SignatureV5";
import AuthoriseC3 from "./Components/Pages/Authorise/AuthoriseC3";
import SignatureV3_C_4 from "./Components/Pages/Signature/SignatureV3_C_4";
import CarRegistrationV5_C_4 from "./Components/Pages/CarRegistration/CarRegistrationV5_C_4";
import IdUploadV5_C4 from "./Components/Pages/IdUpload/IdUploadV5_C4";

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  // const brandName = getBrandName();
  // useEffect(() => {
  //   document.title = brandName;
  // }, []);
  return (
    <>
      <HelmetBasedOnDomain/>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path={["/pcp/TCG_L_B"]} component={TCG_L_B} />
              <Route exact path={["/pcp/TCG_L_C"]} component={TCG_L_C} />
              {/* <Route exact path={"/questionnaire"} component={Questionnaire} /> */}
              {/* <Route exact path={"/signature"} component={Signature} />*/}
              <Route exact path={"/v2/car-registration"} component={CarRegistraionV2} />
              <Route exact path={"/v1/signature"} component={SignatureV1} />
              {/* <Route exact path={"/car-registration"} component={CarRegistraion} /> */}
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={"/thankyou"} component={Thankyou} />
              {/* <Route exact path={["/id-upload"]} component={IdUpload} /> */}
              <Route exact path={["/404"]} component={NotFound} />
               {/*<Route exact path={["/:enviroment/:project_id/pdf/:doc_type/:filename"]} component={PdfView}/>*/}
              <Route exact path={["/google","/testing"]} component={AdtopiaLoadDynamic}/>
              <Route exact path={"/v3/car-registration"} component={CarRegistrationV3} />
              <Route exact path={["/pcp/TCG_L_A"]} component={TCG_L_A} />
              <Route exact path={"/v3/signature"} component={SignatureV3} />
              <Route exact path={"/v1/id-upload"} component={IdUploadV1} />
              <Route exact path={"/v1/thankyou"} component={ThankyouV1} />
              <Route exact path={"/v3/id-upload"} component={IdUploadV3} />
              <Route exact path={"/v2/signature"} component={SignatureV2} />
              <Route exact path={"/v1/car-registration"} component={CarRegistraionV1} />
              <Route exact path={"/pcp/TCG_L_C_2"} component={TCG_L_C_2}/>
              <Route exact path={"/v4/car-registration"} component={CarRegistrationV4} />
              <Route exact path={"/v4/id-upload"} component={IdUploadV4} />
              <Route exact path={"/pcp/TCG_L_D"} component={TCG_L_D}/>
              <Route exact path={"/v4/thankyou"} component={ThankyouV4} />
              <Route exact path={"/trigger-followup-strategy"} component={Trigger_followup_strategy}/>
              <Route exact path={"/followup"} component={FollowUp}/>
              <Route exact path={"/followup-authorise"} component={FollowUpAuthorise}/>
              <Route exact path={"/followup-verification"} component={FollowUpVerification}/>
              <Route exact path={"/followup-thank-you"} component={FollowUpThankYou}/>
              <Route exact path={"/followup-car-registration"} component={FollowUpCarRegistration}/>
              <Route exact path={"/followup-id-upload"} component={FollowUpIdUpload}/>
              <Route exact path={["/pcp/TCG_L_C_3"]} component={TCG_L_C_3} />
              <Route exact path={["/pcp/TCG_L_C_4"]} component={TCG_L_C_4} />
              <Route exact path={"/v5/id-upload"} component={IdUploadV5} />
              <Route exact path={"/v5/car-registration"} component={CarRegistrationV5} />
              <Route exact path={"/v5/signature"} component={SignatureV5} />
              <Route exact path={"/v5/authorise"} component={AuthoriseC3} />
              <Route exact path={"/v3_C4/signature"} component={SignatureV3_C_4} />
              <Route exact path={"/v5_C4/car-registration"} component={CarRegistrationV5_C_4} />
              <Route exact path={"/v5_C4/id-upload"} component={IdUploadV5_C4} />
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
