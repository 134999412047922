import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';
import logoWhite from '../../../../assets/img/logo-white.png';

const ProgressBar = (props) => {
  return (
    <>

      <div className={`progress-wrap ${props.showProgressBar}`} ref={props.scrollToDiv}>
        <div className="progress-bar" id="progress-bar" ref={props.progressBarRef}>
          <span id="progress-value" ref={props.progressValueRef}>0%</span>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;