import React, { useState } from 'react';
import { Salutation } from "../../../../Constants/Constants";
import TextField from "../../../UI/TextField";
import SelectBox from '../../../UI/SelectBox';
import { nameValidate, checkDuplicateExtraPreviousName } from "../../../../Utility/Validations";

const PreviousName = (props) => {
  return (
    <>
      <div className="personal_detail ">
        <div className="field pb-3 animate__animated animate__fadeInUp animate__delay-.5s col-12 col-sm-12">
          <label className="mb-1 fs-6">{props.prevName}</label>
          <div className="field-icon">
            <TextField
              type="text"
              className="capitalised-input d-block"
              placeholder={`Previous Surname `}
              name={`txtPrevLName_${props.preCount}`}
              id={`txtPrevLName_${props.preCount}`}
              autocomplete="off"
              onkeypress={(e) => nameValidate(e)}
              onBlur = {(e) => checkDuplicateExtraPreviousName(e, props.setError)}
              onChange={() => props.clearErrors(`txtPrevLName_${props.preCount}`)}
              validation={props.validation({
                required: "Please Enter Last Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid Last Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}
              validationMsg={props.validationMsg[`txtPrevLName_${props.preCount}`]?.message}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PreviousName;