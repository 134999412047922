import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useContext,
} from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../Contexts/QueryString";
import * as EnvConstants from "../../../Constants/EnvConstants";
import { questionnaire } from "../../../Constants/Questions";
import Lenders from "../../Includes/Layouts/TCG_L_A/Lenders";
import EmailModal from "../../Includes/Layouts/TCG_L_A/EmailModal";
import BasicQuestionnaire from "../../Includes/Layouts/TCG_L_A/BasicQuestionnaire";
import BasicQuestionnaire1 from "../../Includes/Layouts/TCG_L_A/BasicQuestionnaire1";
import { questionnaire_TCG_L_A } from "../../../Constants/Questions";
import PersonalDetails from "../../Includes/Layouts/TCG_L_A/PersonalDetails";
import Button from "../../UI/Button";
import ContactDetails from "../../Includes/Layouts/TCG_L_A/ContactDetails";
import PostCode from "../../Includes/Layouts/TCG_L_A/PostCode";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";

const TCG_L_A_Form = (props) => {

  const splitName = "pcp/TCG_L_A";
  const query = new URLSearchParams(window.location.search);
  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
    showSlide7: "hide",
  };
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "slide1") {
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "slide2") {
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide2: "hide",
            showSlide3: "show",
          };
        } else if (action.payload.clickedSlide.slide === "question_1") {
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "slide_4") {
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show",
          };
        } else if (action.payload.clickedSlide.slide === "slide_5") {
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide5: "hide",
            showSlide6: "show",
          };
        } else if (action.payload.clickedSlide.slide === "slide_6") {
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide6: "hide",
            showSlide7: "show",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      case "previousSlides": {
        if (action.payload.previousSlide.slide == "back1") {
          props.setShowFaq(true);
          props.setFooterEnable(true);
          return {
            ...state,
            showSlide1: "show",
            showSlide2: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back2") {
          return {
            ...state,
            showSlide2: "show",
            showSlide3: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back3") {
          return {
            ...state,
            showSlide3: "show",
            showSlide4: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back4") {
          return {
            ...state,
            showSlide4: "show",
            showSlide5: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back5") {
          return {
            ...state,
            showSlide5: "show",
            showSlide6: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back6") {
          return {
            ...state,
            showSlide6: "show",
            showSlide7: "hide",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      default: {
      }
    }
  };
  const splitForm = useRef(null);
  const scrollfrmv2div = useRef([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const [emailError, setEmailError] = useState("");
  const [formData, setFormData] = useState({});
  const [modelContent, setModalContent] = useState(false);
  const [modelContent1, setModalContent1] = useState(false);
  const [modelContent2, setModalContent2] = useState(true);

  const { register, errors, trigger, setError, clearErrors, getValues,setValue } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const slideChange = (e) => {
    props.setShowFaq('hide');
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const values = getValues();
    const formData1 = values;

    if (name == "question_1" && value == "2") {
      setModalContent(true);
      setModalContent1(false);
      setModalContent2(true);
      return;
    } else {
      setClickedSlide({ slide: e.target.name });
      setTimeout(() => {
        scrollfrmv2div.current.scrollIntoView({
          behavior: "smooth",
          top: 0
        });
      }, 50);
    }
    // switch (e.target.name) {
    //   case "slide_6":
    //     formSubmit();
    //     triggerSildeChange(e.target.name);
    //     break;
    //   default:
    //     triggerSildeChange(e.target.name);
    //     break;
    // }
  };

  const previousSlideChange = (e) => {
    setError("txtLName", { message: "" });
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });
    }, 50);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };
  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: 'previousSlides', payload: { previousSlide } });
    }
  }, [previousSlide]);

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  const formSubmit = async (e) => {

    const form = splitForm.current;
    const values = getValues();
    const formData = values;
    const selectedBanks = JSON.parse(localStorage.getItem("selectedBanks"));
    const preSurNames = JSON.parse(localStorage.getItem("sur_names"));
    formData.page_name = splitName;
    formData.bank = selectedBanks.map((bank) => bank.value);
    formData.txtPrevLName_1 = preSurNames['txtPrevLName_1'];
    formData.txtPrevLName_2 = preSurNames['txtPrevLName_2'];
    formData.txtPrevLName_3 = preSurNames['txtPrevLName_3'];
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("queryString", queryString);
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "split_form_submit",
        splitName,
        queryString,
        visitorParameters.data
      );
      if (formSUbmitResult.data.status === "Success") {
        if (queryString != null) {
          if (queryString.indexOf("&" + field + "=") !== -1) {
            if (EnvConstants.AppConversionPixel === "true") {
              history.push(
                "/fbpixel?split_name=" +
                props.splitName +
                "&next_page=" +
                props.nextPage
              ); // fb fixel firing
            } else {
              window.scrollTo(0, 0);
              history.push(
                "/" +
                props.nextPage +
                "?uuid=" +
                visitorParameters.visitor_parameters.uuid +
                "&splitName=" +
                props.splitName
              );
            }
          } else {
            window.scrollTo(0, 0);
            history.push(
              "/" +
              props.nextPage +
              "?uuid=" +
              visitorParameters.visitor_parameters.uuid +
              "&splitName=" +
              props.splitName
            );
          }
        } else {
          window.scrollTo(0, 0);
          history.push(
            "/" +
            props.nextPage +
            "?uuid=" +
            visitorParameters.visitor_parameters.uuid +
            "&splitName=" +
            props.splitName
          );
        }
      }
    }
  };


  // const triggerSildeChange = (slideName) => {
  //   setClickedSlide({ slide: slideName });
  //   setTimeout(() => {
  //     scrollfrmv2div.current.scrollIntoView({
  //       behavior: "smooth",
  //       top: 0
  //     });
  //   }, 50);
  // }

  // const submitOtherFormDetails = async () => {
  //   const formData = getValues();
  //   const formSUbmitResult = await saveDataIngestion(
  //     visitorParameters.visitor_parameters,
  //     formData,
  //     "extra_form_data_postcode",
  //     "pcp/TCG_L_B",
  //     queryString,
  //     visitorParameters.data
  //   );
  //   localStorage.setItem("formData", JSON.stringify(formData));
  //   if (queryString != null) {
  //     console.log("first if");
  //     console.log(queryString);
  //     if (queryString.indexOf("&" + field + "=") !== -1) {
  //       console.log("s");
  //       if (EnvConstants.AppConversionPixel === "true") {
  //         console.log("if fb");
  //         history.push(
  //           "/fbpixel?split_name=" +
  //           props.splitName +
  //           "&next_page=" +
  //           props.nextPage
  //         ); // fb fixel firing
  //       } else {
  //         console.log("else fb");
  //         window.scrollTo(0, 0);
  //         history.push(
  //           "/" +
  //           props.nextPage +
  //           "?uuid=" +
  //           visitorParameters.visitor_parameters.uuid +
  //           "&splitName=" +
  //           props.splitName
  //         );
  //       }
  //     } else {
  //       console.log("another else fb");
  //       window.scrollTo(0, 0);
  //       history.push(
  //         "/" +
  //         props.nextPage +
  //         "?uuid=" +
  //         visitorParameters.visitor_parameters.uuid +
  //         "&splitName=" +
  //         props.splitName
  //       );
  //     }
  //   } else {
  //     console.log("laset else  fb");
  //     window.scrollTo(0, 0);
  //     history.push(
  //       "/" +
  //       props.nextPage +
  //       "?uuid=" +
  //       visitorParameters.visitor_parameters.uuid +
  //       "&splitName=" +
  //       props.splitName
  //     );
  //   }
  // }
  return (
    <>
      {" "}
      {/* form begins */}
      <form
        // ref={splitForm}
        ref={scrollfrmv2div}
        name="split_form"
        id="user_form"
        onSubmit={(e) => e.preventDefault()}
      >
        <Lenders
          showSlide1={state.showSlide1}
          slideChange={slideChange}
          validation={register}
          setLoaderStatus={props.setLoaderStatus} // This passes setLoaderStatus to Lenders
        />
        <EmailModal
          showSlide2={state.showSlide2}
          validation={register}
          validationMsg={errors}
          clearErrors={clearErrors}
          setError={setError}
          setEmailError={setEmailError}
          slideChange={slideChange}
          previousSlideChange={previousSlideChange}
          getValues={getValues}

        />
        <BasicQuestionnaire
          className={`slide2 animate__animated animate__fadeInUp questionwrapper ${state.showSlide3}`}
          validation={register}
          slideChange={slideChange}
          getValues={getValues}
          questionnaire={questionnaire_TCG_L_A.question_1}
          setModalContent={setModalContent}
          previousSlideChange={previousSlideChange}
        />

        <BasicQuestionnaire1
          className={`slide3 animate__animated animate__fadeInUp questionwrapper ${state.showSlide4}`}
          validation={register}
          slideChange={slideChange}
          getValues={getValues}
          questionnaire={questionnaire_TCG_L_A.question_2}
          setModalContent={setModalContent}
          previousSlideChange={previousSlideChange}
        />
        {/* slide3 end */}
        <PersonalDetails
          showSlide5={state.showSlide5}
          slideChange={slideChange}
          validation={register}
          getValues={getValues}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          previousSlideChange={previousSlideChange}

        />
        {/* previousname popup end */}
        {/* slide4 end */}
        <ContactDetails
          showSlide6={`${state.showSlide6}`}
          validation={register}
          getValues={getValues}
          slideChange={slideChange}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          previousSlideChange={previousSlideChange}

        />

        {/* slide5 end */}
        <PostCode
          showSlide7={`${state.showSlide7}`}
          validation={register}
          validationMsg={errors}
          clearErrors={clearErrors}
          setError={setError}
          slideChange={slideChange}
          trigger={trigger}
          getValues={getValues}
          formSubmit={formSubmit}
          //submitOtherFormDetails={submitOtherFormDetails}
          previousSlideChange={previousSlideChange}
          setValue={setValue}

        />
        {/* previousaddress popup end */}

        {/* Modal */}
        {modelContent && (
          <div
            className="modal fade show"
            id="receivecommission"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md modal-dialog-centered">
              <div className="modal-content form-modal">
                <div className="modal-header text-center">
                  {/* <img src="dist/img/tcgl-logo.svg" alt="" /> */}
                  <LogoBasedOnDomain className="" bg="" />
                </div>
                <div className="modal-body">
                  {modelContent2 && (
                    <div className="slidecommissionyes1">
                      <h3>Please check your answers</h3>
                      <p>
                        You have confirmed that you were told about the
                        commission the car dealerships would receive.
                        Unfortunately, this means we are unable to pursue a
                        claim against your lenders.
                      </p>
                      <p>
                        If you would like to change your answer, please click
                        back. If not please click next to proceed.
                      </p>
                      <div className="mb-3 text-center">
                        <Button
                          type="button"
                          className="button slidecommnext w-100"
                          value="Next"
                          onClick={() => {
                            setModalContent1(true);
                            setModalContent2(false);
                          }}
                          buttonText="Next"
                        />
                      </div>

                      <div className="mb-3 text-center">
                        <Button
                          type="button"
                          className="bck-btn"
                          onClick={() => setModalContent(false)}
                          buttonText="Back"
                        />
                      </div>
                    </div>
                  )}
                  {modelContent1 && (
                    <div className="slidecommissionyes2">
                      <h3>Sorry</h3>
                      <p>
                        We are unable to process a claim for you, as you don’t
                        meet the eligibility criteria.
                      </p>
                      <p>
                        We may be able to support you with other claim types and
                        if so, we will be in touch using the email address you
                        have provided.
                      </p>
                      <p>
                        You can opt out of receiving communications from us at
                        any time by sending us an email to: <a href="mailto:dataprotection@theclaimsguyslegal.com" >
                          dataprotection@theclaimsguyslegal.com
                        </a>
                      </p>
                      <div className="mb-3 text-center">
                        <Button
                          type="button"
                          className="bck-btn"
                          value="Next"
                          onClick={() => setModalContent(false)}
                          buttonText="Back"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* email popup end */}
      </form>
      {/* form end */}
    </>
  );
};

export default TCG_L_A_Form;
