import React, { useState } from "react";
import { DobDay, DobMonth, DobYear } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import { dobValidation } from "../../../../Utility/Validations";
import { monthMapping } from "../../../../Constants/Constants";

const Dob = (props) => {
  const [dobValues, setDobValues] = useState({
    DobDay: "",
    DobMonth: "",
    DobYear: "",
  });
  const [validationMessages, setValidationMessages] = useState({
    DobDay: "",
    DobMonth: "",
    DobYear: "",
  });

  const handleInputChange = (e) => {
    props.clearErrors(["DobDay", "DobMonth", "DobYear"]);
    updateDobValue(e);
  };

  const updateDobValue = (e) => {
    const { name, value } = e.target;
    setDobValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    const newValues = {
      ...dobValues,
      [name]: value,
    };

    if (newValues.DobDay && newValues.DobMonth && newValues.DobYear) {
      const validDate = dobValidation(
        newValues.DobDay,
        newValues.DobMonth,
        newValues.DobYear
      );
      if (!validDate) {
        setValidationMessages((prevMessages) => ({
          ...prevMessages,
          ["DobDay"]: "Please Enter Valid Date",
        }));
        setDobValues((prevValues) => ({
          ...prevValues,
          [name]: "",
        }));
      } else {
        props.clearErrors("DobDay");
        props.clearErrors("DobMonth");
        props.clearErrors("DobYear");

        const dob = new Date(
          newValues.DobYear,
          monthMapping[newValues.DobMonth],
          newValues.DobDay
        );
        const EndDate = new Date(2000, 5, 1);

        if (dob >= EndDate) {
          setValidationMessages({
            DobDay: "",
            DobMonth: "",
            DobYear: "Please Enter a date before 01/6/2000",
          });
        } else {
          setValidationMessages({
            DobDay: "",
            DobMonth: "",
            DobYear: "",
          });
        }
      }
    } else {
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        [name]: "",
      }));
    }
  };

  return (
    <>
      <div className="mt-3">
        <fieldset className="p-2 px-3">
          <legend className="px-2 m-0">Date Of Birth</legend>
          <div className="date-birth">
            <div className="row">
              <div className="mb-2 col-4">
                <SelectBox
                  name="DobDay"
                  id="DobDay"
                  className="form-control dob shadow2"
                  OptionValue={DobDay}
                  myRef={props.validation({
                    required: "Please Select Date",
                  })}
                  validationMsg={
                    validationMessages.DobDay ||
                    props.validationMsg.DobDay?.message
                  }
                  onChange={handleInputChange}
                />
                <i className="tick fa" style={{ display: "block" }} />
              </div>
              <div className="mb-2 col-4 ps-lg-0">
                <SelectBox
                  name="DobMonth"
                  id="DobMonth"
                  className="form-control dob"
                  OptionValue={DobMonth}
                  myRef={props.validation({
                    required: "Please Select Month",
                  })}
                  validationMsg={
                    validationMessages.DobMonth ||
                    props.validationMsg.DobMonth?.message
                  }
                  onChange={handleInputChange}
                />
                <i className="tick fa" style={{ display: "none" }} />
              </div>
              <div className="mb-2 col-4 ps-lg-0">
                <SelectBox
                  name="DobYear"
                  id="DobYear"
                  className="form-control dob"
                  OptionValue={DobYear}
                  myRef={props.validation({
                    required: "Please Select Year",
                  })}
                  validationMsg={
                    validationMessages.DobYear ||
                    props.validationMsg.DobYear?.message
                  }
                  onChange={handleInputChange}
                />
                <i className="tick fa" style={{ display: "none" }} />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default Dob;
