import React, { useContext, useState, useRef, useEffect } from "react";
import "../../../assets/css/TCG_L_A/idupload.scss";
import "../../../assets/css/TCG_L_A/custom.scss";
import uploadgif from '../../../assets/img/upload.gif';
import Header from "../../Includes/Layouts/TCG_L_A/Header";
import Footer from "../../Includes/Layouts/TCG_L_A/Footer";
import Button from "../../UI/Button";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { useIdUpload } from "../../../Hooks/useIdUpload";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import reviewsio from "../../../assets/img/reviewsio-logo-black.png"
import { useSkipIdUpload } from "../../../Hooks/useSkipIdUpload";

const IdUploadV1 = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('TCG_L_A','v1/id-upload');

  const [showSlide2, setShowSlide2] = useState(false);
  const [idType, setIdType] = useState('');
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [progressbar, setProgressBar] = useState(false);
  const [userfile, setUserfile] = useState({ preview: '', title: '' });
  const [selectedFile, setSelectedFile] = useState('');

  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [selectedFileType, setSelectedFileType] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [btnLoader, setBtnLoader] = useState(false);

  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const { saveIdUpload, isLoading } = useIdUpload();
  const { skipidupload } = useSkipIdUpload();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const history = useHistory();
  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters.uuid;
    if (visitorData) {
      var uuid = visitorData;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };

  const uuid = getUuid();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const handleNextUploadClick = (e) => {
    setIdType(e.target.id)
    setShowSlide2(true);
  };

  // handleBackbutton for id upload back button
  const handleBackbutton = () => {
    setShowSlide2(false);
    setIsFileSelected(false);
    setSelectedFileType('');
    setUserfile({ preview: '', title: '' });
    setBase64Image('');
    setErrorMessage('');
  }

  const handleButtonClick = (e) => {
    if (e.target.name == 'photo') {
      document.getElementById(`${idType}_cam`).click();
    }
    if (e.target.name == 'image') {
      document.getElementById(`${idType}_doc`).click();
    }
  };

  const retakeClick = (e) => {
    setUserfile(({ preview: '', title: '' }));
    setIsFileSelected(false);
    setSelectedFile('');
  };

  const handleFileChange = (event) => {
    setProgressBar(true);
    const HandleChangeTimeout = setTimeout(() => {
      setSelectedFile(event.target.files[0]);
      let imageFile = event.target.files[0];
      if (imageFile) {
        let extension = event.target.files[0].type;
        let imgsize = event.target.files[0].size;
        let allowedExtensions = ["image/jpeg", "image/png"];
        if (allowedExtensions.indexOf(extension) === -1) {
          setIsInValidfFile(true);
          setErrorMessage({
            txt: "The uploaded ID type should be an image in jpeg,png or jpg",
          });
          setProgressBar(false);
          return false;
        } else if (imgsize > 1024 * 1024 * 20) {
          setIsInValidfFile(true);
          setErrorMessage({ txt: "Please choose a file less than 20mb" });
          return false;
        } else {
          setSelectedFileType(event.target.name);
          setIsInValidfFile(false);
          setIsFileSelected(true);
        }

        setUserfile({ preview: URL.createObjectURL(imageFile), title: imageFile.name })
        if (imageFile) {
          let reader = new FileReader();
          reader.onload = _handleReaderLoaded.bind(this);
          reader.readAsDataURL(imageFile);
        }
      } else {
        setIsInValidfFile(true);
        setUserfile({ preview: '', title: '' })
      }
      setProgressBar(false);
    }, 2000);
  };
  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image({
      base64TextString: binaryString,
    });
  };
  const split_name = "pcp/TCG_L_A";
  const handleSubmit = async (event) => {
    setBtnLoader(true);
    event.preventDefault();
    let uploadData = "";
    if (isFileSelected) {
      uploadData = {
        imageFile: base64Image.base64TextString,
        captureType: selectedFileType.split('_')[0],
        documentType: selectedFileType.split('_')[0],
      };
      const response = await saveIdUpload(
        uploadData,
        visitorParameters.visitor_parameters,
        formParameters,
        visitorParameters.data,
        queryString,
        "user_docs_store"
      );
      setSelectedFile('');
      setBase64Image('');
      setSelectedFileType('');
      //GTMDataLayer trigger on id upload submit btn
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'verify identity',
        answer: '',
      });
      //history.push('/v1/thankyou?uuid='+uuid);
      history.push(
        "/" +
        DynamicRouteNextPage +
        "?uuid=" +
        visitorParameters.visitor_parameters.uuid +
        "&splitName=" +
        split_name
      );

    } else {
      setBtnLoader(false)
      setIsInValidfFile(true);
      setErrorMessage({ txt: "Please upload a file" });
    }

  };


    //skip btn functionality
    const skipIdPage = async() => {
      const typeTwo = "id_upload";
      const bank_id = "";
      const page_name = "pcp/TCG_L_A";
      const response = await skipidupload(
        uuid,
        page_name,
        "id_upload",
      );
      // history.push(`/thankyou?${query.toString()}`);
      history.push("/" + DynamicRouteNextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + split_name);
    };
  return (
    <>
      <GetVisitorsParams />
      <div className="id_uploadv1">
        {/* main wraping section */}
        {/* preloader */}
        {showLoader && (
          <div className="TheClaimsGuys_ver_A">
            <div id="preloader">
              <div id="loader" />
              <h3>Analysing Your Results...</h3>
            </div>
          </div>
        )}
        <div className="TheClaimsGuys_ver_A"><Header /></div>
        <section className="id_upload">
          <div className="container">
            <div className="row">
              <div className="col-lg-8  col-12 order-lg-1 order-2">
                {/* <form className="idwrapper" action="" method="get"> */}
                <div className="idwrapper">
                  <h2>Now, let's verify your identity</h2>
                  <h3>
                  Your ID will be stored securely. We will use the documents provided for identity verification and claim progression.
                  </h3>
                  <div className="mt-4  animate__animated animate__fadeInUp">
                    {!showSlide2 ? (<div className="uploadbtns ">

                      <Button
                        className="uploads w-100 id_upLoad fw-bold mb-3"
                        id="Driving"
                        name="idType"
                        onClick={handleNextUploadClick}
                        buttonText={
                          <>
                            <span className="float-start" style={{ pointerEvents: 'none' }}>
                              <i className="bi bi-car-front-fill mx-2" />
                              Driver's Licence{" "}
                            </span>
                            <span className="float-end" style={{ pointerEvents: 'none' }}>
                              <img height="25px" src={uploadgif} alt="" />
                            </span>

                          </>
                        }
                      />
                      <Button
                        className="uploads w-100 id_upLoad fw-bold mb-3"
                        id="passport"
                        name="idType"
                        onClick={handleNextUploadClick}
                        buttonText={
                          <>
                            <span className="float-start" style={{ pointerEvents: 'none' }}>
                              <i className="bi bi-airplane mx-2" />
                              Passport{" "}
                            </span>
                            <span className="float-end" style={{ pointerEvents: 'none' }}>
                              <img height="25px" src={uploadgif} alt="" />
                            </span>

                          </>
                        }
                      />
<div>
                      <h6 className="upload-text">When you upload a document, we will receive the documents you have provided, which will be used to progress and/or support your claim(s). Where required, these documents will be shared with your lender(s).</h6></div>
                    </div>) :
                      (
                        <div>
                          <div id="uploarea" >
                            <div className="fw-bold mb-2 fs-5 backupload" onClick={() => { handleBackbutton() }} style={{ cursor: 'pointer' }}>
                              {" "}
                              <i className="bi bi-arrow-left" /> Back
                            </div>
                            <div className="up-wrap p-1">
                              {isFileSelected ? (
                                <div className="uploaded" >
                                  <img
                                    className="img-fluid"
                                    style={{ maxWidth: '100%', height: '190px' }}
                                    src={userfile.preview}
                                    alt={userfile.title}
                                  />
                                </div>) : (
                                <div className="uploadbox">
                                  <div>
                                    <div>
                                      <i className="bi bi-cloud-upload" />
                                    </div>
                                    <div className="upload-text"> Upload image </div>
                                  </div>
                                  {progressbar && <div
                                    className="text-center imgloader "
                                  >
                                    <div
                                      className="progress"
                                      role="progressbar"
                                      aria-label="Animated striped example"
                                      aria-valuenow={75}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    >
                                      <div
                                        className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  </div>}
                                </div>)}
                            </div>
                            {!isFileSelected && <div className="row mt-3 upactionbtn">
                              <div className="col-6">
                                <Button
                                  className="upl-btn w-100 fw-bold"
                                  onClick={(e) => handleButtonClick(e)}
                                  name="photo"
                                  buttonText={
                                    <>
                                      <span style={{ pointerEvents: 'none' }}>
                                        <i className="bi bi-camera" /> Take Photo{" "}
                                        <input
                                          type="file"
                                          accept="image/*"
                                          capture="camera"
                                          id={`${idType}_cam`}
                                          name={`${idType}_cam`}
                                          onChange={handleFileChange}

                                        />
                                      </span>
                                    </>
                                  }
                                />
                              </div>
                              <div className="col-6 ps-0">
                                <Button
                                  className="upl-btn  img-uploadbtn w-100 fw-bold"
                                  onClick={(e) => handleButtonClick(e)}
                                  name="image"
                                  buttonText={
                                    <>
                                      <span style={{ pointerEvents: 'none' }}>
                                        <i className="bi bi-image" />  Upload image{" "}
                                        <input
                                          type="file"
                                          accept="image/*"
                                          id={`${idType}_doc`}
                                          name={`${idType}_doc`}
                                          onChange={handleFileChange}
                                        />
                                      </span>
                                    </>
                                  }
                                />
                              </div>
                            </div>}
                            <div className="row retake mt-3">
                              {isFileSelected &&
                                <div className="col-12 col-lg-6">
                                  <Button
                                    className="upl-btn w-100 fw-bold "
                                    onClick={(e) => retakeClick(e)}
                                    buttonText={
                                      <>
                                        <i className="bi bi-arrow-repeat" />
                                        &nbsp;&nbsp; Retake
                                      </>
                                    }
                                  />
                                </div>
                              }
                              {isFileSelected && (<div
                                className="mt-lg-0  mt-3 col-12 col-lg-6  gonext"
                              >
                                <Button
                                  id="next2"
                                  className=" idButton  green_color_but w-100 mb-3 py-3"
                                  onClick={(e) => (!btnLoader ? handleSubmit(e) : null)}
                                  buttonText={
                                    <>
                                      <span className="fw-bold">Submit</span>
                                    </>
                                  }
                                />
                              </div>)}
                              {isInValidfFile && (<div
                                className="error_msg"
                              >
                                {errorMessage.txt}
                              </div>)}
                            </div>
                          </div>
                        </div>)
                    }
                    
                  </div>
                </div>
                <div class="mt-3  text-center ">
                <Button
                  className="skip-btn"
                  type="button"
                  id="skipLender"
                  onClick={() => skipIdPage()}
                  buttonText='Skip'
                  gtm_question='verify identity'
                  clickevent='slideclick'
                  gtm_answer=''
                // gtm_trigger={true}
                />  
                </div>
                {/* </form> */}
              </div>
              <div className="col-lg-4 top_right_sec order-lg-2 order-1">
                <div className="rating text-center d-block d-lg-none">
                  <img src={reviewsio} fetchpriority='hight' alt="" />
                </div>
                <div className="fixed">
                  <div className="potential-claim large ">
                    <h4>
                      Your Potential Claim Value: <span>£1,100*</span> per vehicle!
                    </h4>
                  </div>
                  <div className="useful-facts d-none d-lg-block">
                    <h4>USEFUL FACTS</h4>
                    <ul>
                      <li>Millions of UK drivers could be eligible for refunds</li>
                      <li>You can claim for current and past vehicles</li>
                      <li>Start your claim in just 60 seconds</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* for mobile view */}
              <div className="col-lg-4 top_right_sec order-lg-2 order-3  d-block d-lg-none">
                <div className="useful-facts">
                  <h4>USEFUL FACTS</h4>
                  <ul>
                    <li>Millions of UK drivers could be eligible for refunds</li>
                    <li>You can claim for current and past vehicles</li>
                    <li>Start your claim in just 60 seconds</li>
                  </ul>
                </div>
              </div>
              {/* for mobile view */}
            </div>
          </div>
        </section>
        <div className="TheClaimsGuys_ver_A"><Footer /></div>
      </div>

    </>

  );
};

export default IdUploadV1;
