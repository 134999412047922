import React from "react";
// import Accordion from 'react-bootstrap/Accordion';
import img1 from "../../../../assets/img/6_1.webp";
import img2 from "../../../../assets/img/9.webp";
import imgv5 from "../../../../assets/img/v5.webp";
import img10 from "../../../../assets/img/10.webp";
import img11 from "../../../../assets/img/11.webp";
import Accordion from "../../Layouts/TCG_L_C/Accordion";
 
const FAQSection = (props) => {
  return (
    <>
      <section className={`faq_ques ${props.showFaq}`}>
        <div className="bg_wrap bg-gray">
          <div className="comm-head">
            <h2 className="text-center animate__animated animate__fadeInUp animate__delay-.5s">
              Frequently Asked Questions
            </h2>

            <h1 className="text-center comm-head  mb-lg-5 mb-md-5 mb-sm-4 mb-4 animate__animated animate__fadeInUp animate__delay-.5s">
              Frequently Asked Questions
            </h1>
          </div>
          <div className="container-sm">
            <div className="row align-center">
              <div className="col-lg-5">
                <Accordion />
              </div>
              <div className="col-lg-6 offset-lg-1">
                <div className="soft-info-thumb animate__animated animate__fadeInUp animate__delay-.5s">
                  <img
                    decoding="async"
                    src={img1}
                    alt="Dashboard"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                    fetchpriority="high"
                  />
                  <div
                    className="go-premium wow fadeInRight"
                    data-wow-delay="500ms"
                    style={{
                      visibility: "visible",
                      animationDelay: "500ms",
                      animationName: "fadeInRight",
                    }}
                  >
                    <span>Claim Now</span>
                    <h5>Unlock Your Potential Claim Value</h5>
                    <ul className="user-lists">
                      <li>
                        <img
                          decoding="async"
                          src={img2}
                          alt="Image Not Found"
                          fetchpriority="high"
                          width="100"
                          height="100"
                        />
                      </li>
                      <li>
                        <img
                          decoding="async"
                          src={img10}
                          alt="Image Not Found"
                          fetchpriority="high"
                          width="100"
                          height="100"
                        />
                      </li>
                      <li>
                        <img
                          decoding="async"
                          src={img11}
                          alt="Image Not Found"
                          fetchpriority="high"
                          width="100"
                          height="100"
                        />
                      </li>
                      <li>
                        <img
                          decoding="async"
                          src={imgv5}
                          alt="Image Not Found"
                          fetchpriority="high"
                          width="100"
                          height="100"
                        />
                      </li>
                      <li>
                        <i className="fas fa-plus"></i>
                      </li>
                    </ul>
                    <p>(Upto £1,100*)</p>
                    <a
                      className="btn circle mt-25 py-2 px-3 btn-sm btn-theme secondary animation"
                      href="#"
                    >
                      Claim Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQSection;
