import React, { useEffect, useState } from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import TCG_L_B_Form from "../../Forms/TCG_L_B/TCG_L_B_Form";
import FbViewPixel from "../../../Utility/FbViewPixel";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import Header from "../../Includes/Layouts/TCG_L_B/Header";
import Footer from "../../Includes/Layouts/TCG_L_B/Footer";
import FAQSection from "../../Includes/Layouts/Common/FAQSection";
import { Split_TCG_L_B } from "../../../Constants/SplitFaq";
import GetLenders from "../../../Utility/GetLenders";
import Loader from "../../Includes/Layouts/Common/Loader";
import "../../../assets/css/TCG_L_B/custom.scss"
const TCG_L_B = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "TCG_L_B",
    "TCG_L_B"
  );
  const [loaderStatus, setLoaderStatus] = useState(true);
  const { getLenderList } = GetLenders();
  const [showFaq, setShowFaq] = useState('show');
  const [footerEnable,setFooterEnable] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await getLenderList();
      if (response) setLoaderStatus(false);
    })();
  }, []);
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="pcp/TCG_L_B" />

      <FbViewPixel />
      {loaderStatus && <Loader loderContent={``}/>}
      {!loaderStatus && (
        <div className="TCG_L_B">
          <Header />
          <section className="dashboad-bottom">
            <div className="inner  pt-3 pb-5">
              <div className="container all-slides">
                <TCG_L_B_Form
                  splitName="pcp/TCG_L_B"
                  nextPage={DynamicRouteNextPage}
                  setShowFaq={setShowFaq}
                  setFooterEnable={setFooterEnable}
                />
              </div>
              <div className="container pt-4">
                <ul className="ps-0 ms-0 mb-3   list-highlight my-lg-4 my-md-4 my-sm-3 my-2">
                  <li className="d-flex align-items-center">
                    <div className="icons pe-3 me-2">
                      {" "}
                      <i className="bi bi-emoji-smile"></i>
                    </div>
                    <div>
                      Millions of UK drivers could be eligible for refunds
                    </div>
                  </li>

                  <li className="d-flex align-items-center">
                    <div className="icons pe-3 me-2">
                      {" "}
                      <i className="bi bi-car-front-fill"></i>
                    </div>
                    <div>You can claim for current and past vehicles</div>
                  </li>

                  <li className="d-flex align-items-center">
                    <div className="icons pe-3 me-2">
                      {" "}
                      <i className="bi bi-clock-history"></i>
                    </div>
                    <div>Start your claim in just 60 seconds</div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <FAQSection faq={Split_TCG_L_B} accordionTitle="FAQ" showFaq={showFaq} />
          <Footer footerEnable={footerEnable}/>
        </div>
      )}
    </>
  );
};

export default TCG_L_B;
