import { Api } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";
import { useErrorWebhook } from "./useErrorWebhook";


export const usePostCodeAddressCustomApi = () => {

    const { ErrorWebhookTrigger } = useErrorWebhook();
    const getPostcodeAddressApi = async (postcode) => {
        const response = await Api.post('v1/get-postcode-details', {
            postcode: postcode
        }).catch(function (error) {
            const erroMessage = {
                serviceName: 'GET POSTCODE DETAILS API',
                errorReason: error.message,
                errorCode: error.code
            }
            ErrorWebhookTrigger(erroMessage);
        });
        let addressDetails = [];
        if (response.data.Address.length > 0) {
            addressDetails = response.data.Address.map((addressData) => ({
                id: addressData.RawAddress.UniqueReference,
                address: addressData.Lines.filter(line => line).join(", "),
                RawAddress: addressData.RawAddress,
                line_1: addressData.Lines[0] || "",
                line_2: addressData.Lines[1] || "",
                line_3: addressData.Lines[2] || "",
                line_4: addressData.Lines[3] || "",
                town: addressData.Lines[4] || "",
                county: addressData.Lines[5] || "",
                country: addressData?.RawAddress?.Location?.Country || "England"
            }));
            addressDetails.unshift({ "id": "", "address": "Please Select Address" });
        }
        return addressDetails;
    };
    return { getPostcodeAddressApi };
};
