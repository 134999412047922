import React, { useEffect, useState } from 'react';
import "../../../assets/css/TCG_L_D/custom.scss";
import "../../../assets/css/TCG_L_D/thankyou.scss";
import Header from "../../Includes/Layouts/TCG_L_D/Header"
import Footer from "../../Includes/Layouts/TCG_L_D/Footer";
import LogoBasedOnDomain from '../../Includes/Layouts/Common/LogoBasedOnDomain';
import tick from "../../../assets/img/TCG_L_D/tickthankyou.png"

const ThankyouV4 = () => {
  localStorage.clear();
  return (
    <>
      <div className="TCG_L_D thankyou" >
        <Header />
        <form>
          <section className="banner ">
            <div className="container">
              <div>
                <div className="brand">
                  <div className="logo pt-3">
                    <LogoBasedOnDomain height="40" />
                  </div>
                </div>
              </div>
              <div className="row mt-5 mb-5 justify-content-center " >
                <div className="col-lg-6">
                  <div className="check-tik mb-5 ">
                    <div className="circle-border"></div>
                    <div className="circle">
                     <img src={tick} />
                    </div>
                  </div>
                  <h1 className="fw-bold text-light text-center">Thank You</h1>
                  <p className="text-light text-center">We will be in touch regarding the next steps and updates.</p>
                </div>
              </div>
            </div>
          </section>
        </form>
        <Footer superScriptQuote={true} />
      </div>

    </>

  );
}
export default ThankyouV4;
