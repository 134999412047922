import React, { useState } from 'react'
import TextField from '../UI/TextField';
import { useUuidValidate } from '../../Hooks/useUuidValidate';
import Button from '../UI/Button';
import FollowUpFooter from '../Pages/FollowUp/FollowUpFooter';
import LogoBasedOnDomain from '../Includes/Layouts/Common/LogoBasedOnDomain';
import "../../assets/css/followup/custom.scss";
import spinner from "../../assets/img/infinite-spinner.svg";


const Trigger_followup_strategy = () => {

	const { validateUuid, TriggerFollowupMethod } = useUuidValidate();
	const [uuid, setUuid] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [error, setError] = useState('');
	const [user, setUser] = useState({ 'userId': '', 'userEmail': '', 'userPhone': ''});
	const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
	const [isLoading, setIsLoading] = useState('');
	const [modalLoading, setModalLoading] = useState('');


	const handleSubmit = async (e) => {
		e.preventDefault();
		let uuid = document.querySelector('#uuidInput').value;
		if (uuid) {
			setIsLoading(true);
			const userData = await validateUuid(uuid);
			const updatedUserData = userData.data.User_data;
			
			
			if(userData.data.status_code != 1 || userData.data.status_code != 0 ){
				const timer = setTimeout(() => {
					setIsLoading(false);
				}, 3500); //1000
			}else{
				setIsLoading(true);
			}

			if (userData) {
				if (userData.data.status_code === 1) {
					setUser({userId : updatedUserData.id, userEmail : updatedUserData.email, userPhone : updatedUserData.telephone});
					setSubmitted(true);
					setError(''); // Clear any previous error messages
				} else {
					setError('Invalid UUID.');
					setSubmitted(false);
				}
			} else {
				setError('Error validating UUID.');
				setSubmitted(false);

			}
		}
	};

	const handleUuidChange = (e) => {
		setUuid(e.target.value);
	};
	const sendClick = async (followup_method) => {
		const response = await TriggerFollowupMethod(user.userId,followup_method); 
		setModalShow(true);	
		setModalLoading(true); 
		if(response.data.status_code === 1){
			let message;
			switch (followup_method) {
				case 'Immediate SMS':
					message = `SMS sent successfully to ${user.userPhone}`;
					break;
				case '24hr SMS':
					message = `24-hour SMS scheduled successfully to ${user.userPhone}`;
					break;
				case '48hr SMS':
					message = `48-hour SMS scheduled successfully to ${user.userPhone}`;
					break;
				case 'Immediate Email':
					message = `Email sent successfully to ${user.userEmail}`;
					break;
				case '24hr Email':
					message = `24-hour Email scheduled successfully to ${user.userEmail}`;
					break;
				default:
					message = 'Follow-up method triggered successfully';
					break;
			}			
			
			const timer = setTimeout(() => {
				setModalLoading(false);
				setModalMessage(message);
				setModalShow(true);			
			}, 4000); //time in milisecond
		} else {
			const timer = setTimeout(() => {
				setModalLoading(false);
				setModalMessage('Error in triggering followup method.');
				setModalShow(true);	
				}, 4000); //time in milisecond

		}

	};
	return (
		<>
			<div className="followup_method_trigger">
				<div className="TGGtcg">
					<div className="bg_grade">
						{/*TOP NAVIGATION*/}
						<header id="header" className="sticky-header">
							<div className="container text-center">
								<a><LogoBasedOnDomain /></a>
							</div>
						</header>
						<section className="section_create">
							<div className="bg_clor">
								<div className="container-md " style={{ paddingTop: 90 }}>
									<div className="row p-0 m-0 pb-4">
										<div className="col-md-6 offset-md-3 col-sm-12 col-12">
											<div className="content">
												<h5 className='text-danger text-center'>For Internal Testing Purposes Only</h5>
												<form onSubmit={handleSubmit} >
													<div className="slide1 pb-3">
														<div className="">
															<fieldset className="mb-3">
																<legend>Enter your UUID number</legend>
																<div className="input-group mb-3">
																	<TextField
																		type="text"
																		className="form-control"
																		id="uuidInput"
																		value={uuid}
																		required={true}
																		onChange={handleUuidChange}
																		placeholder="UUID number"
																	/>
																	<Button
																		type="submit"
																		className="btn btn-outline-secondary main-btn m-0"
																		onClick={() => handleSubmit}
																		buttonText="Check"
																	/>
																</div>
															</fieldset>
														</div>

														{isLoading ? (
															<div className="loader-wrap loadercarlookup1 text-center">
																<div className="mt-5 mb-5 loader-style">
																	<img
																		className="mb-2"
																		height="80"
																		src={spinner}
																		alt="Loading..."
																	/>
																	<h6 className='text-white'>Please wait. This might take a few moments.</h6>
																</div>
															</div>
														) : error ? (
															<div className="alert alert-danger text-danger">
																{error}
															</div>
														) : (
															// Render other content when not loading or in error state
															submitted ? (<>
																<fieldset>
																	<legend>SMS Strategy</legend>
																	<Button
																		type="button"
																		className="main-btn zoom1 w-100  "
																		onClick={() => sendClick('Immediate SMS')}
																		buttonText={<>Immediate{" "}
																			<i className="bi bi-chevron-right bold-icon"></i>
																		</>}
																	/>
																	<Button
																		type="button"
																		className="main-btn zoom1 w-100  "
																		onClick={() => sendClick('24hr SMS')}
																		buttonText={<>After 24 hours{" "}
																			<i className="bi bi-chevron-right bold-icon"></i>
																		</>}
																	/>
																	<Button
																		type="button"
																		className="main-btn zoom1 w-100  "
																		onClick={() => sendClick('48hr SMS')}
																		buttonText={<>After 48 hours{" "}
																			<i className="bi bi-chevron-right bold-icon"></i>
																		</>}
																	/>
																</fieldset>
																<fieldset className="mt-3">
																	<legend>Email Strategy</legend>
																	<Button
																		type="button"
																		className="main-btn zoom1 w-100  "
																		onClick={() => sendClick('Immediate Email')}
																		buttonText={<>Immediate{" "}
																			<i className="bi bi-chevron-right bold-icon"></i>
																		</>}
																	/>
																	<Button
																		type="button"
																		className="main-btn zoom1 w-100  "
																		onClick={() => sendClick('24hr Email')}
																		buttonText={<>After 24 hours{" "}
																			<i className="bi bi-chevron-right bold-icon"></i>
																		</>}
																	/>
																</fieldset>
															</>
															):( <>
																	{/* else case */}
															 		</>
																)
														)}
													</div>
													{/* slide1 end */}
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>

					{/* <FollowUpFooter /> */}

					<footer className="py-lg-5 py-md-5 py-4">
						<div className="wrap larger text-size-smallest">
							<div className="container">
								<div className="ftloog  smaller mb-3">
									<LogoBasedOnDomain className="" bg="black" />
								</div>
							</div>
						</div>
					</footer>

					{modalShow && <div
						className={`modal fade ${modalShow && "show d-block"}`}
						id="purchaseyear"
						data-bs-backdrop="static"
						data-bs-keyboard="false"
						tabIndex="-1"
						aria-labelledby="staticBackdropLabel"
						aria-hidden="true"
					>
						<div className="modal-dialog modal-md modal-dialog-centered">
							<div className="modal-content carregform-modal">
								<div className="modal-header">
									<h5 className="modal-title" id="staticBackdropLabel">Notification</h5>
									<button type="button" className="btn-close" aria-label="Close" onClick={()=>setModalShow(false)}></button>
								</div>
								<div className="modal-body">
									{modalLoading ? (
										<div className="loader-wrap loadercarlookup1 text-center">
											<div className="mt-5 mb-5 loader-style">
												<img
													className="mb-2"
													height="60"
													src={spinner}
													alt="Loading..."
												/>
												<h6>Please wait. This might take a few moments.</h6>
											</div>
										</div>
									) : (
										<>
											{modalMessage}
										</>
									)}
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary" onClick={()=>setModalShow(false)}>Close</button>
								</div>
							</div>
						</div>
					</div>}
					
				</div>

			</div>
		</>

	)
}

export default Trigger_followup_strategy