import React, { useState, useContext } from 'react';
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import InputButton from "../../../UI/InputButton";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import Privacy from "../../../../assets/img/privacy.png";
import Lock from "../../../../assets/img/lock.png";
import { slideValidation } from '../../../../Utility/Validations';
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import ProgressBar from "../../../Includes/Layouts/TCG_L_C/ProgressBar";
import Loader from "../../../Includes/Layouts/TCG_L_C/Loader";
import CheckBox from '../../../UI/CheckBox';
import {getBrandName} from "../../../Includes/Layouts/Common/BrandBasedOnDomain";
import GTMDataLayer from '../../../../Utility/GTMDataLayer';

const ContactDetails = (props) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [contactValidation, setContactValidation] = useState({ 'txtEmail': false, 'txtPhone': false });
  const [loader, setLoader] = useState('hide');
  const [contactPolicy, setContactPolicy] = useState(true);
  const brandName = getBrandName();
  const phoneValidCheck = async () => {
    const values = props.getValues();
    const phone = values['txtPhone'];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone, visitorParameters.visitor_parameters.uuid)
      if (getPhoneValidation.data.status_code === 0) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      }
      else {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: true,
        }));
        props.clearErrors("txtPhone");
        return 1;
      }
    } else {
      props.setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Phone Number",
      });
    }
  };
  const emailValidCheck = async () => {
    const values = props.getValues();
    const email = values['txtEmail'];
    if (email !== "") {
      const getEmailValidation = await emailValidation(email, visitorParameters.visitor_parameters.uuid)
      if (getEmailValidation.data.status_code === 1) {
        setContactValidation(prevState => ({
          ...prevState,
          txtEmail: true,
        }));
        props.clearErrors("txtEmail");
        return 1;
      } else {
        setContactValidation(prevState => ({
          ...prevState,
          txtEmail: false,
        }));
        props.setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
      }
    } else {
      props.setError("txtEmail", {
        type: "manual",
        message: "Please Enter Valid Email",
      });
    }
  };
  const validNumber = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }
  const eventOnchange = (e) => {
    var field = e.target.id;
    setContactValidation(prevState => ({
      ...prevState,
      [field]: false,
    }));
    if (e.target.name == 'txtphone' && e.target.value.length >= 11) phoneValidCheck()
  }
  const handleSubmit = async (e) => {
    let validationStatus = 0;
    let contactPolicyStatus = 0;
    var validationArr = ['txtEmail', 'txtPhone'];
    const validationCheck = await slideValidation(validationArr, props.trigger);
    const contactStatus = Object.entries(contactValidation).find(([key, value]) => value === false);
    if (validationCheck == 'Success') {
      if (contactStatus) {
        let response;
        setLoader('show');
        if (contactStatus[0] === "txtEmail") {
          response = await emailValidCheck();
        } else if (contactStatus[0] === "txtPhone") {
          response = await phoneValidCheck();
        }
        if (response == 1) {
          validationStatus = 1;
        }else{
          validationStatus = 0;
        }
      }else{
        validationStatus = 1;
      }
      if(contactPolicy == false){
        contactPolicyStatus = 0;
        props.setError("contactPolicy", {
          type: "manual",
          message: "Please tick to accept in order to proceed",
        });
      }else{
        setLoader('show');
        contactPolicyStatus = 1;
      }
      if(validationStatus == 1 && contactPolicyStatus == 1){
        setTimeout(() => {
          setLoader('hide');
          props.slideChange(e);
        }, 1500);
      }else{
        setTimeout(() => {
          setLoader('hide');
        }, 1500);
      }
      setLoader('show');
      setTimeout(() => {
        setLoader('hide');
        GTMDataLayer({
          clickEvent: 'slideclick',
          question: 'contact details',
          answer: '',
        });
        //props.slideChange(e);
      }, 3000);
    }
  };
  return (
    <>
      <Loader loader={loader} contentText="Analysing Your Result..." />
      <div className={`slide6 ${props.showSlide6}`} id="slide_6">
        <ProgressBar load="load2" />
        <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
          <label>Enter Your Contact Details</label>

          <div className="form-group mb-3 animate__animated animate__fadeInUp animate__delay-.5s">
            <label className="mb-1 fs-6">Enter Your Email</label>
            <div className="input-group  animate__animated animate__fadeInUp animate__delay-.5s position-relative">
              <Email
                name="txtEmail"
                id="txtEmail"
                className="form-control"
                placeholder="Email Address"
                onBlur={emailValidCheck}
                autoComplete="new-email"
                onKeyPress={e => { if (e.key === ' ') e.preventDefault() }}
                onChange={(e) => { props.clearErrors("txtEmail"); eventOnchange(e) }}
                validation={props.validation({
                  required: "Please Enter Valid Email Address",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid Email Address",
                  },
                })}
                // validationMsg={
                //   props.validationMsg.txtEmail && props.validationMsg.txtEmail.message
                // }
              />
              <div className="input-group-text img-class1">
                <img src={Privacy} alt="" className="privcy" />
              </div>
            </div>
            { props.validationMsg.txtEmail && <span className='error_msg'>{props.validationMsg.txtEmail.message}</span>}
          </div>

          <div className="form-group animate__animated animate__fadeInUp animate__delay-.5s position-relative">
            <label className="mb-1 fs-6">Enter Your Phone Number</label>
            <div className="input-group ">
              <Telephone
                name="txtPhone"
                id="txtPhone"
                className="form-control"
                placeholder="Phone Number"
                onChange={(e) => { props.clearErrors("txtPhone"); eventOnchange(e); }}
                onBlur={phoneValidCheck}
                onKeyPress={validNumber}
                maxlength="11"
                autoComplete="new-phone"
                validation={props.validation({
                  pattern: {
                    value: /^[0-9]*$/i,
                    message: "Please Enter Valid Phone Number",
                  },
                  required: "Please Enter Valid Phone Number",
                })}
                validationMsg={
                  props.validationMsg.txtPhone && props.validationMsg.txtPhone.message
                }
              />
              <div className="input-group-text img-class1">
                <img src={Privacy} alt="" className="privcy" />
              </div>
            </div>
            {props.validationMsg.txtPhone && (
              <div className="error_msg">{props.validationMsg.txtPhone.message}</div>
            )}
          </div>

          <p className="form-check check_box_agree d-flex mt-3">
            {/* <img className="pe-2" src={Lock} alt="" /> */}
            
            <CheckBox
              className="form-check-input cntct-plcy"
              type="checkbox"
              value=""
              id="contactPolicy"
              name="contactPolicy"
              checked={contactPolicy}
              onChange={() => {setContactPolicy(!contactPolicy); props.clearErrors("contactPolicy")}}
              labelClassName={`pe-2`}
              validation={props.validation}
            />
            <span style={{fontSize:"12px"}}>Your personal information will be treated carefully in accordance with our Privacy Policy. We {brandName}, is a trading style of The Claims Guys Legal, will contact you about claim opportunities using the contact details you provide. You can opt out of receiving communications from us at any time by sending an email to <a href="mailto:dataprotection@theclaimsguyslegal.com">dataprotection@theclaimsguyslegal.com</a></span>
          </p>
          {props.validationMsg.contactPolicy && (<span className="error_msg">{props.validationMsg.contactPolicy.message}</span>)}
        </div>

        <div className="form-navigation pt-2 animate__animated animate__fadeInUp animate__delay-.5s">
          <ul>
            <li>
              <InputButton
                name="slide6"
                className="btn center-block next-but sctop"
                id="next-03"
                value="Submit"
                btnType="button"
                style={{ float: "none" }}
                onClick={handleSubmit}
                gtm_question='contact details'
                clickevent='slideclick'
                gtm_answer=''
                // gtm_trigger={true}
              />
            </li>
            <li>
              <InputButton
                name="back4"
                className="btn center-block back-but sctop"
                id="backStep3"
                value="Back"
                btnType="button"
                style={{ float: "none" }}
                onClick={props.previousSlideChange}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default ContactDetails;