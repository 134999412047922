import React, { useState, useRef } from "react";
import LogoBasedOnDomain from "../Common/LogoBasedOnDomain";
import Danger from "../../../../assets/img/TCG_L_D/danger.png";
import RadioButtonV2 from "../../../UI/RadioButtonV2";
import PreviousAddress from "./PreviousAddress";
import { useForm } from "react-hook-form";
import Button from "../../../UI/Button";
import { slideValidation } from "../../../../Utility/Validations";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const PreviousAddressModel = (props) => {
  const { register, errors, trigger, setError, clearErrors, getValues, setValue } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const scrollToPrePc = useRef([]);
  const [previousPostcodeSection, setPreviousPostcodeSection] = useState({ 'prevPostcode1': false, 'prevPostcode2': false, 'prevPostcode3': false, 'prevPostcode4': false, 'prevPostcode5': false });
  const [prevContineButton, setPrevContineButton] = useState(false);
  const previousPostCodeCheckArr = { 'prevPostcode2': [1], 'prevPostcode3': [1, 2], 'prevPostcode4': [1, 2, 3], 'prevPostcode5': [1, 2, 3, 4] };
  const PrevAddressClick = (e) => {
    if (e.target.value == 'no') {
      props.setPreviousAddressModelShow(false);
      props.handlePreviousDataSubmit('pageChange', null);
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'previous address',
        answer: '',
      });
    } else {
      setPreviousPostcodeSection(prevState => ({
        ...prevState,
        prevPostcode1: true,
      }));
    }
  }

  const AddPrevPostcode = async () => {
    var nextSection = Object.entries(previousPostcodeSection).find(([key, value]) => value === false);
    if (nextSection) {
      var addValidationArr = [];
      previousPostCodeCheckArr[nextSection[0]].forEach(element => {
        addValidationArr.push(`txtPrevPostCode_${element}`, `txtPrevAddress1_${element}`, `txtPrevHouseNumber_${element}`);
      });
      const validationCheck = await slideValidation(addValidationArr, trigger);
      if (validationCheck == 'Success') {
        setPreviousPostcodeSection((prevState) => ({
          ...prevState,
          [nextSection[0]]: true,
        }));
        setTimeout(() => {
          scrollToPrePc.current.scrollIntoView({
            behavior: "smooth",
            block: 'end'
          });
        }, 50);
      }
    }
  }
  const prevAddressSubmit = () => {
    const value = getValues();
    props.handlePreviousDataSubmit('pageChange', value);
    GTMDataLayer({
      clickEvent: 'slideclick',
      question: 'previous address',
      answer: '',
    });
  }
  return (
    <>
      <div className={`modal fade bg-dark show`} id="prevAddrees" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered   modal-md">
          <div className="modal-content">
            <div className="modal-header py-3 text-center bg-pophead text-center justify-content-center">
              <LogoBasedOnDomain height="30" />
            </div>
            <div className="modal-body  creditpopup">
              <div className="pb-3">
                <div className="text-center">
                  <img height="50" src={Danger} alt="" />
                  <h5 className=" py-2 lh-base">
                    Did you have any previous addresses when you took car finance? [ <small><i>for the last 20 years</i> </small> ]</h5>
                </div>

                <div className="row mt-4">
                  <div className="col-6">
                    <div className="optionBtn blackoption p-0  drop-in   w-100   fs-6 text-start pointer shadow-lg ">
                      <RadioButtonV2
                        className="radio"
                        id="prevAddYes"
                        name="previousAddress"
                        value="yes"
                        onClick={PrevAddressClick}
                        labelName="Yes"
                        divClass="pe-2"
                        spanclass="me-1"
                        labelClassName="d-flex align-items-center  p-lg-2 p-2"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="optionBtn blackoption p-0  drop-in  w-100   fs-6 text-start pointer shadow-lg ">
                      <RadioButtonV2
                        className="radio"
                        id="prevAddNo"
                        name="previousAddress"
                        value="no"
                        onClick={PrevAddressClick}
                        labelName="No"
                        divClass="pe-2"
                        spanclass="me-1"
                        labelClassName="d-flex align-items-center  p-lg-2 p-2"
                      />
                    </div>
                  </div>
                </div>

                {previousPostcodeSection.prevPostcode1 &&
                  <><PreviousAddress
                    prevAddrs="Previous Address"
                    preCount="1"
                    validation={register}
                    validationMsg={errors}
                    trigger={trigger}
                    clearErrors={clearErrors}
                    setError={setError}
                    setPrevContineButton={setPrevContineButton}
                    scrollToEvent={scrollToPrePc}
                  />
                  </>
                }
                {previousPostcodeSection.prevPostcode2 &&
                  <><PreviousAddress
                    prevAddrs="Previous Address 2"
                    preCount="2"
                    validation={register}
                    validationMsg={errors}
                    trigger={trigger}
                    clearErrors={clearErrors}
                    setError={setError}
                    setPrevContineButton={setPrevContineButton}
                    scrollToEvent={scrollToPrePc}
                  />
                  </>
                }

                {previousPostcodeSection.prevPostcode3 &&
                  <><PreviousAddress
                    prevAddrs="Previous Address 3"
                    preCount="3"
                    validation={register}
                    validationMsg={errors}
                    trigger={trigger}
                    clearErrors={clearErrors}
                    setError={setError}
                    setPrevContineButton={setPrevContineButton}
                    scrollToEvent={scrollToPrePc}
                  />
                  </>
                }

                {previousPostcodeSection.prevPostcode4 &&
                  <><PreviousAddress
                    prevAddrs="Previous Address 4"
                    preCount="4"
                    validation={register}
                    validationMsg={errors}
                    trigger={trigger}
                    clearErrors={clearErrors}
                    setError={setError}
                    setPrevContineButton={setPrevContineButton}
                    scrollToEvent={scrollToPrePc}
                  />
                  </>
                }

                {previousPostcodeSection.prevPostcode5 &&
                  <><PreviousAddress
                    prevAddrs="Previous Address 5"
                    preCount="5"
                    validation={register}
                    validationMsg={errors}
                    trigger={trigger}
                    clearErrors={clearErrors}
                    setError={setError}
                    setPrevContineButton={setPrevContineButton}
                    scrollToEvent={scrollToPrePc}
                  />
                  </>
                }

                {(previousPostcodeSection.prevPostcode1 && !previousPostcodeSection.prevPostcode5) &&
                  <div className="mt-2 text-end addmorediv">
                    <Button
                      type="button"
                      className="btn btn-dark"
                      id="addSurname1"
                      onClick={AddPrevPostcode}
                      buttonText="+ Add More"
                    />
                  </div>}
                {(prevContineButton || previousPostcodeSection.prevPostcode2) && <div className="continue-btn prevcontButton mt-3" ref={scrollToPrePc}>
                  <Button
                    type="button"
                    className="btn-comm2 p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3 fw-bold"
                    id="prevAddSubmit"
                    onClick={prevAddressSubmit}
                    buttonText="Continue"
                  />
                </div>}
              </div>

            </div>

          </div>
        </div>
      </div>
    </>
  )
}


export default PreviousAddressModel;