import React, { useEffect, useState } from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import TCG_L_C_Form from "../../Forms/TCG_L_C/TCG_L_C_Form";
import FbViewPixel from '../../../Utility/FbViewPixel';
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import Header from "../../Includes/Layouts/TCG_L_C/Header";
import Footer from "../../Includes/Layouts/TCG_L_C/Footer";
import FAQSection from "../../Includes/Layouts/TCG_L_C/FAQSection";
// import GetLenders from "../../../Utility/GetLenders";
// import Loader from "../../Includes/Layouts/TCG_L_C/Loader";
import "../../../assets/css/TCG_L_C/custom.scss";
import "../../../assets/css/Modal.scss";
const TCG_L_C = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit('TCG_L_C', 'TCG_L_C');
  const [showFaq, setShowFaq] = useState('show');
  // const { getLenderList, isLoading } = GetLenders();
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [footerEnable,setFooterEnable] = useState(true);
  // useEffect(() => {
  //   (async () => {
  //     const response = await getLenderList();
  //     //if(response) setLoaderStatus(false);
  //   })()
  // }, []);

  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="pcp/TCG_L_C" />
      <FbViewPixel />
      {/* {loaderStatus && <Loader loader='show' contentText=""/>} */}
      {/* <Loader loader={isLoading} contentText=""/> */}
      {/* {!loaderStatus &&<div className="TCG_L_C">   */}
      <div className="TCG_L_C">  
        <div className="wrapper w-100">
          <div className="main-container">
            <Header />
            <TCG_L_C_Form
              nextPage={DynamicRouteNextPage}
              splitName="pcp/TCG_L_C"
              setShowFaq={setShowFaq}
              setFooterEnable={setFooterEnable}
            />
            <FAQSection showFaq={showFaq} />
            <Footer footerEnable={footerEnable} superScriptQuote={!footerEnable} />
          </div>
        </div>
      </div>
    </>
  )
};

export default TCG_L_C;
