import React from "react";

const CheckBoxV2 = ({
  value,
  CheckBoxText,
  name,
  checked,
  onChange,
  dataId,
  className,
  labelClassName,
  labelText,
  onClick,
  validation,
  disable

}) => {
  return (
    <>
      <input
        type="checkbox"
        className={className}
        value={value}
        id={dataId}
        name={name}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        ref={validation}
        disabled={disable}
      />
      <label className={labelClassName} htmlFor={dataId}>
      {labelText}
      </label>
    </>
  );
};
export default CheckBoxV2;
