import { Api } from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const useAuthorizeStatus = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const saveAuthorizeStatus = async ( uuid) => {
    const response = await Api.post("v1/save-authorize-click", {uuid})
    .catch(function (error) {
      const erroMessage = {
        serviceName : 'Save Authorize Status',
        errorReason:error.message,
        errorCode:error.code
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };
  return { saveAuthorizeStatus };
};
