import { useRef, useState, useContext, useReducer, useEffect } from "react";
import { BrowserView, MobileView, MobileOnlyView, TabletView, deviceType, isIPad13, ConsoleView } from "react-device-detect";
import "../../../assets/css/followup/signature.scss";
import Button from "../../UI/Button";
import SignatureCanvas from "react-signature-canvas";
import { useFollowupUserSignature } from "../../../Hooks/useFollowupUserSignature";
import sign_arrow from "../../../assets/img/arrow-green.png";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};

const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};



const FollowUpSignature = (props) => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveFollowupUserSignature, isLoading } = useFollowupUserSignature();
  const followupData = JSON.parse(localStorage.getItem('followup_data'));
  const signPadRef = useRef();
  const focusRef = useRef();
  const handleClear = () => {
    const signPad = signPadRef.current;
    signPad.clear();
    dispatch({ type: 'signPadChange', payload: { value: false } });
    dispatch({ type: 'validSignature', payload: { value: false } });
  };

  const handleSubmit = async (e) => {
    // try {
    const signPad = signPadRef.current;
    if (signPad.isEmpty()) {
      focusRef.current.scrollIntoView({
        behavior: "smooth",
        block: 'end'
      });
      return dispatch({ type: "signPadChange", payload: { value: true } });
    }

    const rpdata = signPad.toData();
    let signLength = 0;
    for (let i = 0; i < rpdata.length; i++) {
      signLength += Object.keys(rpdata[i]).length;
    }

    if (signLength > 13) {
      await saveFollowupUserSignature(
        signPad.getCanvas().toDataURL("image/png"),
        followupData,
        'followup_user_signature',
        props.uuid
      );

      props.slideChange(e);
    } else {
      focusRef.current.focus();
      dispatch({ type: "validSignature", payload: { value: true } });
    }
    // } catch (error) {
    //   // setBtnLoader(false);
    //   console.error("Error submitting signature:", error);
    // }
  };
  return (
    <>
      <div className="signature-section animate__animated animate__fadeInLeft " >
        <div className="row p-0 m-0">
          <div className="col-lg-12 p-0 m-0">
            <div className="text-center">
              <h1 className="fw-bold ">Your signature</h1>
              <p className="text-white">Keep it fully contained within the box below</p>
            </div>
            <div className="sign-sec p-3">
              <div className="text-start signature-footer p-2">
                <p className="fw-semibold">
                  By signing below I confirm that I was not told about the commission the lenders I have told you about would pay to the car dealerships for arranging my car finance.
                </p>
              </div>
              <div className="signbox" id="signBox">
              <h5>Sign below <i class="icodown"><img src={sign_arrow} /></i></h5>
              <div className="border-div">
                {(deviceType === "mobile" ||
                  deviceType === "tablet") && (
                    <MobileView>
                      <SignatureCanvas
                        canvasProps={{
                          className: "",
                          id: "canvas_signature",
                        }}
                        clearOnResize={false}
                        ref={signPadRef}
                        onEnd={() => {
                          if (!signPadRef.current.isEmpty()) {
                            // setButtonText("Proceed");
                            dispatch({
                              type: "signPadChange",
                              payload: { value: false },
                            });
                            dispatch({
                              type: "validSignature",
                              payload: { value: false },
                            });
                          }
                        }}
                      />
                    </MobileView>
                  )}
                {deviceType === "browser" && (
                  <BrowserView>
                    <SignatureCanvas
                      canvasProps={{
                        className: "",
                        id: "canvas_signature",
                      }}
                      clearOnResize={false}
                      ref={signPadRef}
                      onEnd={() => {
                        if (!signPadRef.current.isEmpty()) {
                          dispatch({
                            type: "signPadChange",
                            payload: { value: false },
                          });
                          dispatch({
                            type: "validSignature",
                            payload: { value: false },
                          });
                        }
                      }}
                    />
                  </BrowserView>
                )}
                 <Button
                name="clear"
                className="clearbutton"
                id="clear"
                buttonText="Clear"
                btnType="button"
                onClick={handleClear}
              />
                </div>
                <div ref={focusRef}>
                  {state.isCanvasEmpty && (
                    <span className="error_msg">Signature is required!</span>
                  )}
                  {state.isValid && (
                    <span className="error_msg">Draw valid signature!</span>
                  )}
                  
                </div>
              </div>
              <div className="sign-blw-text mt-3">
                <div className="d-flex align-item-top sign-check mb-3 mt-3">
                  <div className="pe-2">
                    <div className="chicon">
                      <i className="bi bi-lock"></i>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="checkbox1">Your personal information will be treated carefully in accordance with our Privacy Policy. We Claim Finder, is a trading style of The Claims Guys Legal, will contact you about claim opportunities using the contact details you provide. You can opt out of receiving communications from us at any time by sending an email to <a href="maiilto:dataprotection@theclaimsguyslegal.com">dataprotection@theclaimsguyslegal.com</a>
                    </label>
                  </div>
                </div>
              </div>

            </div>
            <div className=" is_sticky box_button mb-5 pt-4 animate__animated animate__fadeInUp animate__delay-1s animated-element"
              id="subRegNo1">
              <div className="py-4">
                <Button
                  type="button"
                  className="zoom main-btn p-2 w-100 mb-lg-0 mb-sm-0 py-3 fs-5 fw-bold sticky" id="submitToClaim"
                  buttonText={<>Submit To Claim  <i className="bi bi-chevron-right bold-icon"></i></>}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FollowUpSignature;