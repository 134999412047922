import React, { useState } from 'react';
import RadioButtonV2 from "../../../UI/RadioButtonV2";
import CheckBoxV2 from "../../../UI/CheckBoxV2";
import InputButton from "../../../UI/InputButton";
import { questionnaire_TCG_L_C } from "../../../../Constants/Questions";
import ProgressBar from "../../../Includes/Layouts/TCG_L_C/ProgressBar";
import { slideValidation } from '../../../../Utility/Validations';
import Logo from "../../../../assets/img/logo_v3.png";
import LogoBasedOnDomain from "../../../Includes/Layouts/Common/LogoBasedOnDomain";
import GTMDataLayer from '../../../../Utility/GTMDataLayer';


const BasicQuestions = (props) => {
  const [isYesModalVisible, setIsYesModalVisible] = useState('hide');
  const [isCheckAnswerModalVisible, setIsCheckAnswerModalVisible] = useState('hide');
  const question1 = questionnaire_TCG_L_C.question_1;
  const question2 = questionnaire_TCG_L_C.question_2;

  const handleFirstQuestionSubmit = async (e) => {
    const question_1 = props.getValues().question_1;
    const validationArr = ['question_1'];
    const validationCheck = await slideValidation(validationArr, props.trigger);
    if (validationCheck == 'Success' && question_1 == 2) {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'commission received',
        answer: '',
      });
      props.slideChange(e);
    };
  };
  const handleSecondQuestionSubmit = async (e) => {
    const validationArr = ['question_2'];
    const validationCheck = await slideValidation(validationArr, props.trigger);
    if (validationCheck == 'Success') {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'higher commission',
        answer: '',
      });
      // props.formSubmit();
      props.slideChange(e);
    };
  };
  const handleOptions = (e) => {
    props.setError("question_1", {
      type: "manual",
      message: "",
    });
    props.setError("question_2", {
      type: "manual",
      message: "",
    });
    if (e.target.value == '1') {
      setIsYesModalVisible('show');
    }
  }
  const handleFirstModalSubmit = (e) => {
    setIsYesModalVisible('hide');
    setIsCheckAnswerModalVisible('show');
  };
  const handleSecondModalSubmit = (e) => {
    setIsCheckAnswerModalVisible('hide');
  };
  const handleBackButtonSubmit = (e) => {
    setIsYesModalVisible('hide');
  };
  return (
    <>
      <div className={`slide7 ${props.showSlide7}`} id="slide_7">
        <ProgressBar load="load3" />
        <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
          <label className="mb-3 fs-5">
            {question1.question}</label>
          <div className="form_drop yes_no_sec col-lg-12 col-12 ">
            <div className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s" style={{ zIndex: 2, position: 'relative' }}>
              <div className="choose_Y_N personal_detail col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                <div className="form-check px-0  choose_Y_N2">
                  {question1.answers.map((answer, index) => (
                    <RadioButtonV2
                      key={index}
                      className="custom-checkbox next03"
                      name="question_1"
                      id={`${answer.label}btn1`}
                      value={answer.value}
                      labelName={answer.label}
                      onClick={handleOptions}
                      validation={props.validation({
                        required: "Please select an option",
                      })}
                      validationMsg={props.validationMsg.question_1?.message}
                    />
                  ))}
                </div>

              </div>
            </div>
          </div>
        </div>
        {props.validationMsg.question_1?.message && (
          <span className="error_msg">{props.validationMsg.question_1.message}</span>
        )}
        <div className="form-navigation pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
          <ul>
            <li>
              <InputButton
                name="slide7"
                className="btn center-block next-but sctop"
                id="next-03"
                value="Next"
                btnType="button"
                style={{ float: "none" }}
                onClick={handleFirstQuestionSubmit}
                gtm_question={question1.gtmQuestion}
                clickevent={question1.clickevent}
                gtm_answer=''
              // gtm_trigger={question1.gtm_trigger}
              />
            </li>
            <li>
              {!question1 && <InputButton
                name="back5"
                className="btn center-block back-but sctop"
                id="backStep4"
                value="Back"
                btnType="button"
                style={{ float: "none" }}
                onClick={props.previousSlideChange}
              />}
            </li>
          </ul>
        </div>

      </div>
      <div className={`slide8 ${props.showSlide8}`} id="slide_8">
        <ProgressBar load="load4" />
        <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
          <label className="mb-3 fs-5 animate__animated animate__fadeInUp animate__delay-.5s">
            {question2.question}:</label>
          <div className="form_drop yes_no_sec col-lg-12 col-12">
            <div className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s" style={{ zIndex: 2, position: 'relative' }}>
              <div className="choose_ques col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">

                <div className="form-check px-0 pb-2">
                  {question2.answers.map((answer, index) => (
                    <CheckBoxV2
                      key={index}
                      className="custom-checkbox"
                      name="question_2"
                      value={answer.value}
                      dataId={`points${index + 1}`}
                      labelClassName=""
                      labelText={answer.label}
                      onClick={handleOptions}
                      validation={props.validation({
                        required: "Please select an option",
                      })}
                      validationMsg={props.validationMsg.question_2?.message}
                    />
                  ))}

                </div>
              </div>
            </div>
          </div>
        </div>
        {props.validationMsg.question_2?.message && (
          <span className="error_msg">{props.validationMsg.question_2.message}</span>
        )}
        <div className="form-navigation pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
          <ul>
            <li>
              <InputButton
                name="slide8"
                className="btn center-block next-but sctop"
                id="next-03"
                value="Next"
                btnType="button"
                style={{ float: "none" }}
                onClick={handleSecondQuestionSubmit}
                gtm_question={question2.gtmQuestion}
                clickevent={question2.clickevent}
                gtm_answer=''
              // gtm_trigger={question2.gtm_trigger}
              />
            </li>
            <li>
              <InputButton
                name="back6"
                className="btn center-block back-but sctop"
                id="backStep5"
                value="Back"
                btnType="button"
                style={{ float: "none" }}
                onClick={props.previousSlideChange}
              />
            </li>
          </ul>
        </div>
      </div>
      <div className={`modal fade ${isYesModalVisible}`} id="Sry_msgpopups1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Sry_msgpopupsLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-header p-0">
              <h1 className="modal-title fs-5" id="add_dbLabel">
                {/* <img src={Logo} alt="" /> */}
                <LogoBasedOnDomain className="" bg="black" width="836" height="142" />
              </h1>
            </div>
            <div className="modal-body">
              <div className="cont_flex animate__animated animate__fadeInUp animate__delay-.5s">
                <h2 className="pt-2">Please check your answers</h2>
                <p className="fs-6 fw-semibold">You have confirmed that you were told about the commission the car dealerships
                  would receive. Unfortunately, this means we are unable to pursue a claim against
                  your lenders.</p>
                <p className="fs-6">If you would like to change your answer, please click back. If not please click next to
                  proceed.</p>
              </div>
            </div>
            <div className="modal-footer m-0">
              <div className="form-navigation m-0 animate__animated animate__fadeInUp animate__delay-.5s">
                <ul>
                  <li>
                    <InputButton
                      name="slide"
                      className="btn center-block next-but sctop"
                      id="backModal1"
                      value="Next"
                      btnType="button"
                      style={{ float: "none" }}
                      onClick={handleFirstModalSubmit}
                    />
                  </li>
                  <li>
                    <InputButton
                      name="back"
                      className="btn center-block back-but sctop"
                      id="backModal1"
                      value="Back"
                      btnType="button"
                      style={{ float: "none" }}
                      onClick={handleBackButtonSubmit}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal fade ${isCheckAnswerModalVisible}`} id="Sry_msgpopups2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Sry_msgpopupsLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-header p-0">
              <h1 className="modal-title fs-5" id="add_dbLabel">
                <LogoBasedOnDomain className="" bg="black" width="836" height="142" />
                {/* <img src={Logo} alt="" /> */}
              </h1>
            </div>
            <div className="modal-body">
              <div className="cont_flex animate__animated animate__fadeInUp animate__delay-.5s">
                <h2 className="pt-2">Sorry</h2>
                <p className="fs-6 fw-semibold">We are unable to process a claim for you, as you don’t meet the eligibility criteria.</p>
                <p className="fs-6">We may be able to support you with other claim types and if so, we will be in touch
                  using the email address you have provided.</p>
                <p className="fs-6">You can opt out of receiving communications from us at any time by sending us an
                  email to: <a href="mailto:dataprotection@theclaimsguyslegal.com">dataprotection@theclaimsguyslegal.com</a> </p>
              </div>
            </div>
            <div className="modal-footer m-0">
              <div className="form-navigation m-0 animate__animated animate__fadeInUp animate__delay-.5s">
                <ul>
                  <li>
                    <InputButton
                      name="back"
                      className="btn center-block back-but sctop"
                      id="backModal1"
                      value="Back"
                      btnType="button"
                      style={{ float: "none" }}
                      onClick={handleSecondModalSubmit}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicQuestions;