import React, { useState } from 'react';
import footerLogo from "../../../../assets/img/footer-logo.png";
import LogoBasedOnDomain from "../../../Includes/Layouts/Common/LogoBasedOnDomain";
import {getBrandName} from "../../../Includes/Layouts/Common/BrandBasedOnDomain";

const Footer = (props) => {
  const brandName = getBrandName();

  return (
    <>

      <footer className="py-lg-5 py-md-5 py-sm-4 py-4">

        <div className="container">
          <div className="row">
            {props.footerEnable && <div className="col-lg-4 tex-lg-start mb-3 text-lg-start text-md-start text-sm-center text-center">
              <div className="ft-logo"> 
              <LogoBasedOnDomain className="img-fluid" bg="black" width="836" height="142"/>
              </div>
            </div>}
            {(props.footerEnable) ? (<div className="col-lg-8">
              <p className="text-lg-start text-lg-start text-sm-center text-center"> Copyright ©2024 {brandName}. All rights
                reserved. {brandName} is a trading style of The Claims Guys Legal. The Claims Guys Legal Limited is authorised
                and regulated by the Solicitors Regulation Authority (SRA Number: 642517).
              </p>
              <ul className="m-0 p-0  text-lg-start text-lg-start text-sm-center text-center ">
                <li><a href="https://theclaimsguyslegal.com/wp-content/uploads/2022/10/TCGL_PP_V03_09_22.pdf" target='_blank'>Privacy Policy</a></li>
                <li>*<a target="_blank" href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf">https://www.fca.org.uk</a> </li>
              </ul>
            </div>):
            (<div className="col-lg-12">
              <p className="text-lg-center "> Copyright ©2024 {brandName}. All rights reserved.
              </p>
            </div>)}
          </div>

        </div>
      </footer>
    </>
  );
}

export default Footer;