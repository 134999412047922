import { useState } from "react";
import { IdUploadApi } from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const useIdUpload = () => {

  const { ErrorWebhookTrigger } = useErrorWebhook();
  const [isLoading, setIsLoading] = useState(false);
  const saveIdUpload = async (
    upload_data,
    visitor_parameters,
    form_data,
    data,
    query_string,
    message_type
  ) => {
    const response = await IdUploadApi.post("v1/data-ingestion-pipeline", {
      upload_data,
      visitor_parameters,
      form_data,
      data,
      query_string,
      message_type,
    }).catch(function (error) {
      const erroMessage = {
        serviceName : 'ID UPLOAD',
        errorReason:error.message,
        errorCode:error.code
      }
      ErrorWebhookTrigger(erroMessage);
    });
    setIsLoading(false);
    return response;
  };
  return {
    isLoading,
    saveIdUpload,
  };
};
