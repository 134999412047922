import tcglLogo from "../../../../assets/img/tcgl-logo.svg";
import logoClaimsfinder from "../../../../assets/img/logos/Claimsfinder/claimsfinder.webp";
import logoClaimsfinderBlack from "../../../../assets/img/logos/Claimsfinder/claimsfinder-black.webp";
import logoThepcpList from "../../../../assets/img/logos/ThepcpList/thepcpList.webp";
import logoThepcpListBlack from "../../../../assets/img/logos/ThepcpList/thepcpList-black.webp";
import logorefundsNow from "../../../../assets/img/logos/refundsNow/refundsNow.webp";
import logorefundsNowBlack from "../../../../assets/img/logos/refundsNow/refundsNow-black.webp";

const LogoBasedOnDomain = (props) => {
    const { className, bg, style = null, verticalAlign = null, width = null, height = null } = props;
	const domainName = window.location.hostname;
	let logo;
    if (domainName === 'localhost' || domainName === 'dev.claimfinder.co.uk' 
        || domainName === 'pre.claimfinder.co.uk' || domainName === 'claimfinder.co.uk') {
        //logo = logoClaimsfinder;
        logo = (bg==='black') ? logoClaimsfinderBlack : logoClaimsfinder;
    }else if (domainName === 'dev.refundsnow.co.uk' || domainName === 'pre.refundsnow.co.uk' 
        || domainName === 'refundsnow.co.uk') {
        //logo = logorefundsNow;
         logo = (bg==='black') ? logorefundsNowBlack : logorefundsNow;
    }else if (domainName === 'dev.thepcplist.co.uk' || domainName === 'pre.thepcplist.co.uk' 
    || domainName === 'thepcplist.co.uk') {
        //logo = logoThepcpList;
        logo = (bg==='black') ? logoThepcpListBlack : logoThepcpList;
    }else {
        logo = logoClaimsfinder;
    }
    const imgProps = {
        src: logo,
        alt: '',
        className: className,
        ...(style && { style }),
        ...(verticalAlign && { verticalAlign }),
        ...(width && { width }),
        ...(height && { height }),
        fetchpriority: 'high'
      };
	return (
		<img {...imgProps}/>
	)
}

export default LogoBasedOnDomain;