import React from 'react';
import Select from 'react-select';

const MultipleSelect = (props) => {
    const handleChange = selectedOptions => {
        props.onChange(selectedOptions);
    };

    return (
        <>
            <Select
                isMulti
                onChange={handleChange}
                id={props.dataId}
                name="banks"
                options={props.options}
                className={props.className}
                classNamePrefix="select"
                placeholder="Select Bank"
            />
            {props.validationMsg && <span className="error_msg">{props.validationMsg}</span>}
        </>
    );
};

export default MultipleSelect;
