import React, { useContext, useEffect, useState, useRef } from "react";
import Header from "../../Includes/Layouts/TCG_L_D/Header";
import Footer from "../../Includes/Layouts/TCG_L_D/Footer";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { useForm } from "react-hook-form";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";
import { useVehicleValidation } from "../../../Hooks/useVehicleValidation";
import { useSkipHandling } from "../../../Hooks/useSkipHandling";
import QueryStringContext from "../../../Contexts/QueryString";
import { useUserInfo } from "../../../Hooks/useUserInfo";
import "../../../assets/css/TCG_L_D/car-regist.scss";
import "../../../assets/css/TCG_L_D/custom.scss";
import CarRegistrationBody from "../../Includes/Layouts/TCG_L_D/CarRegistrationBody";
import Loader from "../../Includes/Layouts/TCG_L_D/Loader";
import { questionnaire_TCG_L_D } from "../../../Constants/Questions";
import CarRegistrationFollowupModal from "../../Includes/Layouts/Followup/CarRegistrationFollowupModal";

const FollowUpCarRegistration = () => {
  const { getBankInfo } = useUserInfo();
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const [selectedlenders, setSelectedLenders] = useState([]);
  const { isLoading, validateRegNum, storefollowUpVehicleDetails } =
    useVehicleValidation();
  const [loading, setloading] = useState(true);
  const { handleSkip } = useSkipHandling();
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const uuid =
    visitorParameters.visitor_parameters.uuid ??
    localStorage.getItem("uuid") ??
    query.get("uuid");
  const page_name = "v1/followup";
  const [questionareModal, setquestionareModal] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const { queryString } = useContext(QueryStringContext);
  const [
    objectRelatedToEnteredVehicleNumber,
    setObjectRelatedToEnteredVehicleNumber,
  ] = useState({
    label: "",
    slug: "",
    value: "",
    vehicleNumber: "",
    vehicleYear: "",
    vehicleModel: "",
    vehicleMake: "",
  });
  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const arrayOfPendingLenderValues = selectedlenders
    .filter((financeprovider) => !financeprovider.questionData)
    .map((financeprovider) => financeprovider.value);

  useEffect(() => {
    const fetchData = async () => {
      let storedSelectedBanks =
        JSON.parse(localStorage.getItem("pendingCarRegistration")) || [];
      let storedAdditionalBanks =
        JSON.parse(localStorage.getItem("additionalBanks")) || [];
      // if (storedSelectedBanks.length === 0) {
      //     const response = await getBankInfo(query_uuid);
      //     const responseData = response.data.response;
      //     if (responseData) {
      //         const banks = responseData.map(bank => ({
      //             bank_code: bank.bank_code,
      //             id: bank.id,
      //             value: String(bank.id),
      //             label: bank.label
      //         }));
      //         localStorage.setItem('selectedBanks', JSON.stringify(banks));
      //         setSelectedLenders(banks);
      //         return banks;
      //     }
      // } else {
      let mergedBanks = [...storedSelectedBanks];
      storedAdditionalBanks.forEach((additionalBank) => {
        if (
          !mergedBanks.find(
            (bank) => bank.bank_code === additionalBank.bank_code
          )
        ) {
          mergedBanks.push(additionalBank);
        }
      });
      setSelectedLenders(mergedBanks);
      // }
    };
    fetchData();
  }, [query_uuid]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const timeout = setTimeout(() => {
      setloading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [loading]);

  const addButtonClick = async (e, item, index) => {
    const inputField = e.target.closest("div").querySelector("input");
    const value = inputField.value;
    const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (value == "") {
      setError(`vehicleNumber_${item.value}`, {
        message: "Please Enter Vehicle Number",
      });
      return;
    }
    if (specialCharsRegex.test(value)) {
      setError(`vehicleNumber_${item.value}`, {
        message: "Please Enter valid Vehicle Number",
      });
      return;
    }
    const validationResponse = await validateRegNum(value, uuid, page_name);

    if (
      validationResponse.status == 200 &&
      validationResponse.data.is_valid == 1
    ) {
      const responseString = JSON.stringify(validationResponse.data);
      localStorage.setItem("validationResponse", responseString);
      setquestionareModal(true);
      setObjectRelatedToEnteredVehicleNumber({
        ...item,
        vehicleNumber: validationResponse.data.car_reg_no,
        vehicleYear: validationResponse.data.vehicle_year,
        vehicleModel: validationResponse.data.vehicle_model,
        vehicleMake: validationResponse.data.vehicle_make,
      });

      setSelectedLenders((prevArray) =>
        prevArray.map((lenderData) => {
          if (lenderData.value == item.value) {
            return {
              ...lenderData,
              vehicleModel: validationResponse.data.vehicle_model,
              vehicleMake: validationResponse.data.vehicle_make,
            };
          }
          return lenderData;
        })
      );
    } else {
      setError(`vehicleNumber_${item.value}`, {
        message: "Enter valid vehicle number",
      });
    }
  };

  const questionModalClick = async (e) => {
    setIsLoaderVisible(true);
    const selectedValue = e.target.value;
    if (selectedValue !== "") {
      const bank_id = objectRelatedToEnteredVehicleNumber.value;
      const formdata = JSON.parse(localStorage.getItem("followup_data"));
      const followup_vehicle_data = {
        question_option_id: e.target.value,
        question_id: 3,
        yourVehicle: "",
        carRegNo: objectRelatedToEnteredVehicleNumber.vehicleNumber,
        carYear: objectRelatedToEnteredVehicleNumber.vehicleYear,
        carModel: objectRelatedToEnteredVehicleNumber.vehicleModel,
        carMake: objectRelatedToEnteredVehicleNumber.vehicleMake,
        pageName: page_name,
        bank_id: bank_id,
      };

      const followupData = JSON.parse(localStorage.getItem("followup_data"));

      const result = await storefollowUpVehicleDetails(
        queryString,
        followup_vehicle_data,
        followupData,
        "followup-store-vehicle-details"
      );
      setSelectedLenders((prevArray) =>
        prevArray.map((financeProvider) => {
          if (financeProvider.value == bank_id) {
            return { ...financeProvider, questionData: selectedValue };
          }
          return financeProvider;
        })
      );
      setIsLoaderVisible(false);
      setquestionareModal(false);
    }
  };

  const handleSkipData = async () => {
    setloading(true);
    const lender_data = arrayOfPendingLenderValues.map((value) =>
      parseInt(value, 10)
    );
    const skip_data = {
      type: "car_registration",
      bank_id: lender_data,
    };
    const response = await handleSkip(
      visitorParameters.visitor_parameters,
      visitorParameters.data,
      JSON.parse(localStorage.getItem("formData")),
      queryString,
      skip_data,
      "skip-vehicle-details",
      page_name
    );
    setloading(false);
    const pending_details = JSON.parse(localStorage.getItem("pending_details"));
    if (
      response.data.status === "Success" &&
      pending_details.is_id_upload === 0
    ) {
      history.push(
        "/followup-id-upload" +
          "?uuid=" +
          visitorParameters.visitor_parameters.uuid +
          "&splitName=" +
          page_name
      );
    } else {
      history.push(
        "/followup-thank-you" +
          "?uuid=" +
          visitorParameters.visitor_parameters.uuid +
          "&splitName=" +
          page_name
      );
    }
  };

  const handleNextClick = async () => {
    const pending_details = JSON.parse(localStorage.getItem("pending_details"));
    for (const value of arrayOfPendingLenderValues) {
      const fieldName = `vehicleNumber_${value}`;
      const isFieldValid = await trigger(fieldName);
      if (isFieldValid) {
        const financeprovider = selectedlenders.find(
          (provider) => provider.value === value
        );
        if (!financeprovider.questionData) {
          setError(fieldName, {
            type: "manual",
            message: "Click Add and select a date from the popup",
          });
          const element = document.getElementById(fieldName);
          if (element) {
            element.focus();
          }
        }
        return;
      } else {
        const element = document.getElementById(fieldName);
        if (element) {
          element.focus();
        }
        return;
      }
    }
    if (
      arrayOfPendingLenderValues.length === 0 &&
      pending_details.is_id_upload === 0
    ) {
      history.push(
        "/followup-id-upload" +
          "?uuid=" +
          visitorParameters.visitor_parameters.uuid +
          "&splitName=" +
          page_name
      );
    } else {
      history.push(
        "/followup-thank-you" +
          "?uuid=" +
          visitorParameters.visitor_parameters.uuid +
          "&splitName=" +
          page_name
      );
    }
  };

  return (
    <>
      <GetVisitorsParams />
      <div className="TCG_L_D car_regV4">
        <Loader loader={loading} loderContent=" Analysing your Result" />
        <Header />
        <form>
          <CarRegistrationBody
            selectedlenders={selectedlenders}
            handleSkipData={handleSkipData}
            handleNextClick={handleNextClick}
            errors={errors}
            addButtonClick={addButtonClick}
            clearErrors={clearErrors}
            register={register}
            setSelectedLenders={setSelectedLenders}
            vehicleData={objectRelatedToEnteredVehicleNumber}
          />
          {questionareModal && (
            <CarRegistrationFollowupModal
              questionareModal={questionareModal}
              questionModalClick={questionModalClick}
              isLoaderVisible={isLoaderVisible}
              questionnaire={questionnaire_TCG_L_D.question_1}
            />
          )}
        </form>
        <Footer superScriptQuote={true} />
      </div>
    </>
  );
};

export default FollowUpCarRegistration;
