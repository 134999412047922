import React from 'react';
import { DobYear, DobMonth, DobDay } from '../../../../Constants/Constants';
import SelectBox from '../../../UI/SelectBox';
const DateOfBirth = (props) => {

  return (
    <div className="personal_detail animate__animated animate__fadeInUp animate__delay-.5s">
      <div className="field pb-3">
        <label className="mb-1 fs-6">Date</label>
        <SelectBox
          className="date_of_birth_select"
          OptionValue={DobDay}
          name="DobDay"
          id="DobDay"
          myRef={props.validation({
            required: "Please Select Date"
          })}
          validationMsg={props.validationMsg.DobDay && props.validationMsg.DobDay.message}
          onChange={() => {
            props.clearErrors(`DobDay`);
          }}
        />

      </div>
      <div className="field pb-3">
        <label className="mb-1 fs-6">Month</label>
        <SelectBox
          className="date_of_birth_select"
          OptionValue={DobMonth}
          name="DobMonth"
          id="DobMonth"
          myRef={props.validation({
            required: "Please Select Month"
          })}
          validationMsg={props.validationMsg.DobMonth && props.validationMsg.DobMonth.message}
          onChange={() => {
            props.clearErrors(`DobMonth`);
          }}

        />

      </div>
      <div className="field pb-3">
        <label className="mb-1 fs-6">Year</label>
        <SelectBox
          className="date_of_birth_select"
          OptionValue={DobYear}
          name="DobYear"
          id="DobYear"
          myRef={props.validation({
            required: "Please Select Year"
          })}
          validationMsg={props.validationMsg.DobYear && props.validationMsg.DobYear.message}
          onChange={() => {
            props.clearErrors(`DobYear`);
          }}

        />

      </div>
      {props.valids != '' && (<span className="error_msg">{props.valids}</span>)}
    </div>

  )
}

export default DateOfBirth;