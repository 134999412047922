import React from "react";

const SelectBox = ({
  className,
  OptionValue,
  onChange,
  name,
  id,
  myRef,
  validationMsg,
  value,
  selectText
}) => {
  const OptionData = OptionValue.map((dobData, index) => {
    return (
      <option value={dobData.value} key={index}>
        {dobData.label}
      </option>
    );
  });
  return (
    <>
      <select className={className} name={name} ref={myRef} onChange={onChange} id={id} value={value}>
        {selectText && (
          <option value="">{selectText}</option>
        )}
        {OptionData}
      </select>
      {validationMsg && (
        <>
          <span className="error_msg mt-1">{validationMsg}</span>
        </>
      )}
    </>
  );
};

export default SelectBox;
