import React from "react";
import Accordion from "./Accordion";
const FAQSection = (props) => {

    return (
        <>
            <section className={`faq_area ${props.showFaq}`} >
                <div className="container">
                    <div className="row">
                        {/* <div class="col-lg-3 col-12"><img class="img-fluid" src="dist/img/faq.png" alt="" /> </div> */}
                        <div className="col-lg-12 col-12">
                            <h2>FAQ</h2>
                            <Accordion />
                        </div>
                    </div>
                </div>
            </section>

        </>

    );
};

export default FAQSection;
