
import React, { useState, useEffect, useContext } from "react";
import { useAdditionalBank } from "../../../../Hooks/useAdditionalBank";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useUserInfo } from "../../../../Hooks/useUserInfo";
import { queryString } from "../../../../Utility/QueryString";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";
import CheckBox from "../../../UI/CheckBox";
import CheckBoxV3 from "../../../UI/CheckBoxV3";
import CheckBoxV4 from "../../../UI/CheckBoxV4";
import Select from "react-select";
import Button from "../../../UI/Button";
import LenderVerModal from "./LenderVerModal";
import Authorize from "./Authorize";
import { BaseBackend } from "@sentry/core";
import CheckBoxV2 from "../../../UI/CheckBoxV2";


const AdditionalLender = (props) => {
  const { validationMsg } = props;

  const [banks, setBanks] = useState([]);
  const [remainingBanks, setRemainingBanks] = useState([]);

  const [additionalBanks, setAdditionalBanks] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [validationError, setValidationError] = useState(false);
  const [showLender, setShowLender] = useState(false);
  const [showLender1, setShowLender2] = useState('hide');
  const { saveBankIngestion, isLoading } = useAdditionalBank();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { getBankInfo } = useUserInfo();
  const storedBanks = localStorage.getItem("banks");
  const selectLender = localStorage.getItem("selectedBanks");
  const selectAdditionalLender = localStorage.getItem("additionalBanks");
  const split_name = "pcp/TCG_L_D";
  const message_type = "additional_bank_store";
  const formData = JSON.parse(localStorage.getItem("formData"));
  const [additionalLenderData, setAdditionalLenderData] = useState([]);
  const [clickIndex, setClickIndex] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);



  // useEffect(() => {
  //     // if (!props.addBank) return;

  //     const fetchData = async () => {
  //       if (!storedBanks) return;
  //       const parsedBanks = JSON.parse(storedBanks);
  //       if (!Array.isArray(parsedBanks) || parsedBanks.length === 0) return;
  //       const response = await getBankInfo(props.uuid);
  //       const mergedBanks = localStorage.getItem("selectedBanks");
  //       if (selectLender) {
  //         const parsedSelectedLender = JSON.parse(selectLender);
  //         let parsedAdditionalLender = selectAdditionalLender
  //           ? JSON.parse(selectAdditionalLender)
  //           : [];

  //         if (
  //           !Array.isArray(parsedSelectedLender) ||
  //           !Array.isArray(parsedAdditionalLender)
  //         )
  //           return;
  //         const mergedLenders = [
  //           ...parsedSelectedLender,
  //           ...parsedAdditionalLender,
  //         ];

  //         const selectedLenderIds = mergedLenders.map((lender) => lender.id);
  //         const filteredBanks = parsedBanks.filter(
  //           (bank) => !selectedLenderIds.includes(bank.id)
  //         );
  //         setBanks(filteredBanks.slice(0, 12));
  //         const sortBanks = filteredBanks
  //           .slice(12)
  //           .sort((a, b) => a.label.localeCompare(b.label));
  //         setRemainingBanks(sortBanks);
  //       } else if (response.data && response.data.response) {
  //         const dataLength = response.data.response.length;
  //         if (dataLength > 0) {
  //           const savedData = response.data.response;
  //           const saveDataMap = savedData.map((data) => data.id);
  //           setAdditionalLenderData(saveDataMap);
  //           const filteredSavedBank = parsedBanks.filter(
  //             (bank) => !saveDataMap.includes(bank.id)
  //           );
  //           setBanks(filteredSavedBank.slice(0, 12));
  //           const sortBanks = filteredSavedBank
  //             .slice(12)
  //             .sort((a, b) => a.label.localeCompare(b.label));
  //           setRemainingBanks(sortBanks);
  //         } else {
  //           setBanks(parsedBanks.slice(0, 12));
  //           const sortBanks = parsedBanks
  //             .slice(12)
  //             .sort((a, b) => a.label.localeCompare(b.label));
  //           setRemainingBanks(sortBanks);
  //         }
  //       } else {
  //         setBanks(parsedBanks.slice(0, 12));
  //         const sortBanks = parsedBanks
  //           .slice(12)
  //           .sort((a, b) => a.label.localeCompare(b.label));
  //         setRemainingBanks(sortBanks);
  //       }
  //     };

  //     fetchData();
  //   }, [props.addBank]);


  useEffect(() => {
    const remaining = props.remainingBanks.filter((bank) => {
      return !props.selectedBanks.some((banks) => {
        return bank.id === banks.id
      })

    });
    setBanks(remaining.slice(0, 12));
    const sortedRemainingBanks = remaining.slice(12).sort((a, b) => a.label.localeCompare(b.label));
    setRemainingBanks(sortedRemainingBanks);
  }, [props.selectedBanks]);

  const handleSelectChange = (selectedOption) => {
    setValidationError(false);
    const bank = remainingBanks.find((item) => item.id == selectedOption.id);
    setAdditionalBanks((prev) => [...prev, bank]);
    setRemainingBanks((prev) => prev.filter((banks) => banks.id !== bank.id));
    setSelectedBanks((prev) => [...prev, bank]);
  };

  const handleCheckboxChange = (event,box) => {
    const checked = event.target.checked;
    const id = event.target.value;
    const bank = banks.find((bank) => `${bank.id}` === id) ||
      additionalBanks.find((bank) => `${bank.id}` === id);
    if (checked && bank) {
      setSelectedBanks((prev) => [...prev, bank]);
      setAdditionalBanks((prev) => [...prev, bank])
    }
    else {
      setSelectedBanks((prev) => prev.filter((item) => item.id != bank.id))
      setAdditionalBanks((prev) => prev.filter((item) => item.id != bank.id))
      if(box == 'sub'){
        setRemainingBanks((prev) => {
          const updatedBanks = [...prev, bank];
          updatedBanks.sort((a, b) => a.label.localeCompare(b.label));
          return updatedBanks;
        });
      }
    }
    setTimeout(() => setSelectedOption(null), 0);

  };

  const handleSubmit = async (e) => {
    if (selectedBanks.length === 0) {
      setValidationError(true);
    }
    else {
      setValidationError(false);
      localStorage.setItem(
        "additionalBanks",
        JSON.stringify(
          selectedBanks.map((bank) => ({
            bank_code: bank.bank_code,
            id: bank.id,
            value: `${bank.id}`,
            label: bank.label,
          }))
        )
      );
      let additionalBanksData = selectedBanks.map((bank) => ({
        bank_code: bank.bank_code,
        id: bank.id,
        value: `${bank.id}`,
        label: bank.label,
      }))

      let storedFormData = formData ? formData : {};
      const formSUbmitResult = await saveBankIngestion(
        visitorParameters.visitor_parameters,
        visitorParameters.data,
        storedFormData,
        queryString,
        additionalBanksData,
        message_type,
        split_name
      );
      setShowLender("block");
    }
  }

  if (showLender == "block") {
    setTimeout(() => {
      setShowLender2("show");
    }, 2000)
  }

  const showIndex = (e, index) => {
    e.preventDefault();
    setClickIndex(clickIndex == index ? null : index);
  }

  const nextPage = (e) => {
    e.preventDefault();
    setTimeout(() => {
      setShowLender("block");
      //setShowLender2("show");
    }, 2000)
  }
  return (
    <>
      <div className="" id="moreLenders2" style={props.show == "show" ? { display: "block" } : { display: "none" }}>
        <h5>Select additional Lenders</h5>
        {/* <ul className="ps-0 ms-0 banklist d-lg-flex d-md-flex d-sm-block gap-2 mt-3 flex-wrap">
                    {banks.map((item) => {
                        return (
                            <li key={item.id}>
                                <div className="optionBtn blackoption p-0  drop-in  w-100   fs-6 text-start pointer shadow-lg ">
                                    <CheckBoxV2
                                        type="checkbox"
                                        className="radio"
                                        id="bank1"
                                        name="lo"
                                        value={item.id}
                                        dataId={`${item.id}`}
                                        onChange={handleCheckboxChange}
                                        checked={selectedBanks.some(
                                            (selectedBank) =>
                                                selectedBank.id === item.id
                                        )}
                                        labelClassName='d-flex align-items-center h-100 p-2  p-lg-2 '
                                        divClass='pe-2'
                                        spanclass='me-1'
                                        labelText={item.label}
                                    />
              
                                </div>
                            </li>)
                    })}

                </ul>
                <div className="field mb-3 margin_use animate__animated animate__fadeInUp animate__delay-.5s">

                </ul> */}
        <ul className="ps-0 ms-0 banklist d-lg-flex d-md-flex d-sm-block gap-2 mt-3 flex-wrap">
          {banks.map((item) => {
            return (
              <li key={item.id}>
                <div className="optionBtn blackoption p-0 drop-in w-100 fs-6 text-start pointer shadow-lg">
                  <CheckBoxV4
                    type="checkbox"
                    className="radio"
                    id={`bank-${item.id}`} // Use dynamic id
                    name="lo"
                    value={item.id} // Ensure value is set correctly
                    dataId={`addbank_${item.id}`}
                    onChange={(e) => handleCheckboxChange(e,'')}
                    checked={selectedBanks.some(
                      (selectedBank) =>
                        selectedBank.id === item.id
                    )}
                    labelClassName="d-flex align-items-center h-100 p-2 p-lg-2"
                    divClass="pe-2"
                    spanclass="me-1"
                    labelText={item.label}
                  />
                </div>
              </li>
            );
          })}
        </ul>

        <div className="mb-2 mt-2 drop-in modelInput">
          <Select
            className="select_search"
            options={remainingBanks}
            name="lender"
            id="selectanother"
            placeholder="Another lender..."
            onChange={handleSelectChange}
            value={selectedOption}
          />


        </div>
        {additionalBanks.length > 0 &&<>
            <div><p className="pop-p" >Lender's Selected :</p> </div>

            <p className="font-11"> Your lender may be part of a group - to view grouped lenders use the dropdown next to the lender name. By proceeding you are giving us instruction to investigate and submit claims to all lenders in each group. </p>
            </>
          }
        <div className="col-12 d-inline d-flex flex-wrap selectoptions  gap-2">
          {additionalBanks.map((bank,index) => (
            <>
            <div
              key={bank.id}
              className="optionBtn blackoption p-0  w-100 fs-6 text-start pointer shadow-lg"
            >
              <CheckBoxV4
                className="custom-checkbox"
                name="lender"
                value={bank.value}
                checked={selectedBanks.some(
                  (selectedBank) =>
                    selectedBank.id === bank.id
                )}
                onChange={(e) => handleCheckboxChange(e,'sub')}
                dataId={`${bank.id}`}
                divClass='pe-2'
                spanclass='me-1'
                labelClassName="d-flex align-items-center h-100 p-2  p-lg-2"
                labelText={
                <><p className="pop-p">{bank.label}</p>
                  {bank.sublender.length > 0 && <div className="selected-defendant">
                  <Button className="group-button group-button-pop" buttonText="Group" onClick={(e) => showIndex(e, index)} />
                </div>}
                </>}
            
              />  
            </div>
            {bank.sublender.length > 0 &&
              <>
                {/* <div className="selected-defendant">
                  <Button className="group-button group-button-pop" buttonText="Group" onClick={(e) => showIndex(e, index)} />
                </div> */}
                {clickIndex === index && (
                  <div className="sublender-div sublender-pop">
                    <p style={{borderBottom:"dashed 0px #f9d8cf"}}><b>Lenders in this group:</b></p>
                    {bank.sublender.map((item, idx) => (
                      <div key={idx }> 
                        <p style={{borderBottom:"dashed 1px #f9d8cf"}}>{item.label}</p>
                      </div>
                    ))}
                  </div>
                )}

              </>}
              </>))
          }
        </div>
      </div>
      {validationError && (
        <p className="error_msg ">
          Select at least one lender
        </p>
      )}
      <div style={props.show == "show" ? { display: "block" } : { display: "none" }} className="lenderBtmBtn">
        <div className="mt-3">
          <Button
            className=" btn-comm2 p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3 fw-bold"
            id="slctLender"
            name="next"
            type="button"
            buttonText="Next"
            onClick={(e) => handleSubmit(e)}
            spanText="bi bi-arrow-right"
          />
        </div>
        <div className="text-center mt-3">
          <Button
            className="btn-skip btn skip2 outline-0"
            name="skip"
            buttonText="Skip"
            onClick={
              nextPage
            }
          />
        </div>
      </div>
      <LenderVerModal
        show={showLender}
      />
      <Authorize
        show={showLender1}
        authrizeSubmit={props.authrizeSubmit}
      />
    </>
  )
}
export default AdditionalLender;