import React, { useState } from 'react';
import { Api } from '../api/Api';
import { useErrorWebhook } from "./useErrorWebhook";

export const useQuestionnaire = () => {
    const { ErrorWebhookTrigger } = useErrorWebhook();
    const [isLoading, setIsLoading] = useState(false);
    const SaveQuestionnaire = async (visitor_parameters, question_data, message_type, data, query_string, form_data) => {

        setIsLoading(true);
        const response = await Api.post("v1/data-ingestion-pipeline", {
            visitor_parameters,
            question_data,
            message_type,
            data,
            query_string,
            form_data
        }).catch(function (error) {
            const erroMessage = {
                serviceName: 'Data Ingestion : ' + message_type,
                errorReason: error.message,
                errorCode: error.code,
                uuid: visitor_parameters.uuid
            }
            ErrorWebhookTrigger(erroMessage);
        });
        setIsLoading(false);
        return response;
    }
    return {
        isLoading,
        SaveQuestionnaire
    }
}