import { Helmet } from "react-helmet";
import faviconClaimsfinder from "../../../../assets/img/logos/Claimsfinder/favicon.ico";
import faviconThepcpList from "../../../../assets/img/logos/ThepcpList/favicon.png";
import faviconrefundsNow from "../../../../assets/img/logos/refundsNow/favicon.ico";
import {getBrandName} from "../../../Includes/Layouts/Common/BrandBasedOnDomain";

const HelmetBasedOnDomain = () => {
    const domainName = window.location.hostname;
    // const brandName = getBrandName();
    // console.log(brandName)
    //console.log("domain name===="+domainName);
    let favicon;
    let brandName;
    if (domainName === 'localhost' || domainName === 'dev.claimfinder.co.uk' 
        || domainName === 'pre.claimfinder.co.uk' || domainName === 'claimfinder.co.uk') {
        favicon = faviconClaimsfinder;
        brandName = 'Claim Finder';
    }else if (domainName === 'dev.refundsnow.co.uk' || domainName === 'pre.refundsnow.co.uk' 
        || domainName === 'refundsnow.co.uk') {
        favicon = faviconrefundsNow;
        brandName = "Refunds Now"
    }else if (domainName === 'dev.thepcplist.co.uk' || domainName === 'pre.thepcplist.co.uk' 
    || domainName === 'thepcplist.co.uk') {
        favicon = faviconThepcpList;
        brandName = "Thepcplist"
    }else {
        favicon = faviconClaimsfinder;
        brandName ="Claim Finder"
    }
    return (
        <Helmet>
            <link rel="icon" href={favicon} />
            <title>{brandName}</title>
        </Helmet>
    )
}

export default HelmetBasedOnDomain;