import React, { useState } from "react";

const AccordionItem = ({ title, content, index, openIndex, setOpenIndex }) => {
  const isOpen = index === openIndex;

  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index);
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`flush-heading${index}`}>
        <button
          className={`accordion-button ${isOpen ? "" : "collapsed"}`}
          type="button"
          onClick={toggleAccordion}
          aria-expanded={isOpen}
          aria-controls={`flush-collapse${index}`}
        >
          {title}
        </button>
      </h2>
      <div
        id={`flush-collapse${index}`}
        className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
        aria-labelledby={`flush-heading${index}`}
      >
        <div className="accordion-body">{content}</div>
      </div>
    </div>
  );
};

const FaqAccordion = ({ extraParentClasses }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const accordionData = [
    {
      title: "Why might I have a claim?",
      content: (<>
        If you purchased a vehicle on finance between 2007 and 2021,
        then it is likely that the car finance was mis-sold to you due
        to the car dealership failing to disclose commission they were
        paid by the lender for setting up your car finance. Unbeknown
        to consumers, some lenders allowed car dealerships to increase
        the interest rate paid by consumers for their car finance, to
        increase the commission they earned. It is estimated that
        consumers were overcharged by a staggering £300 million a year
        for their car finance due to the poor practices of car
        dealerships and lenders*. *See:{" "}<a target="_blank" href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf">
          https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf
        </a> s. 2.17, Pg9.
      </>)
    },
    {
      title: "How long will my claim take?",
      content: (<>
        Lenders are usually expected to provide a response to any
        complaint received within 8 weeks, but the Financial Conduct
        Authority (FCA) has temporarily paused this timescale for car
        finance complaints about Discretionary Commission
        Arrangements, allowing lenders additional time to provide a
        response to due to investigative work in this area and the
        volumes of claims lenders are receiving. At present, we expect
        that lenders will start responding to complaints about
        Discretionary Commission Arrangements after 25 September 2024.
        <br />
        If your complaint is escalated to the Financial Ombudsman
        Service (FOS), it can take four to six months to receive an
        initial response and may take longer if your case is about a
        Discretionary Commission Arrangement or is referred to an
        Ombudsman. We are continuously working with lenders and the
        FOS to ensure that timely and fair responses are provided to
        your claims.
      </>)
    },
    {
      title: "How much could my claim be worth?",
      content: (<>The amount of compensation you may be entitled to is dependent on several factors, including the
        amount of finance your lender provided, the date the finance was taken out and the duration of the
        agreement. <br />
        The Financial Conduct Authority, confirmed in their publication Our work on motor finance – final
        finding – March 2019 that on a typical motor finance agreement of £10,000, a Discretionary
        Commission Arrangement typically resulted in consumers being overcharged £1,100 over a four-year
        agreement.* <br />

        *See: <a target="_blank" href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf">
          https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf
        </a>{" "}s. 2.15, Pg9.</>)
    },
    {
      title: "Do I need to use a law firm, or claims management company to make my claim?",
      content: (<>
        You do not have to use a law firm, or claims management
        company to make your claim. You can claim directly yourself
        for free to your lender, and then the Financial Ombudsman.
        Although you can make your claim directly, you may find it
        preferrable to use a professional representative, like us, to
        help you manage the claims process due to our expertise in
        this area, or simply because you do not have the time.
      </>)
    },
    {
      title: "What are your fees?",
      content: (<>
        If your claim is successful, our Success Fee is 48%, inclusive
        of VAT, of any compensation that your lender offers to you. An
        Administration Charge of £10.00 also applies to all claims. If
        you have an outstanding balance with your lender they may use
        all, or part of, your compensation to pay this outstanding
        balance meaning that you may not receive any cash “in hand”.
        In this event, our Success Fee will still be payable against
        the full compensation amount.
      </>)
    },
    {
      title: "I was referred to you via a third-party, how are they paid?",
      content: (<>
        If you have been introduced to us via a third-party, we will
        pay them a fee for this introduction. This fee is usually a
        percentage of our Success Fee for any successful claim. This
        fee is paid directly from us to the third-party at no cost to
        you.
      </>)
    },
  ];

  return (
    <section className={`faqsection ${extraParentClasses}`}>
      <div className="container">
        <div className="title">
          <h2 className="text-center">FAQ</h2>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-lg-5 mb-md-5 mb-sm-5 mb-2">
            <div
              className="accordion mt-lg-4 mt-md-4 mt-sm-4 mt-3"
              id="accordFAQ"
            >
              {accordionData.map((item, index) => (
                <AccordionItem
                  key={index}
                  index={index}
                  title={item.title}
                  content={item.content}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqAccordion;
