import React, { useState, useEffect, useContext } from "react";
import CheckBoxV2 from "../../../UI/CheckBoxV2";
import SelectBox from "../../../UI/SelectBox";
import InputButton from "../../../UI/InputButton";
import "../../../../assets/css/TCG_L_C/aditionallender.scss";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useAdditionalBank } from "../../../../Hooks/useAdditionalBank";
import { useUserInfo } from "../../../../Hooks/useUserInfo";
import Button from "../../../UI/Button";
import Select from "react-select";
import GetLenders from "../../../../Utility/GetLenders";
import RadioButtonV2 from "../../../UI/RadioButtonV2";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";
import { queryString } from "../../../../Utility/QueryString";

const AdditionalLender = (props) => {
  const { validationMsg } = props;
  const { getLenderList } = GetLenders();
  const [banks, setBanks] = useState([]);
  const [remainingBanks, setRemainingBanks] = useState([]);
  const [additionalBanks, setAdditionalBanks] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [validationError, setValidationError] = useState(false);
  const [showLender, setShowLender] = useState(false);
  const [showLender1, setShowLender2] = useState(true);
  const { saveBankIngestion, isLoading } = useAdditionalBank();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { getBankInfo } = useUserInfo();
  const split_name = "pcp/TCG_L_A";
  const message_type = "additional_bank_store";
  const formData = JSON.parse(localStorage.getItem("formData"));
  const selectLender = localStorage.getItem("selectedBanks");
  const selectAdditionalLender = localStorage.getItem("additionalBanks");
  const [additionalLenderData, setAdditionalLenderData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const lenderResponse = await getLenderList();
      const storedBanks = localStorage.getItem("banks");
     
      if (!storedBanks) return;
      const parsedBanks = JSON.parse(storedBanks);
      if (!Array.isArray(parsedBanks) || parsedBanks.length === 0) return;
      const response = await getBankInfo(props.uuid);
      const mergedBanks = localStorage.getItem("selectedBanks");
      if (selectLender) {
        const parsedSelectedLender = JSON.parse(selectLender);
        let parsedAdditionalLender = selectAdditionalLender
          ? JSON.parse(selectAdditionalLender)
          : [];

        // if (!Array.isArray(parsedSelectedLender)) return;
        if (
          !Array.isArray(parsedSelectedLender) ||
          !Array.isArray(parsedAdditionalLender)
        )
          return;
        const mergedLenders = [
          ...parsedSelectedLender,
          ...parsedAdditionalLender,
        ];

        // const selectedLenderIds = parsedSelectedLender.map(lender => lender.id);
        const selectedLenderIds = mergedLenders.map((lender) => lender.id);
        const filteredBanks = parsedBanks.filter(
          (bank) => !selectedLenderIds.includes(bank.id)
        );
        setBanks(filteredBanks.slice(0, 12));
        const sortedbanks = filteredBanks.slice(12).sort((a, b) => a.label.localeCompare(b.label));
        setRemainingBanks(sortedbanks);
      } else if (response.data && response.data.response) {
        const dataLength = response.data.response.length;
        if (dataLength > 0) {
          const savedData = response.data.response;
          const saveDataMap = savedData.map((data) => data.id);
          setAdditionalLenderData(saveDataMap);
          const filteredSavedBank = parsedBanks.filter(
            (bank) => !saveDataMap.includes(bank.id)
          );
          setBanks(filteredSavedBank.slice(0, 12));
          const sortedbanks = filteredSavedBank.slice(12).sort((a, b) => a.label.localeCompare(b.label));
          setRemainingBanks(sortedbanks);
        }else {
          setBanks(parsedBanks.slice(0, 12));
          const sortedbanks = parsedBanks.slice(12).sort((a, b) => a.label.localeCompare(b.label));
          setRemainingBanks(sortedbanks);
        }
      } else {
        setBanks(parsedBanks.slice(0, 12));
        const sortedbanks = parsedBanks.slice(12).sort((a, b) => a.label.localeCompare(b.label));
        setRemainingBanks(sortedbanks);
      }
    };

    fetchData();
  }, [props.uuid]);

  const handleSelectChange = (selectedOption) => {
    setValidationError(false);
    const selectedBankId = parseInt(selectedOption.value, 10);
    const selectedBank = remainingBanks.find(
      (bank) => bank.id === selectedBankId
    );
    if (selectedBank) {
      setAdditionalBanks((prev) => [...prev, selectedBank]);
      setRemainingBanks((prev) =>
        prev.filter((bank) => bank.id !== selectedBankId)
      );
      setSelectedBanks((prev) => [...prev, selectedBank]);
    }
  };

  const handleCheckboxChange = (event) => {
    setValidationError(false);
    const { id, checked } = event.target;
    const bank =
      banks.find((bank) => `${bank.id}` === id) ||
      additionalBanks.find((bank) => `${bank.id}` === id);

    if (checked && bank) {
      setSelectedBanks((prev) => [...prev, bank]);
    } else {
      setSelectedBanks((prev) =>
        prev.filter((selectedBank) => selectedBank.id !== bank.id)
      );
    }
  };


  const handleSubmit = async(e) => {
    const existingBanks = JSON.parse(localStorage.getItem('additionalBanks')) || [];
    const mergedBanks = [...existingBanks, ...selectedBanks];
    if (mergedBanks.length === 0) {
        setValidationError(true);
    } else {
        setValidationError(false);
        localStorage.setItem('additionalBanks', JSON.stringify(mergedBanks.map(bank => ({
            bank_code: bank.bank_code,
            id: bank.id,
            value: `${bank.id}`,
            label: bank.label
        }))));
        const additionalBanksData = mergedBanks.map(bank => ({
            bank_code: bank.bank_code,
            id: bank.id,
            value: `${bank.id}`,
            label: bank.label
        }));

        //set additional bank id in local storage
        const storedFormData = formData ? formData : {};
      
        if (storedFormData.bank) {
          storedFormData.bank = [...storedFormData.bank, ...selectedBanks.map(bank => `${bank.id}`)];
        } else {
          storedFormData.bank = [...additionalLenderData.map(data => String(data)), ...selectedBanks.map(bank => `${bank.id}`)];
        }
        storedFormData.user_sign = '';
        
        localStorage.setItem('formData', JSON.stringify(storedFormData));
        //end
        const formSubmitResult = await saveBankIngestion(
            visitorParameters.visitor_parameters,
            visitorParameters.data,
            storedFormData,
            queryString,
            additionalBanksData,
            message_type,
            split_name
           
            
        );
        props.setShow1(false);
        GTMDataLayer({
          clickEvent: 'slideclick',
          question: 'select additional lenders',
          answer: '',
        });
    }   
};

  const handleOption = () => {
    setShowLender(true);
    setShowLender2(false);
  };

  return (
    <>
      {props.show1 && (
        <div className="aditionallender">
          <div className="form-popupdetails ">
            <div
              className="modal fade show"
              id="additionalLendersPop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="additionalLendersPopLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content modal-popupdetails animate__animated animate__zoomIn animate__delay-.5s ">
                  <div className="modal-body">
                    {showLender1 && (
                      <div className="lenderpopslide1">
                        <div className=" animate__animated animate__fadeInUp animate__delay-.5s">
                          <p className="fs-6">
                            <b>Would you like to add more Lenders?</b>
                          </p>
                        </div>
                        <div className="form_drop yes_no_sec col-lg-12 col-12 ">
                          <div
                            className=" animate__animated animate__fadeInUp animate__delay-.5s"
                            style={{ zIndex: 2, position: "relative" }}
                          >
                            <div className="choose_Y_N personal_detail col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                              <div className="form-check px-0">
                                {/* <input
                                  type="radio"
                                  className="custom-checkbox "
                                  name="adlender_no"
                                  id="admorlendrN_no"
                                  defaultValue="No"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    props.setShow1(false);
                                  }}
                                />
                                <label htmlFor="admorlendrN_no">No</label> */}
                                <RadioButtonV2
                                  className="custom-checkbox "
                                  name="adlender_no"
                                  id="admorlendrN_no"
                                  defaultValue="No"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    props.setShow1(false);
                                  }}
                                  labelName='No'
                                  gtm_question='additional lenders'
                                  clickevent='slideclick'
                                  gtm_answer=''
                                  gtm_trigger={true}
                                />
                              </div>
                              <div className="form-check px-0">
                                {/* <input
                                  type="radio"
                                  className="custom-checkbox admorelender"
                                  name="adlender_yes"
                                  id="admorlendrY_yes"
                                  defaultValue="Yes"
                                  onClick={handleOption}
                                />
                                <label htmlFor="admorlendrY_yes">Yes</label> */}
                                <RadioButtonV2
                                  className="custom-checkbox admorelender"
                                  name="adlender_yes"
                                  id="admorlendrY_yes"
                                  defaultValue="Yes"
                                  data-bs-dismiss="modal"
                                  onClick={handleOption}
                                  labelName='Yes'
                                  gtm_question='additional lenders'
                                  clickevent='slideclick'
                                  gtm_answer=''
                                  gtm_trigger={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* slide2 lenderslist*/}
                    {showLender && (
                      <div
                        className="lenderpopslide2"
                        style={{ display: "block" }}
                      >
                        <div
                          className="slide1"
                          id="slide_1"
                          style={{ display: "block" }}
                        >
                          <div className="field animate__animated animate__fadeInUp animate__delay-.5s">
                            <label className="">
                              Select additional Lenders
                            </label>
                            <ul className="options two lender animate__animated animate__fadeInUp animate__delay-.5s">
                              {banks.map((bank) => (
                                <li key={bank.id}>
                                  <CheckBoxV2
                                    className="custom-checkbox"
                                    name="lender"
                                    value={bank.label}
                                    checked={selectedBanks.some(
                                      (selectedBank) =>
                                        selectedBank.id === bank.id
                                    )}
                                    onChange={handleCheckboxChange}
                                    dataId={`${bank.id}`}
                                    labelClassName=""
                                    labelText={bank.label}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className=" field mb-3 margin_use animate__animated animate__fadeInUp animate__delay-.5s">
                            <Select
                              className="select_search"
                              options={remainingBanks}
                              name="lender"
                              id="selectanother"
                              placeholder="Another lender..."
                              onChange={handleSelectChange}
                            />
                              <div className="field margin_use pt-1">
                            <div className="col-12 d-inline">
                              {additionalBanks.map((bank) => (
                                <div
                                  key={bank.id}
                                  className="l1_lender anotherlenderoptions mb-2"
                                >
                                  <CheckBoxV2
                                    className="custom-checkbox"
                                    name="lender"
                                    value={bank.label}
                                    checked={selectedBanks.some(
                                      (selectedBank) =>
                                        selectedBank.id === bank.id
                                    )}
                                    onChange={handleCheckboxChange}
                                    dataId={`${bank.id}`}
                                    labelClassName="selected-false"
                                    labelText={bank.label}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                          {validationError && (
                            <p className="error_msg ">
                              Select at least one lender
                            </p>
                          )}
                           <div className="form-navigation pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                            <InputButton
                              name=""
                              className="btn center-block next-but "
                              id="next01"
                              value="Next"
                              btnType="button"
                              style={{ float: "none" }}
                              onClick={handleSubmit}
                            />
                            <Button
                              type={`button`}
                              className={`add-lndr-skip`}
                              id={`skipAddLender`}
                              onClick={() => {
                                props.setShow1(false);
                              }}
                              name={`skipAddLender`}
                              buttonText={`Skip`}
                            />
                          </div>
                          </div>
                        
                         
                        </div>
                      </div>
                    )}
                    {/* slide 2 pop end */}
                  </div>
                </div>
              </div>
            </div>
            {/* lender popup end*/}
          </div>
        </div>
      )}
    </>
  );
};

export default AdditionalLender;
