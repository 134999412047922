import { useState, useRef } from "react";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";
import Button from "../../UI/Button";
import TextField from "../../UI/TextField";
import { useFollowupDataIngestion } from "../../../Hooks/useFollowupDataIngestion";

const FollowUpUnsubscribeModel = (props) => {
  const emailRef = useRef();
  const { saveFollowupUserIngestion } = useFollowupDataIngestion();
  const [unsubscribeError, setUnsubscribeError] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("user_info"));
  const followup_data = JSON.parse(localStorage.getItem("followup_data")) || {};
  const uuid = localStorage.getItem("uuid") || '';
  const userEmail = userInfo?.email || '';
  console.log(userEmail, "userEmail");

  const unsubscribeUser = async() => {
    if (emailRef.current.value == userEmail) {
      var user_data = {'userId' : userInfo.user_id, 'type' : "unsubscribe_email" };
      await saveFollowupUserIngestion(
        uuid,
        followup_data,
        user_data,
        'unsubscribe_user');
      props.setUnsubscribeModelStatus(false);
    } else {
      setUnsubscribeError(true);
    }
  };
  return (
    <>
      <div
        className="modal fade show"
        id="unsubscribemodal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="text-center">
                <LogoBasedOnDomain />
                By clicking the button below, you will be unsubscribed from all
                future communications from The Claims Guys Legal.
              </h3>

              <div className="p-4">
                <label htmlFor="" className="form-label">
                  Enter Your Email Address
                </label>
                <TextField
                  name="txtEmail"
                  id="txtEmail"
                  className="form-control"
                  type="email"
                  placeholder="Email Address"
                  validation={emailRef}
                  onChange={()=>{setUnsubscribeError(false)}}
                />
                {unsubscribeError && (
                  <span className="error_msg">Email Mismatch</span>
                )}
              </div>

              <div className="col-auto text-center">
                <Button
                  type="button"
                  className="modal-btn"
                  buttonText="Unsubscribe"
                  onClick={unsubscribeUser}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FollowUpUnsubscribeModel;
