import React from 'react';
import LogoBasedOnDomain from '../Common/LogoBasedOnDomain';
import loader03 from "../../../../assets/img/TCG_L_D/loader03.svg";

const Loader = (props) => {
    return (
        <>
            <div className={`overlay loader1 flex align-items-center justify-content-center ${props.loader ? 'show' : 'd-none'}`}>
                <div>
                    <div className="loader-logo">
                        <LogoBasedOnDomain
                            height={30}
                            style={{ marginBottom: "auto" }}
                            bg='black'
                        />
                    </div>
                    <div className="text-center">
                        <img height={100} src={loader03} alt="" />
                        <h3 className="fs-5 loadertext2">{props.loderContent}</h3>
                    </div>
                </div>
            </div>
        </>

    );
};

export default Loader;
