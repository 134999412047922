import React, { useEffect, useState, useRef, useReducer, useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../Contexts/QueryString";
import * as EnvConstants from "../../../Constants/EnvConstants";
import Lenders from "../../Includes/Layouts/TCG_L_C/Lenders";
import PersonalDetails from "../../Includes/Layouts/TCG_L_C/PersonalDetails";
import Postcode from "../../Includes/Layouts/TCG_L_C/Postcode";
import BasicQuestions from "../../Includes/Layouts/TCG_L_C/BasicQuestions";
import PreviousDetails from "../../Includes/Layouts/TCG_L_C/PreviousDetails";
import ContactDetails from "../../Includes/Layouts/TCG_L_C/ContactDetails";
import TcglLogo from "../../../assets/img/tcgl-logo.png";
import Odometer from "odometer";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";
import { useQuestionnaire } from "../../../Hooks/useQuestionnaire";

const TCG_L_C_Form = (props) => {

  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
    showSlide7: "hide",
    showSlide8: "hide"
  };
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "slide1") {
          props.setShowFaq('hide');
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "slide2") {
          props.setShowFaq('hide');
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide2: "hide",
            showSlide3: "show",
          };
        } else if (action.payload.clickedSlide.slide === "slide3") {
          props.setShowFaq('hide');
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "slide4No") {
          props.setShowFaq('hide');
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide4: "hide",
            showSlide6: "show",
          };
        } else if (action.payload.clickedSlide.slide === "slide4Yes") {
          props.setShowFaq('hide');
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show",
          };
        } else if (action.payload.clickedSlide.slide === "slide5") {
          props.setShowFaq('hide');
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide5: "hide",
            showSlide6: "show",
          };
        } else if (action.payload.clickedSlide.slide === "slide6") {
          props.setShowFaq('hide');
          props.setFooterEnable(false)
            return {
              ...state,
              showSlide6: "hide",
              showSlide7: "show",
            };
        } else if (action.payload.clickedSlide.slide === "slide7") {
          props.setShowFaq('hide');
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide7: "hide",
            showSlide8: "show",
          };
        } else if (action.payload.clickedSlide.slide === "skip1") {
          props.setShowFaq('hide');
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "hide",
            showSlide6: "show",
          };
        }  else if (action.payload.clickedSlide.slide === "slide-confirm") {
          props.setShowFaq('hide');
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "hide",
            showSlide6: "show",
          };
        }else {
          return {
            ...state,
          };
        }
      }
      case 'previousSlides': {
        if (action.payload.previousSlide.slide == 'back1') {
          props.setShowFaq('show');
          props.setFooterEnable(true);
          return {
            ...state,
            showSlide1: "show",
            showSlide2: "hide"
          }
        } else if (action.payload.previousSlide.slide == 'back2') {
          return {
            ...state,
            showSlide2: "show",
            showSlide3: "hide"
          }
        } else if (action.payload.previousSlide.slide == 'back3') {
          return {
            ...state,
            showSlide2: "show",
            showSlide4: "hide",
            showSlide5:"hide"
          }
        } else if (action.payload.previousSlide.slide == 'back4') {
          return {
            ...state,
            showSlide3: "show",
            showSlide6: "hide"
          }
        } else if (action.payload.previousSlide.slide == 'back5') {
          return {
            ...state,
            showSlide6: "show",
            showSlide7: "hide",
          }
        } else if (action.payload.previousSlide.slide == 'back6') {
          return {
            ...state,
            showSlide8: "hide",
            showSlide7: "show"
          }
        }else if (action.payload.previousSlide.slide == 'back7') {
          return {
            ...state,
            showSlide5: "show",
            showSlide4:"hide"
          }
        }
        else {
          return {
            ...state
          }
        }
      }
      default: {
      }
    }
  };
  //const splitForm = useRef(null);
  const odometerRef = useRef(null);
  const scrollfrmv2div = useRef([]);
  const scrollToPc = useRef([]);
  const scrollToPrePc = useRef([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const [odometerValue, setOdometerValue] = useState(1000);
  const { SaveQuestionnaire } = useQuestionnaire();


  const { register, errors, trigger, setError, clearErrors, getValues, setValue } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const slideChange = (e) => {
    switch (e.target.name) {
      case "slide6":
        formSubmit();
        triggerSildeChange(e.target.name);
        break;
      case "slide7":
      case "slide8":
        saveSplitQuestionnaire(e.target.name)
        triggerSildeChange(e.target.name);
        break;
      default:
        triggerSildeChange(e.target.name);
        break;
    }
    
  };
  const previousSlideChange = (e) => {
    setError("txtLName", { message: "" });
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });
    }, 50);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: 'previousSlides', payload: { previousSlide } });
    }
  }, [previousSlide]);
  useEffect(() => {
    const od = new Odometer({
      el: odometerRef.current,
      value: 0,
      format: '(,ddd)',
      theme: 'default'
    });
    od.update(odometerValue);
  }, [odometerValue]);

  const formSubmit = async () => {
    const values = getValues();
    const formData = values;
    const selectedBanks = JSON.parse(localStorage.getItem('selectedBanks'));
    if (selectedBanks) {
      formData.bank = selectedBanks.map(bank => bank.value);
    }
    // if (formData.address1 !== "") {
    //   const txtUdprn = form["txtUdprn"].value;
    //   const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
    //   const txtPz_mailsort = form["txtPz_mailsort"].value;
    //   const txtStreet = form["txtStreet"].value;
    //   formData.txtUdprn = txtUdprn;
    //   formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
    //   formData.txtPz_mailsort = txtPz_mailsort;
    //   formData.txtStreet = txtStreet;
    // }
    formData.page_name = 'pcp/TCG_L_C';
    // formData.pCode_manual = form['pCode_EntryType'].value;
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem('formData', JSON.stringify(formData));
      localStorage.setItem('queryString', queryString);
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        'split_form_submit',
        'pcp/TCG_L_C',
        queryString,
        visitorParameters.data
      )
      
    }
  };

  const triggerSildeChange = (slideName) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });
    }, 50);
    setClickedSlide({ slide: slideName });
  }

  const saveSplitQuestionnaire = async (questionId) => {
    const formdata = getValues();
    const selectedBanks = JSON.parse(localStorage.getItem('selectedBanks'));
    if (selectedBanks) {
      formdata.bank = selectedBanks.map(bank => bank.value);
    }
    const questionDetails = {'slide7': '1','slide8': '2'};
    let question_data = {
      question_id: questionDetails[questionId],
      option_id: formdata[`question_${questionDetails[questionId]}`],
      input_answer: null,
      bankId: null
    };
    formdata.page_name = localStorage.getItem('split_name');
    localStorage.setItem("formData", JSON.stringify(formdata));
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      queryString,
      formdata
    )
    if(questionId == 'slide8'){
      if (queryString != null) {
        console.log("first if");
        console.log(queryString);
        if (queryString.indexOf("&" + field + "=") !== -1) {
          console.log("s");
          if (EnvConstants.AppConversionPixel === 'true') {
            console.log("if fb");
            history.push("/fbpixel?split_name=" + props.splitName + "&next_page=" + props.nextPage); // fb fixel firing
          } else {
            console.log("else fb");
            window.scrollTo(0, 0);
            history.push(
              "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + props.splitName
            );
          }
        } else {
          console.log("another else fb");
          window.scrollTo(0, 0);
          history.push(
            "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + props.splitName
          );
        }
      } else {

        console.log("laset else  fb");
        window.scrollTo(0, 0);
        history.push(
          "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + props.splitName
        );
      }
    }
  }
  return (
    <>
      <section className="form">
        <form className="main_form" id="step_1_form" ref={scrollfrmv2div} autoComplete="off">
          <div className="container">
            <div className="row justify-content-between">
              <div className="form-left col-lg-7 col-md-12 col-12">
                <h1 className="head_text mb-3">
                Have you ever taken out a car on finance?
                </h1>
                <h5 className="sub_text">You could be owed <span className="text-claim-value highlight-circle">
                  <span className="text-size-pence">£</span>
                  <span ref={odometerRef} id="i" className="odometer">1000</span>
                  <span className="text-size-caveat">s</span>
                </span> in compensation </h5>
                <div className="form-right top-up col-lg-4 col-md-12 col-12 d-block d-lg-none">
                  <div className="badge_right">
                    <div className="logo_design">
                      <LogoBasedOnDomain className="" bg="black" width="836" height="142"/>
                      {/* <img src={TcglLogo} alt="" /> */}
                    </div>
                    <div className="content_aware">
                      <p className="mb-0">Your Potential Claim Value:</p>
                      <h2 className="mb-0">£1,100* <span>per vehicle!</span></h2>
                    </div>
                  </div>
                </div>
                <div className="form-bg">
                  <Lenders 
                    slideChange={slideChange}
                    showSlide1={state.showSlide1}
                    btnStatus='hide'
                  />
                  <PersonalDetails 
                    showSlide2={state.showSlide2}
                    slideChange={slideChange}
                    validation={register}
                    validationMsg={errors}
                    trigger={trigger}
                    clearErrors={clearErrors}
                    setError={setError}
                    previousSlideChange={previousSlideChange}
                    getValues={getValues}
                  />
                  <Postcode
                   showSlide3={state.showSlide3}
                   slideChange={slideChange}
                   validation={register}
                   validationMsg={errors}
                   trigger={trigger}
                   clearErrors={clearErrors}
                   previousSlideChange={previousSlideChange}
                   setError={setError}
                   scrollToEvent={scrollToPc}
                  />

                  <PreviousDetails
                    showSlide4={state.showSlide4}
                    showSlide5={state.showSlide5}
                    slideChange={slideChange}
                    validation={register}
                    validationMsg={errors}
                    trigger={trigger}
                    clearErrors={clearErrors}
                    previousSlideChange={previousSlideChange}
                    getValues={getValues}
                    scrollToEvent={scrollToPrePc}
                    setValue={setValue}
                    setError={setError}
                   />
                  <ContactDetails
                  showSlide6={state.showSlide6}
                  validation={register}
                  validationMsg={errors}
                  trigger={trigger}
                  slideChange={slideChange}
                  setError={setError}
                  clearErrors={clearErrors}
                  showLoader={props.showLoader}
                  getValues={getValues}
                  previousSlideChange={previousSlideChange}

                   />
                  <BasicQuestions
                   showSlide7={state.showSlide7}
                   showSlide8={state.showSlide8}
                   setLoader={props.setLoader}
                   trigger={trigger}
                   //formSubmit={formSubmit}
                   slideChange={slideChange}
                   previousSlideChange={previousSlideChange}
                   validation={register}
                   validationMsg={errors}
                   setError={setError}
                   getValues={getValues}
                   />
                </div>
              </div>
           
            <div className="form-right top-up col-lg-4 col-md-12 col-12 d-none d-lg-block">
              <div className="badge_right ">
                <div className="logo_design">
                  <LogoBasedOnDomain className="" bg="black" width="836" height="142"/>
                  {/* <img src={TcglLogo} alt="" /> */}
                </div>
                <div className="content_aware">
                  <p className="mb-0 animate__animated animate__fadeInUp animate__delay-.5s">Your Potential Claim Value:</p>
                  <h2 className="mb-0 animate__animated animate__fadeInUp animate__delay-.5s">£1,100* <span>per vehicle!</span> </h2>
                </div>
              </div>
            </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default TCG_L_C_Form;
