
import React, { useReducer, useState, useContext } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import HiddenField from "../../../UI/HiddenField";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import Button from "../../../UI/Button";
import SelectBox from "../../../UI/SelectBox";
import { slideValidation } from "../../../../Utility/Validations";


const initialState = {
    lookUp: true,
    nextClick: false,
    checkSelect: false,
    getDetails: [],
    getAddress: [],
    showManualAddress: "hide",
    getPostcode: "#",
    clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
    switch (action.type) {
        case "validationSucess": {
            return {
                ...state,
                lookUp: action.payload.lookup,
                nextClick: action.payload.next_click,
                getPostcode: action.payload.postcode,
                getAddress: action.payload.get_address,
                checkSelect: action.payload.check_select,
                showManualAddress: action.payload.manual_address,
            };
        }
        case "getExtraAddressDetails": {
            return {
                ...state,
                getDetails: action.payload.get_details,
                showManualAddress: action.payload.manual_address,
            };
        }
        case "showManualDiv": {
            return {
                ...state,
                showManualAddress: "show",
                lookUp: false,
                nextClick: true,
                checkSelect: false,
                clickManualLink: action.payload.manual_click,
            };
        }
        case "resetaddress": {
            return {
                ...state,
                getDetails: [],
                getAddress: [],
                showManualAddress: "hide",
                lookUp: true,
                nextClick: false,
                checkSelect: false,
            };
        }
        default:
            return state;
    }
};
const PreviousAddress = ({
    validation,
    validationMsg,
    clearErrors,
    setError,
    slideChange,
    trigger,
    className,
    backClick,
    splitForm,
    getValues,
    showSlide7,
    setShow1
}) => {
    const { getPostcodeAddress, getSplitPostcodeAddress, isLoading } = usePostCodeAddress();
    const [state, dispatch] = useReducer(postcodeReducer, initialState);
    const [postCodeAddress, setPostCodeAddress] = useState([]);
    const [postCodeEntry, setPostCodeEntry] = useState("0");
    const { visitorParameters } = useContext(VisitorParamsContext);
    const [manualAddress, setManualAddress] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);

    const checkValidation = async (e) => {
        e.preventDefault();
        dispatch({
            type: "resetaddress",
            get_address: [],
        });
        setManualAddress(false);
        const txtPostCode = await trigger("txtPrevPostCode_1"); console.log(txtPostCode);
        if (txtPostCode) {
            await getPostcodeAddress(e.target.value, visitorParameters.visitor_parameters.uuid).then(res => {
                if (res.data.status === "0") {
                    setError("txtPrevPostCode_1", {
                        type: "manual",
                        message: "Please Enter Valid Postcode",
                    });
                }
                if (res.data.length > 0) {
                    dispatch({
                        type: "validationSucess",
                        payload: {
                            lookup: false,
                            next_click: true,
                            postcode: e.target.value,
                            get_address: res.data,
                            check_select: true,
                            manual_address: "hide",
                        },
                    });
                }
            }).catch(
                function () {
                    setError("txtPrevPostCode_1", {
                        type: "manual",
                        message: "Please Enter Valid Postcode",
                    });
                }
            );
        }
    };
    const getValue = async (e) => {
        if (e.target.name == "txtPrevAddress1_1") {
            setShowNextButton(true);
        }
        e.preventDefault();
        setManualAddress(false);
        dispatch({
            type: "getExtraAddressDetails",
            payload: { get_details: [], manual_address: "hide" },
        });
        setPostCodeAddress([]);
        clearErrors([
            "txtPrevHouseNumber_1",
            "txtPrevAddress1_1",
            "txtPrevaddress_3",
            "txtPrevCounty_1",
            "txtPrevTown_1",
            "txtPrevCountry_1",
        ]);
        if (e.target.value != "") {
            const getData = await getSplitPostcodeAddress(
                e.target.value,
                state.getPostcode,
                visitorParameters.visitor_parameters.uuid
            );
            dispatch({
                type: "getExtraAddressDetails",
                payload: { get_details: getData.data, manual_address: "show" },
            });
            setPostCodeAddress(getData.data);
            // clearErrors([
            //     "txtPrevHouseNumber_1",
            //     "txtPrevAddress1_1",
            //     "txtPrevaddress_3",
            //     "txtCounty1",
            //     "txtTown1",
            //     "txtCountry1",
            // ]);
        }
        else {
            dispatch({
                type: "getExtraAddressDetails",
                payload: { get_details: [], manual_address: "hide" },
            });
            setPostCodeAddress([]);
        }
    };

    const lookupValidation = async (e) => {
        await trigger("txtPrevPostCode_1");
    };

    const HiddenFields = () => (
        <>
            <HiddenField
                name="txtPrevUdprn_1"
                inputId="txtPrevUdprn_1"
                inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
            />
            <HiddenField
                name="txtPrevDeliveryPointSuffix_1"
                inputId="txtPrevDeliveryPointSuffix_1"
                inputValue={
                    state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
                }
            />
            <HiddenField
                name="txtPrevPz_mailsort_1"
                inputId="txtPrevPz_mailsort_1"
                inputValue={
                    state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
                }
            />
            <HiddenField
                name="txtPrevStreet_1"
                inputId="txtPrevStreet_1"
                inputValue={
                    state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
                }
            />
            <HiddenField
                name="txtPrevCountry_1"
                inputId="txtPrevCountry_1"
                inputValue={
                    state.getDetails.county !== "#" ? state.getDetails.county : ""
                }
            />
            <HiddenField
                name="txtPrevTown_1"
                inputId="txtPrevTown_1"
                inputValue={
                    state.getDetails.town !== "#" ? state.getDetails.town : ""
                }
            />
            <HiddenField
                name="txtPrevCountry_1"
                inputId="txtPrevCountry_1"
                inputValue={
                    state.getDetails.country !== "#" ? state.getDetails.country : ""
                }
            />
        </>
    );


    return (
        <div className="row previousaddresses animate__animated animate__fadeInUp">
            <div className="col-lg-12 col-12 mb-3">
                <AddressTextField
                    type="text"
                    placeholder="Post Code"
                    name="txtPrevPostCode_1"
                    id="txtPrevPostCode_1"
                    className="form-control d-block"
                    onBlur={checkValidation}
                    autoComplete="off"
                    validation={validation({
                        required: "Please Enter Postcode",
                        pattern: {
                            value: /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                            message: "Please Enter a Valid Postcode",
                        },
                        minLength: {
                            value: 5,
                            message: "Please Enter a Valid Postcode",
                        },
                    })}
                    validationMsg={
                        validationMsg.txtPrevPostCode_1 && validationMsg.txtPrevPostCode_1.message
                    }
                />
            </div>
            <div className="col-lg-12 col-12 mb-3">
                {/* <button
                    type="button"
                    className="button next-btn w-100 button_lookup"
                    id="lookUp2"
                >
                    <span>Look Up Address</span>
                </button> */}
                <Button
                    type="button"
                    name={`postcode`}
                    id={`next_postcode`}
                    className="button next-btn w-100 button_lookup"
                    buttonText="Look Up Address"
                    onClick={lookupValidation}
                />
            </div>
            <div
                className="col-lg-12 mb-3 animate__animated animate__fadeInUp"
                id="LookupAddress2"
                style={state.checkSelect ? { display: "block" } : { display: "none" }}
            >
                <AddressSelectBox
                    className="form-select watermark anim_ylw animated-effect"
                    OptionValue={state.getAddress}
                    name="txtPrevAddress1_1"
                    id="txtPrevAddress1_1"
                    onChange={(e) => { getValue(e); clearErrors('txtPrevAddress1_1') }}
                    myRef={validation({ required: "Please select address" })}
                    validationMsg={
                        validationMsg.txtPrevAddress1_1 && validationMsg.txtPrevAddress1_1.message
                    }
                />
            </div>
            <div
                className={`col-lg-12 animate__animated animate__fadeInUp ${state.showManualAddress}`}
                id="fullAddress2"
            >
                <div className="mb-3">
                    <AddressTextField
                        type="text"
                        placeholder="Address Line 1 *"
                        name="txtPrevHouseNumber_1"
                        id="txtPrevHouseNumber_1"
                        className="form-control d-block"
                        autoComplete="off"
                        readOnly={true}
                        validation={validation({
                            required: "Please Enter Address Line 1",
                        })}
                        validationMsg={
                            validationMsg.txtPrevHouseNumber_1 &&
                            validationMsg.txtPrevHouseNumber_1.message
                        }
                        value={state.getDetails.line_1}
                    />
                </div>
                <div className="mb-3">
                    <AddressTextField
                        type="text"
                        placeholder="Address Line 2"
                        name="txtPrevaddress_3"
                        id="txtPrevaddress_3"
                        className="form-control hero-input d-block"
                        autoComplete="off"
                        readOnly={true}
                        validation={validation}
                        value={state.getDetails.line_2}
                    />
                </div>
                {Object.keys(state.getDetails).length > 0 && <HiddenFields />}
            </div>
            {showNextButton && <div
                className="col-lg-12 col-12 text-center text-lg-end mt-3 slide_7_div animate__animated animate__fadeInUp"
            >
                <button
                    type="submit"
                    className="button next-btn"
                    id=""
                    value="Next"
                    name=""
                    onClick={()=>{setShow1(false)}}
                >
                    {" "}
                    Continue
                </button>
            </div>}
        </div>
    );
};
export default PreviousAddress;
