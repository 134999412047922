import React from 'react';
import LoaderImage from "../../../../assets/img/loader02.gif";
import loader04 from "../../../../assets/img/loader04.gif";

const Loader = (props) => {
  return (
    <>

      <div className={`modal fade ${props.loader} loader-modal`} id="addPreDetails1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addPreDetails1Label" aria-hidden="true" style={{background:"white"}}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className={`TCG_L_C`}>
              <div className="text-center loader loader1">
                <div className=" py-5">
                  <div className="my-5">
                    <img height="100" src={loader04} alt="" />
                  </div>
                  <h2 className="px-4">{props.loderContent}
                  </h2>
                </div>
              </div>
              {/* <div className="loader_full_Pg">
                <div id="wifi-loader">
                  <svg className="circle-outer" viewBox="0 0 86 86">
                    <circle className="back" cx="43" cy="43" r="40"></circle>
                    <circle className="front" cx="43" cy="43" r="40"></circle>
                    <circle className="new" cx="43" cy="43" r="40"></circle>
                  </svg>
                  <svg className="circle-middle" viewBox="0 0 60 60">
                    <circle className="back" cx="30" cy="30" r="27"></circle>
                    <circle className="front" cx="30" cy="30" r="27"></circle>
                  </svg>
                  <svg className="circle-inner" viewBox="0 0 34 34">
                    <circle className="back" cx="17" cy="17" r="14"></circle>
                    <circle className="front" cx="17" cy="17" r="14"></circle>
                  </svg>
                  <div
                    className="text"
                    data-text={props.contentText}
                  ></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default Loader;
