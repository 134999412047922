import React, { useContext, useState, useRef, useEffect } from "react";
import Button from "../../UI/Button";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { useIdUpload } from "../../../Hooks/useIdUpload";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import Header from "../../Includes/Layouts/TCG_L_D/Header"
import Footer from "../../Includes/Layouts/TCG_L_D/Footer";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import { useSkipHandling } from "../../../Hooks/useSkipHandling";
import "../../../assets/css/TCG_L_D/uploads.scss"
import bannerimg1 from "../../../assets/img/TCG_L_D/banner-img1.png";
import "../../../assets/css/TCG_L_D/custom.scss"
import "../../../assets/css/TCG_L_D/car-regist.scss"
import FileUploadBox from "../../Includes/Layouts/TCG_L_D/FileUploadBox ";
import ssl_img from '../../../assets/img/TCG_L_D/ssl.png';
import IdUploadModal from "../../Includes/Layouts/TCG_L_D/IdUploadModal";
import PreviousSurnameModel from "../../Includes/Layouts/TCG_L_D/PreviousSurnameModel";
import PreviousAddressModel from "../../Includes/Layouts/TCG_L_D/PreviousAddressModel";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import Loader from "../../Includes/Layouts/TCG_L_D/Loader";

const IdUploadV4 = () => {
  const [isModalVisible, setIsModalVisible] = useState({ show: false, type: '' });
  const [selectedFileType, setSelectedFileType] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [userfile, setUserfile] = useState({ preview: '', title: '', type: '' });
  const [progressbar, setProgressBar] = useState(false);
  const [idType, setIdType] = useState('');
  const [isInValidFile, setIsInValidFile] = useState(false);
  const [base64Image, setBase64Image] = useState('');
  const [btnLoader, setBtnLoader] = useState(false);
  const { saveIdUpload, isLoading } = useIdUpload();
  const { handleSkip } = useSkipHandling();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const split_name = query.get("splitName");
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const [previousSurnameModelShow, setPreviousSurnameModelShow] = useState(false);
  const [previousAddressModelShow, setPreviousAddressModelShow] = useState(false);
  const { saveDataIngestion } = useDataIngestion();
  const [loading, setLoading] = useState(true);

  const handleUploadClick = (e, fileType) => {
    setIdType(e.target.id);
    setErrorMessage('')
    setIsInValidFile(false);
    setIsModalVisible({ show: true, type: fileType });
  };

  const handleCloseModal = () => {
    setIsModalVisible({ show: false, type: '' });
    setSelectedFileType('');
    setIsFileSelected(false);
    setUserfile({ preview: '', title: '', type: '' });
    setBase64Image('');
    setErrorMessage('');
    setProgressBar(false);
    setBtnLoader(false);

  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [loading])
  const handleFileChange = (event) => {
    setProgressBar(true);
    const timer = setTimeout(() => {
      setProgressBar(false);
      const imageFile = event.target.files[0];
      if (imageFile) {
        setErrorMessage(''); // for clear error msg when user upload doc
        const extension = imageFile.type;
        const imgsize = imageFile.size;
        const allowedExtensions = ["image/jpeg", "image/png", "application/pdf"];
        if (!allowedExtensions.includes(extension)) {
          setIsInValidFile(true);
          setErrorMessage({ txt: "The uploaded ID type should be an image in jpeg, png, or jpg" });
          return;
        } else if (imgsize > 1024 * 1024 * 10) {
          setIsInValidFile(true);
          setErrorMessage({ txt: "Please choose a file less than 10MB" });
          return;
        } else {
          setIsInValidFile(false);
          setIsFileSelected(true);
          setUserfile({ preview: URL.createObjectURL(imageFile), title: imageFile.name, type: imageFile.type });
          const reader = new FileReader();
          reader.onload = _handleReaderLoaded;
          reader.readAsDataURL(imageFile);
        }
      } else {
        setIsInValidFile(true);
        setUserfile({ preview: '', title: '', type: '' });
      }
      setProgressBar(false);
    }, 2000)
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image(binaryString);
  };

  const retakeClick = (e) => {
    setUserfile(({ preview: '', title: '', type: '' }));
    setIsFileSelected(false);
  };

  const handleSubmit = async (event) => {
    setBtnLoader(true);
    event.preventDefault();

    if (isFileSelected) {
      const uploadData = {
        imageFile: base64Image,
        captureType: isModalVisible.type.split('_')[0],
        documentType: isModalVisible.type.split('_')[0],
      };

      const response = await saveIdUpload(
        uploadData,
        visitorParameters.visitor_parameters,
        formParameters,
        visitorParameters.data,
        queryString,
        "user_docs_store"
      );

      setBase64Image('');
      setIsModalVisible({ show: false, type: "" });
      setErrorMessage('');
      setIsInValidFile(false);
      setSelectedFileType(isModalVisible.type);

    } else {
      setIsInValidFile(true);
      setErrorMessage({ txt: "Please upload a file" });
    }
    setBtnLoader(false);
  };

  const skipIdPage = async () => {
    const typeTwo = "id_upload";
    const skip_data = {
      type: "id_upload",
      bank_id: 0
    }
    GTMDataLayer({
      clickEvent: 'slideclick',
      question: 'id upload',
      answer: '',
    });
    const response = await handleSkip(
      visitorParameters.visitor_parameters,
      visitorParameters.data,
      formParameters,
      queryString,
      skip_data,
      "skip-id-upload",
      split_name
    );
    setPreviousSurnameModelShow(true);
  };

  const handleNextClick = (e) => {
    if (!isFileSelected) {
      setIsInValidFile(e.target.id);
      setErrorMessage({ txt: "Please upload a file" });
      return false;
    } else if (isFileSelected) {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'id upload',
        answer: '',
      });
      setPreviousSurnameModelShow(true);
    }
  };

  const handlePreviousDataSubmit = async (event, data = null) => {
    if (data != null) {
      const formData = JSON.parse(localStorage.getItem("formData")) || {};
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          formData[key] = data[key];
        }
      }
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        'extra_form_data_previous_details',
        split_name,
        queryString,
        visitorParameters.data
      )
    }
    if (event == 'pageChange') {
      history.push(`/v4/thankyou?uuid=${visitorParameters.visitor_parameters.uuid}&splitName=${split_name}`);
    }
  }
  return (
    <>
      <GetVisitorsParams />
      {/* <Loader loader={showLoader} contentText="" /> */}
      <div className=" TCG_L_D car_regV4 ">
        <Loader loader={loading} loderContent=" Analysing your Result" />
        <div>
          <Header />
          <section className="banner  ">
            <div className="container">
              <div>
                <div className="brand">
                  <div className="logo pt-3">
                    <LogoBasedOnDomain height={40} />
                  </div>
                </div>
              </div>
              <div className="row mt-5 mb-5">
                <div className="col-lg-6">
                  <h1 className="text-light" style={{ fontWeight: '600' }}>
                  Your Identification
                  </h1>
                  <p className="text-light">
                  To progress your claims, your lenders may require proof of ID.
                  </p>
                  <div className="car-regi-middle">
                    {/* idUpload MainSec */}
                    <div className="id_uploadV4">
                      <div className="row">
                        <div className="col-lg-10">
                          {/* <FileUploadBox
                            iconClassName="bi-person-badge"
                            title="National ID Card"
                            selectedFileType={selectedFileType}
                            handleUploadClick={handleUploadClick}
                            fileType="NationalId_doc"
                            fileId="NationalId"
                            setSelectedFileType={setSelectedFileType}
                            setIsFileSelected={setIsFileSelected}
                          /> */}
                          <FileUploadBox
                            iconClassName="bi-car-front-fill"
                            title="Driver's License"
                            selectedFileType={selectedFileType}
                            handleUploadClick={handleUploadClick}
                            fileType="DrivingLicense_doc"
                            fileId="DrivingLicense"
                            setSelectedFileType={setSelectedFileType}
                            setIsFileSelected={setIsFileSelected}
                          />
                          <FileUploadBox
                            iconClassName="bi-airplane"
                            title="Passport"
                            selectedFileType={selectedFileType}
                            handleUploadClick={handleUploadClick}
                            fileType="Passport_doc"
                            fileId="Passport"
                            setSelectedFileType={setSelectedFileType}
                            setIsFileSelected={setIsFileSelected}
                          />
                          {isInValidFile && isInValidFile == "next4" && (
                            <div className="error_msg mb-1 mt-0">
                              {errorMessage.txt}
                            </div>
                          )}
                          <div className="row my-lg-4 my-md-4 my-sm-4 my-4">
                            <div className="col-4 pe-0">
                              <Button
                                className="com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2 skipbtn"
                                id="next3"
                                type="button"
                                onClick={skipIdPage}
                                buttonText="Skip"
                              />
                            </div>
                            <div className="col-8">
                              <Button
                                className="com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2"
                                id="next4"
                                type="button"
                                onClick={(e) => handleNextClick(e)}
                                buttonText={<>  Next <i className="bi bi-arrow-right" /></>}
                              />
                            </div>
                          </div>
                          <p style={{color:"white"}}>When you click “Next”, we will receive the identity documents you have provided, which will be used to progress and/or support your claim(s). Where required, these documents will be shared with your lender(s)</p>
                          <div className="text-center">
                            <img height={50} src={ssl_img} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="bannerimage-wraper text-lg-start text-md-center text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                    <img
                      className="bannerimg fade-in d-lg-block d-md-block d-sm-none d-none"
                      src={bannerimg1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {isModalVisible.show &&
            <IdUploadModal
              isModalVisible={isModalVisible}
              handleCloseModal={handleCloseModal}
              isFileSelected={isFileSelected}
              userfile={userfile}
              idType={idType}
              handleFileChange={handleFileChange}
              progressbar={progressbar}
              isInValidFile={isInValidFile}
              errorMessage={errorMessage}
              retakeClick={retakeClick}
              btnLoader={btnLoader}
              handleSubmit={handleSubmit}
            />}
        </div>
        {previousSurnameModelShow &&
          <PreviousSurnameModel
            setPreviousSurnameModelShow={setPreviousSurnameModelShow}
            setPreviousAddressModelShow={setPreviousAddressModelShow}
            handlePreviousDataSubmit={handlePreviousDataSubmit}
          />}
        {previousAddressModelShow &&
          <PreviousAddressModel
            setPreviousAddressModelShow={setPreviousAddressModelShow}
            handlePreviousDataSubmit={handlePreviousDataSubmit}
          />}
       <Footer superScriptQuote = {true}/>
      </div>
    </>
  );
};

export default IdUploadV4;
