const full_url = window.location.href;
const query_string_arr = full_url.split("?");
var queryString = "";
if (query_string_arr != null) {
  if(query_string_arr[1] === undefined || query_string_arr[1] === "" || query_string_arr[1] === null){
    queryString = "";
  }else{
    queryString = query_string_arr[1];
  }  
}
const urlParams = new URLSearchParams(window.location.search);

export { queryString, urlParams };

export const getQueryString = () => {
  const full_url = window.location.href;
  const query_string_arr = full_url.split("?");
  if (query_string_arr && query_string_arr[1]) {
    return query_string_arr[1];
  } else {
    return "";
  }
}