import React, { useState, useEffect, useTransition } from 'react';
import { DateOfBirth } from './DateOfBirth';
import SelectBox from '../../../UI/SelectBox';
import { Salutation } from '../../../../Constants/Constants';
import { slideValidation, nameValidate, profanityValidation, preNameDuplicationCheck } from '../../../../Utility/Validations';
import TextField from "../../../UI/TextField";
import tcglLogo from "../../../../assets/img/tcgl-logo.svg"
import RadioButtonV2 from '../../../UI/RadioButtonV2';
import PreviousName from './PreviousName';
import Button from '../../../UI/Button';
import LogoBasedOnDomain from '../../Layouts/Common/LogoBasedOnDomain';
import InputButton from "../../../UI/InputButton";
import GTMDataLayer from '../../../../Utility/GTMDataLayer';
import { monthMapping } from "../../../../Constants/Constants";
import { dobValidation } from "../../../../Utility/Validations";


const PersonalDetails = (props) => {
  const [showPopUp, SetShowPopUp] = useState(false)
  const [showPopUp1, SetShowPopUp1] = useState(false)
  const [selectedOption, setSelectedOption] = useState('');
  const [previousNameSectionCount, setPreviousNameSectionCount] = useState(1);
  const [lastNameError, setLastNameError] = useState(false);
  const [valids, setValids] = useState(""); // State to manage validation message

  useEffect(() => {
      if (props.showSlide5) {
          SetShowPopUp(false);
          SetShowPopUp1(false);
          setPreviousNameSectionCount(1);
      }
  }, [props.showSlide5]);

  const handleNextClick = async (e) => {
    const surname = { txtLName: props.getValues().txtLName };
    localStorage.setItem('sur_names', JSON.stringify(surname));
    
    // Check for profanity in names
    const profanityFields = profanityValidation(['txtFName', 'txtLName'], props.getValues);
    if (profanityFields !== 'success') {
      props.setError(profanityFields, { message: "Please Enter valid Name" });
      return false;
    }
    
    // Validate date of birth
    const dobValues = props.getValues();
    const dobValid = dobValidation(dobValues.DobDay, dobValues.DobMonth, dobValues.DobYear);
    if (!dobValid) {
      props.setError('DobDay', { type: "manual", message: "Please Enter Valid Date" });
      return false;
    }
  
    // Additional date check
    const dob = new Date(dobValues.DobYear, monthMapping[dobValues.DobMonth], dobValues.DobDay);
    const endDate = new Date(2000, 5, 1);
    if (dob >= endDate) {
      setValids("Please Enter a date before 01/6/2000");
      return false;
    } else {
      setValids(""); // Clear any previous validation messages
    }
  
    // If all validations pass, proceed to show popup
    const validationArr = ['lstSalutation', 'txtFName', 'txtLName', 'DobDay', 'DobMonth', 'DobYear'];
    const validationCheck = await slideValidation(validationArr, props.trigger);
    if (validationCheck === 'Success') {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'personal details',
        answer: '',
      });
      SetShowPopUp(true);
    }
  }
  

  const handleNextClick2 = async (e) => {
    e.preventDefault();
    const isValidPreviousName = await preNameDuplicationCheck(props.getValues,props.setError,props.clearErrors, setLastNameError);
    if (!isValidPreviousName) return;
    let allValid = true;
    let atLeastOneValid = false;
    const isValid = await validateFields(1);
    if (isValid) {
      atLeastOneValid = true;
    } else {
      allValid = false;
      // Set an error message for the first input field if it is not valid
      const errorMessage = "Please enter at least one previous surname.";
      props.validationMsg[`txtPrevLName_1`] = { message: errorMessage };
    }
    const profanityFields = profanityValidation(['txtPrevLName_1','txtPrevLName_2','txtPrevLName_3'], props.getValues);
    if (profanityFields != 'success') {
      props.setError(profanityFields, { message: "Please Enter valid Name" });
      return false;
    }
    if (allValid && atLeastOneValid) {
      SetShowPopUp(false);
      props.slideChange(e);
    } else if (!atLeastOneValid) {
      // Display a general error message if the first input field is not valid
      const errorMessage = "Please enter at least one previous surname.";
      props.validationMsg[`txtPrevLName_1`] = { message: errorMessage };
      // Update the state or re-render to display the error message
    }
  };

  const handleSkipDetails = (e) => {
    SetShowPopUp(false);
    setPreviousNameSectionCount(0);
    setSelectedOption(e.target.value);
    props.slideChange(e);
  
    let surname = JSON.parse(localStorage.getItem('sur_names'));
  
    delete surname.txtPrevLName_1;
    delete surname.txtPrevLName_2;
    delete surname.txtPrevLName_3;

    localStorage.setItem('sur_names', JSON.stringify(surname));
  };

  
  const handleYesClick = (e) => {
    SetShowPopUp1(true);
    setSelectedOption(e.target.value);

  };
  // below are code for adding extra prename section
  const addExtraSection = async () => {
    const isValid = await preNameDuplicationCheck(props.getValues,props.setError, props.clearErrors, setLastNameError);
    if (!isValid) return;
    let allValid = true;
    for (let i = 1; i <= previousNameSectionCount; i++) {
      const isValid = await validateFields(i);
      if (!isValid) {
        allValid = false;
        break;
      }
    }
    if (allValid && previousNameSectionCount <= 3) {
      setPreviousNameSectionCount(previousNameSectionCount + 1);
    }
  }

  const validateFields = async (index) => {
    const lastNameField = `txtPrevLName_${index}`;
    const isLastNameValid = await props.trigger(lastNameField);
    return isLastNameValid;
  };
 
  return (
    <>
      <div className={`slide4 questionwrapper  ${props.showSlide5}`} >
        <h4 className="contacthead">Enter Your Personal Details</h4>
        <div className="row name animate__animated animate__fadeInUp mt-2 mb-3 ">
          <div className="col-lg-2 mb-3  pe-lg-0 ">
            <SelectBox
              className="form-select select1"
              OptionValue={Salutation}
              name="lstSalutation"
              id="lstSalutation"
              onChange={() => props.clearErrors("lstSalutation")}
              clearErrors={props.clearErrors}
              myRef={props.validation({ required: "Please Select Title" })}
              validationMsg={
                props.validationMsg.lstSalutation &&
                props.validationMsg.lstSalutation.message
              }
            ></SelectBox>
          </div>
          <div className="col-lg-5  pe-lg-0">
            <div className="mb-3">
              <TextField
                type="text"
                className="form-control"
                placeholder="First Name"
                name="txtFName"
                id="txtFName"
                onkeypress={(e) => nameValidate(e)}
                onChange={(e) => { props.clearErrors("txtFName"); e.target.value = e.target.value.trimStart() }}
                onBlur={(e) => { e.target.value = e.target.value.trim() }}
                validation={props.validation({
                  required: "Please Enter First Name",
                  minLength: {
                    value: 3,
                    message: "Please Enter Valid First Name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid. Please Recheck",
                  },
                })}

              ></TextField>
              {props.validationMsg.txtFName && (
                <span
                  className="error_msg error"
                  id="txtFName_err"
                >
                  {props.validationMsg.txtFName.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-lg-5 ">
            <div className="mb-3">
              <TextField
                type="text"
                className="form-control"
                placeholder="Surname"
                name="txtLName"
                id="txtLName"
                onkeypress={(e) => nameValidate(e)}
                onChange={(e) => { props.clearErrors("txtLName"); e.target.value = e.target.value.trimStart() }}
                onBlur={(e) => { e.target.value = e.target.value.trim() }}
                validation={props.validation({
                  required: "Please Enter Last Name",
                  minLength: {
                    value: 3,
                    message: "Please Enter Valid Last Name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid. Please Recheck",
                  },
                })}

              ></TextField>
              {props.validationMsg.txtLName && !lastNameError &&(
                <span
                  className="error_msg error"
                  id="txtLName_err"
                >
                  {props.validationMsg.txtLName.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="date-birth mt-2 mb-3 animate__animated animate__fadeInUp">
          <fieldset className="p-3   ">
            <legend className="px-2 m-0 fw-bold">Date Of Birth</legend>
            <div className="row px-md-n5">
              <DateOfBirth
                clearErrors={props.clearErrors}
                validation={props.validation}
                validationMsg={props.validationMsg}
                getValues={props.getValues}
                setError={props.setError}
              />
            </div>
          </fieldset>
        </div>

        <div className="col-lg-12 col-12 text-center text-lg-end mt-3">
          <div className="row">
            <InputButton
              name="back4"
              className="back-btn order-lg-1 order-md-1 order-2"
              id="backStep4"
              value="Back"
              btnType="button"
              style={{ float: "none" }}
              onClick={props.previousSlideChange}
            />
            <button
              type="button"
              className="button next-btn order-lg-2 order-md-2 order-1"
              id=""
              value="Next"
              name=""
              data-bs-toggle="modal"
              data-bs-target="#previousname"
              onClick={(e) => handleNextClick(e)}
            >
              {" "}
              Next
            </button>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className={`modal fade show ${showPopUp ? "show" : "d-none"}`}
        id="previousname"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content form-modal">
            <div className="modal-header text-center">
              <LogoBasedOnDomain className="" bg="" />
            </div>
            <div className="modal-body">
              <h4>
                Did you have any previous surname?{" "}
                <em>[for the last 20 years]</em>
              </h4>
              <div className="d-block d-lg-flex choose_Y_N col-lg-12 col-12 mt-3">
                <div className="form-check px-0 col-lg-6 col-12">
                  <RadioButtonV2
                    type="radio"
                    className="form-check-input"
                    name="slide_5"
                    id="yes"
                    value="yes"
                    labelName="Yes"
                    checked={selectedOption === 'yes'}
                    onClick={handleYesClick}
                    dataBsDismiss="modal"
                  />
                </div>
                <div className="form-check px-0 col-lg-6 col-12">
                  <RadioButtonV2
                    type="radio"
                    className="form-check-input"
                    name="slide_5"
                    id="no"
                    value="no"
                    labelName="No"
                    onClick={(e) => handleSkipDetails(e)}
                    checked={selectedOption === 'no'}

                    dataBsDismiss="modal"
                  />
                </div>
              </div>
              {showPopUp1 && <div
                className={`row previousname pre_name_section animate__animated animate__fadeInUp ${showPopUp1 ? "" : "d-none"}`}
                style={{ display: "block" }}
              >
                {previousNameSectionCount >= 1 &&
                  <PreviousName
                    validation={props.validation}
                    validationMsg={props.validationMsg}
                    preCount='1'
                    getValues={props.getValues}
                    clearErrors={props.clearErrors}
                    prevName=" Previous Surname"
                    setError={props.setError}
                  />
                }
                {previousNameSectionCount >= 2 &&
                  <>
                    <hr className="hr" />
                    <PreviousName
                      validation={props.validation}
                      validationMsg={props.validationMsg}
                      preCount='2'
                      getValues={props.getValues}
                      clearErrors={props.clearErrors}
                      prevName=" Previous Surname 1"
                      setError={props.setError}
                    />
                  </>
                }
                {previousNameSectionCount >= 3 &&
                  <>
                    <hr className="hr" />
                    <PreviousName
                      validation={props.validation}
                      validationMsg={props.validationMsg}
                      preCount='3'
                      getValues={props.getValues}
                      clearErrors={props.clearErrors}
                      prevName=" Previous Surname 2"
                      setError={props.setError}
                    />
                  </>
                }
                {previousNameSectionCount < 3 &&
                  <div style={{ textAlign: "end" }}><button type="button" className="prev-field-btn" style={{ padding: "6px" }} onClick={addExtraSection} id="addPrevPostcode" name="addPrevPostcode">+ Add More</button></div>
                }
                <div className="mb-3 mt-2 text-center">

                  <Button
                    type="button"
                    className="button next-btn slide_5 w-100"
                    id=""
                    value="Next"
                    name="slide_5"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => handleNextClick2(e)}
                    buttonText="Continue"
                  />
                </div>

              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PersonalDetails;