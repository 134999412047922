import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import Button from "../../../UI/Button";
import CheckBoxV2 from "../../../UI/CheckBoxV2";
import InputButton from "../../../UI/InputButton";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";
const BasicQuestionnaire1 = (props) => {
  const question_id = props.questionnaire.questionId;
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const nextSlide = async (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setSelectedOptions((prev) => [...prev, value]);
    } else {
      setSelectedOptions((prev) => prev.filter((item) => item !== value));
    }

    setErrorMessage("");
  };
 
  const handleChange = (e) => {
    if (selectedOptions.length === 0) {
      setErrorMessage("Please select an option.");
    } else {
      setErrorMessage("");
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'higher commission',
        answer: '',
      });
      props.slideChange(e);
    }

  }

  const Radio = props.questionnaire.answers.map((answer, index) => {
    return (
      <div className={`form-check px-0 col-lg-6 col-12  w-100 `} key={`${index}_${answer.value}`}>
        {/* <RadioButton
          id={`question_${props.questionnaire.questionId}_${answer.value}`}
          value={answer.value}
          name={`question_${props.questionnaire.questionId}`}
          className="form-check-input"
          labelName={answer.label}
          labelClassName={`radio label-class `}
          onClick={nextSlide}
          validation={props.validation}
          gtm_question={props.questionnaire.gtmQuestion}
          gtm_trigger={props.questionnaire.gtm_trigger}
          gtm_answer={answer.gtmAnswer}
          clickevent={props.questionnaire.clickevent}
          checked={index}
        /> */}
        <CheckBoxV2
          dataId={`question_${props.questionnaire.questionId}_${answer.value}`}
          value={answer.value}
          name={`question_${props.questionnaire.questionId}`}
          className="form-check-input"
          labelText={answer.label}
          labelClassName={`radio label-class `}
          onClick={nextSlide}
          validation={props.validation}
          // gtm_question={props.questionnaire.gtmQuestion}
          // gtm_trigger={props.questionnaire.gtm_trigger}
          // gtm_answer={answer.gtmAnswer}
          // clickevent={props.questionnaire.clickevent}
          // checked={index}
        />
      </div>
    )
  });

  return (
    <>

      <div className={`${props.className}`}>
        <h4 >
          {" "}
          {props.questionnaire.question}
        </h4>
        <div className="choose_Y_N col-lg-12 col-12 d-block">
          {Radio}
        </div>
        {errorMessage && (
          <div
            className="validation-error mt-3 text-center"
            style={{
              background: "red",
              borderRadius: "4px",
              width: "100%",
              marginLeft: "0px",
              marginTop: "5px",
              padding: "5px",
              color: "white",
            }}
          >{errorMessage}</div>
        )}

        <div className="col-lg-12 col-12 text-center text-lg-end mt-3">
          <div className="row"> 
          <Button
          name="back3"
          className="back-btn order-lg-1 order-md-1 order-2"
          id="backStep3"
          buttonText="Back"
          type="button"
          style={{ float: "none" }}
          onClick={props.previousSlideChange}
        />
          <Button
            type="button"
            className="button next-btn-home1 nxt-btn  slide_4 order-lg-2 order-md-2 order-1"
            id=""
            value={selectedOptions.join(', ')}
            name="slide_4"
            buttonText="Next"
            onClick={(e) => handleChange(e)}
          />
        </div>
      </div>

      </div>
    </>
  );
};
export default BasicQuestionnaire1;
