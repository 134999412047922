import Button from "../../UI/Button";

const FollowUpLender = (props) => {
  const bankList = props.bankList.map((bank, index) => {
    return (
      <div className="form-check px-0" key={`ub_${index}`}>
        <input className="form-check-input" type="checkbox" name="lendercheck" id="check1" value="" checked />
        <label className="form-check-label lender_pad" htmlFor="check1"> {bank.label}  </label>
      </div>
    )
  });
  return (
    <>
      <div className="slide2">
        <div className="slide_header text-md-start text-center">
          <p className="subtext2">It is important to confirm the lenders who you wish to start a claim with.</p>
        </div>

        <div className="ess_info mb-3">
          <h5 className="text-center">The Lenders you have selected</h5>
          <div className="choose_Y_N flex-column col-lg-12 col-12">
            {bankList}
          </div>

          <div className="py-2 w-100">
            <Button
              type="button"
              className="main-btn p-2 w-100 mb-lg-0 mb-sm-0 fs-5 fw-normal sticky add_btn add-lnder-btn"
              id="addLender"
              buttonText={<>Add More Lenders <i className="bi bi-plus-lg" id="addLender"></i></>}
              onClick={props.slideChange}
            />
            <p className="sml_text d-flex fst-italic fw-semibold text-danger mb-0 pt-1"><i className="bi bi-info-circle"></i>&nbsp;&nbsp;<span>Most drivers have taken out several vehicle finance between 2007 and 2021.</span></p>
          </div>

          <div className="py-4 w-100">
            <Button
              type="button"
              className="main-btn p-2 w-100 mb-lg-0 mb-sm-0 py-3 fs-5 fw-bold sticky next4"
              id="confirmLender"
              buttonText={<>I Confirm <i className="bi bi-chevron-right bold-icon" id="confirmLender"></i></>}
              onClick={props.slideChange}
            />
          </div>

        </div>

      </div>
    </>
  )
}

export default FollowUpLender;