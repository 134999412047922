import { useState } from "react";
import { IdUploadApi } from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const useFollowupIdUpload = () => {

  const { ErrorWebhookTrigger } = useErrorWebhook();
  const [isLoading, setIsLoading] = useState(false);
  const saveFollowupIdUpload = async (
    upload_data,
    followup_data,
    message_type
  ) => {
    const response = await IdUploadApi.post("v1/data-ingestion-pipeline", {
      upload_data,
      followup_data,
      message_type
    }).catch(function (error) {
      const erroMessage = {
        serviceName : 'ID UPLOAD',
        errorReason:error.message,
        errorCode:error.code
      }
      ErrorWebhookTrigger(erroMessage);
    });
    setIsLoading(false);
    return response;
  };
  return {
    isLoading,
    saveFollowupIdUpload,
  };
};
