const  questionnaire_TCG_L_A = {
    question_1: {
        questionId: 1,
        question: " Did the car dealership explain they would receive a commission for selling you the car finance?",
        gtmQuestion: "commission received",
        gtm_trigger:true,
        clickevent:'slideclick',
        answers: [
            {
                label: "No",
                value: "1",
                gtmAnswer: 'no'
            },
            {
                label: "Yes",
                value: "2",
                gtmAnswer: 'yes',
            }
        ],

    },
    question_2: {
        questionId: 2,
        question: "  Your lenders may have allowed the car dealership to increase the cost of your car finance to earn a higher commission, if you had known this would you have:",
        gtmQuestion: "",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "Asked the car dealership if they could reduce the cost",
                value: "3",
                gtmAnswer: 'no'
            },
            {
                label: " Shopped around for a better deal",
                value: "4",
                gtmAnswer: 'yes',
            },
            {
                label: "  Refused to take the car finance due to the increased cost",
                value: "5",
                gtmAnswer: 'yes',
            }
        ],

    },
}

const questionnaire_TCG_L_B = {
    question_1: {
        questionId: 1,
        question: "Did the car dealership explain they would receive a commission for selling you the car finance?",
        gtmQuestion: "commission received",
        gtm_trigger: true,
        clickevent: 'slideclick',
        answers: [
            {
                label: "No",
                value: "2",
                gtmAnswer: ''
            },
            {
                label: "Yes",
                value: "1",
                gtmAnswer: '',
            }
        ],

    },
    question_2: {
        questionId: '2',
        question: "Your lenders may have allowed the car dealership to increase the cost of your car finance to earn a higher commission, if you had known this would you have:",
        gtmQuestion: "",
        gtm_trigger: false,
        clickevent: 'slideclick',
        answers: [
            {
                label: "Asked the car dealership if they could reduce the cost",
                value: "3",
                gtmAnswer: '',
            },
            {
                label: "Shopped around for a better deal",
                value: "4",
                gtmAnswer: '',
            },
            {
                label: "Refused to take the car finance due to the increased cost",
                value: "5",
                gtmAnswer: '',
            }
        ],
    },
    question_3: {
        questionId: '3',
        question: "When did you purchase the vehicle?",
        gtmQuestion: "",
        gtm_trigger: false,
        clickevent: 'slideclick',
        answers: [
            {
                label: "On or before 27th January 2021",
                value: "6",
                gtmAnswer: '',
            },
            {
                label: "On or after 28th January 2021",
                value: "7",
                gtmAnswer: '',
            },

        ],
    }
}

const questionnaire_TCG_L_C = {
    question_1: {
        questionId: 1,
        question: "Did the car dealership explain they would receive a commission for selling you the car finance?",
        gtmQuestion: "commission received",
        gtm_trigger: true,
        clickevent: 'slideclick',
        answers: [
            {
                label: "No",
                value: "2",
                gtmAnswer: ''
            },
            {
                label: "Yes",
                value: "1",
                gtmAnswer: '',
            }
        ],

    },
    question_2: {
        questionId: '2',
        question: "Your lenders may have allowed the car dealership to increase the cost of your car finance to earn a higher commission, if you had known this would you have",
        gtmQuestion: "higher commission",
        gtm_trigger: true,
        clickevent: 'slideclick',
        answers: [
            {
                label: "Asked the car dealership if they could reduce the cost",
                value: "3",
                gtmAnswer: '',
            },
            {
                label: "Shopped around for a better deal",
                value: "4",
                gtmAnswer: '',
            },
            {
                label: "Refused to take the car finance due to the increased cost",
                value: "5",
                gtmAnswer: '',
            }
        ],
    },
    question_3: {
        questionId: '3',
        question: "When did you purchase the vehicle?",
        gtmQuestion: "",
        gtm_trigger: false,
        clickevent: 'slideclick',
        answers: [
            {
                label: "On or before 27th January 2021",
                value: "6",
                gtmAnswer: '',
            },
            {
                label: "On or after 28th January 2021",
                value: "7",
                gtmAnswer: '',
            },

        ],
    }
}

const questionnaire_TCG_L_D = {
    question_1: {
        questionId: '1',
        question: "When did you purchase the vehicle?",
        gtmQuestion: "",
        gtm_trigger: false,
        clickevent: 'slideclick',
        answers: [
            {
                label: "On or before 27th January 2021",
                value: "6",
                gtmAnswer: '',
            },
            {
                label: "On or after 28th January 2021",
                value: "7",
                gtmAnswer: '',
            },

        ],
    }
}


export {
    questionnaire_TCG_L_A,
    questionnaire_TCG_L_B,
    questionnaire_TCG_L_C,
    questionnaire_TCG_L_D
};
