import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";

const FollowUpLoader = (props) => {
  return (
    <>
      <div className={`loading-step ${props.status}`}>
        <div className="loading-spinner">
          <LogoBasedOnDomain className="loader_logo" bg="black" />
        </div>
        <div className="overlay ">
          <p className="fw-bold showBefore_add ">Analysing your results
          </p>
        </div>
      </div>
    </>
  )
}


export default FollowUpLoader;