import React, { useState } from 'react';
import {getBrandName} from "../../../Includes/Layouts/Common/BrandBasedOnDomain";

const Footer = (props) => {
  const brandName = getBrandName();

  return (
    <>

      <footer className="car-footer-style">
        <div className="container text-center">
          <div className="row justify-content-center">
            {props.footerEnable && <div className="col-lg-12 col-12 text-lg-start text-center">
              <p>Copyright ©2024 {brandName}. All rights reserved.</p>
              <p>{brandName} is a trading style of The Claims Guys Legal. The Claims Guys Legal Limited is authorised and regulated by the Solicitors Regulation Authority (SRA Number: 642517).</p>
              <p>*<a href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf" target="_blank" rel="noopener noreferrer">https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf</a></p>
              <ul>
                <li>
                  <a href="https://theclaimsguyslegal.com/wp-content/uploads/2022/10/TCGL_PP_V03_09_22.pdf" target='_blank'>Privacy Policy</a>
                </li>
              </ul>
            </div>}
            {!props.footerEnable && <div className="col-lg-12  text-lg-center ">
              <p>Copyright ©2024 {brandName}. All rights reserved.</p>
            </div>}
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;