import React, { useReducer, useState, useContext, useRef, useEffect } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import HiddenField from "../../../UI/HiddenField";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import Button from "../../../UI/Button";
import { slideValidation } from "../../../../Utility/Validations";
import InputButton from "../../../UI/InputButton";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";
import { usePostCodeAddressCustomApi } from '../../../../Hooks/usePostCodeAddressCustomApi';

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};
const PostCode = ({
  validation,
  validationMsg,
  clearErrors,
  setError,
  slideChange,
  trigger,
  className,
  previousSlideChange,
}) => {
  const { getPostcodeAddressApi } = usePostCodeAddressCustomApi();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [manualAddress, setManualAddress] = useState(false);
  const [getAddressDetails, setGetAddressDetails] = useState([]);
  const scrollToPcBtn = useRef(null);
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    setManualAddress(false);
    const txtPostCode = await trigger("txtPostCode");
    console.log(txtPostCode);
    if (txtPostCode) {
      try {
        const response = await getPostcodeAddressApi(e.target.value);
        console.log('response', response);
        if (response.length > 0) {
          setGetAddressDetails(response);
          dispatch({
            type: "validationSucess",
            payload: {
              lookup: false,
              next_click: false,
              postcode: e.target.value,
              get_address: response,
              check_select: true,
              manual_address: "d-none",
            },
          });
        } else {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        }
      } catch (error) {
        console.error('Error fetching postcode details:', error);
        setError("txtPostCode", {
          type: "manual",
          message: "Please Enter Valid Postcode",
        });
      }
    }
  };


  useEffect(() => {
    localStorage.removeItem("userSelectedAddress")
  }, [])

  const getValue = async (e) => {
    e.preventDefault();
    setManualAddress(false);
    dispatch({
      type: "getExtraAddressDetails",
      payload: { get_details: [], manual_address: "hide" },
    });
    setPostCodeAddress([]);
    clearErrors([
      "txtHouseNumber",
      "txtAddress3",
      "txtCounty",
      "txtTown",
      "txtCountry"
    ]);
    // check for duplicate address
    const { name, value } = e.target;
    let storedData = JSON.parse(localStorage.getItem('userSelectedAddress')) || {};

    for (let key in storedData) {
      if (storedData[key] === value && key !== name) {
        setError(name, {
          type: "manual",
          message: "The Address cannot be the same as previously entered",
        });
        dispatch({
          type: "getExtraAddressDetails",
          payload: { get_details: [], manual_address: "d-none" },
        });
        setPostCodeAddress([]);

        delete storedData[name];
        localStorage.setItem('userSelectedAddress', JSON.stringify(storedData));
        e.target.value = "";

        return;
      }
    }

    clearErrors(name);
    storedData[name] = value;
    localStorage.setItem('userSelectedAddress', JSON.stringify(storedData));
    //////////////////
    if (e.target.value != "") {
      const getExtraAddressData = getAddressDetails.find(address => address.id === e.target.value);
      console.log(getExtraAddressData.RawAddress.BuildingNumber);
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getExtraAddressData, manual_address: "show", next_click: true },
      });
      setPostCodeAddress(getExtraAddressData);
      clearErrors([
        "txtHouseNumber",
        "txtAddress3",
        "txtCounty",
        "txtTown",
        "txtCountry",
      ]);
      setTimeout(() => {
        scrollToPcBtn.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 100);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookupValidation = async (e) => {
    await trigger("txtPostCode");
  };



  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
    </>
  );

  const handleOnchange = (e) => {
    setManualAddress(false);
    dispatch({
      type: "resetaddress",
      payload: { get_details: [], manual_address: "hide" },
    });
    setPostCodeAddress([]);
  };

  const validatePostcodeSlide = async (e) => {
    var validationArr = ["txtPostCode", "address1", "txtHouseNumber"];
    const validationCheck = await slideValidation(validationArr, trigger);
    console.log(validationCheck, "validationCheck");
    if (validationCheck == "Success") {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'postcode',
        answer: '',
      });
      slideChange(e);
    }
  };

  return (
    <>
      <div className={`slide slide3 ${className}`}>
        <HiddenField
          name="pCode_EntryType"
          inputId="pCode_EntryType"
          inputValue={postCodeEntry}
        />
        <div className="form-style">
          <h2 className="mb-3  title mt-2  animate__animated animate__fadeInUp ">
            Registered Address
          </h2>
          <div className="row">
            <div className="col-lg-8  mb-3 animate__animated animate__fadeInUp  ">
              <div className="">
                <AddressTextField
                  type="text"
                  placeholder="Enter Your Postcode"
                  name="txtPostCode"
                  id="txtPostCode"
                  className="form-control"
                  onBlur={(e)=>{e.target.value = e.target.value.trim();checkValidation(e);}}
                  autoComplete="off"
                  onChange={() => {
                    clearErrors("txtPostCode");
                    handleOnchange();
                  }}
                  validation={validation({
                    required: "Please Enter Postcode",
                    pattern: {
                      value:
                        /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                      message: "Please Enter a Valid Postcode",
                    },
                    minLength: {
                      value: 5,
                      message: "Please Enter a Valid Postcode",
                    },
                  })}
                  validationMsg={
                    validationMsg.txtPostCode &&
                    validationMsg.txtPostCode.message
                  }
                />
              </div>
            </div>
            <div className="col-lg-4 ps-lg-0 mb-2 animate__animated animate__fadeInUp  ">
              <Button
                type="button"
                name={`postcode`}
                id={`next_postcode`}
                className="btn-comm p-2 w-100  lookup-btn"
                buttonText="Look Up Address"
                onClick={lookupValidation}
              />
            </div>
            {state.checkSelect && (
              <div className="col-lg-12 mb-2" id="LookupAddress">
                <AddressSelectBox
                  className="form-select watermark anim_ylw animated-effect"
                  OptionValue={state.getAddress}
                  name="address1"
                  id="address1"
                  onChange={(e) => {
                    getValue(e);
                    clearErrors("address1");
                  }}
                  myRef={validation({ required: "Please select address" })}
                  validationMsg={
                    validationMsg.address1 &&
                    validationMsg.address1.message
                  }
                />
              </div>
            )}
            <div
              className={`col-lg-12 ${state.showManualAddress}`}
              id="fullAddress"
            >
              {state.getDetails.line_1 != '' && <div className=" mb-2">
                <AddressTextField
                  type="text"
                  placeholder="Address Line 1 *"
                  name="txtHouseNumber"
                  id="txtHouseNumber"
                  className="form-control  hero-input"
                  dataId="txtHouseNumber"
                  autoComplete="off"
                  readonly={true}
                  validation={validation({
                    required: "Please Enter Address Line 1",
                  })}
                  validationMsg={
                    validationMsg.txtHouseNumber &&
                    validationMsg.txtHouseNumber.message
                  }
                  value={state.getDetails.line_1}
                  onChange={() => {
                    clearErrors("txtHouseNumber");
                  }}
                />
              </div>
              }
              {state.getDetails.line_2 != '' && <div className=" mb-2">
                <AddressTextField
                  type="text"
                  placeholder="Address Line 2"
                  name="txtAddress2"
                  id="txtAddress2"
                  className="form-control  hero-input"
                  dataId="txtAddress2"
                  autoComplete="off"
                  readonly={true}
                  validation={validation}
                  onChange={() => {
                    clearErrors("txtAddress2");
                  }}
                  value={state.getDetails.line_2}
                />
              </div>
              }
              {state.getDetails.line_3 != '' && <div className=" mb-2">
                <AddressTextField
                  type="text"
                  placeholder="Address Line 3"
                  name="txtAddress3"
                  id="txtAddress3"
                  className="form-control  hero-input"
                  dataId="txtAddress3"
                  autoComplete="off"
                  readonly={true}
                  validation={validation}
                  onChange={() => {
                    clearErrors("txtAddress3");
                  }}
                  value={state.getDetails.line_3}
                />
              </div>
              }
              {state.getDetails.county != '' && <div className={`col-lg-12 form-group adj_width mb-1`}>
                <AddressTextField
                  type="text"
                  placeholder="County"
                  name="txtCounty"
                  id="txtCounty"
                  className="form-control  hero-input"
                  dataId="txtCounty"
                  autoComplete="off"
                  readonly={true}
                  value={state.getDetails.county}
                  onChange={() => {
                    clearErrors("county");
                  }}
                  validation={validation}
                />
              </div>
              }
              {state.getDetails.town != '' && <div className={`col-lg-12 form-group adj_width mb-1`}>
                <AddressTextField
                  type="text"
                  placeholder="Town *"
                  name="txtTown"
                  id="txtTown"
                  className="form-control  hero-input"
                  dataId="txtTown"
                  autoComplete="off"
                  readonly={true}
                  validation={validation}
                  value={state.getDetails.town}
                  onChange={() => {
                    clearErrors("txtTown");
                  }}
                />
              </div>
              }
              {state.getDetails.country != '' && <div className={`col-lg-12 form-group adj_width mb-1`}>
                <AddressTextField
                  type="text"
                  placeholder="Country *"
                  name="txtCountry"
                  id="txtCountry"
                  className="form-control  hero-input"
                  dataId="txtCountry"
                  autoComplete="off"
                  readonly={true}
                  validation={validation}
                  value={state.getDetails.country}
                  onChange={() => {
                    clearErrors("txtCountry");
                  }}
                />
              </div>}
              <div className="d-none col-lg-12 form-group adj_width mb-1">
                <AddressTextField
                  type="text"
                  placeholder="Building Name"
                  name="txtBuildingName"
                  id="txtBuildingName"
                  className="form-control address hero-input"
                  dataId="txtBuildingName"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.BuildingName}
                  readonly={true}
                />
              </div>
              <div className="d-none col-lg-12 form-group adj_width mb-1">
                <AddressTextField
                  type="text"
                  placeholder="Building Number"
                  name="txtBuildingNumber"
                  id="txtBuildingNumber"
                  className="form-control address hero-input"
                  dataId="txtBuildingNumber"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.BuildingNumber}
                  readonly={true}
                />
              </div>
              <div className="d-none col-lg-12 form-group adj_width mb-1">
                <AddressTextField
                  type="text"
                  placeholder="Thoroughfare"
                  name="txtThoroughfare"
                  id="txtThoroughfare"
                  className="form-control address hero-input"
                  dataId="txtThoroughfare"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.ThoroughfareName}
                  readonly={true}
                />
              </div>
              <div className="d-none col-lg-12 form-group adj_width mb-1">
                <AddressTextField
                  type="text"
                  placeholder="Sub building name"
                  name="txtSubBuildingName"
                  id="txtSubBuildingName"
                  className="form-control address hero-input"
                  dataId="txtSubBuildingName"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.SubBuildingName}
                  readonly={true}
                />
              </div>
              <div className="d-none col-lg-12 form-group adj_width mb-1">
                <AddressTextField
                  type="text"
                  placeholder="Sub building number"
                  name="txtSubBuildingNumber"
                  id="txtSubBuildingNumber"
                  className="form-control address hero-input"
                  dataId="txtSubBuildingNumber"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.SubBuildingNumber}
                  readonly={true}
                />
              </div>
              <div className="d-none mb-2">
                <AddressTextField
                  type="text"
                  placeholder="Address Line 4"
                  name="txtAddress4"
                  id="txtAddress4"
                  className="form-control  hero-input"
                  dataId="txtAddress4"
                  autoComplete="off"
                  readonly={true}
                  validation={validation}
                  onChange={() => {
                    clearErrors("txtAddress4");
                  }}
                  value={state.getDetails.line_4}
                />
              </div>
              <div className="d-none col-lg-12 form-group adj_width mb-1">
                <AddressTextField
                  type="text"
                  placeholder="Locality"
                  name="txtLocality"
                  id="txtLocality"
                  className="form-control address hero-input"
                  dataId="txtLocality"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.Locality}
                  readonly={true}
                />
              </div>
              <div className="d-none col-lg-12 form-group adj_width mb-1">
                <AddressTextField
                  type="text"
                  placeholder="District"
                  name="txtDistrict"
                  id="txtDistrict"
                  className="form-control address hero-input"
                  dataId="txtDistrict"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.Location?.District}
                  readonly={true}
                />
              </div>
              <div className="d-none col-lg-12 form-group adj_width mb-1">
                <AddressTextField
                  type="text"
                  placeholder="Thoroughfare Description"
                  name="txtThoroughfareDesc"
                  id="txtThoroughfareDesc"
                  className="form-control address hero-input"
                  dataId="txtThoroughfareDesc"
                  autoComplete="off"
                  validation={validation}
                  value={state.getDetails?.RawAddress?.ThoroughfareDesc}
                  readonly={true}
                />
              </div>
              <div className="d-none">
                <AddressTextField
                  type="text"
                  name="txtOrganisation"
                  id="txtOrganisation"
                  value={state.getDetails?.RawAddress?.Organisation}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtDepartment"
                  id="txtDepartment"
                  value={state.getDetails?.RawAddress?.Department}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtAddressKey"
                  id="txtAddressKey"
                  value={state.getDetails?.RawAddress?.AddressKey}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtOrganisationKey"
                  id="txtOrganisationKey"
                  value={state.getDetails?.RawAddress?.OrganisationKey}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtPostcodeType"
                  id="txtPostcodeType"
                  value={state.getDetails?.RawAddress?.PostcodeType}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtDependentThoroughfareName"
                  id="txtDependentThoroughfareName"
                  value={state.getDetails?.RawAddress?.DependentThoroughfareName}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtDependentThoroughfareDesc"
                  id="txtDependentThoroughfareDesc"
                  value={state.getDetails?.RawAddress?.DependentThoroughfareDesc}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtDoubleDependentLocality"
                  id="txtDoubleDependentLocality"
                  value={state.getDetails?.RawAddress?.DoubleDependentLocality}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtDependentLocality"
                  id="txtDependentLocality"
                  value={state.getDetails?.RawAddress?.DependentLocality}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtDps"
                  id="txtDps"
                  value={state.getDetails?.RawAddress?.Dps}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtPoBox"
                  id="txtPoBox"
                  value={state.getDetails?.RawAddress?.PoBox}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtPostalCounty"
                  id="txtPostalCounty"
                  value={state.getDetails?.RawAddress?.PostalCounty}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtTraditionalCounty"
                  id="txtTraditionalCounty"
                  value={state.getDetails?.RawAddress?.TraditionalCounty}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtAdministrativeCounty"
                  id="txtAdministrativeCounty"
                  value={state.getDetails?.RawAddress?.AdministrativeCounty}
                  validation={validation}
                />
                <AddressTextField
                  type="text"
                  name="txtCountryISO2"
                  id="txtCountryISO2"
                  value={state.getDetails?.RawAddress?.CountryISO2}
                  validation={validation}
                />
              </div>
              {Object.keys(state.getDetails).length > 0 ? (
                <HiddenFields />
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="pb-2  animate__animated animate__fadeInUp "
          ref={scrollToPcBtn}
        >
          <Button
            type="button"
            className="mt-3 btn-comm p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3  progress-button fw-bold"
            id="postcode"
            onClick={validatePostcodeSlide}
            name={`postcode`}
            buttonText={`Next >`}
          />
          <Button
            name="back02"
            className="mt-3 btn center-block back-but sctop"
            id="backStep1"
            buttonText="< Back"
            type="button"
            style={{ float: "none" }}
            onClick={previousSlideChange}
          />
        </div>
      </div>
    </>
  );
};
export default PostCode;
